<div class="wrapper">
  <div class="container">
    <div class="filter card">
      <app-select
        [id]="'skill-map-filter'"
        [options]="this.skillMapOptions"
        [selectedOption]="this.selectedSkillMap"
        (selectedOptionChange)="this.onSkillMapChange($event)"
      ></app-select>
      <app-select
        [id]="'key-action-filter'"
        [options]="this.keyActionOptions"
        [selectedOption]="this.selectedKeyAction"
        (selectedOptionChange)="this.onKeyActionChange($event)"
      ></app-select>
      <app-select
        [id]="'department-filter'"
        [options]="this.departmentOptions"
        [selectedOption]="this.selectedDepartment"
        (selectedOptionChange)="this.onDepartmentChange($event)"
      ></app-select>
    </div>
    <div class="page-header">
      <div class="card org-attribute">
        <div class="company-icon">
          <mat-icon svgIcon="company"> </mat-icon>
        </div>
        <div class="name-plate">
          <div class="name">{{ this.viewGroup?.name }}</div>
        </div>
      </div>
      <div class="org-score card">
        <div class="org-score-header">
          <div class="title">
            最新{{
              !this.selectedKeyAction || this.selectedKeyAction.id === 'all'
                ? '総合'
                : 'キーアクション'
            }}スコア
          </div>
          <div class="between">
            集計期間：{{ this.startDate | date: 'yyyy/MM/dd' }} ~
            {{ this.endDate | date: 'yyyy/MM/dd' }}
          </div>
        </div>
        <div class="score">{{ this.average | number: '1.2-2' }}</div>
      </div>
    </div>

    <div class="score-transition card">
      <div class="score-transition-header">
        <div class="score-transition-title">キーアクション別スコア推移</div>
        <div class="right-section">
          <div class="view-range">
            <label class="label" for="view-range">表示期間</label>
            <div class="value">
              <app-select
                [id]="'view-range'"
                [options]="this.rangeOptions"
                (selectedOptionChange)="this.onRangeChange($event)"
              ></app-select>
            </div>
          </div>
        </div>
      </div>
      <div class="score-transition-chart">
        <app-time-series-chart
          [data]="this.timeSeriesData"
        ></app-time-series-chart>
        <app-loading [showLoading]="this.isLoading"></app-loading>
      </div>
    </div>
    <div class="score-distribution card">
      <div class="score-distribution-header">
        <div class="score-transition-title">スコア分布</div>
      </div>
      <div class="score-distribution-chart">
        <app-pie-chart
          [data]="this.distributionData"
          [average]="this.average"
        ></app-pie-chart>
        <app-loading [showLoading]="this.isLoading"></app-loading>
      </div>
    </div>
  </div>
</div>
