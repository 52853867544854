import { Injectable } from '@angular/core'

import { map, Observable } from 'rxjs'

import {
  SkillMap,
  mappingAssessment,
  mappingAssessmentSummary,
  mappingSkillMap,
} from './assessment.mapping'
import {
  CreateAssessmentGQL,
  ArchiveAssessmentGQL,
  ArchiveAssessmentsGQL,
  GetAssessmentGQL,
  GetSkillMapsGQL,
  SearchAssessmentsGQL,
  SearchAssessmentStatus,
  GetAccountsGQL,
  MarkContentAsAccessedGQL,
  ExportAssessmentsGQL,
} from '../../../_generated/graphql'

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  constructor(
    private getAssessmentGQL: GetAssessmentGQL,
    private searchAssessmentsGQL: SearchAssessmentsGQL,
    private getSkillMapsGQL: GetSkillMapsGQL,
    private createAssessmentGQL: CreateAssessmentGQL,
    private getAccountsGQL: GetAccountsGQL,
    private markContentAsAccessedGQL: MarkContentAsAccessedGQL,
    private exportAssessmentsGQL: ExportAssessmentsGQL,
    private archiveAssessmentGQL: ArchiveAssessmentGQL,
    private archiveAssessmentsGQL: ArchiveAssessmentsGQL,
  ) {}

  getSkillMaps(): Observable<SkillMap[]> {
    return this.getSkillMapsGQL.fetch().pipe(
      map((res) => {
        return res.data.getSkillMaps.map((skillMap) => {
          return mappingSkillMap(skillMap)
        })
      }),
    )
  }

  getAccounts() {
    return this.getAccountsGQL
      .fetch(undefined, {
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => {
          return res.data?.getAccounts.map((account) => {
            return { name: account.name }
          })
        }),
      )
  }

  getAssessment(assessmentId: string) {
    return this.getAssessmentGQL
      .fetch(
        { assessmentId: assessmentId },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => {
          const assessment = res.data?.getAssessment

          if (!assessment) {
            alert('Assessment not found')
            throw new Error('Assessment not found')
          }
          return mappingAssessment(assessment)
        }),
      )
  }

  searchAssessments(
    query: string,
    pagination: { limit: number; offset: number },
    status: 'ALL' | 'PROGRESS_STATUSES',
    sort?: { sortField: string; sortOrder: 'asc' | 'desc' },
    useCache = false,
  ) {
    return this.searchAssessmentsGQL
      .fetch(
        {
          query,
          pagination,
          status:
            status === 'ALL'
              ? SearchAssessmentStatus.All
              : SearchAssessmentStatus.ProgressStatuses,
          sort,
        },
        {
          fetchPolicy: useCache ? 'cache-first' : 'network-only',
        },
      )
      .pipe(
        map((res) => {
          return res.data.searchAssessments.map((assessment) => {
            return mappingAssessmentSummary(assessment)
          })
        }),
      )
  }

  createAssessment(param: {
    userId: string
    businessMeetingName: string
    accountName: string | null
    recordDate: Date
    fileName: string
    skillMapId: string
    phaseIds: string[]
  }) {
    return this.createAssessmentGQL.mutate({
      input: {
        userId: param.userId,
        title: param.businessMeetingName,
        accountName: param.accountName,
        recordDate: param.recordDate,
        fileName: param.fileName,
        skillMapId: param.skillMapId,
        phaseIds: param.phaseIds,
      },
    })
  }

  markContentAsAccessed(recommendedContentId: string) {
    return this.markContentAsAccessedGQL.mutate({
      recommendedContentId,
    })
  }

  exportAssessments() {
    return this.exportAssessmentsGQL.fetch()
  }

  deleteAssessment(assessmentId: string) {
    return this.archiveAssessmentGQL.mutate({ assessmentId })
  }

  deleteAssessments(assessmentIds: string[]) {
    return this.archiveAssessmentsGQL.mutate({ assessmentIds })
  }
}
