import { CommonModule } from '@angular/common'
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core'
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatDialogRef } from '@angular/material/dialog'
import { MatIcon } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'

import {
  Observable,
  catchError,
  debounceTime,
  of,
  startWith,
  switchMap,
  tap,
  finalize,
} from 'rxjs'

import { ButtonComponent } from '../../../../components/button/button.component'
import { DateInputComponent } from '../../../../components/date-input/date-input.component'
import { FileUploaderComponent } from '../../../../components/file-uploader/file-uploader.component'
import { MessageDialogService } from '../../../../components/message-dialog/message-dialog.service'
import {
  Item,
  MultipleSelectComponent,
} from '../../../../components/multiple-select/multiple-select.component'
import {
  Option,
  SelectComponent,
} from '../../../../components/select/select.component'
import {
  SUPPORTED_TEXT_EXTENSIONS,
  SUPPORTED_AUDIO_EXTENSIONS,
} from '../../../../util/file/constants'
import {
  calculateAudioDuration,
  isAudioFile,
} from '../../../../util/file/audio'
import { readFileAsText } from '../../../../util/file/reader'
import {
  isZoomTranscriptFormat,
  validateZoomTranscriptFormat,
  calculateZoomTextDuration,
} from '../../../../util/file/zoom'
import {
  isAmptalkTranscriptFormat,
  validateAmptalkTranscriptFormat,
  calculateAmptalkTextDuration,
} from '../../../../util/file/amptalk'
import {
  isValidFileExtension,
  isValidFileSize,
} from '../../../../util/file/validate'

import { SkillMap } from '../../../../services/assessment/assessment.mapping'
import { User } from '../../../../services/user/user.mapping'
import { UserLicense } from '../../../../services/user/user.service'
import { AssessmentStore } from '../../../../stores/assessment.store'
import { LoadingComponent } from '../../../../components/loading/loading.component'

@Component({
  templateUrl: './file-information-input.component.html',
  standalone: true,
  imports: [
    MatIcon,
    ReactiveFormsModule,
    CommonModule,
    MatTooltipModule,
    DateInputComponent,
    ButtonComponent,
    FileUploaderComponent,
    MatAutocompleteModule,
    SelectComponent,
    MultipleSelectComponent,
    LoadingComponent,
  ],
  providers: [AssessmentStore],
  styleUrls: ['./file-information-input.component.scss'],
})
export class FileInformationInputComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>

  @Input() user: User | null = null
  @Input() skillMaps: SkillMap[] = []
  @Input() accounts: { name: string }[] = []
  @Input() userLicense: UserLicense | null = null

  file: File | null = null
  fileDuration: number | null = 0

  businessMeetingName = new FormControl()
  accountName = new FormControl()
  recordDate = new FormControl()

  skillMapOptions: Option[] = []
  selectedSkillMap: Option = { id: '', name: '' }
  phaseItems: Item[] = []

  submitted = false

  filteredAccounts: Observable<{ name: string }[]> =
    this.accountName.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((val) => {
        const lowerCaseVal = val.toLowerCase()
        return of(
          this.accounts.filter((account) =>
            account.name.toLowerCase().includes(lowerCaseVal),
          ),
        )
      }),
    )

  private formGroup = this.formBuilder.group({
    businessMeetingName: this.businessMeetingName,
    accountName: this.accountName,
    recordDate: this.recordDate,
  })

  constructor(
    private matDialogRef: MatDialogRef<FileInformationInputComponent>,
    private formBuilder: FormBuilder,
    private assessmentStore: AssessmentStore,
    private messageDialogService: MessageDialogService,
  ) {}

  ngOnInit(): void {
    this.businessMeetingName.setValidators(Validators.required)
    this.recordDate.setValidators(Validators.required)

    this.skillMapOptions = this.skillMaps.map((skillMap) => ({
      id: skillMap.id,
      name: skillMap.name,
    }))
  }

  get acceptFileExtensions(): string[] {
    if (this.userLicense?.plan === 'ADVANCED') {
      return [...SUPPORTED_TEXT_EXTENSIONS, ...SUPPORTED_AUDIO_EXTENSIONS]
    } else {
      return SUPPORTED_TEXT_EXTENSIONS
    }
  }

  get inputValid(): boolean {
    return (
      this.formGroup.valid &&
      !!this.selectedSkillMap.id &&
      this.phaseItems.some((item) => item.checked) &&
      this.file !== null
    )
  }

  isAudioFile(file: File | null): boolean {
    if (!file) {
      return false
    }
    return isAudioFile(file)
  }

  getFileSizeWithUnit(fileSize: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    let unitIndex = 0
    while (fileSize >= 1024 && unitIndex < units.length - 1) {
      fileSize /= 1024
      unitIndex++
    }
    return `${fileSize.toFixed(2)} ${units[unitIndex]}`
  }

  remainsHours(): number {
    if (!this.userLicense) {
      return 0
    }

    const remainingSeconds =
      this.userLicense.userMonthlyLimitSeconds +
      this.userLicense.extraChargeLimitSeconds -
      this.userLicense.usedSeconds
    const remainingHours = Math.floor((remainingSeconds / 3600) * 10) / 10
    return remainingHours
  }

  formatDuration(duration: number | null): string {
    if (duration === null) return '記録期間を取得できませんでした。'

    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor((duration % 3600) / 60)
    const seconds = duration % 60
    return `${hours}:${String(minutes).padStart(2, '0')}:${String(
      seconds,
    ).padStart(2, '0')}`
  }

  async onFileUploaded(files: File[]) {
    const file = files[0]
    if (!file) {
      return
    }

    const isValid = await this.validateFile(file)
    if (isValid) {
      this.file = file
      if (!this.recordDate.value)
        this.recordDate.setValue(new Date(file.lastModified))
    }
  }

  close(): void {
    this.matDialogRef.close()
  }

  changeSkillMap(option: Option): void {
    this.selectedSkillMap = this.skillMapOptions.find(
      (op) => op.id === option.id,
    ) ?? { id: '', name: '' }
    this.phaseItems =
      this.skillMaps
        .find((skillMap) => skillMap.id === option.id)
        ?.phases.map((phase) => ({
          id: phase.id,
          label: phase.name,
          checked: false,
        })) ?? []
  }

  changeSelectedPhases(items: Item[]): void {
    this.phaseItems = items
  }

  executeAssessment(): void {
    if (this.submitted) {
      return
    }

    if (!this.file) {
      throw new Error('File is not selected')
    }

    if (!this.userLicense) {
      this.messageDialogService.showError(
        'ライセンスのないユーザーに対してアセスメントを実行することはできません。',
        {
          title: 'ライセンスエラー',
        },
      )
      return
    }

    const remainingSeconds =
      this.userLicense?.userMonthlyLimitSeconds +
      this.userLicense?.extraChargeLimitSeconds -
      this.userLicense?.usedSeconds

    if (remainingSeconds <= 0) {
      this.messageDialogService.showError(
        '残りアップロード時間がありません。アップロード可能時間を購入してから再度お試しください。',
        {
          title: 'アップロード時間切れ',
        },
      )
      return
    }

    this.submitted = true
    this.assessmentStore
      .executeAssessment(
        this.user?.id ?? '',
        this.user?.orgId ?? '',
        this.businessMeetingName.value,
        this.accountName.value,
        this.recordDate.value,
        `${this.file?.name}`,
        this.selectedSkillMap.id,
        this.phaseItems
          .filter((item) => item.checked)
          .map((item) => item.id ?? ''),
        this.file,
      )
      .pipe(
        tap((assessmentId) => {
          this.matDialogRef.close(assessmentId)
        }),
        catchError((error) => {
          console.error('Assessment execution failed', error)
          alert('アセスメントの実行に失敗しました。再試行してください。')
          return of(error)
        }),
        finalize(() => {
          this.submitted = false
        }),
      )
      .subscribe()
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: BeforeUnloadEvent): void {
    if (this.submitted) {
      event.preventDefault()
    }
  }

  private async validateFile(file: File): Promise<boolean> {
    if (!isValidFileExtension(file, this.acceptFileExtensions)) {
      this.messageDialogService.showError(
        `サポートされていないフォーマットがアップロードされました。\n対応フォーマットを確認してください。`,
        {
          title: 'ファイル形式エラー',
        },
      )
      return false
    }

    if (file.size === 0) {
      this.messageDialogService.showError(
        'ファイルの中身が空です。\nファイルを確認して、再度アップロードしてください。',
        {
          title: 'ファイルサイズエラー',
        },
      )
      return false
    }

    if (!isValidFileSize(file)) {
      this.messageDialogService.showError(
        'ファイルサイズが1GBを超えています。\n1GB以下のファイルをアップロードしてください。',
        {
          title: 'ファイルサイズエラー',
        },
      )
      return false
    }

    if (isAudioFile(file)) {
      const duration = await calculateAudioDuration(file)
      this.fileDuration = duration
      return true
    }

    const content = await readFileAsText(file)
    let duration: number | null = null

    if (isZoomTranscriptFormat(content)) {
      if (!validateZoomTranscriptFormat(content)) {
        this.messageDialogService.showError(
          'ファイルの形式が正しくありません。\nZoomの書き起こし形式のファイルをアップロードしてください。',
          {
            title: 'ファイルフォーマットエラー',
          },
        )
        return false
      }
      duration = calculateZoomTextDuration(content)
    } else if (isAmptalkTranscriptFormat(content)) {
      if (!validateAmptalkTranscriptFormat(content)) {
        this.messageDialogService.showError(
          'ファイルの形式が正しくありません。\nAmptalkの書き起こし形式のファイルをアップロードしてください。',
          {
            title: 'ファイルフォーマットエラー',
          },
        )
      }
      duration = calculateAmptalkTextDuration(content)
    } else {
      this.messageDialogService.showError(
        'ファイルの形式が正しくありません。\nAmptalkもしくはZoomの書き起こし形式のファイルをアップロードしてください。',
        {
          title: 'ファイルフォーマットエラー',
        },
      )
      return false
    }

    this.fileDuration = duration
    return true
  }
}
