import { CommonModule } from '@angular/common'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { Title } from '@angular/platform-browser'

import { MatIcon } from '@angular/material/icon'
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'
import { MatTooltip } from '@angular/material/tooltip'

import { forkJoin, of, finalize } from 'rxjs'
import { tap, switchMap, catchError } from 'rxjs/operators'

import { ButtonComponent } from '../../../components/button/button.component'
import {
  SelectComponent,
  Option,
} from '../../../components/select/select.component'
import {
  MultipleSelectComponent,
  Item,
} from '../../../components/multiple-select/multiple-select.component'
import { AvatarComponent } from '../../../components/avatar/avatar.component'
import { LoadingComponent } from '../../../components/loading/loading.component'
import { EllipsisTooltipDirective } from '../../../directives/ellipsis-tooltip.directive'

import {
  AmptalkIntegrationComponent,
  AmptalkIntegrationData,
} from './amptalk-integration/amptalk-integration.component'
import { AmptalkIntegrationSuccessComponent } from './messages/amptalk-integration-success/amptalk-integration-success.component'
import { AmptalkIntegrationResetWarningComponent } from './messages/amptalk-integration-reset-warning/amptalk-integration-reset-warning.component'
import { AssessmentSettingSaveErrorComponent } from './messages/assessment-setting-save-error/assessment-setting-save-errorcomponent'
import {
  ExternalIntegrationService,
  SaveAutoAssessmentSettingParam,
} from '../../../services/external-integration/external-integration.service'

import { getTitle } from '../../../util/accessibility'
import { SkillMap } from '../../../services/assessment/assessment.mapping'
import { AssessmentStore } from '../../../stores/assessment.store'
import { UserStore } from '../../../stores/user.store'
import { UserForAdmin } from '../../../services/user/user.mapping'

interface UserRow {
  selected: boolean
  id: string
  name: string
  email: string
  thumbnailPath: string | null
  departmentName: string
  reportTo: string
  remainingHours: number | null
  hasLicense: boolean
}

@Component({
  selector: 'app-external-integration-page',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatCheckbox,
    MatSlideToggle,
    MatTableModule,
    MatTooltip,
    ButtonComponent,
    SelectComponent,
    MultipleSelectComponent,
    AvatarComponent,
    LoadingComponent,
    EllipsisTooltipDirective,
  ],
  templateUrl: './external-integration-page.component.html',
  styleUrl: './external-integration-page.component.scss',
})
export class ExternalIntegrationPageComponent implements OnInit, OnDestroy {
  isAmptalkIntegrationEnabled = false
  hasAmptalkIntegrationError = false
  isShowError = false
  isLoading = false

  amptalkSetting: {
    apiKey: string
    authKey: string
  } = {
    apiKey: '',
    authKey: '',
  }

  displayedColumns: string[] = [
    'select',
    'userName',
    'userDepartmentName',
    'reportTo',
    'remainingHours',
    'license',
  ]
  users: UserRow[] = []

  id: string | undefined = undefined
  syncStartDate: Date | undefined = undefined
  skillMaps: SkillMap[] = []
  skillMapOptions: Option[] = []
  selectedSkillMap: Option = { id: '', name: '' }
  phaseItems: Item[] = []
  minTransferDurationMinutes = ''

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userStore: UserStore,
    private assessmentStore: AssessmentStore,
    private externalIntegrationService: ExternalIntegrationService,
  ) {
    this.titleService.setTitle(getTitle('外部連携'))
  }

  ngOnInit() {
    const getAmptalkAutoAssessmentSetting = this.externalIntegrationService
      .getAmptalkAutoAssessmentSetting()
      .pipe(
        tap((setting) => {
          if (setting === null) {
            return
          }

          this.id = setting.id
          this.minTransferDurationMinutes =
            setting.minTransferDurationSeconds !== null
              ? String(setting.minTransferDurationSeconds / 60)
              : ''
          this.syncStartDate = setting.syncStartDate

          const targetUsers = setting.targetUsers.map((user) => ({
            ...user,
            hasLicense: user.license !== null,
          }))
          targetUsers.forEach((user) => {
            const row = this.users.find((row) => row.email === user.email)
            if (row) {
              row.selected = true
            }
          })

          this.changeSkillMap(
            this.skillMapOptions.find(
              (op) => op.id === setting.targetSkillMap.id,
            ) ?? { id: '', name: '' },
          )

          setting.targetPhaseIds.forEach((phaseId) => {
            const phaseItem = this.phaseItems.find(
              (item) => item.id === phaseId,
            )
            if (phaseItem) {
              phaseItem.checked = true
            }
          })
        }),
      )

    this.isLoading = true
    forkJoin([
      this.externalIntegrationService.getAmptalkKeys().pipe(
        tap((keys) => {
          this.amptalkSetting = keys
          this.isAmptalkIntegrationEnabled = !!keys.apiKey || !!keys.authKey
          this.hasAmptalkIntegrationError = !!keys.apiKey && !keys.authKey
        }),
      ),
      this.assessmentStore.getSkillMaps().pipe(
        tap((skillMaps) => {
          this.skillMaps = skillMaps
          this.skillMapOptions = skillMaps.map((skillMap) => ({
            id: skillMap.id,
            name: skillMap.name,
          }))
        }),
      ),
      this.userStore.searchUsersForAdmin(null, []).pipe(
        tap((users) => {
          this.users = users.map((user) => this.toUserRow(user))
        }),
      ),
    ])
      .pipe(
        switchMap(() => {
          return getAmptalkAutoAssessmentSetting
        }),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe()
  }

  ngOnDestroy() {
    this.snackBar.dismiss()
  }

  onAmptalkToggleChange(event: MatSlideToggleChange, slider: MatSlideToggle) {
    if (event.checked) {
      this.openAmptalkIntegrationDialog(slider)
    } else {
      this.openAmptalkResetWarningDialog(slider)
    }
  }

  onUpdateAmptalkIntegration(slider: MatSlideToggle) {
    this.openAmptalkIntegrationDialog(slider)
  }

  onAssessmentSettingSave() {
    this.isShowError = true

    if (
      !this.skillMapSelected() ||
      !this.phaseSelected() ||
      !this.userSelected() ||
      !this.isValidMinTransferDuration()
    ) {
      this.openAssessmentSettingSaveErrorDialog()
      return
    }

    this.isLoading = true
    this.externalIntegrationService
      .saveAmptalkAssessmentSetting(this.getSaveAutoAssessmentSettingParam())
      .pipe(
        tap(() => {
          this.snackBar.open(
            '設定が正常に保存されました。設定に基づいて、今後のアセスメントや同期が実行されます。',
            '閉じる',
          )
        }),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe()
  }

  changeSkillMap(option: Option): void {
    this.selectedSkillMap = this.skillMapOptions.find(
      (op) => op.id === option.id,
    ) ?? { id: '', name: '' }
    this.phaseItems =
      this.skillMaps
        .find((skillMap) => skillMap.id === option.id)
        ?.phases.map((phase) => ({
          id: phase.id,
          label: phase.name,
          checked: false,
        })) ?? []
  }

  changeSelectedPhases(items: Item[]): void {
    this.phaseItems = items
  }

  changeMinimumIntegrationDuration(event: Event): void {
    function toHalfWidth(str: string) {
      return str.replace(/[０-９]/g, function (ch) {
        return String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
      })
    }
    this.minTransferDurationMinutes = toHalfWidth(
      (event.target as HTMLInputElement).value,
    )
  }

  get checkedCount(): number {
    return this.users.filter((row) => row.selected)?.length
  }
  get checkedState(): 'CHECKED' | 'UNCHECKED' | 'INDETERMINATE' {
    const checkedCount =
      this.users.filter((assessment) => assessment.selected)?.length ?? 0
    if (checkedCount === 0) {
      return 'UNCHECKED'
    } else if (checkedCount === this.users.length) {
      return 'CHECKED'
    } else {
      return 'INDETERMINATE'
    }
  }

  skillMapSelected(): boolean {
    return this.selectedSkillMap.id !== ''
  }

  phaseSelected(): boolean {
    return this.phaseItems.filter((item) => item.checked).length > 0
  }

  isValidMinTransferDuration(): boolean {
    return /^[0-9]*$/.test(this.minTransferDurationMinutes)
  }

  userSelected(): boolean {
    return this.users.filter((user) => user.selected).length > 0
  }

  checkAllUsers = (checked: boolean) => {
    this.users.forEach((user) => {
      if (checked && !user.selected) {
        if (user.hasLicense) {
          user.selected = checked
        }
      } else if (!checked && user.selected) {
        user.selected = checked
      }
    })
  }

  checkChanged = (user: UserRow) => {
    user.selected = !user.selected
  }

  private openAmptalkIntegrationDialog(slider: MatSlideToggle) {
    this.dialog
      .open<AmptalkIntegrationComponent, AmptalkIntegrationData>(
        AmptalkIntegrationComponent,
        {
          disableClose: true,
          panelClass: 'unset-mat-dialog-padding',
          data: {
            apiKey: this.amptalkSetting.apiKey,
            authKey: this.amptalkSetting.authKey,
          },
        },
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          if (!this.isAmptalkIntegrationEnabled) {
            slider.checked = false
          }
          return
        }
        this.isAmptalkIntegrationEnabled = true
        this.hasAmptalkIntegrationError = false
        this.amptalkSetting.apiKey = result.apiKey
        this.amptalkSetting.authKey = result.authKey
        this.dialog.open(AmptalkIntegrationSuccessComponent)
      })
  }

  private openAmptalkResetWarningDialog(slider: MatSlideToggle) {
    this.dialog
      .open(AmptalkIntegrationResetWarningComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (result === 'reset') {
            return this.externalIntegrationService.disconnectAmptalk().pipe(
              tap(() => {
                slider.checked = false
                this.initializeAmptalkIntegration()
              }),
              catchError(() => {
                slider.checked = true
                return of(null)
              }),
            )
          } else {
            slider.checked = true
            return of(null)
          }
        }),
      )
      .subscribe()
  }

  private openAssessmentSettingSaveErrorDialog() {
    this.dialog.open(AssessmentSettingSaveErrorComponent, {
      disableClose: true,
      panelClass: 'unset-mat-dialog-padding',
    })
  }

  private toUserRow(user: UserForAdmin): UserRow {
    let remainingHours = null
    if (user.license) {
      const remainingSeconds =
        user.license.userMonthlyLimitSeconds -
        user.license.usedSeconds +
        user.license.extraChargeLimitSeconds
      remainingHours = Math.floor(remainingSeconds / 3600)
    }

    return {
      selected: false,
      id: user.id,
      name: user.name,
      email: user.email,
      thumbnailPath: user.thumbnailPath || null,
      departmentName: user.departmentName,
      reportTo: user.reportTo?.name || '',
      remainingHours: remainingHours,
      hasLicense: user.license !== null,
    }
  }

  private getSaveAutoAssessmentSettingParam(): SaveAutoAssessmentSettingParam {
    const phaseIds: string[] = []
    this.phaseItems.forEach((item) => {
      if (item.checked && item.id) {
        phaseIds.push(item.id)
      }
    })
    const targetUserIds = this.users
      .filter((user) => user.selected)
      .map((user) => user.id)
    return {
      id: this.id,
      syncStartDate: this.syncStartDate ?? new Date(),
      skillMapId: this.selectedSkillMap.id,
      phaseIds: phaseIds,
      targetUserIds: targetUserIds,
      minTransferDurationSeconds:
        this.minTransferDurationMinutes === ''
          ? null
          : Number(this.minTransferDurationMinutes) * 60,
    }
  }

  private initializeAmptalkIntegration() {
    this.isShowError = false
    this.isAmptalkIntegrationEnabled = false
    this.hasAmptalkIntegrationError = false
    this.amptalkSetting = {
      apiKey: '',
      authKey: '',
    }
    this.id = undefined
    this.syncStartDate = undefined
    this.skillMaps = []
    this.selectedSkillMap = { id: '', name: '' }
    this.phaseItems = []
    this.users.forEach((user) => {
      user.selected = false
    })
    this.minTransferDurationMinutes = ''
  }
}
