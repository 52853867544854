export const isZoomTranscriptFormat = (transcript: string): boolean => {
  const lines = transcript.split('\n').filter((line) => line.trim() !== '')
  if (lines.length < 2) return false

  // Zoomの特徴的な形式をチェック
  // - [話者名] HH:mm:ss の形式
  // 1行目が話者名の形式であるかどうかをチェック
  const speakerLineRegex = /^\[[^\]]+\]\s+\d{2}:\d{2}:\d{2}$/
  const firstLine = lines[0].trim()

  return speakerLineRegex.test(firstLine)
}

export const validateZoomTranscriptFormat = (transcript: string) => {
  const lines = transcript.split('\n').filter((line) => line.trim() !== '')
  const regexSpeakerLine = /^\[([^\]]+)\] \d{2}:\d{2}:\d{2}$/

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim()

    if (i % 2 === 0) {
      if (!regexSpeakerLine.test(line)) {
        return false
      }

      const parts = line.split(' ')
      const timestamp = parts[parts.length - 1]
      const [hours, minutes, seconds] = timestamp.split(':').map(Number)

      if (
        hours < 0 ||
        hours > 23 ||
        minutes < 0 ||
        minutes > 59 ||
        seconds < 0 ||
        seconds > 59
      ) {
        return false
      }
    } else {
      if (!line) {
        return false
      }
    }
  }

  return true
}

export const calculateZoomTextDuration = (transcript: string): number => {
  const lines = transcript.split('\n')
  const timeRegex = /\d{2}:\d{2}:\d{2}/

  // 合計時間を計算
  const times = lines
    .map((line) => {
      const match = line.match(timeRegex)
      if (match) {
        const [hours, minutes, seconds] = match[0].split(':').map(Number)
        return hours * 60 * 60 + minutes * 60 + seconds
      }
      return null
    })
    .filter((time) => time !== null) as number[]

  if (times.length <= 1) return 0

  return times[times.length - 1] - times[0]
}
