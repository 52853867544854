import { Directive, ElementRef, HostListener } from '@angular/core'
import { MatTooltip } from '@angular/material/tooltip'

@Directive({
  selector: '[appEllipsisTooltip]',
  standalone: true,
})
export class EllipsisTooltipDirective {
  constructor(
    private el: ElementRef,
    private tooltip: MatTooltip,
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    const element = this.el.nativeElement

    if (
      element.offsetWidth < element.scrollWidth ||
      element.offsetHeight < element.scrollHeight
    ) {
      this.tooltip.disabled = false
    } else {
      this.tooltip.disabled = true
    }
  }
}
