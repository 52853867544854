<div class="wrapper">
  <div class="container">
    <div class="section">
      <div class="page-header">
        <h2 class="heading">プライバシー設定</h2>
        <a
          class="help-link"
          target="_blank"
          href="https://storage.googleapis.com/xp-gpt-prod-public-contents/help/privacy_setting.pdf"
          >公開範囲の詳細について <mat-icon svgIcon="arrow-right"></mat-icon
        ></a>
      </div>
      <ul class="privacy-options">
        <li *ngFor="let option of privacySettingOptions">
          <button
            class="privacy-option"
            [class.active]="option.type === selectedPrivacySetting"
            (click)="onPrivacySettingChange(option.type)"
          >
            <mat-icon
              [svgIcon]="
                option.type === selectedPrivacySetting
                  ? 'radio-checked'
                  : 'radio-unchecked'
              "
            ></mat-icon>
            <div class="privacy-caption">
              <div class="privacy-title">{{ option.title }}</div>
              <div class="privacy-description">{{ option.description }}</div>
            </div>
          </button>
        </li>
      </ul>
    </div>
    <div class="section">
      <div class="page-header">
        <h2 class="heading">二要素認証</h2>
      </div>
      <mat-checkbox
        class="mfa-checkbox"
        [checked]="requiredMfa"
        (change)="onMfaChange($event)"
        >二要素認証を有効にする</mat-checkbox
      >
    </div>
  </div>
</div>
