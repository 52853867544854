import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatIcon } from '@angular/material/icon'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { LoadingComponent } from '../../components/loading/loading.component'
import { ButtonComponent } from '../../components/button/button.component'
import { AuthService } from '../../services/auth.service'
import { MfaService } from '../../services/mfa.service'
import { getTitle } from '../../util/accessibility'
import { invalidStringValidator } from '../../directives/validator/custom-validator'

const VERIFICATION_CODE_REGEX = /^\d{6}$/

@Component({
  selector: 'app-sms-verification-page-page',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    ButtonComponent,
    MatIcon,
    ReactiveFormsModule,
  ],
  templateUrl: './sms-verification-page.component.html',
  styleUrl: './sms-verification-page.component.scss',
})
export class SmsVerificationPageComponent implements OnInit {
  isLoading = false
  verificationCode = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      invalidStringValidator(VERIFICATION_CODE_REGEX),
    ],
  })

  smsVerificationForm = new FormGroup({
    verificationCode: this.verificationCode,
  })

  private phoneNumber = ''

  constructor(
    private router: Router,
    private authService: AuthService,
    private mfaService: MfaService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('SMS認証'))
  }

  ngOnInit(): void {
    const loginResult = this.authService.getLoginResult()
    if (!loginResult || (!loginResult.user && !loginResult.resolver)) {
      this.router.navigate(['/login'])
      return
    }

    if (loginResult.mfaVerified) {
      this.router.navigate(['/home'])
      return
    }

    this.phoneNumber = this.authService.getPhoneNumber()
    if (!this.phoneNumber) {
      this.router.navigate(['/auth/phone-setup'])
      return
    }
  }

  backToPhoneSetup() {
    this.router.navigate(['/auth/phone-setup'])
  }

  sendVerificationCodeSubmit = async (event: Event) => {
    event.preventDefault()

    if (this.smsVerificationForm.invalid) {
      return
    }

    const verificationCode = this.verificationCode.value
    const loginResult = this.authService.getLoginResult()
    const verificationId = this.authService.getVerificationId()

    if (!loginResult) {
      this.router.navigate(['/login'])
      return
    }

    this.isLoading = true
    try {
      if (loginResult.needsEnrollment && loginResult.user) {
        await this.mfaService.confirmEnrollment(
          loginResult.user,
          verificationId,
          verificationCode,
        )
      } else if (loginResult.resolver) {
        await this.mfaService.verifyCode(
          loginResult.resolver,
          verificationId,
          verificationCode,
        )
      } else {
        this.router.navigate(['/login'])
        return
      }
    } catch (error) {
      alert('エラーが発生しました。')
      throw error
    } finally {
      this.isLoading = false
    }

    this.authService.clearLoginState()
    this.router.navigate(['/home'])
  }
}
