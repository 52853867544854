import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatIcon } from '@angular/material/icon'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { ButtonComponent } from '../../components/button/button.component'
import { AuthService } from '../../services/auth.service'
import { getTitle } from '../../util/accessibility'

@Component({
  selector: 'app-forgot-password-page',
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatIcon, ReactiveFormsModule],
  templateUrl: './forgot-password-page.component.html',
  styleUrl: './forgot-password-page.component.scss',
})
export class ForgotPasswordPageComponent implements OnInit {
  hasSent = false
  message: string | null = null

  email = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.email],
  })

  resetPasswordForm = new FormGroup({
    email: this.email,
  })

  constructor(
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('パスワードの再設定'))
  }

  ngOnInit(): void {
    const navigation = this.router.lastSuccessfulNavigation
    if (navigation?.extras?.state) {
      this.message = navigation.extras.state.message
    }
  }

  backToLogin() {
    this.router.navigate(['/login'])
  }

  sendPasswordResetEmailSubmit = (event: Event) => {
    event.preventDefault()
    this.sendPasswordResetEmail()
  }

  private sendPasswordResetEmail = async () => {
    await this.authService.sendPasswordResetEmail(this.email.value)
    this.hasSent = true
  }
}
