import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { Title } from '@angular/platform-browser'

import { MatIcon } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { MatTooltip } from '@angular/material/tooltip'
import { MatAutocompleteModule } from '@angular/material/autocomplete'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  Subject,
  from,
  mergeMap,
  tap,
  catchError,
  Observable,
  map,
  finalize,
  last,
  startWith,
  debounceTime,
} from 'rxjs'

import { ButtonComponent } from '../../../components/button/button.component'
import {
  SelectComponent,
  Option,
} from '../../../components/select/select.component'
import {
  MultipleSelectComponent,
  Item,
} from '../../../components/multiple-select/multiple-select.component'
import { FileUploaderComponent } from '../../../components/file-uploader/file-uploader.component'
import { DateInputComponent } from '../../../components/date-input/date-input.component'
import {
  UploadListComponent,
  UploadItem,
} from './upload-list/upload-list.component'
import { ContextMenuComponent } from '../../../components/context-menu/context-menu.component'
import { LoadingComponent } from '../../../components/loading/loading.component'

import {
  BusinessMeetingCsvImportComponent,
  BusinessMeetingCsvImportDialogParam,
  CsvBusinessMeeting,
} from './business-meeting-csv-import/business-meeting-csv-import.component'
import {
  LicenseExceededErrorComponent,
  LicenseExceededErrorDialogParam,
  LicenseExceededErrorItem,
} from './messages/license-exceeded-error/license-exceeded-error.component'
import { BulkExecutionSuccessComponent } from './messages/bulk-execution-success/bulk-execution-success.component'
import {
  FileUploadErrorComponent,
  FileUploadErrorDialogParam,
  FileUploadErrorItem,
} from './messages/file-upload-error/file-upload-error.component'
import { ValidationErrorComponent } from './messages/validation-error/validation-error.component'

import {
  SUPPORTED_TEXT_EXTENSIONS,
  SUPPORTED_AUDIO_EXTENSIONS,
} from '../../../util/file/constants'
import { calculateAudioDuration, isAudioFile } from '../../../util/file/audio'
import { readFileAsText } from '../../../util/file/reader'
import {
  isZoomTranscriptFormat,
  validateZoomTranscriptFormat,
  calculateZoomTextDuration,
} from '../../../util/file/zoom'
import {
  isAmptalkTranscriptFormat,
  validateAmptalkTranscriptFormat,
  calculateAmptalkTextDuration,
} from '../../../util/file/amptalk'
import {
  isValidFileExtension,
  isValidFileSize,
} from '../../../util/file/validate'
import { SkillMap } from '../../../services/assessment/assessment.mapping'
import { AssessmentStore } from '../../../stores/assessment.store'
import { UserStore } from '../../../stores/user.store'
import { AdminOrg } from '../../../services/user/user.mapping'
import { LicenseService } from '../../../services/license/license.service'
import { License } from '../../../services/license/license.mapping'
import { getTitle } from '../../../util/accessibility'
import { AssessmentExecutionType } from '../../../../_generated/graphql'

export type BusinessMeeting = {
  fileName: string
  name: string
  accountName: string
  fileDuration: number
  recordDate: Date | null
  license: License | null
  file: File
}

type BusinessMeetingValidationError = {
  fileName: string
  name: {
    required: boolean
  }
  license: {
    required: boolean
    exceeded: boolean
  }
}

type FileMetadata = {
  fileName: string
  duration: number
}

@UntilDestroy()
@Component({
  selector: 'app-assessment-bulk-execution',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatTableModule,
    MatTooltip,
    MatAutocompleteModule,
    ButtonComponent,
    SelectComponent,
    MultipleSelectComponent,
    DateInputComponent,
    FileUploaderComponent,
    UploadListComponent,
    ContextMenuComponent,
    LoadingComponent,
  ],
  templateUrl: './bulk-execution.component.html',
  styleUrl: './bulk-execution.component.scss',
})
export class AssessmentBulkExecutionComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>

  skillMaps: SkillMap[] = []
  skillMapOptions: Option[] = []
  selectedSkillMap: Option = { id: '', name: '' }
  phaseItems: Item[] = []
  accountNames: string[] = []
  accountNameChange$ = new Subject<string>()
  accountNameAutoCompleteOptions: string[] = []
  NO_LICENSE_OPTION: Option = { id: 'none', name: 'ユーザー未選択' }
  adminOrg: AdminOrg | null = null
  licenses: License[] = []
  selectableLicenses: Option[] = []
  displayedColumns: string[] = [
    'fileName',
    'name',
    'accountName',
    'fileDuration',
    'recordDate',
    'license',
    'action',
  ]
  contextMenu = [
    {
      icon: 'delete',
      title: '削除',
      type: 'DELETE',
    },
  ]
  dataSource: BusinessMeeting[] = []
  uploadItems: UploadItem[] = []
  businessMeetingValidationErrors: BusinessMeetingValidationError[] = []

  isDragging = false
  expandUploadList = false
  showUploadList = false
  showErrors = false
  submitted = false

  get acceptFileExtensions(): string[] {
    if (this.adminOrg?.licenseInfo?.plan === 'ADVANCED') {
      return [...SUPPORTED_TEXT_EXTENSIONS, ...SUPPORTED_AUDIO_EXTENSIONS]
    }

    return [...SUPPORTED_TEXT_EXTENSIONS]
  }

  get acceptFileExtensionsForInput(): string {
    return this.acceptFileExtensions
      .map((extension) => `.${extension}`)
      .join(',')
  }

  constructor(
    private dialog: MatDialog,
    private assessmentStore: AssessmentStore,
    private userStore: UserStore,
    private licenseService: LicenseService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('アセスメント一括実行'))
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: BeforeUnloadEvent): void {
    if (this.submitted) {
      event.preventDefault()
    }
  }

  ngOnInit() {
    this.assessmentStore.getSkillMaps().subscribe((skillMaps) => {
      this.skillMaps = skillMaps
      this.skillMapOptions = skillMaps.map((skillMap) => ({
        id: skillMap.id,
        name: skillMap.name,
      }))
    })

    this.assessmentStore.getAccounts().subscribe((accounts) => {
      this.accountNames = accounts.map((account) => account.name)
    })

    this.accountNameChange$
      .pipe(
        untilDestroyed(this),
        startWith(''),
        debounceTime(300),
        tap((val) => {
          const lowerCaseVal = val.toLowerCase()
          this.accountNameAutoCompleteOptions = this.accountNames.filter(
            (name) => name.toLowerCase().includes(lowerCaseVal),
          )
        }),
      )
      .subscribe()

    this.userStore.getAdminOrg().subscribe((org) => {
      this.adminOrg = org

      this.licenseService.searchLicenses().subscribe((licenses) => {
        const assignedLicenses = licenses.filter((license) => license.user)
        this.licenses = assignedLicenses

        this.selectableLicenses = assignedLicenses.map((license) => ({
          id: license.id,
          name: `${license.user?.username}（残り${this.getRemainingHours({
            userMonthlyLimitSeconds:
              license.user?.license?.userMonthlyLimitSeconds ?? 0,
            extraChargeLimitSeconds:
              license.user?.license?.extraChargeLimitSeconds ?? 0,
            usedSeconds: license.user?.license?.usedSeconds ?? 0,
          })}時間）`,
        }))
      })
    })
  }

  changeSkillMap(option: Option): void {
    this.selectedSkillMap = this.skillMapOptions.find(
      (op) => op.id === option.id,
    ) ?? { id: '', name: '' }
    this.phaseItems =
      this.skillMaps
        .find((skillMap) => skillMap.id === option.id)
        ?.phases.map((phase) => ({
          id: phase.id,
          label: phase.name,
          checked: false,
        })) ?? []
  }

  changeSelectedPhases(items: Item[]): void {
    this.phaseItems = items
  }

  formatDuration(duration: number | null): string {
    if (duration === null) return 'なし'

    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor((duration % 3600) / 60)
    const seconds = duration % 60
    return `${hours}:${String(minutes).padStart(2, '0')}:${String(
      seconds,
    ).padStart(2, '0')}`
  }

  getSelectableLicenses(userId: string) {
    return userId === this.userStore.ctxUser()?.id
      ? this.selectableLicenses
      : [this.NO_LICENSE_OPTION, ...this.selectableLicenses]
  }

  skillMapSelected(): boolean {
    return this.selectedSkillMap.id !== ''
  }

  phaseSelected(): boolean {
    return this.phaseItems.filter((item) => item.checked).length > 0
  }

  getSelectedLicenseOption(license: License | null): Option {
    return (
      this.selectableLicenses.find((op) => op.id === license?.id) ??
      this.NO_LICENSE_OPTION
    )
  }

  hasBusinessMeetingNameError(fileName: string): boolean {
    return this.businessMeetingValidationErrors.some(
      (error) => error.fileName === fileName && error.name.required,
    )
  }

  hasLicenseRequiredError(fileName: string): boolean {
    return this.businessMeetingValidationErrors.some(
      (error) => error.fileName === fileName && error.license.required,
    )
  }

  hasLicenseExceededError(fileName: string): boolean {
    return this.businessMeetingValidationErrors.some(
      (error) => error.fileName === fileName && error.license.exceeded,
    )
  }

  onDragOver(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragging = true
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragging = false
  }

  async onDrop(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragging = false
    const files = Array.from(event.dataTransfer?.files || [])

    if (files.length === 0) {
      return
    }

    const fileUploadResult = await this.validateFiles(files)
    if (fileUploadResult.type === 'error') {
      this.openFileUploadErrorDialog(fileUploadResult.errors)
      return
    }

    this.upsertBusinessMeetings(files, fileUploadResult.fileMetadata)
  }

  async onFileUploaded(files: File[]) {
    const fileUploadResult = await this.validateFiles(files)
    if (fileUploadResult.type === 'error') {
      this.openFileUploadErrorDialog(fileUploadResult.errors)
      return
    }

    this.upsertBusinessMeetings(files, fileUploadResult.fileMetadata)
  }

  onFileUploadButtonClicked(): void {
    this.fileInput.nativeElement.click()
  }

  async onFileChange(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target.files?.length) {
      this.fileInput.nativeElement.value = ''
      return
    }
    const files = Array.from(target.files)
    if (files.length === 0) {
      this.fileInput.nativeElement.value = ''
      return
    }

    this.fileInput.nativeElement.value = ''
    const fileUploadResult = await this.validateFiles(files)
    if (fileUploadResult.type === 'error') {
      this.openFileUploadErrorDialog(fileUploadResult.errors)
      return
    }

    this.upsertBusinessMeetings(files, fileUploadResult.fileMetadata)
  }

  onCsvImportButtonClicked(): void {
    this.openCsvImportDialog()
  }

  onEditName(businessMeeting: BusinessMeeting, event: Event) {
    const name = (event.target as HTMLInputElement).value
    businessMeeting.name = name
  }

  onEditAccountName(businessMeeting: BusinessMeeting, event: Event) {
    const accountName = (event.target as HTMLInputElement).value
    businessMeeting.accountName = accountName
    this.accountNameChange$.next(accountName)
  }

  onEditRecordDate(businessMeeting: BusinessMeeting, recordDate: Date) {
    businessMeeting.recordDate = recordDate
  }

  onEditLicense(businessMeeting: BusinessMeeting, licenseOption: Option) {
    businessMeeting.license =
      this.licenses.find((license) => license.id === licenseOption.id) ?? null
  }

  contextMenuClick(type: string, fileName: string) {
    if (type === 'DELETE') {
      this.dataSource = this.dataSource.filter(
        (meeting) => meeting.fileName !== fileName,
      )
    }
  }

  onExecuteButtonClicked(): void {
    this.execute()
  }

  private upsertBusinessMeetings(
    files: File[],
    fileMetadata: FileMetadata[],
  ): void {
    const upsertBusinessMeeting = (
      file: File,
      fileMetadata: FileMetadata,
      businessMeetings: BusinessMeeting[],
    ) => {
      const addedBusinessMeeting = this.mappingToBusinessMeeting(
        file,
        fileMetadata,
      )
      const existingBusinessMeeting = businessMeetings.find(
        (meeting) => meeting.fileName === file.name,
      )

      if (existingBusinessMeeting) {
        const { fileDuration, recordDate, file } = addedBusinessMeeting
        existingBusinessMeeting.fileDuration = fileDuration
        existingBusinessMeeting.recordDate = recordDate
        existingBusinessMeeting.file = file
      } else {
        businessMeetings.push(addedBusinessMeeting)
      }
    }

    const newBusinessMeetings = structuredClone(this.dataSource)
    files.forEach((file) => {
      upsertBusinessMeeting(
        file,
        fileMetadata.find((metadata) => metadata.fileName === file.name) ?? {
          fileName: file.name,
          duration: 0,
        },
        newBusinessMeetings,
      )
    })
    this.dataSource = newBusinessMeetings
  }

  private mappingToBusinessMeeting(
    file: File,
    fileMetadata: FileMetadata,
  ): BusinessMeeting {
    return {
      fileName: file.name,
      name: '',
      accountName: '',
      fileDuration: fileMetadata.duration,
      recordDate: file.lastModified ? new Date(file.lastModified) : null,
      license: null,
      file,
    }
  }

  private async validateFiles(files: File[]): Promise<
    | {
        type: 'error'
        errors: FileUploadErrorItem[]
      }
    | {
        type: 'success'
        fileMetadata: FileMetadata[]
      }
  > {
    const errors: FileUploadErrorItem[] = []
    const fileMetadata: FileMetadata[] = []

    for (const file of files) {
      const result = await this.validateFile(file)
      if (!result.isValid) {
        errors.push({
          fileName: file.name,
          message: result.message,
        })
      } else {
        fileMetadata.push({
          fileName: file.name,
          duration: result.duration ?? 0,
        })
      }
    }

    if (errors.length > 0) {
      return { type: 'error', errors }
    }

    return { type: 'success', fileMetadata: fileMetadata }
  }

  private async validateFile(file: File): Promise<{
    isValid: boolean
    message: string
    duration: number | null
  }> {
    if (!isValidFileExtension(file, this.acceptFileExtensions)) {
      return {
        isValid: false,
        message:
          'サポートされていないフォーマットがアップロードされました。対応フォーマットを確認してください。',
        duration: null,
      }
    }

    if (file.size === 0) {
      return {
        isValid: false,
        message:
          'ファイルの中身が空です。ファイルを確認して、再度アップロードしてください',
        duration: null,
      }
    }

    if (!isValidFileSize(file)) {
      return {
        isValid: false,
        message:
          'ファイルサイズが1GBを超えています。1GB以下のファイルをアップロードしてください。',
        duration: null,
      }
    }

    if (isAudioFile(file)) {
      const duration = await calculateAudioDuration(file)
      return {
        isValid: true,
        message: '',
        duration,
      }
    }

    const content = await readFileAsText(file)
    let duration = null

    if (isZoomTranscriptFormat(content)) {
      if (!validateZoomTranscriptFormat(content)) {
        return {
          isValid: false,
          message:
            'ファイルの形式が正しくありません。Zoomの書き起こし形式のファイルをアップロードしてください。',
          duration: null,
        }
      }
      duration = calculateZoomTextDuration(content)
    } else if (isAmptalkTranscriptFormat(content)) {
      if (!validateAmptalkTranscriptFormat(content)) {
        return {
          isValid: false,
          message:
            'ファイルの形式が正しくありません。Amptalkの書き起こし形式のファイルをアップロードしてください。',
          duration: null,
        }
      }
      duration = calculateAmptalkTextDuration(content)
    } else {
      return {
        isValid: false,
        message:
          'ファイルの形式が正しくありません。AmptalkもしくはZoomの書き起こし形式のファイルをアップロードしてください。',
        duration: null,
      }
    }

    return {
      isValid: true,
      message: '',
      duration,
    }
  }

  private openCsvImportDialog(): void {
    this.dialog
      .open<
        BusinessMeetingCsvImportComponent,
        BusinessMeetingCsvImportDialogParam
      >(BusinessMeetingCsvImportComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        data: {
          businessMeetings: this.dataSource,
          licenses: this.licenses,
        },
      })
      .afterClosed()
      .subscribe((csvBusinessMeetings: CsvBusinessMeeting[]) => {
        if (csvBusinessMeetings) {
          this.reflectCsvImportedData(csvBusinessMeetings)
        }
      })
  }

  private reflectCsvImportedData(
    csvBusinessMeetings: CsvBusinessMeeting[],
  ): void {
    csvBusinessMeetings.forEach((csvBusinessMeeting) => {
      const businessMeeting = this.dataSource.find(
        (meeting) => meeting.fileName === csvBusinessMeeting.fileName,
      )
      if (!businessMeeting) {
        return
      }

      businessMeeting.name = csvBusinessMeeting.name || businessMeeting.name
      businessMeeting.accountName =
        csvBusinessMeeting.accountName || businessMeeting.accountName
      businessMeeting.recordDate =
        csvBusinessMeeting.recordDate || businessMeeting.recordDate
      businessMeeting.license =
        this.licenses.find(
          (license) => license.user?.email === csvBusinessMeeting.userEmail,
        ) ?? null
    })
  }

  private openFileUploadErrorDialog(errors: FileUploadErrorItem[]): void {
    this.dialog.open<FileUploadErrorComponent, FileUploadErrorDialogParam>(
      FileUploadErrorComponent,
      {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        data: {
          items: errors,
        },
      },
    )
  }

  private execute(): void {
    this.showErrors = true

    if (!this.validateBusinessMeetings()) {
      this.dialog.open<ValidationErrorComponent>(ValidationErrorComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
      })
      return
    }

    const licenseExceededErrors = this.validateLicenseExceeded()
    if (licenseExceededErrors.length > 0) {
      this.dialog.open<
        LicenseExceededErrorComponent,
        LicenseExceededErrorDialogParam
      >(LicenseExceededErrorComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        data: {
          items: licenseExceededErrors,
          adminOrg: this.adminOrg,
        },
      })
      return
    }

    this.executeBulkExecution()
      .pipe(last())
      .subscribe(() => {
        this.dialog.open<BulkExecutionSuccessComponent>(
          BulkExecutionSuccessComponent,
          {
            disableClose: true,
            panelClass: 'unset-mat-dialog-padding',
          },
        )
      })
  }

  private validateBusinessMeetings(): boolean {
    let isValid = true
    const validationErrors: BusinessMeetingValidationError[] = []

    this.dataSource.forEach((meeting) => {
      const error: BusinessMeetingValidationError = {
        fileName: meeting.fileName,
        name: {
          required: !meeting.name,
        },
        license: {
          required: !meeting.license,
          exceeded: false,
        },
      }
      validationErrors.push(error)
      if (error.name.required) {
        isValid = false
      }
      if (error.license.required) {
        isValid = false
      }
    })

    this.businessMeetingValidationErrors = validationErrors

    if (!this.skillMapSelected() || !this.phaseSelected()) {
      isValid = false
    }

    return isValid
  }

  private validateLicenseExceeded(): LicenseExceededErrorItem[] {
    const licenseExceededErrors: LicenseExceededErrorItem[] = []
    const fileDurationTotalByUser = new Map<string, number>()

    this.dataSource.forEach((meeting) => {
      const user = meeting.license?.user
      if (!user) {
        return
      }

      const total = fileDurationTotalByUser.get(user.id) ?? 0
      fileDurationTotalByUser.set(user.id, total + meeting.fileDuration)
    })

    fileDurationTotalByUser.forEach((total, userId) => {
      const user = this.licenses.find(
        (license) => license.user?.id === userId,
      )?.user
      if (!user) {
        return
      }

      const exceededSeconds =
        (user.license?.userMonthlyLimitSeconds ?? 0) +
        (user.license?.extraChargeLimitSeconds ?? 0) -
        (total + (user.license?.usedSeconds || 0))

      if (exceededSeconds < 0) {
        licenseExceededErrors.push({
          userName: user.username,
          exceededSeconds: Math.abs(exceededSeconds),
        })

        const exceededFileNames = this.dataSource
          .filter((meeting) => meeting.license?.user?.id === userId)
          .map((meeting) => meeting.fileName)
        this.businessMeetingValidationErrors
          .filter((error) => exceededFileNames.includes(error.fileName))
          .forEach((error) => {
            error.license.exceeded = true
          })
      }
    })

    return licenseExceededErrors
  }

  private executeBulkExecution(): Observable<void> {
    this.uploadItems = this.dataSource.map((meeting) => ({
      name: meeting.fileName,
      status: 'in_progress',
    }))
    this.showUploadList = true
    this.submitted = true

    const maxConcurrent = 5

    return from(this.dataSource)
      .pipe(
        mergeMap((businessMeeting) => {
          return this.assessmentStore
            .executeAssessment(
              businessMeeting.license?.user?.id ?? '',
              businessMeeting.license?.user?.orgId ?? '',
              businessMeeting.name,
              businessMeeting.accountName,
              businessMeeting.recordDate ?? new Date(),
              businessMeeting.fileName,
              this.selectedSkillMap.id,
              this.phaseItems
                .filter((item) => item.checked)
                .map((item) => item.id ?? ''),
              businessMeeting.file,
              AssessmentExecutionType.Batch,
            )
            .pipe(
              tap(() => {
                const uploadItem = this.uploadItems.find(
                  (item) => item.name === businessMeeting.fileName,
                )
                if (uploadItem) {
                  uploadItem.status = 'completed'
                }
              }),
              catchError((error) => {
                const uploadItem = this.uploadItems.find(
                  (item) => item.name === businessMeeting.fileName,
                )
                if (uploadItem) {
                  uploadItem.status = 'error'
                }
                return error
              }),
            )
        }, maxConcurrent),
      )
      .pipe(
        map(() => {}),
        finalize(() => {
          this.submitted = false
        }),
      )
  }

  private getRemainingHours(param: {
    userMonthlyLimitSeconds: number
    extraChargeLimitSeconds: number
    usedSeconds: number
  }): number {
    const remainingSeconds =
      param.userMonthlyLimitSeconds +
      param.extraChargeLimitSeconds -
      param.usedSeconds
    return Math.floor((remainingSeconds / 3600) * 10) / 10
  }
}
