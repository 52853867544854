import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { MatIcon } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'

import { SUPPORTED_AUDIO_EXTENSIONS } from '../../../../util/file/constants'
import { AssessmentStore } from '../../../../stores/assessment.store'

@Component({
  templateUrl: './file-information-view.component.html',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatIcon],
  providers: [AssessmentStore],
  styleUrls: ['./file-information-view.component.scss'],
})
export class FileInformationViewComponent {
  @Input() businessMeetingName: string | null = null
  @Input() accountName: string | null = null
  @Input() fileName: string | null = null
  @Input() skillMapName: string | null = null
  @Input() phaseName: string | null = null
  @Input() recordDate: Date | null = null
  @Input() durationTIme: string | null = null

  constructor(
    private matDialogRef: MatDialogRef<FileInformationViewComponent>,
  ) {}

  isAudioFile(fileName: string | null): boolean {
    if (!fileName) {
      return false
    }
    return SUPPORTED_AUDIO_EXTENSIONS.some((ext) =>
      fileName.toLowerCase().endsWith(`.${ext}`),
    )
  }

  close(): void {
    this.matDialogRef.close()
  }
}
