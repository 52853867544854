import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete'
import { MatChipsModule } from '@angular/material/chips'
import { MatIcon } from '@angular/material/icon'
import { MatCheckboxModule } from '@angular/material/checkbox'

import {
  Observable,
  debounceTime,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs'

import {
  Item,
  MultipleSelectComponent,
} from '../../../../components/multiple-select/multiple-select.component'
import {
  Option,
  SelectComponent,
} from '../../../../components/select/select.component'
import {
  invalidStringValidator,
  requiredArrayValidator,
} from '../../../../directives/validator/custom-validator'
import { User, UserForAdmin } from '../../../../services/user/user.mapping'
import { EMAIL_REGEXP } from '../../../../services/user/user.service'
import { UserStore } from '../../../../stores/user.store'

interface UserEditModel {
  name: string
  email: string
  departmentName: string
  reportToEmail: string
  roleCodes: string[]
  licenseId: string | null
  requiredMfa: boolean
}

@Component({
  selector: 'app-admin-user-form',
  templateUrl: './admin-user-form.component.html',
  styleUrls: ['./admin-user-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatIcon,
    MatChipsModule,
    MatCheckboxModule,
    MultipleSelectComponent,
    SelectComponent,
  ],
})
export class AdminUserFormComponent implements OnInit, OnChanges {
  @Input() roleEditable = true
  @Input() emailEditable = true
  @Input() departmentNames: string[] = []
  @Input() isEdit: boolean = false
  @Input() user: UserForAdmin | null = null
  @Input() roles: { name: string; code: string }[] = []
  @Input() selectableLicenses: Option[] = []
  @Input() selectedOption!: Option
  @Output() validChanges = new EventEmitter<boolean>()

  name = new FormControl()
  email = new FormControl()
  departmentName = new FormControl()
  reportTo = new FormControl()
  reportToInput = new FormControl()
  roleCodes = new FormControl()
  requiredMfa = new FormControl()

  roleItems: Item[] = []

  isMine = false

  filteredDepartmentNames: Observable<string[]> = of([])
  filteredUsers: Observable<User[]> = of([])

  private formGroup = this.formBuilder.group({
    name: this.name,
    email: this.email,
    departmentName: this.departmentName,
    reportTo: this.reportTo,
    roleCodes: this.roleCodes,
    requiredMfa: this.requiredMfa,
  })

  constructor(
    private formBuilder: FormBuilder,
    private userStore: UserStore,
  ) {}

  ngOnInit(): void {
    const isAdmin = this.userStore.isAdmin()
    this.isMine = this.user?.id === this.userStore.ctxUser()?.id
    this.roleItems = this.roles.map((role) => {
      const notSelectableEnabler = !isAdmin && role.code === 'SUPER_ADMIN'
      const notSelectableMine =
        role.code === 'SUPER_ADMIN' &&
        this.user?.id === this.userStore.ctxUser()?.id
      return {
        label: role.name,
        id: role.code,
        checked: false,
        notSelectable: notSelectableEnabler || notSelectableMine,
      }
    })
    if (this.user) {
      this.name.setValue(this.user.name)
      this.email.setValue(this.user.email)
      this.departmentName.setValue(this.user.departmentName)
      this.reportTo.setValue(this.user.reportTo)
      this.user.roles.forEach((role) => {
        const item = this.roleItems.find((r) => r.id === role.code)
        if (item) {
          item.checked = true
        }
      })
      this.roleCodes.setValue(this.user.roles.map((role) => role.code))
      this.requiredMfa.setValue(this.user.requiredMfa)
    }
    this.name.setValidators(Validators.required)
    this.email.setValidators([
      Validators.required,
      invalidStringValidator(EMAIL_REGEXP),
    ])
    this.departmentName.setValidators(Validators.required)
    this.roleCodes.setValidators(requiredArrayValidator())
    this.roleCodes.updateValueAndValidity()

    this.filteredDepartmentNames = this.departmentName.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((val) => {
        return of(
          this.departmentNames.filter((depName) =>
            depName.toLocaleLowerCase().includes(val.toLocaleLowerCase()),
          ),
        )
      }),
    )

    this.filteredUsers = this.reportToInput.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((val) => {
        return this.userStore
          .searchUsers(
            val ?? '',
            [],
            {
              limit: 50,
              offset: 0,
            },
            { sortField: 'name', sortOrder: 'asc' },
            false,
          )
          .pipe(
            map((users) => {
              return users.filter((user) => user.email !== this.email.value)
            }),
          )
      }),
    )

    this.formGroup.statusChanges
      .pipe(tap((status) => this.validChanges.next(status === 'VALID')))
      .subscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.currentValue) {
      const user = changes.user.currentValue
      this.formGroup.setValue({
        name: user.name ?? '',
        email: user.email ?? '',
        departmentName: user.departmentName ?? '',
        reportTo: user.reportTo ?? '',
        roleCodes: user.roles.map((role: { code: string }) => role.code) ?? [],
        requiredMfa: user.requiredMfa ?? false,
      })
    }
  }

  reportToOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.reportToInput.setValue(null)
    this.reportTo.setValue(event.option.value)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const elem = (this.reportToInput as any).nativeElement
    if (elem) {
      elem.value = ''
    }
  }

  reportToInputBlur(): void {
    this.reportToInput.setValue(null)
  }

  getInputUser = (): UserEditModel => {
    return {
      name: this.name.value ?? '',
      email: this.email.value ?? '',
      departmentName: this.departmentName.value ?? '',
      reportToEmail: this.reportTo.value?.email ?? '',
      roleCodes: this.roleItems
        .filter((role) => role.checked)
        .map((role) => role.id ?? ''),
      licenseId: this.selectedOption.id ? this.selectedOption.id : null,
      requiredMfa: this.requiredMfa.value,
    }
  }

  changeSelectedRoles(items: Item[]): void {
    this.roleItems = items
    this.roleCodes.setValue(
      items.filter((role) => role.checked).map((role) => role.id),
    )
    this.roleCodes.updateValueAndValidity()
  }

  selectedOptionChange(option: Option): void {
    this.selectedOption = option
    this.formGroup.updateValueAndValidity()
  }
}
