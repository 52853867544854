export class ColorAllocator {
  private colors: string[]
  private allocated: Map<string, string>
  private currentIndex: number

  constructor(colors: string[]) {
    this.colors = colors
    this.allocated = new Map<string, string>()
    this.currentIndex = 0
  }

  allocate(keys: string[]): void {
    for (const key of keys) {
      if (!this.allocated.has(key)) {
        if (this.currentIndex >= this.colors.length) {
          this.currentIndex = 0
        }
        const color = this.colors[this.currentIndex]
        this.allocated.set(key, color)
        this.currentIndex++
      }
    }
  }

  getColor(key: string): string {
    if (this.allocated.has(key)) {
      return this.allocated.get(key) ?? ''
    } else {
      throw new Error(`Color not allocated for key: ${key}`)
    }
  }

  deallocate(key: string): void {
    this.allocated.delete(key)
  }

  deallocateAll(): void {
    this.allocated.clear()
  }
}
