import { Component, OnInit } from '@angular/core'
import { AvatarComponent } from '../../../components/avatar/avatar.component'
import {
  Row as TimeSeriesPlot,
  TimeSeriesComponent,
} from '../time-series-chart/time-series-chart.component'
import { ActivatedRoute } from '@angular/router'
import { UserStore } from '../../../stores/user.store'
import {
  PeriodKeyActionScore,
  RecentKeyActionScore,
} from '../../../services/user/user.mapping'
import { CommonModule } from '@angular/common'
import {
  Option,
  SelectComponent,
} from '../../../components/select/select.component'
import { MatIconModule } from '@angular/material/icon'
import { Title } from '@angular/platform-browser'
import { getTitle } from '../../../util/accessibility'
import { LoadingComponent } from '../../../components/loading/loading.component'
import { AssessmentStore } from '../../../stores/assessment.store'
import { switchMap, tap } from 'rxjs'
import { SkillMap } from '../../../services/assessment/assessment.mapping'
import { PieComponent } from '../pie-chart/pie-chart.component'

@Component({
  selector: 'app-organization-dashboard-page',
  standalone: true,
  imports: [
    AvatarComponent,
    TimeSeriesComponent,
    CommonModule,
    SelectComponent,
    MatIconModule,
    LoadingComponent,
    PieComponent,
  ],
  templateUrl: './organization.component.html',
  styleUrl: './organization.component.scss',
})
export class OrganizationComponent implements OnInit {
  orgName: string = ''
  viewGroup: {
    name: string
  } | null = null
  startDate: Date | null = null
  endDate: Date = new Date()
  rawData: {
    name: string
    periodKeyActionScores: PeriodKeyActionScore[]
    departments: {
      name: string
      periodKeyActionScores: PeriodKeyActionScore[]
    }[]
    users: {
      departmentName: string
      recentKeyActionScores: RecentKeyActionScore[]
    }[]
  } | null = null
  timeSeriesData: TimeSeriesPlot[] = []
  distributionData: { name: string; y: number }[] = []
  average: string = ''
  isLoading = true
  rangeOptions: Option[] = [
    { id: '1', name: '1ヵ月', isDefault: true },
    { id: '2', name: '2ヵ月' },
    { id: '3', name: '3ヵ月' },
    { id: '6', name: '6ヵ月' },
    { id: '9', name: '9ヵ月' },
    { id: '12', name: '1年' },
  ]
  selectedRange: Option = this.rangeOptions[0]
  skillMaps: SkillMap[] = []
  skillMapOptions: Option[] = []
  selectedSkillMap!: Option
  keyActionOptions: Option[] = []
  selectedKeyAction!: Option
  departmentOptions: Option[] = []
  selectedDepartment!: Option

  constructor(
    private activatedRoute: ActivatedRoute,
    private assessmentStore: AssessmentStore,
    private userStore: UserStore,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('組織スコア推移'))
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        switchMap(() => {
          return this.assessmentStore.getSkillMaps()
        }),
        tap(() => {
          this.userStore.getDepartmentNames().subscribe((departments) => {
            this.departmentOptions = [
              { id: 'all', name: '組織全体', isDefault: true } as Option,
            ].concat(
              departments.map((department) => ({
                id: department,
                name: department,
              })),
            )
            this.selectedDepartment = this.departmentOptions[0]
          })
        }),
      )
      .subscribe(async (skillMaps) => {
        this.skillMaps = skillMaps
        this.skillMapOptions = skillMaps.map((skillMap, index) => ({
          id: skillMap.id,
          name: skillMap.name,
          isDefault: index === 0,
        }))
        this.selectedSkillMap = this.skillMapOptions[0]
        this.fetchData()
      })
  }

  onRangeChange(range: Option) {
    this.selectedRange = range
    this.fetchData()
  }

  onSkillMapChange(skillMap: Option) {
    this.selectedSkillMap = skillMap
    this.selectedKeyAction = { id: 'all', name: 'すべてのキーアクション' }
    this.fetchData()
  }

  onKeyActionChange(keyAction: Option) {
    this.selectedKeyAction = keyAction
    this.setFilteredData()
  }

  onDepartmentChange(department: Option) {
    this.selectedDepartment = department
    this.setFilteredData()
  }

  private setFilteredData() {
    if (!this.selectedKeyAction) {
      this.selectedKeyAction = this.keyActionOptions.find(
        (option) => option.isDefault,
      ) ?? { id: 'all', name: 'すべてのキーアクション' }
    }

    if (!this.selectedDepartment) {
      this.selectedDepartment = this.departmentOptions.find(
        (option) => option.isDefault,
      ) ?? { id: 'all', name: '組織全体' }
    }

    let periodKeyActionScores: PeriodKeyActionScore[] = []
    let distributionTargetUsers: {
      recentKeyActionScores: RecentKeyActionScore[]
    }[] = []
    if (this.selectedDepartment?.id === 'all' || !this.selectedDepartment) {
      periodKeyActionScores = this.rawData?.periodKeyActionScores ?? []
      distributionTargetUsers = this.rawData?.users ?? []
      this.viewGroup = {
        name: this.orgName,
      }
    } else {
      periodKeyActionScores =
        this.rawData?.departments.find(
          (department) => department.name === this.selectedDepartment?.name,
        )?.periodKeyActionScores ?? []
      distributionTargetUsers =
        this.rawData?.users.filter(
          (u) => u.departmentName === this.selectedDepartment?.name,
        ) ?? []
      this.viewGroup = {
        name: this.selectedDepartment.name,
      }
    }

    const data = this.transformToHighChartFormat(periodKeyActionScores)

    if (this.selectedKeyAction?.id === 'all') {
      this.timeSeriesData = data
    } else {
      this.timeSeriesData = data.filter((row) => {
        return row.name === this.selectedKeyAction?.name
      })
      distributionTargetUsers = distributionTargetUsers.map((u) => {
        return {
          recentKeyActionScores: u.recentKeyActionScores.filter(
            (r) => r.keyAction.name === this.selectedKeyAction?.name,
          ),
        }
      })
    }

    this.setDistributionChartData(distributionTargetUsers)
  }

  private setDistributionChartData(
    data: {
      recentKeyActionScores: RecentKeyActionScore[]
    }[],
  ) {
    // first. calculate average score. exclude null scores
    const averageScores: number[] = []
    data.forEach(({ recentKeyActionScores }) => {
      const scores = recentKeyActionScores
        .map(({ score }) => score)
        .filter((score) => score !== null)
      const totalScore = scores.reduce((sum, score) => sum + score, 0)
      if (totalScore === 0 || scores.length === 0) return
      const averageScore = totalScore / scores.length
      averageScores.push(averageScore)
    })

    // distribute data by score
    // 1-2, 2-3, 3-4, 4-5, 5
    const distribution = [0, 0, 0, 0, 0]
    averageScores.forEach((score) => {
      if (score < 2) distribution[0]++
      else if (score < 3) distribution[1]++
      else if (score < 4) distribution[2]++
      else if (score < 5) distribution[3]++
      else distribution[4]++
    })

    this.distributionData = [
      { name: '1-2点', y: distribution[0] },
      { name: '2-3点', y: distribution[1] },
      { name: '3-4点', y: distribution[2] },
      { name: '4-5点', y: distribution[3] },
      { name: '5点', y: distribution[4] },
    ]

    this.average =
      averageScores.length === 0
        ? ''
        : (
            averageScores.reduce((sum, score) => sum + score, 0) /
            averageScores.length
          ).toFixed(2)
  }

  private setKeyActionOptions() {
    this.keyActionOptions = [
      { id: 'all', name: 'すべてのキーアクション', isDefault: true } as Option,
    ].concat(
      this.skillMaps
        .find((skillMap) => skillMap.id === this.selectedSkillMap?.id)
        ?.phases.flatMap((phase) => phase.keyActions)
        .map((keyAction) => ({
          id: keyAction.id,
          name: keyAction.name,
        })) ?? [],
    )
  }

  private fetchData() {
    this.isLoading = true
    this.startDate = this.getMonthsAgoDate(Number(this.selectedRange.id))
    this.userStore
      .getWeeklyScores(
        this.selectedSkillMap?.id ?? '',
        this.startDate,
        new Date(),
      )
      .subscribe((data) => {
        this.rawData = data
        this.orgName = data.name
        this.setKeyActionOptions()
        this.setFilteredData()

        this.isLoading = false
      })
  }

  private getMonthsAgoDate(monthsAgo: number) {
    const dt = new Date()
    dt.setMonth(dt.getMonth() - monthsAgo)
    return dt
  }

  private transformToHighChartFormat(data: PeriodKeyActionScore[]) {
    const resultMap = new Map<string, [number, number | null][]>()
    const weekScoresMap = new Map()

    data.forEach(({ keyAction, weeklyScores }) => {
      if (!resultMap.has(keyAction.name)) {
        resultMap.set(keyAction.name, [])
      }

      const seriesData = resultMap.get(keyAction.name) ?? []

      weeklyScores.forEach(({ weekStartDate, score }) => {
        seriesData.push([
          weekStartDate.getTime(),
          score === null ? null : Math.floor(score * 100) / 100,
        ])

        const weekTime = weekStartDate.getTime()
        if (!weekScoresMap.has(weekTime)) {
          weekScoresMap.set(weekTime, { totalScore: 0, count: 0 })
        }
        const weekData = weekScoresMap.get(weekTime)
        weekData.totalScore += score ?? 0
        if (score) weekData.count++
        weekData.weekStartDate = weekStartDate
      })
    })

    const averageData: [number, number | null][] = []
    weekScoresMap.forEach(({ totalScore, weekStartDate, count }) => {
      averageData.push([
        weekStartDate.getTime(),
        totalScore ? Math.floor((totalScore / count) * 100) / 100 : null,
      ])
    })

    resultMap.set('総合スコア', averageData)

    return Array.from(resultMap.entries()).map(([name, data]) => ({
      name,
      data,
    }))
  }
}
