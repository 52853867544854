<div class="container">
  <div class="main">
    <div class="header">
      <div class="icon"><mat-icon>donut_large</mat-icon></div>
      <h2 class="title">AI Sales Coach</h2>
    </div>
    <div class="content">
      <h3 class="title">ログイン</h3>
      <button rectangle variant="secondary" (click)="loginWithGoogle()">
        <div class="login-button">
          <img src="assets/icons/google-icon.svg" alt="" />
          <span>Googleで実行</span>
        </div>
      </button>
      <div class="login-form-divider">
        <span>もしくは</span>
      </div>
      <form
        [formGroup]="loginForm"
        (submit)="loginWithEmailAndPasswordSubmit($event)"
      >
        <div class="inputs">
          <div class="input-wrapper">
            <div class="input-field">
              <input
                id="email"
                placeholder="メールアドレス"
                autocomplete="email"
                formControlName="email"
                required
                [class.error]="
                  (this.email.touched && this.email.hasError('required')) ||
                  this.email.hasError('invalidString')
                "
              />
              <span
                class="error-message"
                *ngIf="this.email.touched && this.email.hasError('required')"
              >
                メールアドレスは必須です
              </span>
              <span
                class="error-message"
                *ngIf="
                  this.email.touched && this.email.hasError('invalidString')
                "
              >
                メールアドレスの形式が正しくありません
              </span>
            </div>
            <div class="input-field">
              <input
                matInput
                id="password"
                type="password"
                placeholder="パスワード"
                autocomplete="current-password"
                formControlName="password"
                required
                [class.error]="
                  this.password.touched && this.password.hasError('required')
                "
              />
              <span
                class="error-message"
                *ngIf="
                  this.password.touched && this.password.hasError('required')
                "
              >
                パスワードは必須です
              </span>
            </div>
          </div>
        </div>
        <div class="forgot-password-container">
          <a [routerLink]="['/forgot-password']" mat-button color="primary"
            >パスワードを忘れた場合</a
          >
        </div>
        <div class="button-wrapper">
          <button
            type="submit"
            rectangle
            class="primary"
            [disabled]="loginForm.invalid"
          >
            ログイン
          </button>
        </div>
      </form>
      <div class="agreement">
        ログインすることで、当社の利用約款およびプライバシーポリシーに同意したものとみなされます。
      </div>
      <div class="divider"></div>
      <div class="contact">
        アカウントをお持ちでない方は 弊社営業へお問い合わせください
      </div>
    </div>
    <app-loading [showLoading]="isLoading" [fullScreen]="false"></app-loading>
  </div>
  <div id="recaptcha-container-id"></div>
</div>
