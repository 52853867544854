<div class="container">
  <h1 class="header">
    <div class="title">
      <mat-icon class="warning" fontIcon="warning_amber"></mat-icon
      >連携設定をリセットしますか？
    </div>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="main">
    <div class="success-message">
      <p>
        オフにすると、現在の連携設定がリセットされます。この操作を実行してもよろしいですか？
      </p>
    </div>
  </div>
  <div class="footer">
    <button rectangle variant="secondary" (click)="this.cancel()">
      キャンセル
    </button>
    <button rectangle variant="primary" (click)="this.reset()">リセット</button>
  </div>
</div>
