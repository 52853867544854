<div class="wrapper">
  <div class="container">
    <div class="page-header">
      <h1 class="heading">外部連携</h1>
    </div>
    <p class="caption">
      外部アカウントを連携することで、対象のサービスを活用した自動的なデータ連携やアセスメントが可能になります。
    </p>
    <div class="alert" *ngIf="hasZoomIntegrationError">
      <mat-icon fontIcon="warning_amber" class="icon"></mat-icon>
      <div class="message">
        <span class="strong">zoom連携が切断されました。</span>
        <div>
          接続が切れた可能性があります。認証情報を確認し、再接続してください。
        </div>
      </div>
    </div>
    <div
      class="zoom common-integration"
      *ngIf="this.isZoomIntegrationEnabledTenant()"
    >
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2 class="card-heading">Zoomを連携</h2>
            <div class="card-caption">
              Zoomアカウントを連携することで、Zoomレコーディングを元にアセスメントを実施できるようになります。
            </div>
          </div>
          <mat-slide-toggle
            [(ngModel)]="isZoomIntegrationEnabled"
            (change)="onZoomToggleChange($event)"
          ></mat-slide-toggle>
        </div>
        <div class="card-actions" *ngIf="isZoomIntegrationEnabled">
          <button rectangle (click)="openZoomSettingDialog()">
            アセスメント設定
          </button>
        </div>
      </div>
    </div>
    <div class="alert" *ngIf="hasAmptalkIntegrationError">
      <mat-icon fontIcon="warning_amber" class="icon"></mat-icon>
      <div class="message">
        <span class="strong">amptalk連携が切断されました。</span>
        <div>
          APIキーまたは認証キーの有効期限が切れた可能性があります。認証情報を確認し、更新を行ってください。
        </div>
      </div>
    </div>
    <div class="amptalk common-integration">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2 class="card-heading">amptalkを連携</h2>
            <div class="card-caption">
              amptalkアカウントを連携することで、コールデータを元にアセスメントを実施できるようになります。
            </div>
          </div>
          <mat-slide-toggle
            [(ngModel)]="isAmptalkIntegrationEnabled"
            (change)="onAmptalkToggleChange($event)"
          ></mat-slide-toggle>
        </div>
        <div class="card-actions" *ngIf="isAmptalkIntegrationEnabled">
          <button rectangle (click)="openAmptalkSettingDialog()">
            アセスメント設定
          </button>
          <button
            rectangle
            (click)="openAmptalkIntegrationDialog()"
            [disabled]="!isAmptalkIntegrationEnabled"
            variant="secondary"
          >
            <mat-icon>sync</mat-icon>
            認証キーを更新
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
