import { Injectable } from '@angular/core'

import { Observable, catchError, map, of } from 'rxjs'

import {
  GetMaskedKeysGQL,
  GetAutoAssessmentSettingGQL,
  GetOutboundIntegrationSettingGQL,
  SaveAmptalkKeyGQL,
  InboundConnectService,
  OutboundConnectService,
  SecretKeyType,
  SaveAutoAssessmentSettingGQL,
  SaveOutboundIntegrationSettingGQL,
  DisconnectAmptalkGQL,
  DisconnectZoomGQL,
  SaveZoomTokenGQL,
  CheckSecretKeyExistenceGQL,
  SaveSalesforceTokenGQL,
  ValidateSalesforceIntegrationSettingGQL,
  DisconnectSalesforceGQL,
} from '../../../_generated/graphql'

import {
  mappingAutoAssessmentSetting,
  AutoAssessmentSetting,
  mappingOutboundIntegrationSetting,
  OutboundIntegrationSetting,
  mappingSalesforceIntegrationSetting,
  SalesforceValidationResult,
} from './external-integration.mapping'
import { throwIfCustomError } from '../../util/throwIfCustomError'

@Injectable({
  providedIn: 'root',
})
export class ExternalIntegrationService {
  constructor(
    private getMaskedKeysGQL: GetMaskedKeysGQL,
    private getAutoAssessmentSettingGQL: GetAutoAssessmentSettingGQL,
    private getOutboundIntegrationSettingGQL: GetOutboundIntegrationSettingGQL,
    private saveAmptalkKeyGQL: SaveAmptalkKeyGQL,
    private saveZoomTokenGQL: SaveZoomTokenGQL,
    private saveSalesforceTokenGQL: SaveSalesforceTokenGQL,
    private saveAutoAssessmentSettingGQL: SaveAutoAssessmentSettingGQL,
    private saveOutboundIntegrationSettingGQL: SaveOutboundIntegrationSettingGQL,
    private disconnectAmptalkGQL: DisconnectAmptalkGQL,
    private disconnectZoomGQL: DisconnectZoomGQL,
    private CheckSecretKeyExistenceGQL: CheckSecretKeyExistenceGQL,
    private validateSalesforceIntegrationSettingGQL: ValidateSalesforceIntegrationSettingGQL,
    private disconnectSalesforceGQL: DisconnectSalesforceGQL,
  ) {}

  getAutoAssessmentSetting(
    connectService: InboundConnectService,
  ): Observable<AutoAssessmentSetting | null> {
    return this.getAutoAssessmentSettingGQL
      .fetch({
        connectService,
      })
      .pipe(
        map((res) => {
          return mappingAutoAssessmentSetting(res.data.getAutoAssessmentSetting)
        }),
      )
  }

  getOutboundIntegrationSetting(
    connectService: OutboundConnectService,
  ): Observable<OutboundIntegrationSetting | null> {
    return this.getOutboundIntegrationSettingGQL
      .fetch({
        connectService,
      })
      .pipe(
        map((res) => {
          return mappingOutboundIntegrationSetting(
            res.data.getOutboundIntegrationSetting,
          )
        }),
      )
  }

  getAmptalkKeys(): Observable<AmptalkKeys> {
    return this.getMaskedKeysGQL
      .fetch({
        connectService: InboundConnectService.AmpTalk,
      })
      .pipe(
        map((res) => {
          const maskedKeys = res.data.getMaskedKeys
          const apiKey =
            maskedKeys.find((key) => key.keyType === SecretKeyType.AmptalkApi)
              ?.maskedKey || ''
          const authKey =
            maskedKeys.find((key) => key.keyType === SecretKeyType.AmptalkAuth)
              ?.maskedKey || ''
          return {
            apiKey,
            authKey,
          }
        }),
      )
  }

  checkSecretKeyExistence(secretKeyType: SecretKeyType): Observable<boolean> {
    return this.CheckSecretKeyExistenceGQL.fetch({ secretKeyType }).pipe(
      map((res) => res.data.checkSecretKeyExistence),
    )
  }

  validateSalesforceIntegrationSetting(): Observable<SalesforceValidationResult> {
    return this.validateSalesforceIntegrationSettingGQL
      .fetch()
      .pipe(
        map((res) =>
          mappingSalesforceIntegrationSetting(
            res.data.validateSalesforceIntegrationSetting,
          ),
        ),
      )
  }

  saveAmptalkKey(apiKey: string, authKey: string) {
    return this.saveAmptalkKeyGQL.mutate({ apiKey, authKey })
  }

  saveZoomToken(code: string) {
    return this.saveZoomTokenGQL.mutate({ code })
  }

  saveSalesforceToken(code: string) {
    return this.saveSalesforceTokenGQL.mutate({ code })
  }

  saveAutoAssessmentSetting(
    setting: SaveAutoAssessmentSettingParam,
    connectServiceName: InboundConnectService,
  ) {
    return this.saveAutoAssessmentSettingGQL
      .mutate({
        param: {
          ...setting,
          connectServiceName,
        },
      })
      .pipe(
        catchError((error: unknown) => {
          throwIfCustomError(error)
          return of()
        }),
      )
  }

  saveOutboundIntegrationSetting(
    setting: SaveOutboundSettingParam,
    connectServiceName: OutboundConnectService,
  ) {
    return this.saveOutboundIntegrationSettingGQL
      .mutate({
        param: {
          ...setting,
          connectServiceName,
        },
      })
      .pipe(
        catchError((error: unknown) => {
          throwIfCustomError(error)
          return of()
        }),
      )
  }

  disconnectAmptalk() {
    return this.disconnectAmptalkGQL.mutate()
  }

  disconnectZoom() {
    return this.disconnectZoomGQL.mutate()
  }

  disconnectSalesforce() {
    return this.disconnectSalesforceGQL.mutate()
  }
}

export interface TargetUser {
  id: string
  name: string
  email: string
  departmentName?: string | null
  thumbnailPath?: string | null
  reportTo?: {
    id: string
    name: string
  } | null
  license?: {
    id: string
    plan: string
    userMonthlyLimitSeconds: number
    extraChargeLimitSeconds: number
    usedSeconds: number
  } | null
}

export interface AmptalkKeys {
  apiKey: string
  authKey: string
}

export interface SaveAutoAssessmentSettingParam {
  id?: string
  syncStartDate: Date
  skillMapId: string
  phaseIds: string[]
  targetUserIds: string[]
  minTransferDurationSeconds: number | null
  whiteList: string[]
  blackList: string[]
}

export interface SaveOutboundSettingParam {
  id?: string
  targetUserIds: string[]
}
