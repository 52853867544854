import {
  AssessmentStatus,
  AssessmentQuestionType,
} from '../../../_generated/graphql'

export const mappingAssessment = (
  fetchedAssessment: FetchedAssessment,
): Assessment => {
  return {
    id: fetchedAssessment.id,
    orgId: fetchedAssessment.orgId,
    user: {
      id: fetchedAssessment.user.id,
      orgId: fetchedAssessment.user.orgId,
      name: fetchedAssessment.user.name,
      email: fetchedAssessment.user.email,
      departmentName: fetchedAssessment.user.departmentName,
    },
    businessMeeting: {
      id: fetchedAssessment.businessMeeting.id,
      orgId: fetchedAssessment.businessMeeting.orgId,
      account: fetchedAssessment.businessMeeting.account
        ? {
            id: fetchedAssessment.businessMeeting.account.id,
            orgId: fetchedAssessment.businessMeeting.account.orgId,
            name: fetchedAssessment.businessMeeting.account.name,
          }
        : null,
      recordDate: fetchedAssessment.businessMeeting.recordDate,
      filePath: fetchedAssessment.businessMeeting.filePath,
      title: fetchedAssessment.businessMeeting.title,
      transcript: fetchedAssessment.businessMeeting.transcript,
      summary: fetchedAssessment.businessMeeting.summary || null,
      applicablePhaseNames:
        fetchedAssessment.businessMeeting.applicablePhaseNames,
      reference: fetchedAssessment.businessMeeting.reference,
    },
    assessDate: new Date(fetchedAssessment.assessDate),
    keyActionEvaluations: fetchedAssessment.keyActionEvaluations.map(
      (keyActionEvaluation) => ({
        id: keyActionEvaluation.id,
        orgId: keyActionEvaluation.orgId,
        keyAction: {
          id: keyActionEvaluation.keyAction.id,
          orgId: keyActionEvaluation.keyAction.orgId,
          name: keyActionEvaluation.keyAction.name,
          alias:
            'KA' +
            String(keyActionEvaluation.keyAction.orderNo + 1).padStart(2, '0'),
          orderNo: keyActionEvaluation.keyAction.orderNo,
          assessmentQuestionType:
            keyActionEvaluation.keyAction.assessmentQuestionType,
        },
        score: keyActionEvaluation.score,
        evaluation: keyActionEvaluation.evaluation,
        highlight: keyActionEvaluation.highlight,
        recommendedContents: keyActionEvaluation.recommendedContents,
        timeSeries: keyActionEvaluation.timeSeriesEvaluations.map((ts) => {
          return {
            fromTime: ts.fromTime,
            toTime: ts.toTime,
            score: ts.score,
            reason: ts.reason,
            improvement: ts.improvement,
            strength: ts.strength,
            quote: ts.quote,
          }
        }),
      }),
    ),
    status: fetchedAssessment.status,
    skillMapName: fetchedAssessment.skillMapName,
    durationSeconds: fetchedAssessment.durationSeconds,
  }
}

export const mappingAssessmentSummary = (
  fetchedAssessment: FetchedAssessmentSummary,
): AssessmentSummary => {
  return {
    id: fetchedAssessment.id,
    orgId: fetchedAssessment.orgId,
    user: {
      id: fetchedAssessment.user.id,
      orgId: fetchedAssessment.user.orgId,
      name: fetchedAssessment.user.name,
      email: fetchedAssessment.user.email,
      departmentName: fetchedAssessment.user.departmentName,
      reportTo: fetchedAssessment.user.reportTo,
    },
    businessMeeting: {
      id: fetchedAssessment.businessMeeting.id,
      orgId: fetchedAssessment.businessMeeting.orgId,
      account: fetchedAssessment.businessMeeting.account
        ? {
            id: fetchedAssessment.businessMeeting.account.id,
            orgId: fetchedAssessment.businessMeeting.account.orgId,
            name: fetchedAssessment.businessMeeting.account.name,
          }
        : null,
      recordDate: fetchedAssessment.businessMeeting.recordDate,
      title: fetchedAssessment.businessMeeting.title,
      applicablePhaseNames:
        fetchedAssessment.businessMeeting.applicablePhaseNames,
    },
    assessDate: new Date(fetchedAssessment.assessDate),
    keyActionEvaluations: fetchedAssessment.keyActionEvaluations.map(
      (keyActionEvaluation) => ({
        id: keyActionEvaluation.id,
        orgId: keyActionEvaluation.orgId,
        score: keyActionEvaluation.score,
        recommendedContents: keyActionEvaluation.recommendedContents,
      }),
    ),
    status: fetchedAssessment.status,
    skillMapName: fetchedAssessment.skillMapName,
  }
}

export const mappingSkillMap = (skillMap: FetchedSkillMap): SkillMap => {
  return {
    id: skillMap.id,
    name: skillMap.name,
    phases: skillMap.phases.map((phase) => {
      return {
        id: phase.id,
        name: phase.name,
        keyActions: phase.keyActions.map((keyAction) => {
          return {
            id: keyAction.id,
            name: keyAction.name,
            orderNo: keyAction.orderNo,
            alias: 'KA' + String(keyAction.orderNo + 1).padStart(2, '0'),
          }
        }),
      }
    }),
  }
}

interface TranscriptEntryModel {
  speaker: string
  speakedAt: string
  quote: string
}

export interface FetchedAssessment {
  id: string
  orgId: string
  user: {
    id: string
    orgId: string
    name: string
    email: string
    departmentName?: string | null
  }
  businessMeeting: {
    id: string
    orgId: string
    account?: {
      id: string
      orgId: string
      name: string
    } | null
    recordDate: Date
    filePath: string
    title: string
    transcript?: TranscriptEntryModel[]
    summary?: string | null
    applicablePhaseNames?: string[]
    reference?: string | null
  }
  skillMapName?: string
  assessDate: Date
  keyActionEvaluations: FetchedKeyActionEvaluation[]
  status: AssessmentStatus
  durationSeconds: number
}

export interface FetchedAssessmentSummary {
  id: string
  orgId: string
  user: {
    id: string
    orgId: string
    name: string
    email: string
    departmentName?: string | null
    reportTo?: {
      id: string
    } | null
  }
  businessMeeting: {
    id: string
    orgId: string
    account?: {
      id: string
      orgId: string
      name: string
    } | null
    recordDate: Date
    title: string
    applicablePhaseNames?: string[]
  }
  skillMapName?: string
  assessDate: Date
  keyActionEvaluations: {
    id: string
    orgId: string
    score: number | null
    recommendedContents: RecommendedContent[]
  }[]
  status: AssessmentStatus
}

interface FetchedSkillMap {
  id: string
  name: string
  phases: FetchedPhase[]
}

interface FetchedPhase {
  id: string
  name: string
  keyActions: FetchedKeyAction[]
}

interface FetchedKeyAction {
  id: string
  name: string
  orderNo: number
}

export interface Assessment {
  id: string
  orgId: string
  user: {
    id: string
    orgId: string
    name: string
    email: string
    departmentName?: string | null
  }
  businessMeeting: {
    id: string
    orgId: string
    account?: {
      id: string
      orgId: string
      name: string
    } | null
    recordDate: Date
    filePath: string
    title: string
    transcript?: TranscriptEntryModel[]
    summary: string | null
    applicablePhaseNames?: string[]
    reference?: string | null
  }
  assessDate: Date
  keyActionEvaluations: KeyActionEvaluation[]
  status: AssessmentStatus
  skillMapName?: string
  durationSeconds: number
}

export interface AssessmentSummary {
  id: string
  orgId: string
  user: {
    id: string
    orgId: string
    name: string
    email: string
    departmentName?: string | null
    reportTo?: {
      id: string
    } | null
  }
  businessMeeting: {
    id: string
    orgId: string
    account?: {
      id: string
      orgId: string
      name: string
    } | null
    recordDate: Date
    title: string
    applicablePhaseNames?: string[]
  }
  assessDate: Date
  keyActionEvaluations: {
    id: string
    orgId: string
    score: number | null
    recommendedContents: RecommendedContent[]
  }[]
  status: AssessmentStatus
  skillMapName?: string
}

export interface SkillMap {
  id: string
  name: string
  phases: Phase[]
}

export interface Phase {
  id: string
  name: string
  keyActions: KeyAction[]
}

export interface KeyAction {
  id: string
  name: string
  orderNo: number
  alias: string
}

export interface RecommendedContent {
  id: string
  content: Content
  isAccessed: boolean
}

export interface Content {
  name: string
  url: string
}

export interface KeyActionEvaluation {
  id: string
  orgId: string
  keyAction: {
    id: string
    orgId: string
    name: string
    orderNo: number
    alias: string
    assessmentQuestionType: AssessmentQuestionType
  }
  score: number
  evaluation: string
  highlight: string
  recommendedContents: RecommendedContent[]
  timeSeries: TimeSeriesEvaluation[]
}

export interface FetchedKeyActionEvaluation {
  id: string
  orgId: string
  keyAction: {
    id: string
    orgId: string
    name: string
    orderNo: number
    assessmentQuestionType: AssessmentQuestionType
  }
  score: number
  evaluation: string
  highlight: string
  recommendedContents: RecommendedContent[]
  timeSeriesEvaluations: FetchedKeyActionTimeSeriesEvaluation[]
}

export interface TimeSeriesEvaluation {
  fromTime: string
  toTime: string
  score: number
  reason: string
  improvement: string
  strength: string
  quote: string
}

interface FetchedKeyActionTimeSeriesEvaluation {
  fromTime: string
  toTime: string
  score: number
  reason: string
  improvement: string
  strength: string
  quote: string
}
