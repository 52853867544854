import { SUPPORTED_AUDIO_EXTENSIONS } from './constants'

export const isAudioFile = (file: File): boolean => {
  return SUPPORTED_AUDIO_EXTENSIONS.some((ext) =>
    file.name.toLowerCase().endsWith(`.${ext.toLocaleLowerCase()}`),
  )
}

export const calculateAudioDuration = (file: File): Promise<number | null> => {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(file)
    const mediaElement = document.createElement(
      file.type.startsWith('audio') ? 'audio' : 'video',
    )
    mediaElement.src = url
    mediaElement.addEventListener('loadedmetadata', function () {
      const duration = Math.ceil(mediaElement.duration)
      resolve(duration)
      URL.revokeObjectURL(url)
    })

    mediaElement.addEventListener('error', function () {
      resolve(null)
      URL.revokeObjectURL(url)
    })
  })
}
