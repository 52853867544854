import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { MatIcon } from '@angular/material/icon'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
  MatCheckboxModule,
  MatCheckboxChange,
} from '@angular/material/checkbox'

import { tap, catchError, of } from 'rxjs'

import { MessageDialogService } from '../../../components/message-dialog/message-dialog.service'
import {
  UserService,
  PrivacySettingType,
} from '../../../services/user/user.service'
import { getTitle } from '../../../util/accessibility'
interface PrivacySettingOption {
  type: PrivacySettingType
  title: string
  description: string
}

@Component({
  selector: 'app-organization-setting-page',
  standalone: true,
  imports: [CommonModule, MatIcon, MatCheckboxModule],
  templateUrl: './organization-setting-page.component.html',
  styleUrl: './organization-setting-page.component.scss',
})
export class OrganizationSettingPageComponent implements OnInit {
  privacySettingOptions: PrivacySettingOption[] = [
    {
      type: 'FULLY_OPEN',
      title: 'Fully Open',
      description: '全ユーザーが全ユーザーのデータを見ることが可能です。',
    },
    {
      type: 'PARTIALLY_OPEN',
      title: 'Partially Open',
      description:
        '自分のデータ、自分のマネジメントチェーン、直属の上司を共有しているユーザー、直属の上司を共有しているユーザーのマネジメントチェーンを見ることが可能です。',
    },
    {
      type: 'FULLY_CLOSED',
      title: 'Fully Closed',
      description:
        '全ユーザーが、自分と自分の部下のデータのみ見ることが可能です。',
    },
  ]
  selectedPrivacySetting: PrivacySettingType | undefined = undefined
  requiredMfa: boolean = false

  constructor(
    private messageDialogService: MessageDialogService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('組織管理'))
  }

  ngOnInit(): void {
    this.userService
      .getAdminOrg()
      .pipe(
        tap((adminOrg) => {
          this.selectedPrivacySetting = adminOrg.privacySetting
          this.requiredMfa = adminOrg.requiredMfa
        }),
      )
      .subscribe()
  }

  onPrivacySettingChange(setting: PrivacySettingType): void {
    if (setting === this.selectedPrivacySetting) {
      return
    }

    this.selectedPrivacySetting = setting
    this.userService
      .updatePrivacySetting(setting)
      .pipe(
        tap(() => {
          this.snackBar.open('プライバシー設定を更新しました。', 'close', {
            duration: 3000,
          })
        }),
        catchError(() => {
          this.messageDialogService.showError(
            'プライバシー設定の更新に失敗しました。時間を置いて再度お試しください。',
            {
              title: 'エラー',
            },
          )
          return of()
        }),
      )
      .subscribe()
  }

  onMfaChange(event: MatCheckboxChange): void {
    this.requiredMfa = event.checked

    this.userService
      .updateRequiredMfa(event.checked)
      .pipe(
        tap(() => {
          this.snackBar.open('二要素認証を更新しました。', 'close', {
            duration: 3000,
          })
        }),
        catchError(() => {
          event.source.checked = !event.checked
          this.messageDialogService.showError(
            '二要素認証の更新に失敗しました。時間を置いて再度お試しください。',
            {
              title: 'エラー',
            },
          )
          return of()
        }),
      )
      .subscribe()
  }
}
