import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core'

import * as Highcharts from 'highcharts'
import Accessibility from 'highcharts/modules/accessibility'

import { chartColor } from '../../../constants/chart'
import { ColorAllocator } from '../../../utils/color-allocator'
import { HighchartsChartModule } from 'highcharts-angular'

export type Row = { name: string; y: number }
export type SelectSeriesEvent = {
  label: string
  index: number
}

Accessibility(Highcharts)
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  standalone: true,
  imports: [HighchartsChartModule],
})
export class PieComponent
  implements AfterViewInit, OnDestroy, OnChanges, OnInit
{
  @ViewChild('container') container!: ElementRef
  @Input() average: string = ''
  @Input() data: Row[] = []
  @Output() readonly selectSeries = new EventEmitter<SelectSeriesEvent>()

  private readonly colorAllocator = new ColorAllocator(chartColor.map((c) => c))
  chart: Highcharts.Chart | undefined = undefined
  readonly Highcharts = Highcharts
  options!: Highcharts.Options

  private readonly observer: ResizeObserver = new ResizeObserver(() => {
    this.resizeChart()
  })

  ngOnInit(): void {
    this.options = {
      chart: {
        type: 'pie',
        spacing: [24, 24, 24, 24],
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      subtitle: {
        text: undefined,
      },
      colors: [...chartColor],
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.updateChart()
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.observer.observe(this.container?.nativeElement)
      this.resizeChart()
    }, 100)
  }

  ngOnDestroy(): void {
    this.observer.disconnect()
  }

  chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    this.chart = chart
    this.updateChart()
  }

  onSelectSeries($event: Highcharts.SeriesClickEventObject): void {
    this.selectSeries.emit({
      label: $event.point.series.name,
      index: $event.point.series.index,
    })
  }

  private updateChart(): void {
    if (!this.chart) return

    this.colorAllocator.allocate(this.data.map((row) => row.name))

    this.chart?.update(
      {
        chart: {
          type: 'pie',
          spacing: [24, 24, 24, 24],
        },
        subtitle: {
          useHTML: true,
          text: `<div style="text-align:center;"><h1 style="color: #333;">${this.average}</h1><div>平均スコア</div></div>`,
          floating: true,
          verticalAlign: 'middle',
          y: -10,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },

        legend: {
          labelFormatter: function () {
            return (
              this.name +
              ':' +
              (this as unknown as { y: string }).y +
              '(' +
              (this as unknown as { percentage: number }).percentage.toFixed(
                1,
              ) +
              '%)'
            )
          },
        },
        series: [
          {
            type: 'pie',
            name: '人数',
            data: this.data,
            innerSize: '65%',
          },
        ],
      },
      true,
      true,
    )
  }

  private resizeChart(): void {
    if (!this.container?.nativeElement) return

    const width = this.container.nativeElement.offsetWidth
    const height = this.container.nativeElement.offsetHeight

    this.chart?.update({
      chart: {
        width,
        height,
      },
    })
  }
}
