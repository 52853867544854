{
  "name": "encoding-japanese",
  "version": "2.2.0",
  "description": "Convert and detect character encoding in JavaScript",
  "main": "src/index.js",
  "files": [
    "encoding.js",
    "encoding.min.js",
    "src/*"
  ],
  "scripts": {
    "build": "npm run compile && npm run minify",
    "compile": "browserify src/index.js -o encoding.js -s Encoding -p [ bannerify --file src/banner.js ] --no-bundle-external --bare",
    "minify": "uglifyjs encoding.js -o encoding.min.js --comments -c -m -b ascii_only=true,beautify=false",
    "test": "eslint . && npm run build && mocha tests/test",
    "watch": "watchify src/index.js -o encoding.js -s Encoding -p [ bannerify --file src/banner.js ] --no-bundle-external --bare --poll=300 -v"
  },
  "engines": {
    "node": ">=8.10.0"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/polygonplanet/encoding.js.git"
  },
  "author": "polygonplanet <polygon.planet.aqua@gmail.com>",
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/polygonplanet/encoding.js/issues"
  },
  "homepage": "https://github.com/polygonplanet/encoding.js",
  "keywords": [
    "base64",
    "charset",
    "convert",
    "detect",
    "encoding",
    "euc-jp",
    "eucjp",
    "iconv",
    "iso-2022-jp",
    "japanese",
    "jis",
    "shift_jis",
    "sjis",
    "unicode",
    "urldecode",
    "urlencode",
    "utf-16",
    "utf-32",
    "utf-8"
  ],
  "dependencies": {},
  "devDependencies": {
    "bannerify": "^1.0.1",
    "browserify": "^17.0.0",
    "eslint": "^8.57.0",
    "mocha": "^10.4.0",
    "package-json-versionify": "^1.0.4",
    "power-assert": "^1.6.1",
    "uglify-js": "^3.17.4",
    "uglifyify": "^5.0.2",
    "watchify": "^4.0.0"
  },
  "browserify": {
    "transform": [
      "package-json-versionify"
    ]
  }
}
