import { Component, OnInit, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'

import { MatIcon } from '@angular/material/icon'
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { tap, catchError } from 'rxjs/operators'

import { invalidStringValidator } from '../../../../directives/validator/custom-validator'
import { ButtonComponent } from '../../../../components/button/button.component'
import { AmptalkIntegrationErrorComponent } from '../messages/amptalk-integration-error/amptalk-integration-error.component'
import { ExternalIntegrationService } from '../../../../services/external-integration/external-integration.service'

export type AmptalkIntegrationData = {
  apiKey: string
  authKey: string
}
@Component({
  selector: 'app-amptalk-integration',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIcon, ButtonComponent],
  templateUrl: './amptalk-integration.component.html',
  styleUrls: ['./amptalk-integration.component.scss'],
})
export class AmptalkIntegrationComponent implements OnInit {
  apiKey = new FormControl()
  authKey = new FormControl()

  formGroup = this.formBuilder.group({
    apiKey: this.apiKey,
    authKey: this.authKey,
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AmptalkIntegrationData,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AmptalkIntegrationComponent>,
    private dialog: MatDialog,
    private externalIntegrationService: ExternalIntegrationService,
  ) {}

  ngOnInit(): void {
    const apiKeyRegex = /^[a-zA-Z0-9]{40}$/

    this.apiKey.setValidators([
      Validators.required,
      invalidStringValidator(apiKeyRegex),
    ])

    const authKeyRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

    this.authKey.setValidators([
      Validators.required,
      invalidStringValidator(authKeyRegex),
    ])

    this.apiKey.setValue(this.data.apiKey)
    this.authKey.setValue(this.data.authKey)
  }

  onSubmit(event: Event): void {
    event.preventDefault()

    this.externalIntegrationService
      .saveAmptalkKey(this.apiKey.value, this.authKey.value)
      .pipe(
        tap(() => {
          this.dialogRef.close({
            apiKey: this.apiKey.value,
            authKey: this.authKey.value,
          })
        }),
        catchError(() => {
          this.openErrorDialog()
          return []
        }),
      )
      .subscribe()
  }

  close(): void {
    this.dialogRef.close()
  }

  private openErrorDialog(): void {
    this.dialog.open(AmptalkIntegrationErrorComponent)
  }
}
