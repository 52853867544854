import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core'

import * as Highcharts from 'highcharts'
import Accessibility from 'highcharts/modules/accessibility'

import { chartColor } from '../../../constants/chart'
import { ColorAllocator } from '../../../utils/color-allocator'
import { HighchartsChartModule } from 'highcharts-angular'

export type Row = { name: string; data: [number, number | null][] }
export type SelectSeriesEvent = {
  label: string
  index: number
}

Accessibility(Highcharts)
@Component({
  selector: 'app-time-series-chart',
  templateUrl: './time-series-chart.component.html',
  styleUrls: ['./time-series-chart.component.scss'],
  standalone: true,
  imports: [HighchartsChartModule],
})
export class TimeSeriesComponent
  implements AfterViewInit, OnDestroy, OnChanges, OnInit
{
  @ViewChild('container') container!: ElementRef
  @Input() data: Row[] = []
  @Output() readonly selectSeries = new EventEmitter<SelectSeriesEvent>()

  private readonly colorAllocator = new ColorAllocator(chartColor.map((c) => c))
  chart: Highcharts.Chart | undefined = undefined
  readonly Highcharts = Highcharts
  options!: Highcharts.Options

  private readonly observer: ResizeObserver = new ResizeObserver(() => {
    this.resizeChart()
  })

  ngOnInit(): void {
    this.options = {
      chart: {
        type: 'line',
        spacing: [24, 24, 24, 24],
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      subtitle: {
        text: undefined,
      },
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          style: {
            fontSize: '12px',
            color: '#282829',
          },
        },
        gridLineDashStyle: 'Dash',
        tickPositions: [0, 1, 2, 3, 4, 5],
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 7 * 24 * 3600 * 1000,
        dateTimeLabelFormats: {
          year: '%Y',
          month: '%Y/%m',
          week: '%Y/%m/%d',
        },
        labels: {
          style: {
            fontSize: '12px',
            color: '#282829',
          },
        },
        minPadding: 0,
        startOfWeek: 0,
      },
      plotOptions: {
        series: {
          connectNulls: true,
        },
      },
      tooltip: {
        xDateFormat: '%Y/%m/%d',
      },
      colors: [...chartColor],
      series: [],
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.updateChart()
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.observer.observe(this.container?.nativeElement)
      this.resizeChart()
    }, 100)
  }

  ngOnDestroy(): void {
    this.observer.disconnect()
  }

  chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    this.chart = chart
    this.updateChart()
  }

  onSelectSeries($event: Highcharts.SeriesClickEventObject): void {
    this.selectSeries.emit({
      label: $event.point.series.name,
      index: $event.point.series.index,
    })
  }

  private updateChart(): void {
    if (!this.chart) return

    this.colorAllocator.allocate(this.data.map((row) => row.name))

    this.chart?.update(
      {
        yAxis: {
          title: {
            text: 'スコア',
            align: 'high',
            rotation: 0,
            style: {
              fontWeight: '600',
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
          layout: 'horizontal',
          y: 0,
        },
        series: this.data.map((row) => ({
          name: row.name,
          color: this.colorAllocator.getColor(row.name),
          type: 'line',
          data: [...row.data],
          point: {
            events: {
              click: (e) => this.onSelectSeries(e),
            },
          },
        })),
      },
      true,
      true,
    )
  }

  private resizeChart(): void {
    if (!this.container?.nativeElement) return

    const width = this.container.nativeElement.offsetWidth
    const height = this.container.nativeElement.offsetHeight

    this.chart?.update({
      chart: {
        width,
        height,
      },
    })
  }
}
