import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  FormControl,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../components/button/button.component'

import { AssessmentService } from '../../../../services/assessment/assesssment.service'

export function previousValueValidator(previousValue: unknown): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const currentValue = control.value

    if (currentValue === previousValue) {
      return {
        previousValueError: {
          previous: previousValue,
          current: currentValue,
        },
      }
    }

    return null
  }
}

export type RenameBusinessMeetingData = {
  assessmentId: string
  name: string
}
@Component({
  selector: 'app-rename-business-meeting',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIcon, ButtonComponent],
  templateUrl: './rename-business-meeting.component.html',
  styleUrls: ['./rename-business-meeting.component.scss'],
})
export class RenameBusinessMeetingComponent implements OnInit, AfterViewInit {
  @ViewChild('nameInput') nameInput: ElementRef<HTMLInputElement> | undefined
  name = new FormControl()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RenameBusinessMeetingData,
    private dialogRef: MatDialogRef<RenameBusinessMeetingComponent>,
    private assessmentService: AssessmentService,
  ) {}

  ngOnInit(): void {
    this.name.setValidators([
      Validators.required,
      previousValueValidator(this.data.name),
    ])
    this.name.setValue(this.data.name)
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.nameInput?.nativeElement.focus()
    }, 0)
  }

  onSubmit(event: Event): void {
    event.preventDefault()

    if (this.name.invalid) {
      return
    }

    this.assessmentService
      .updateAssessment({
        assessmentId: this.data.assessmentId,
        businessMeetingName: this.name.value,
      })
      .subscribe(() => {
        this.dialogRef.close(this.name.value)
      })
  }

  close(): void {
    this.dialogRef.close()
  }
}
