<div class="container">
  <div class="header">
    <h1 class="title">ユーザー管理</h1>
    <div class="actions">
      <button rectangle [matMenuTriggerFor]="userRegistrationMenu">
        <mat-icon>add</mat-icon>ユーザー登録
        <span class="vertical-line"></span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #userRegistrationMenu="matMenu">
        <ng-template matMenuContent>
          <div class="menu-item-width">
            <button mat-menu-item (click)="addUser()">個別登録</button>
            <button mat-menu-item (click)="onCsvImportButtonClicked()">
              一括登録
            </button>
          </div>
        </ng-template>
      </mat-menu>
    </div>
  </div>
  <div class="sub-header">
    <div class="filter">
      <div class="input-name">
        <input [formControl]="this.name" [placeholder]="'名前で検索'" />
      </div>
      <app-select
        class="select-department"
        [options]="this.selectableDepartments"
        (selectedOptionChange)="this.departmentSelected($event)"
      ></app-select>
      <div class="grow"></div>
    </div>
    <div
      class="remaining-licenses"
      [matTooltip]="this.getRemainingLicensesInfo()"
      matTooltipClass="pre-line-tooltip"
    >
      {{
        '残りのライセンス数: ' +
          (this.adminOrg
            ? this.adminOrg.licenseInfo.count -
              this.adminOrg.licenseInfo.usedCount
            : 0) +
          '件'
      }}
      <app-loading [showLoading]="this.licenseIsLoading"></app-loading>
    </div>
  </div>
  <div
    class="bulk-actions"
    [ngClass]="{ 'visible-hidden': this.checkedState === 'UNCHECKED' }"
  >
    <div class="selected-count">{{ checkedCount }}&nbsp;{{ '件選択中' }}</div>
    <button
      type="button"
      class="action-button"
      (click)="this.resendInvitationBulk()"
    >
      <mat-icon class="material-icons-outlined">send</mat-icon>
      {{ '招待メール再送信' }}
    </button>
    <button
      type="button"
      class="action-button"
      (click)="this.deleteAccountBulk()"
    >
      <mat-icon class="material-icons-outlined">delete</mat-icon>
      {{ 'ユーザーの削除' }}
    </button>
  </div>
  <div class="list-header">
    <div class="col-select">
      <mat-checkbox
        [checked]="this.checkedState === 'CHECKED'"
        [indeterminate]="this.checkedState === 'INDETERMINATE'"
        (change)="this.checkAllUsers($event.checked)"
      ></mat-checkbox>
    </div>
    <div class="col-name col-head">名前</div>
    <div class="col-department col-head">部署</div>
    <div class="col-report-to col-head">上司</div>
    <div class="col-roles col-head">ロール</div>
    <div class="col-license col-head">ライセンス</div>

    <div class="col-operation col-head"></div>
  </div>
  <div
    class="list"
    [ngClass]="{ 'item-selecting': this.checkedCount > 0 }"
    infinite-scroll
    [scrollWindow]="false"
    (scrolled)="loadMoreUsers()"
  >
    <ng-container *ngFor="let user of this.users">
      <div class="list-row">
        <div class="col-select">
          <mat-checkbox
            #checkbox
            [checked]="user.selected"
            (change)="this.checkChanged(checkbox, user)"
          ></mat-checkbox>
        </div>
        <div class="col-name col-val">
          <div class="user-info">
            <div class="avatar">
              <app-avatar
                [thumbnailUrl]="user.thumbnailPath ?? ''"
              ></app-avatar>
            </div>
            <div class="user-name-email">
              <div class="name">{{ user.name }}</div>
              <div class="email" [matTooltip]="user.email" appEllipsisTooltip>
                {{ user.email }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-department col-val">
          {{ user.departmentName }}
        </div>
        <div class="col-report-to col-val">
          {{ user.reportTo?.name ?? '' }}
        </div>
        <div class="col-roles col-val">
          <div class="role" *ngFor="let role of user.roles">
            {{ role.name }}
          </div>
        </div>
        <div class="col-license">
          <app-select
            [disabled]="!isAssignableLicense(user)"
            [options]="this.getSelectableLicenses(user.id)"
            [selectedOption]="this.getSelectedLicense(user)"
            (selectedOptionChange)="this.licenseSelected($event, user)"
          ></app-select>
        </div>
        <div class="col-operation">
          <app-context-menu
            [menus]="this.getContextMenus(user)"
            (clicked)="contextMenuClick($event, user)"
          >
          </app-context-menu>
        </div>
      </div>
    </ng-container>
    <app-loading
      [showLoading]="this.isLoading || this.licenseIsLoading"
    ></app-loading>
  </div>
</div>
