<div class="container">
  <div class="main">
    <div class="header">
      <div class="icon"><mat-icon>donut_large</mat-icon></div>
      <h2 class="title">AI Sales Coach</h2>
    </div>
    <div class="content">
      <div class="navigation">
        <button class="back-button" (click)="backToLogin()">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      </div>
      <h3 class="title">携帯電話番号を設定</h3>
      <p class="message">
        2段階認証に登録する携帯電話番号を入力してください。<br />
        登録された番号に認証コードが送信されます。<br />
        電話番号は国際電話番号形式で入力してください。<br />
        （例：+819012345678）<br />
      </p>
      <form
        [formGroup]="setPhoneNumberForm"
        (submit)="sendPhoneNumberSubmit($event)"
      >
        <div class="inputs">
          <div class="input-wrapper">
            <input
              id="phoneNumber"
              name="phoneNumber"
              class="phoneNumber"
              placeholder="電話番号"
              autocomplete="tel"
              formControlName="phoneNumber"
              required
            />
          </div>
        </div>
        <div class="button-wrapper">
          <button
            type="submit"
            rectangle
            class="primary"
            [disabled]="setPhoneNumberForm.invalid"
          >
            送信
          </button>
        </div>
      </form>
    </div>
    <app-loading [showLoading]="isLoading" [fullScreen]="false"></app-loading>
  </div>
  <div id="recaptcha-container-id"></div>
</div>
