import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

import {
  SalesforceValidationResult,
  SalesforceValidationError,
} from '../../../../../services/external-integration/external-integration.mapping'

export interface SalesforceIntegrationValidationErrorParam {
  salesforceValidationResult: SalesforceValidationResult
}

const getErrorMessage = (
  error: SalesforceValidationError,
): {
  title: string
  message: string
} => {
  switch (error.code) {
    case 'FAILED_TO_FETCH_SALESFORCE_OBJECTS':
      return {
        title: 'Salesforceのオブジェクト一覧の取得に失敗しました',
        message:
          'Salesforceのオブジェクトの取得に失敗しました。必要な権限が設定されているか確認してください。',
      }
    case 'FAILED_TO_DESCRIBE_SALESFORCE_OBJECT':
      return {
        title: 'Salesforceのオブジェクトの取得に失敗しました',
        message: `Salesforceのオブジェクトの取得に失敗しました（オブジェクト名：${error.objectName}）。必要な権限が設定されているか確認してください。`,
      }
    case 'MISSING_OBJECT':
      return {
        title: 'Salesforceのオブジェクトが見つかりません',
        message: `Salesforceのオブジェクトが見つかりません（オブジェクト名：${error.objectName}）。オブジェクトが作成されているか確認してください。`,
      }
    case 'MISSING_FIELD':
      return {
        title: 'Salesforceのフィールドが見つかりません',
        message: `Salesforceのフィールドが見つかりません（オブジェクト名：${error.objectName}、フィールド名：${error.fieldName}）。フィールドが作成されているか確認してください。`,
      }
    case 'INVALID_OBJECT_DEFINITION':
      return {
        title: 'Salesforceのオブジェクト定義が不正です',
        message: `Salesforceのオブジェクト定義が不正です（オブジェクト名：${error.objectName}）。オブジェクト定義が正しいか確認してください。`,
      }
    case 'INVALID_OBJECT_PERMISSION':
      return {
        title: 'Salesforceのオブジェクトの権限が不正です',
        message: `Salesforceのオブジェクトの権限が不正です（オブジェクト名：${error.objectName}）。オブジェクトの権限が正しいか確認してください。`,
      }
    case 'INVALID_FIELD_DEFINITION':
      return {
        title: 'Salesforceのフィールド定義が不正です',
        message: `Salesforceのフィールド定義が不正です（オブジェクト名：${error.objectName}、フィールド名：${error.fieldName}）。フィールド定義が正しいか確認してください。`,
      }
    case 'INVALID_FIELD_PERMISSION':
      return {
        title: 'Salesforceのフィールドの権限が不正です',
        message: `Salesforceのフィールドの権限が不正です（オブジェクト名：${error.objectName}、フィールド名：${error.fieldName}）。フィールドの権限が正しいか確認してください。`,
      }
    case 'INVALID_TEXTAREA_LENGTH':
      return {
        title: 'テキストエリアの文字数が不正です',
        message: `テキストエリアの文字数が不正です（オブジェクト名：${error.objectName}、フィールド名：${error.fieldName}）。文字数が正しいか確認してください（最小文字数：${error.minLength}）。`,
      }
    case 'DISABLED_HTML_FORMATTING':
      return {
        title: 'HTMLフォーマットが無効です',
        message: `HTMLフォーマットが無効です（オブジェクト名：${error.objectName}、フィールド名：${error.fieldName}）。データ型にリッチテキストエリアが設定されているか確認してください。`,
      }
  }

  return {
    title: '不明なエラーが発生しました',
    message: '不明なエラーが発生しました',
  }
}

@Component({
  selector: 'app-salesforce-integration-validation-error',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './salesforce-integration-validation-error.component.html',
  styleUrls: ['./salesforce-integration-validation-error.component.scss'],
})
export class SalesforceIntegrationValidationErrorComponent {
  errorMessage: {
    title: string
    message: string
  } = {
    title: '',
    message: '',
  }

  constructor(
    private dialogRef: MatDialogRef<SalesforceIntegrationValidationErrorComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: SalesforceIntegrationValidationErrorParam,
  ) {
    const error = data.salesforceValidationResult.errors[0]
    this.errorMessage = getErrorMessage(error)
  }

  close(): void {
    this.dialogRef.close()
  }
}
