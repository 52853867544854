import { Injectable } from '@angular/core'

import { ApolloError } from '@apollo/client/errors'
import { GraphQLErrorExtensions } from 'graphql/error'
import { Observable, catchError, map, of } from 'rxjs'

import {
  AdminOrg,
  PeriodKeyActionScore,
  RecentKeyActionScore,
  User,
  mappingUsers,
  mappingUsersForAdmin,
} from './user.mapping'
import {
  AssignLicenseGQL,
  CreateUserGQL,
  GetAdminOrgGQL,
  GetRolesGQL,
  GetUserByIdGQL,
  GetUserOneSelfGQL,
  RevokeLicenseGQL,
  SearchUsersForAdminGQL,
  SearchUsersGQL,
  UpdateUserGQL,
  LicensePlan,
  DeleteUsersGQL,
  SendInvitationsGQL,
  AssessmentStatus,
  GetDepartmentNamesGQL,
  PrivacySetting,
  UpdatePrivacySettingGQL,
  GetWeeklyUserScoresGQL,
  GetWeeklyScoresGQL,
} from '../../../_generated/graphql'

export enum CustomErrorCode {
  DUPLICATED_USER = 'DUPLICATED_USER',
  MANAGER_RELATION_LOOP = 'MANAGER_RELATION_LOOP',
}

export class CustomError extends Error {
  code: CustomErrorCode
  extensions: GraphQLErrorExtensions

  constructor(
    code: CustomErrorCode,
    extensions: GraphQLErrorExtensions,
    message: string,
  ) {
    super()
    this.code = code
    this.extensions = extensions
    this.message = message
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private getUserOneSelfGQL: GetUserOneSelfGQL,
    private assignLicenseGQL: AssignLicenseGQL,
    private revokeLicenseGQL: RevokeLicenseGQL,
    private searchUsersGQL: SearchUsersGQL,
    private getUserByIdGQL: GetUserByIdGQL,
    private searchUsersForAdminGQL: SearchUsersForAdminGQL,
    private getAdminOrgGQL: GetAdminOrgGQL,
    private getRolesGQL: GetRolesGQL,
    private updateUserGQL: UpdateUserGQL,
    private createUserGQL: CreateUserGQL,
    private deleteUsersGQL: DeleteUsersGQL,
    private sendInvitationsGQL: SendInvitationsGQL,
    private getDepartmentNamesGQL: GetDepartmentNamesGQL,
    private updatePrivacySettingGQL: UpdatePrivacySettingGQL,
    private getWeeklyUserScoresGQL: GetWeeklyUserScoresGQL,
    private getWeeklyScoresGQL: GetWeeklyScoresGQL,
  ) {}

  getOneSelf(useCache: boolean): Observable<UserInfo> {
    return this.getUserOneSelfGQL
      .fetch(undefined, {
        fetchPolicy: useCache ? 'cache-first' : 'no-cache',
      })
      .pipe(
        map((res) => {
          if (!res.data.getUserOneSelf) {
            throw new Error('User not found')
          }
          return mappingUserInfo(res.data.getUserOneSelf)
        }),
      )
  }
  getUser(userId: string): Observable<User> {
    return this.getUserByIdGQL
      .fetch(
        { userId: userId },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => {
          const user = res.data?.getUserById
          if (!user) {
            alert('User not found')
            throw new Error('User not found')
          }
          return mappingUsers([user])[0]
        }),
      )
  }

  searchUsers(
    query: string,
    departmentNames: string[],
    paginatin?: { limit: number; offset: number },
    sort?: { sortField: string; sortOrder: 'asc' | 'desc' },
    useCache = true,
  ) {
    return this.searchUsersGQL
      .fetch(
        {
          query: query,
          departmentNames: departmentNames,
          pagination: paginatin,
          sort: sort,
        },
        {
          fetchPolicy: useCache ? 'cache-first' : 'no-cache',
        },
      )
      .pipe(
        map((res) => {
          return mappingUsers(res.data?.getUsers || [])
        }),
      )
  }

  searchUsersForAdmin(
    query: string,
    departmentNames: string[],
    paginatin?: { limit: number; offset: number },
    sort?: { sortField: string; sortOrder: 'asc' | 'desc' },
  ) {
    return this.searchUsersForAdminGQL
      .fetch(
        {
          query: query,
          departmentNames: departmentNames,
          pagination: paginatin,
          sort: sort,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => {
          return mappingUsersForAdmin(res.data?.getUsers || [])
        }),
      )
  }

  getAdminOrg(): Observable<AdminOrg> {
    return this.getAdminOrgGQL
      .fetch(undefined, {
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => {
          return {
            licenseInfo: res.data.getAdminOrg.licenseInfo ?? null,
            departmentNames: res.data.getAdminOrg.departmentNames,
            privacySetting: res.data.getAdminOrg.privacySetting,
          }
        }),
      )
  }

  getRoles(): Observable<{ name: string; code: string }[]> {
    return this.getRolesGQL.fetch().pipe(
      map((res) => {
        return res.data.getRoles.map((role) => ({
          name: role.name,
          code: role.code,
        }))
      }),
    )
  }

  assignLicense(userId: string, licenseId: string) {
    return this.assignLicenseGQL.mutate({
      userId: userId,
      licenseId: licenseId,
    })
  }

  revokeLicense(userId: string) {
    return this.revokeLicenseGQL.mutate({
      userId: userId,
    })
  }

  updateUser(userInput: {
    id: string
    name: string
    email: string
    departmentName: string
    reportToEmail: string | null
    roleCodes: string[]
    licenseId: string | null
  }) {
    return this.updateUserGQL
      .mutate({
        param: {
          id: userInput.id,
          name: userInput.name,
          email: userInput.email,
          departmentName: userInput.departmentName,
          reportToEmail: userInput.reportToEmail,
          roles: userInput.roleCodes,
          licenseId: userInput.licenseId,
        },
      })
      .pipe(
        catchError((error: unknown) => {
          this.checkGraphError(error)
          return of()
        }),
      )
  }

  deleteUsers(userIds: string[]) {
    return this.deleteUsersGQL
      .mutate({
        userIds: userIds,
      })
      .pipe(
        catchError((error: unknown) => {
          this.checkGraphError(error)
          return of()
        }),
      )
  }

  sendInvitations(userIds: string[]) {
    return this.sendInvitationsGQL
      .mutate({
        userIds: userIds,
      })
      .pipe(
        catchError((error: unknown) => {
          this.checkGraphError(error)
          return of()
        }),
      )
  }

  createUser(userInput: {
    name: string
    email: string
    departmentName: string
    reportToEmail: string | null
    roleCodes: string[]
    licenseId: string
  }) {
    return this.createUserGQL
      .mutate({
        param: {
          name: userInput.name,
          email: userInput.email,
          departmentName: userInput.departmentName,
          reportToEmail: userInput.reportToEmail,
          roles: userInput.roleCodes,
          licenseId: userInput.licenseId,
        },
      })
      .pipe(
        catchError((error: unknown) => {
          this.checkGraphError(error)
          return of()
        }),
      )
  }

  getDepartmentNames(): Observable<string[]> {
    return this.getDepartmentNamesGQL
      .fetch(undefined, {
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => {
          return res.data.getAdminOrg.departmentNames
        }),
      )
  }

  getWeeklyUserScores(
    userId: string,
    startDate: Date,
    endDate: Date,
    skillMapId: string,
  ): Observable<{
    id: string
    name: string
    departmentName: string
    thumbnailPath: string | null
    keyActionRecentlyAverageScores: RecentKeyActionScore[]
    periodKeyActionScores: PeriodKeyActionScore[]
  }> {
    return this.getWeeklyUserScoresGQL
      .fetch({
        userId: userId,
        startDate: startDate,
        endDate: endDate,
        skillMapIds: [skillMapId],
      })
      .pipe(
        map((res) => {
          const user = res.data.getUserById
          if (!user) {
            throw new Error(`we can't get user by id: ${userId}`)
          }
          return {
            id: user.id,
            name: user.name,
            departmentName: user.departmentName ?? '',
            thumbnailPath: user.thumbnailPath ?? null,
            keyActionRecentlyAverageScores:
              user.keyActionRecentlyAverageScores.map((score) => ({
                keyAction: {
                  id: score.keyAction.id,
                  name: score.keyAction.name,
                },
                score: score.recentlyAverageScore ?? null,
              })),
            periodKeyActionScores: user.periodKeyActionScores.map((score) => ({
              keyAction: {
                id: score.keyAction.id,
                name: score.keyAction.name,
              },
              weeklyScores: score.weeklyScores.map((weeklyScore) => ({
                weekStartDate: new Date(weeklyScore.weekStartDate),
                score: weeklyScore.score ?? null,
              })),
            })),
          }
        }),
      )
  }

  getWeeklyScores(
    skillMapId: string,
    startDate: Date,
    endDate: Date,
  ): Observable<{
    name: string
    periodKeyActionScores: PeriodKeyActionScore[]
    departments: {
      name: string
      periodKeyActionScores: PeriodKeyActionScore[]
    }[]
    users: {
      departmentName: string
      recentKeyActionScores: RecentKeyActionScore[]
    }[]
  }> {
    return this.getWeeklyScoresGQL
      .fetch({
        skillMapIds: [skillMapId],
        startDate: startDate,
        endDate: endDate,
      })
      .pipe(
        map((res) => {
          return {
            name: res.data.getAdminOrg.name,
            periodKeyActionScores:
              res.data.getAdminOrg.periodKeyActionScores.map((score) => ({
                keyAction: {
                  id: score.keyAction.id,
                  name: score.keyAction.name,
                },
                weeklyScores: score.weeklyScores.map((weeklyScore) => ({
                  weekStartDate: new Date(weeklyScore.weekStartDate),
                  score: weeklyScore.score ?? null,
                })),
              })),
            departments: res.data.getAdminOrg.departments.map((department) => ({
              name: department.name,
              periodKeyActionScores: department.periodKeyActionScores.map(
                (score) => ({
                  keyAction: {
                    id: score.keyAction.id,
                    name: score.keyAction.name,
                  },
                  weeklyScores: score.weeklyScores.map((weeklyScore) => ({
                    weekStartDate: new Date(weeklyScore.weekStartDate),
                    score: weeklyScore.score ?? null,
                  })),
                }),
              ),
            })),
            users: res.data.getAdminOrg.users.map((user) => ({
              departmentName:
                !user.departmentName || user.departmentName === 'noDepartment'
                  ? '所属無し'
                  : user.departmentName,
              recentKeyActionScores: user.keyActionRecentlyAverageScores.map(
                (score) => ({
                  keyAction: {
                    id: score.keyAction.id,
                    name: score.keyAction.name,
                  },
                  score: score.recentlyAverageScore ?? null,
                }),
              ),
            })),
          }
        }),
      )
  }

  updatePrivacySetting(setting: PrivacySettingType): Observable<void> {
    return this.updatePrivacySettingGQL
      .mutate({ privacySetting: setting as PrivacySetting })
      .pipe(map(() => {}))
  }

  private checkGraphError(error: unknown) {
    if (!error) {
      return
    }

    if (!(error instanceof ApolloError)) {
      return
    }

    if (!error.graphQLErrors.length) {
      return
    }

    const index = error.graphQLErrors.findIndex((err) =>
      Object.values(CustomErrorCode).includes(
        err.extensions?.code as CustomErrorCode,
      ),
    )
    if (index !== -1) {
      const err = error.graphQLErrors[index]
      const extensions = err.extensions as GraphQLErrorExtensions
      throw new CustomError(
        extensions.code as CustomErrorCode,
        extensions,
        err.message,
      )
    } else {
      throw new Error(error.graphQLErrors[0].message)
    }
  }
}

const mappingUserInfo = (data: FetchUserInfo): UserInfo => {
  return {
    id: data.id,
    orgId: data.orgId,
    name: data.name,
    email: data.email,
    departmentName: data.departmentName ?? null,
    roles: data.roles ?? [],
    isManager: !!data.isManager,
    thumbnailPath: data.thumbnailPath ?? null,
    license: data.license ?? null,
  }
}

export interface FetchUserInfo {
  id: string
  orgId: string
  name: string
  email: string
  departmentName?: string | null
  roles?: { name: string; code: string }[]
  isManager?: boolean
  thumbnailPath?: string | null
  license?: {
    plan: LicensePlan
  } | null
}

export interface UserLicense {
  plan: LicensePlan
  userMonthlyLimitSeconds: number
  extraChargeLimitSeconds: number
  usedSeconds: number
}

export interface UserInfo {
  id: string
  orgId: string
  name: string
  email: string
  departmentName: string | null
  roles: { name: string; code: string }[]
  isManager: boolean
  thumbnailPath: string | null
  license: {
    plan: LicensePlan
  } | null
}

export interface FetchedUser {
  id: string
  orgId: string
  name: string
  email: string
  departmentName?: string | null
  thumbnailPath?: string | null
  assessments: {
    id: string
    status: AssessmentStatus
    assessDate: Date
    businessMeeting: { title: string; recordDate: Date }
  }[]
  latestAssessment?: {
    id: string
    assessDate: Date
    businessMeeting: { title: string; recordDate: Date }
    keyActionEvaluations: { score: number }[]
  } | null
  license?: UserLicense | null
  reportTo?: {
    id: string
  } | null
}

export type FetchedUserForAdmin = Omit<
  FetchedUser,
  'assessments' | 'license'
> & {
  reportTo?: { id: string; name: string; email: string } | null
  roles: { name: string; code: string }[]
  license?: {
    id: string
    plan: 'BASIC' | 'ADVANCED'
    userMonthlyLimitSeconds: number
    extraChargeLimitSeconds: number
    usedSeconds: number
  } | null
}

export const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/

export type PrivacySettingType =
  | 'FULLY_OPEN'
  | 'PARTIALLY_OPEN'
  | 'FULLY_CLOSED'
