import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

const ERROR_MESSAGE =
  'システムでエラーが発生しました。再試行しても問題が解決しない場合はサポートチームにご連絡ください。'

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (res) => {
          if (
            'body' in res &&
            typeof res.body === 'object' &&
            'errors' in res.body
          ) {
            this.snackBar.open(ERROR_MESSAGE, 'x', {
              duration: 3000,
              panelClass: ['default-snackbar'],
            })
          }
        },
        (error) => {
          if (error.status === 401) {
            // Error handling will be done in auth.interceptor
          } else if (error.status === 400) {
            this.snackBar.open(ERROR_MESSAGE, 'x', {
              duration: 3000,
              panelClass: ['default-snackbar'],
            })
          } else {
            this.snackBar.open(ERROR_MESSAGE, 'x', {
              duration: 3000,
              panelClass: ['default-snackbar'],
            })
          }
        },
      ),
    )
  }
}
