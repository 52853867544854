import { GetAutoAssessmentSettingQuery } from '../../../_generated/graphql'

export const mappingAutoAssessmentSetting = (
  fetchedData: GetAutoAssessmentSettingQuery['getAutoAssessmentSetting'],
): AutoAssessmentSetting | null => {
  if (!fetchedData) {
    return null
  }

  return {
    id: fetchedData.id,
    targetUsers: fetchedData.targetUsers.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      departmentName: user.departmentName || null,
      thumbnailPath: user.thumbnailPath || null,
      reportTo: user.reportTo
        ? {
            id: user.reportTo.id,
            name: user.reportTo.name,
          }
        : null,
      license: user.license
        ? {
            id: user.license.id,
            plan: user.license.plan,
            userMonthlyLimitSeconds: user.license.userMonthlyLimitSeconds,
            extraChargeLimitSeconds: user.license.extraChargeLimitSeconds,
            usedSeconds: user.license.usedSeconds,
          }
        : null,
    })),
    targetSkillMap: {
      id: fetchedData.targetSkillMap.id,
      name: fetchedData.targetSkillMap.name,
    },
    targetPhaseIds: fetchedData.targetPhaseIds,
    minTransferDurationSeconds:
      fetchedData.minTransferDurationSeconds != null
        ? fetchedData.minTransferDurationSeconds
        : null,
    syncStartDate: new Date(fetchedData.syncStartDate),
  }
}

export interface TargetUser {
  id: string
  name: string
  email: string
  departmentName: string | null
  thumbnailPath: string | null
  reportTo: {
    id: string
    name: string
  } | null
  license: {
    id: string
    plan: string
    userMonthlyLimitSeconds: number
    extraChargeLimitSeconds: number
    usedSeconds: number
  } | null
}

export interface TargetSkillMap {
  id: string
  name: string
}

export interface AutoAssessmentSetting {
  id: string
  targetUsers: TargetUser[]
  targetSkillMap: TargetSkillMap
  targetPhaseIds: string[]
  minTransferDurationSeconds: number | null
  syncStartDate: Date
}

export interface AmptalkKeys {
  apiKey: string
  authKey: string
}
