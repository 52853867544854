import Encoding from 'encoding-japanese'

type AvailableEncoding =
  | 'UTF32'
  | 'UTF16'
  | 'UTF16BE'
  | 'UTF16LE'
  | 'BINARY'
  | 'ASCII'
  | 'JIS'
  | 'UTF8'
  | 'EUCJP'
  | 'SJIS'
  | 'UNICODE'

export const detectEncode = (codes: Uint8Array): string => {
  return Encoding.detect(codes) || ''
}

export const validateEncoding = async (
  arrayBuffer: ArrayBuffer,
  availableEncodes: AvailableEncoding[],
): Promise<boolean> => {
  const codes = new Uint8Array(arrayBuffer)
  const detectedEncoding = await detectEncode(codes)

  return availableEncodes.includes(detectedEncoding as AvailableEncoding)
}

export const getMimeType = (extension: string) => {
  switch (extension) {
    case 'mp3':
      return 'audio/mpeg'
    case 'mp4':
      return 'audio/mp4'
    case 'aac':
      return 'audio/aac'
    case 'wav':
      return 'audio/wav'
    case 'flac':
      return 'audio/flac'
    case 'm4a':
      return 'audio/x-m4a'
    case 'webm':
      return 'audio/webm'
    default:
      throw new Error('Unsupported audio extension')
  }
}
