import { FetchedUser as FetchedUser, FetchedUserForAdmin } from './user.service'
import { AssessmentStatus, LicensePlan } from '../../../_generated/graphql'

export const mappingUsers = (users: FetchedUser[]): User[] => {
  return users.map((user) => ({
    id: user.id,
    orgId: user.orgId,
    name: user.name,
    email: user.email,
    departmentName: user.departmentName || '',
    thumbnailPath: user.thumbnailPath,
    assessments: user.assessments.map((assessment) => ({
      id: assessment.id,
      status: assessment.status,
      assessDate: new Date(assessment.assessDate),
      businessMeeting: {
        title: assessment.businessMeeting.title,
        recordDate: new Date(assessment.businessMeeting.recordDate),
      },
    })),
    latestAssessment: user.latestAssessment,
    license: user.license ?? null,
    reportToUserId: user.reportTo?.id ?? null,
  }))
}

export const mappingUsersForAdmin = (
  users: FetchedUserForAdmin[],
): UserForAdmin[] => {
  return users.map((user) => ({
    id: user.id,
    orgId: user.orgId,
    name: user.name,
    email: user.email,
    departmentName: user.departmentName || '',
    thumbnailPath: user.thumbnailPath,
    reportTo: user.reportTo ?? null,
    roles: user.roles,
    license: user.license ?? null,
  }))
}

export interface UserLicense {
  plan: LicensePlan
  userMonthlyLimitSeconds: number
  extraChargeLimitSeconds: number
  usedSeconds: number
}

export interface User {
  id: string
  orgId: string
  name: string
  email: string
  departmentName: string
  assessments: {
    id: string
    status: AssessmentStatus
    assessDate: Date
    businessMeeting: { title: string; recordDate: Date }
  }[]
  latestAssessment?: {
    id: string
    assessDate: Date
    businessMeeting: { title: string; recordDate: Date }
    keyActionEvaluations: { score: number }[]
  } | null
  reportToUserId?: string | null
  thumbnailPath?: string | null
  license: UserLicense | null
}

export interface UserSummary {
  id: string
  orgId: string
  name: string
  email: string
  departmentName: string
  assessments: {
    id: string
    status: AssessmentStatus
    assessDate: Date
    businessMeeting: { recordDate: Date; title: string }
  }[]
  thumbnailPath?: string | null
  license: UserLicense | null
}

export type UserForAdmin = Omit<User, 'assessments' | 'license'> & {
  reportTo: { id: string; name: string; email: string } | null
  roles: { name: string; code: string }[]
  license: {
    id: string
    userMonthlyLimitSeconds: number
    extraChargeLimitSeconds: number
    usedSeconds: number
  } | null
}

export interface AdminOrg {
  licenseInfo: {
    plan: 'BASIC' | 'ADVANCED'
    count: number
    expiration: Date
    userMonthlyLimitSeconds: number
    usedCount: number
    remainingLicenses: License[]
  }
  departmentNames: string[]
  privacySetting: 'FULLY_OPEN' | 'PARTIALLY_OPEN' | 'FULLY_CLOSED'
}

export interface License {
  id: string
  userMonthlyLimitSeconds: number
  extraCharges: ExtraCharge[]
  usedSeconds: number
  user?: User
}

export interface ExtraCharge {
  limitSeconds: number
}

export interface PeriodKeyActionScore {
  keyAction: {
    id: string
    name: string
  }
  weeklyScores: {
    weekStartDate: Date
    score: number | null
  }[]
}

export interface RecentKeyActionScore {
  keyAction: {
    name: string
  }
  score: number | null
}
