import { SUPPORTED_FILE_SIZE } from './constants'

export const isValidFileExtension = (
  file: File,
  supportedExtensions: string[],
): boolean => {
  return supportedExtensions.some((ext) =>
    file.name.toLowerCase().endsWith(`.${ext.toLocaleLowerCase()}`),
  )
}

export const isValidFileSize = (file: File): boolean => {
  return file.size <= SUPPORTED_FILE_SIZE
}
