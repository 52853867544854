import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
class HighchartsChartComponent {
  constructor(el, _zone // #75
  ) {
    this.el = el;
    this._zone = _zone;
    this.updateChange = new EventEmitter(true);
    this.chartInstance = new EventEmitter(); // #26
  }
  ngOnChanges(changes) {
    const update = changes.update?.currentValue;
    if (changes.options || update) {
      this.wrappedUpdateOrCreateChart();
      if (update) {
        this.updateChange.emit(false); // clear the flag after update
      }
    }
  }
  wrappedUpdateOrCreateChart() {
    if (this.runOutsideAngular) {
      this._zone.runOutsideAngular(() => {
        this.updateOrCreateChart();
      });
    } else {
      this.updateOrCreateChart();
    }
  }
  updateOrCreateChart() {
    if (this.chart?.update) {
      this.chart.update(this.options, true, this.oneToOne || false);
    } else {
      this.chart = this.Highcharts[this.constructorType || 'chart'](this.el.nativeElement, this.options, this.callbackFunction || null);
      // emit chart instance on init
      this.chartInstance.emit(this.chart);
    }
  }
  ngOnDestroy() {
    if (this.chart) {
      // #56
      this.chart.destroy();
      this.chart = null;
      // emit chart instance on destroy
      this.chartInstance.emit(this.chart);
    }
  }
  static {
    this.ɵfac = function HighchartsChartComponent_Factory(ɵt) {
      return new (ɵt || HighchartsChartComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HighchartsChartComponent,
      selectors: [["highcharts-chart"]],
      inputs: {
        Highcharts: "Highcharts",
        constructorType: "constructorType",
        callbackFunction: "callbackFunction",
        oneToOne: "oneToOne",
        runOutsideAngular: "runOutsideAngular",
        options: "options",
        update: "update"
      },
      outputs: {
        updateChange: "updateChange",
        chartInstance: "chartInstance"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function HighchartsChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighchartsChartComponent, [{
    type: Component,
    args: [{
      selector: 'highcharts-chart',
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    Highcharts: [{
      type: Input
    }],
    constructorType: [{
      type: Input
    }],
    callbackFunction: [{
      type: Input
    }],
    oneToOne: [{
      type: Input
    }],
    runOutsideAngular: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    update: [{
      type: Input
    }],
    updateChange: [{
      type: Output
    }],
    chartInstance: [{
      type: Output
    }]
  });
})();
class HighchartsChartModule {
  static {
    this.ɵfac = function HighchartsChartModule_Factory(ɵt) {
      return new (ɵt || HighchartsChartModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: HighchartsChartModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighchartsChartModule, [{
    type: NgModule,
    args: [{
      declarations: [HighchartsChartComponent],
      exports: [HighchartsChartComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of highcharts-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { HighchartsChartComponent, HighchartsChartModule };
