<div class="container">
  <h1 class="header">
    <div class="title">「{{ this.data.name }}」の名前を変更</div>
    <button type="button" class="close-button" (click)="this.close()">
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <form class="main" (submit)="onSubmit($event)">
    <div class="form">
      <div class="field">
        <div class="input-with-error">
          <div class="value">
            <input
              #nameInput
              id="name"
              class="input"
              [class.error]="this.name.touched && this.name.invalid"
              placeholder="名前を入力してください"
              [formControl]="this.name"
            />
          </div>
          <div
            class="input-error"
            *ngIf="this.name.touched && this.name.hasError('required')"
          >
            名前は必須です
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button
        type="button"
        rectangle
        variant="secondary"
        (click)="this.close()"
      >
        キャンセル
      </button>
      <button
        type="submit"
        rectangle
        variant="primary"
        [disabled]="this.name.invalid"
      >
        保存
      </button>
    </div>
  </form>
</div>
