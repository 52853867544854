<button
  type="button"
  class="multiple-select"
  [class.error]="this.showError"
  #trigger
  (click)="showSelectControl(trigger)"
  [disabled]="!this.items.length"
>
  <span class="content"
    ><span class="label"
      ><span class="placeholder">{{ fieldName }}</span
      >{{ this.checkedItemsLabel }}</span
    ><span class="counter" *ngIf="!this.allUnChecked && !this.allChecked"
      >({{ this.checkedItems.length }})</span
    ></span
  >
  <ng-container *ngIf="this.controlOverlay === null">
    <mat-icon class="expand-icon">expand_more</mat-icon>
  </ng-container>
  <ng-container *ngIf="this.controlOverlay !== null">
    <mat-icon class="expand-icon">expand_less</mat-icon>
  </ng-container>
</button>
