import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatDialog } from '@angular/material/dialog'
import { MatIcon } from '@angular/material/icon'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTooltipModule } from '@angular/material/tooltip'

import { InfiniteScrollDirective } from 'ngx-infinite-scroll'
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  finalize,
  filter,
  switchMap,
  catchError,
  of,
} from 'rxjs'

import { AvatarComponent } from '../../../../components/avatar/avatar.component'
import { ButtonComponent } from '../../../../components/button/button.component'
import {
  ContextMenu,
  ContextMenuComponent,
} from '../../../../components/context-menu/context-menu.component'
import { LoadingComponent } from '../../../../components/loading/loading.component'
import {
  DialogResult,
  MessageDialogService,
} from '../../../../components/message-dialog/message-dialog.service'
import {
  SelectComponent,
  Option,
} from '../../../../components/select/select.component'
import { EllipsisTooltipDirective } from '../../../../directives/ellipsis-tooltip.directive'
import {
  AdminOrg,
  License,
  UserForAdmin,
} from '../../../../services/user/user.mapping'
import { UserStore } from '../../../../stores/user.store'
import {
  AdminUserRegisterComponent,
  AdminUserRegisterDialogResult,
} from '../register/admin-user-register.component'
import { getTitle } from '../../../../util/accessibility'
import { AdminUserCsvImportComponent } from './admin-user-csv-import/admin-user-csv-import.component'
import { BulkCreateUserParam } from '../../../../../_generated/graphql'
import { MatMenuModule } from '@angular/material/menu'
import { CustomErrorCode } from '../../../../util/throwIfCustomError'

type SearchCriteria = {
  offset: number
  name: string
  departmentId: string
  jobTitleId: string
  hasLicense: boolean | undefined
}
export interface UserViewModel extends UserForAdmin {
  selected: boolean
  licenseAssigned: boolean
}

enum ContextMenuType {
  Edit = 'EDIT',
  ResendInvitation = 'RESEND_INVITATION',
  DeleteAccount = 'DELETE_ACCOUNT',
}

const MAXIMUM_SELECTION_COUNT = 50

@Component({
  templateUrl: './admin-user-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ContextMenuComponent,
    AvatarComponent,
    MatTooltipModule,
    EllipsisTooltipDirective,
    MatIcon,
    SelectComponent,
    ReactiveFormsModule,
    MatSlideToggleModule,
    LoadingComponent,
    MatCheckbox,
    InfiniteScrollDirective,
    ButtonComponent,
    AdminUserRegisterComponent,
    MatMenuModule,
  ],
  styleUrls: ['./admin-user-list.component.scss'],
})
export class AdminUserListComponent implements OnInit {
  users: UserViewModel[] = []
  userTotalCount = 0
  isLoading = true
  licenseSubscriptionIds: string[] = []
  licenseCurrentUsage = 0
  licenseCurrentQuantity = 0
  licenseIsLoading = true
  csvExporting = false
  allowLoginWithIdByOrg = false
  selectableDepartments: Option[] = []
  selectableLicenses: Option[] = []
  remainingLicensesCount: { name: string; count: number }[] = []

  NO_LICENSE_OPTION: Option = { id: 'none', name: 'ライセンスなし' }

  adminOrg: AdminOrg | null = null
  roles: { name: string; code: string }[] = []

  get checkedCount(): number {
    return this.users?.filter((user) => user.selected)?.length
  }
  get checkedState(): 'CHECKED' | 'UNCHECKED' | 'INDETERMINATE' {
    const checkedCount =
      this.users?.filter((user) => user.selected)?.length ?? 0
    if (checkedCount === 0) {
      return 'UNCHECKED'
    } else if (checkedCount === this.users.length) {
      return 'CHECKED'
    } else {
      return 'INDETERMINATE'
    }
  }

  name = new FormControl('')

  private readonly PAGE_SIZE = 20
  private searchCriteria: SearchCriteria = {
    offset: 0,
    name: '',
    departmentId: '',
    jobTitleId: '',
    hasLicense: true,
  }

  constructor(
    private userStore: UserStore,
    private messageDialogService: MessageDialogService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('ユーザー管理'))
    this.loadUsers()
    this.loadAdminOrg()
    this.loadRoles()
  }

  ngOnInit(): void {
    this.name.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((value) => {
          this.searchCriteria.offset = 0
          this.searchCriteria.name = value ?? ''
          this.loadUsers()
        }),
      )
      .subscribe()
  }

  addUser = () => {
    this.addUserIndividually()
  }

  addUserIndividually = () => {
    if (this.selectableLicenses.length === 0) {
      this.messageDialogService.showError(
        '割り当て可能なライセンスがありません。',
      )
      return
    }

    this.matDialog
      .open(AdminUserRegisterComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        data: {
          departmentNames: this.adminOrg?.departmentNames ?? [],
          roles: this.roles,
          selectableLicenses: this.selectableLicenses,
          selectedOption: this.selectableLicenses[0],
        },
      })
      .afterClosed()
      .pipe(
        tap((result: AdminUserRegisterDialogResult) => {
          if (result?.isSucceeded) {
            this.searchCriteria.offset = 0
            this.loadUsers()
            this.loadAdminOrg()
          }
        }),
      )
      .subscribe()
  }

  departmentSelected = (option: Option) => {
    if (option) {
      this.searchCriteria.offset = 0
      this.searchCriteria.departmentId = option.id
      this.loadUsers()
    }
  }

  checkAllUsers = (checked: boolean) => {
    let selectedCount = this.users.filter((u) => u.selected).length
    this.users.forEach((user) => {
      if (checked && !user.selected) {
        if (selectedCount < MAXIMUM_SELECTION_COUNT) {
          user.selected = checked
          selectedCount += 1
        }
      } else if (!checked && user.selected) {
        user.selected = checked
      }
    })
    if (checked && this.users.length > MAXIMUM_SELECTION_COUNT) {
      this.messageDialogService.showInfo(
        `一度に選択できるのは ${MAXIMUM_SELECTION_COUNT} 件までです。`,
      )
    }
  }

  checkChanged = (checkbox: MatCheckbox, user: UserViewModel) => {
    const selectedCount = this.users.filter((u) => u.selected)?.length ?? 0
    if (checkbox.checked && selectedCount >= MAXIMUM_SELECTION_COUNT) {
      this.messageDialogService
        .showInfo(
          `一度に選択できるのは ${MAXIMUM_SELECTION_COUNT} 件までです。`,
        )
        .pipe(
          finalize(() => {
            checkbox.checked = false
          }),
        )
        .subscribe()
      return
    }

    user.selected = !user.selected
  }

  loadMoreUsers = () => {
    if (!this.isLoading) {
      this.searchCriteria.offset += 1
      this.loadUsers()
    }
  }

  resendInvitationBulk = () => {
    const userIds = this.users
      .filter((user) => user.selected)
      .map((user) => user.id)
    if (userIds.length === 0) return

    this.messageDialogService
      .showConfirm(`${userIds.length} 名のユーザーに招待メールを送信します。`)
      .pipe(
        filter((result) => result === DialogResult.PrimaryButtonClicked),
        switchMap(() => {
          return this.userStore.sendInvitations(userIds)
        }),
        tap(() => {
          this.users.forEach((user) => {
            user.selected = false
          })
          this.snackBar.open('招待メールを送信しました', 'close', {
            duration: 3000,
          })
        }),
      )
      .subscribe()
  }

  getContextMenus = (user: UserViewModel): ContextMenu[] => {
    const menus: ContextMenu[] = []

    menus.push({
      icon: 'edit',
      title: '編集',
      type: ContextMenuType.Edit,
    })
    menus.push({
      icon: 'send',
      title: '招待メール送信',
      type: ContextMenuType.ResendInvitation,
      disable: false,
    })
    menus.push({
      icon: 'delete',
      title: '削除',
      type: ContextMenuType.DeleteAccount,
      disable: !this.isDeletableUser(user),
    })

    return menus
  }

  deleteAccount = (user: UserViewModel) => {
    this.messageDialogService
      .showConfirm(
        `以下のユーザーを削除してもよろしいですか？
         削除されたユーザーは外部連携の対象外となります。
            
            ${user.name}
            
            `,
        {
          primaryButtonText: '削除',
          secondaryButtonText: 'キャンセル',
        },
      )
      .pipe(
        filter((result) => result === DialogResult.PrimaryButtonClicked),
        switchMap(() => {
          return this.userStore.deleteUsers([user.id]).pipe(
            tap(() => {
              this.searchCriteria.offset = 0
              this.loadUsers()
              this.loadAdminOrg()
            }),
          )
        }),
        tap(() => {
          this.snackBar.open('削除しました', 'close', {
            duration: 3000,
          })
        }),
      )
      .subscribe()
  }

  deleteAccountBulk = () => {
    const containNotDeletableUser = this.users.some(
      (user) => user.selected && !this.isDeletableUser(user),
    )
    if (containNotDeletableUser) {
      this.messageDialogService.showError(
        '削除できないユーザーが含まれています。',
      )
      return
    }

    const userIds = this.users
      .filter((user) => user.selected)
      .map((user) => user.id)
    if (userIds.length === 0) return

    this.messageDialogService
      .showConfirm(
        `本当に ${userIds.length} 名のユーザーを削除してもよろしいですか？
         削除されたユーザーは外部連携の対象外となります。
        `,
        {
          primaryButtonText: '削除',
          secondaryButtonText: 'キャンセル',
        },
      )
      .pipe(
        filter((result) => result === DialogResult.PrimaryButtonClicked),
        switchMap(() => {
          return this.userStore.deleteUsers(userIds).pipe(
            tap(() => {
              this.searchCriteria.offset = 0
              this.loadUsers()
              this.loadAdminOrg()
            }),
          )
        }),
        tap(() => {
          this.snackBar.open('削除しました', 'close', {
            duration: 3000,
          })
        }),
      )
      .subscribe()
  }

  sendInvitation = (user: UserViewModel) => {
    this.messageDialogService
      .showConfirm(`${user.email} に招待メールを送信します。`)
      .pipe(
        filter((result) => result === DialogResult.PrimaryButtonClicked),
        switchMap(() => {
          return this.userStore.sendInvitations([user.id])
        }),
        tap(() => {
          this.snackBar.open('招待メールを送信しました', 'close', {
            duration: 3000,
          })
        }),
      )
      .subscribe()
  }

  isAssignableLicense(user: UserViewModel): boolean {
    const unchangeableSuperAdmin =
      !this.userStore
        .ctxUser()
        ?.roles.some((role) => role.code === 'SUPER_ADMIN') &&
      user.roles.some((role) => role.code === 'SUPER_ADMIN')
    if (unchangeableSuperAdmin) {
      return false
    }

    const orgLicense = this.adminOrg?.licenseInfo
    if (!orgLicense) {
      return false
    }

    const reachedLicenseLimit = orgLicense.usedCount >= orgLicense.count
    if (reachedLicenseLimit && !user.licenseAssigned) {
      return false
    }

    return true
  }

  isDeletableUser(user: UserViewModel): boolean {
    const isMyAccount = user.id === this.userStore.ctxUser()?.id
    if (isMyAccount) {
      return false
    }

    const unchangeableSuperAdmin =
      !this.userStore
        .ctxUser()
        ?.roles.some((role) => role.code === 'SUPER_ADMIN') &&
      user.roles.some((role) => role.code === 'SUPER_ADMIN')
    if (unchangeableSuperAdmin) {
      return false
    }

    return true
  }

  licenseSelected(option: Option, user: UserViewModel): void {
    if (!option) {
      return
    }

    if (option.id === 'none') {
      this.userStore
        .revokeLicense(user.id)
        .pipe(
          tap(() => {
            this.searchCriteria.offset = 0
            this.loadUsers()
            this.loadAdminOrg()
          }),
        )
        .subscribe()
    } else {
      this.userStore
        .assignLicense(user.id, option.id)
        .pipe(
          tap(() => {
            this.searchCriteria.offset = 0
            this.loadUsers()
            this.loadAdminOrg()
          }),
        )
        .subscribe()
    }
  }

  contextMenuClick = (type: string, user: UserViewModel) => {
    if (type === ContextMenuType.Edit) {
      const selectedOption = user.license
        ? {
            id: user.license.id,
            name: `残り：${this.getRemainingHours({
              userMonthlyLimitSeconds: user.license.userMonthlyLimitSeconds,
              extraChargeLimitSeconds: user.license.extraChargeLimitSeconds,
              usedSeconds: user.license.usedSeconds,
            })} 時間`,
          }
        : this.NO_LICENSE_OPTION
      const selectableLicenses = user.license
        ? this.selectableLicenses
            .filter((license) => license.name !== selectedOption.name)
            .concat([selectedOption])
        : this.selectableLicenses

      this.matDialog
        .open(AdminUserRegisterComponent, {
          disableClose: true,
          panelClass: 'unset-mat-dialog-padding',
          data: {
            user: {
              id: user.id,
              name: user.name,
              email: user.email,
              departmentName: user.departmentName,
              reportTo: user.reportTo,
              roles: user.roles,
              requiredMfa: user.requiredMfa,
            },
            departmentNames: this.adminOrg?.departmentNames,
            roles: this.roles,
            selectableLicenses:
              user.id === this.userStore.ctxUser()?.id
                ? selectableLicenses
                : [this.NO_LICENSE_OPTION, ...selectableLicenses],
            selectedOption: selectedOption,
          },
        })
        .afterClosed()
        .pipe(
          tap((result: AdminUserRegisterDialogResult) => {
            if (result?.isSucceeded) {
              this.searchCriteria.offset = 0
              this.loadUsers()
              this.loadAdminOrg()
            }
          }),
        )
        .subscribe()
    } else if (type === ContextMenuType.ResendInvitation) {
      this.sendInvitation(user)
    } else if (type === ContextMenuType.DeleteAccount) {
      this.deleteAccount(user)
    }
  }

  getSelectedLicense(user: UserViewModel): Option {
    if (!user.licenseAssigned) {
      return this.NO_LICENSE_OPTION
    }
    if (!user.license) {
      return this.NO_LICENSE_OPTION
    }

    const remainingHours = this.getRemainingHours({
      userMonthlyLimitSeconds: user.license.userMonthlyLimitSeconds,
      extraChargeLimitSeconds: user.license.extraChargeLimitSeconds,
      usedSeconds: user.license.usedSeconds,
    })

    return {
      id: '',
      name: `残り：${remainingHours} 時間`,
    }
  }

  getRemainingLicensesInfo() {
    return this.remainingLicensesCount
      .map((license) => {
        return `${license.name} : ${license.count} 件`
      })
      .join('\n')
  }

  getSelectableLicenses(userId: string) {
    return userId === this.userStore.ctxUser()?.id
      ? this.selectableLicenses
      : [this.NO_LICENSE_OPTION, ...this.selectableLicenses]
  }

  onCsvImportButtonClicked(): void {
    this.openCsvImportDialog()
  }

  private openCsvImportDialog(): void {
    this.matDialog
      .open<AdminUserCsvImportComponent>(AdminUserCsvImportComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        data: {},
      })
      .afterClosed()
      .subscribe((csvUsers: BulkCreateUserParam[]) => {
        this.isLoading = true
        if (csvUsers) {
          this.userStore
            .bulkCreateUser(csvUsers)
            .pipe(
              tap(() => {
                this.loadUsers()
                this.loadAdminOrg()
                this.snackBar.open('ユーザーの作成が完了しました', 'close', {
                  duration: 3000,
                })
              }),
              catchError((error) => {
                const dialogTitle = 'エラー'
                this.messageDialogService.showError(
                  this.getErrorMessage(error),
                  { title: dialogTitle },
                )
                return of()
              }),
              finalize(() => {
                this.isLoading = false
              }),
            )
            .subscribe()
        } else {
          this.isLoading = false
        }
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- errorなのでany許容する
  private getErrorMessage(error: any): string {
    switch (error.code) {
      case CustomErrorCode.BATCH_MANAGER_RELATION_LOOP:
        return `以下のユーザーと上司の関係が循環しています:\n\n- ${error.param.name}`
      case CustomErrorCode.BATCH_LICENSE_NOT_ENOUGH:
        return 'ライセンスが不足しています'
      case CustomErrorCode.BATCH_TARGET_USER_NOT_FOUND:
        return `以下のユーザーが存在しません:\n\n- ${error.param.name}`
      case CustomErrorCode.BATCH_MANAGER_NOT_FOUND:
        return `以下の上司が存在しません:\n\n- ${error.param.reportToEmail}`
      case CustomErrorCode.BATCH_DUPLICATED_USER:
        return `以下のメールアドレスは既に登録されています:\n\n- ${error.param.email}`
      case CustomErrorCode.BATCH_FIREBASE_DUPLICATED_USER:
        return 'Firebaseのユーザーが重複しています'
      default:
        return '予期しないエラーが発生しました。時間をおいて再度お試しください。'
    }
  }

  private getRemainingHours(param: {
    userMonthlyLimitSeconds: number
    extraChargeLimitSeconds: number
    usedSeconds: number
  }): number {
    const remainingSeconds =
      param.userMonthlyLimitSeconds +
      param.extraChargeLimitSeconds -
      param.usedSeconds
    return Math.floor((remainingSeconds / 3600) * 10) / 10
  }

  private loadAdminOrg(): void {
    this.licenseIsLoading = true
    this.userStore.getAdminOrg().subscribe((org) => {
      this.adminOrg = org

      this.selectableDepartments = [
        {
          id: '',
          name: '全ての部署',
          isDefault: true,
        },
        ...org.departmentNames.map((departmentName) => {
          return {
            id: departmentName,
            name: departmentName,
          }
        }),
      ]

      const uniqueRemainingLicenses: (License & {
        remainingHours: number
        count: number
      })[] = []
      const seenRemainingHours = new Map()

      org.licenseInfo.remainingLicenses.forEach((license) => {
        const extraSeconds = license.extraCharges.reduce(
          (sum, charge) => sum + charge.limitSeconds,
          0,
        )
        const remainingHours = this.getRemainingHours({
          userMonthlyLimitSeconds: license.userMonthlyLimitSeconds,
          extraChargeLimitSeconds: extraSeconds,
          usedSeconds: license.usedSeconds,
        })

        if (!seenRemainingHours.has(remainingHours)) {
          seenRemainingHours.set(remainingHours, {
            ...license,
            remainingHours,
            count: 1,
          })
        } else {
          seenRemainingHours.get(remainingHours).count++
        }
      })

      seenRemainingHours.forEach((value) => {
        uniqueRemainingLicenses.push(value)
      })

      this.selectableLicenses = uniqueRemainingLicenses
        .sort((a, b) => b.remainingHours - a.remainingHours)
        .map((license) => {
          return {
            id: license.id,
            name: `残り：${license.remainingHours} 時間`,
          }
        })

      this.remainingLicensesCount = uniqueRemainingLicenses.map((license) => {
        return {
          name: `残り：${license.remainingHours} 時間`,
          count: license.count,
        }
      })
      this.licenseIsLoading = false
    })
  }

  private loadRoles(): void {
    this.userStore.getRoles().subscribe((roles) => {
      this.roles = roles
    })
  }

  private loadUsers(): void {
    this.isLoading = true
    this.userStore
      .searchUsersForAdmin(
        this.searchCriteria.name ? this.searchCriteria.name : null,
        this.searchCriteria.departmentId
          ? [this.searchCriteria.departmentId]
          : [],
        {
          limit: this.PAGE_SIZE,
          offset: this.PAGE_SIZE * this.searchCriteria.offset,
        },
        {
          sortField: 'name',
          sortOrder: 'asc',
        },
      )
      .pipe(
        tap((users) => {
          if (this.searchCriteria.offset === 0) {
            this.users = []
          }
          this.users.push(
            ...users.map((user) => {
              return {
                ...user,
                selected: false,
                licenseAssigned: !!user.license,
              }
            }),
          )
          this.userTotalCount = 10
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe()
  }
}
