import { Injectable } from '@angular/core'

import {
  distinctUntilChanged,
  interval,
  map,
  Subscription,
  timeout,
  Observable,
  takeWhile,
  tap,
} from 'rxjs'
import { filter } from 'rxjs/operators'

const WINDOW_NAME = 'salesforceAuthPopup'
const POPUP_WIDTH = 483
const POPUP_HEIGHT = 650
const TIMER_INTERVAL = 1000
const POPUP_TIMEOUT = 60000

const WINDOW_FEATURES_BASE =
  'menubar=no,location=yes,resizable=yes,scrollbars=yes,status=no'
const Salesforce_LOGIN_URL =
  'https://login.salesforce.com/services/oauth2/authorize'

export interface SalesforceAuthRequestConfig {
  clientId: string
  redirectUri: string
  scope: string
}

@Injectable({
  providedIn: 'root',
})
export class SalesforceAuthService {
  private popUp: Window | null = null
  private observeUrlSubscription: Subscription | null = null

  requestCode(config: SalesforceAuthRequestConfig): Promise<string | null> {
    this.close()
    return new Promise((resolve, reject) => {
      const url = this.getLoginUrl(config)
      const windowFeature = this.getWindowFeature()
      this.popUp = this.createPopUp(url, windowFeature)
      this.observeUrlSubscription = this.observeUrl(
        config.redirectUri,
      ).subscribe({
        next: (code) => {
          resolve(code)
          this.close()
        },
        complete: () => {
          resolve(null)
        },
        error: (error) => {
          reject(error)
          this.close()
        },
      })
    })
  }

  close() {
    if (this.observeUrlSubscription) {
      if (!this.observeUrlSubscription.closed) {
        this.observeUrlSubscription.unsubscribe()
      }
      this.observeUrlSubscription = null
    }
    if (this.popUp) {
      if (!this.popUp.closed) {
        this.popUp.close()
      }
      this.popUp = null
    }
  }

  private createPopUp(url: string, windowFeature: string): Window | null {
    const popUp = window.open(url, WINDOW_NAME, windowFeature)
    return popUp
  }

  private getLoginUrl(config: SalesforceAuthRequestConfig) {
    const params = {
      client_id: config.clientId,
      response_type: 'code',
      redirect_uri: config.redirectUri,
      scope: config.scope,
    }
    const paramString = new URLSearchParams(params).toString()

    return `${Salesforce_LOGIN_URL}?${paramString}`
  }

  private getWindowFeature() {
    const { top, left } = this.getPosition()
    return `${WINDOW_FEATURES_BASE},left=${left},top=${top},width=${POPUP_WIDTH},height=${POPUP_HEIGHT}`
  }

  private getPosition() {
    const winLeft = window.screenLeft ? window.screenLeft : window.screenX
    const winTop = window.screenTop ? window.screenTop : window.screenY
    const winWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    const winHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    return {
      top: Math.max(0, (winHeight - POPUP_HEIGHT) / 2 + winTop),
      left: Math.max(0, (winWidth - POPUP_WIDTH) / 2 + winLeft),
    }
  }

  private observeUrl(redirectUri: string): Observable<string> {
    return interval(TIMER_INTERVAL).pipe(
      takeWhile(() => !!this.popUp && !this.popUp.closed),
      tap(() => {
        if (this.popUp?.closed) {
          this.close()
        }
      }),
      map(() => {
        try {
          return this.popUp?.location?.href || ''
        } catch {
          // ignore cross origin access error
          return 'not_callback_url'
        }
      }),
      distinctUntilChanged(),
      filter((url) => {
        return !!url && url.startsWith(redirectUri)
      }),
      timeout({ each: POPUP_TIMEOUT }),
      map((url) => {
        const urlObj = new URL(url)
        const code = urlObj.searchParams.get('code')

        if (!code) {
          const errorDescription =
            urlObj.searchParams.get('error_description') || 'Failed to get code'
          throw new Error(errorDescription)
        }
        return code
      }),
    )
  }
}
