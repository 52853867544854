export const chartColor = [
  '#87cefa',
  '#88bd94',
  '#e3a2a2',
  '#dca664',
  '#e7d46e',
  '#8dcbc9',
  '#648f8f',
  '#e77561',
  '#cc8da2',
  '#98e498',
  '#69b2c5',
  '#bed892',
  '#d893c2',
  '#e38866',
  '#cdcd62',
  '#98e2ff',
  '#bd8dd1',
  '#f27d95',
  '#839be1',
  '#7da17d',
  '#8c7bed',
  '#c9706e',
  '#e6e6fa',
  '#9fb0c2',
  '#5ac989',
  '#e176e1',
  '#b8b372',
  '#9165b1',
  '#a97591',
  '#e1ecf6',
] as const
