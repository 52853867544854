<div class="dialog-container">
  <app-loading [showLoading]="isLoading"></app-loading>
  <div class="header">
    <div class="outbound-integration-setting">
      <button
        type="button"
        mat-icon-button
        class="close-button"
        (click)="closeSettingDialog()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <h1 class="heading">Salesforce連携設定</h1>
    </div>
  </div>
  <div class="form dialog-content">
    <div class="field label-fixed">
      <div class="value">
        <label class="label" for="applicable-phases">対象ユーザー *</label>
        <div class="user-list">
          <div class="table-container">
            <table mat-table [dataSource]="this.users">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    [checked]="this.checkedState === 'CHECKED'"
                    [indeterminate]="this.checkedState === 'INDETERMINATE'"
                    (change)="this.checkAllUsers($event.checked)"
                  ></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let user">
                  <mat-checkbox
                    [disabled]="!user.hasLicense"
                    [checked]="user.selected"
                    (change)="this.checkChanged(user)"
                  ></mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef>名前</th>
                <td mat-cell *matCellDef="let user">
                  <div class="user-info">
                    <div class="avatar">
                      <app-avatar
                        [thumbnailUrl]="user.thumbnailPath ?? ''"
                      ></app-avatar>
                    </div>
                    <div class="user-name-email">
                      <div
                        class="name"
                        [matTooltip]="user.name"
                        appEllipsisTooltip
                      >
                        {{ user.name }}
                      </div>
                      <div
                        class="email"
                        [matTooltip]="user.email"
                        appEllipsisTooltip
                      >
                        {{ user.email }}
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="userDepartmentName">
                <th mat-header-cell *matHeaderCellDef>部署</th>
                <td mat-cell *matCellDef="let user">
                  <div [matTooltip]="user.departmentName" appEllipsisTooltip>
                    {{ user.departmentName }}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="reportTo">
                <th mat-header-cell *matHeaderCellDef>上司</th>
                <td mat-cell *matCellDef="let user">
                  <div [matTooltip]="user.reportTo ?? ''" appEllipsisTooltip>
                    {{ user.reportTo ?? '' }}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="remainingHours">
                <th mat-header-cell *matHeaderCellDef>残りの利用時間</th>
                <td mat-cell *matCellDef="let user">
                  {{ user.hasLicense ? user.remainingHours + '時間' : '' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="license">
                <th mat-header-cell *matHeaderCellDef>ライセンス</th>
                <td mat-cell *matCellDef="let user">
                  <span class="chip" [class.disabled]="!user.hasLicense">
                    {{ user.hasLicense ? 'あり' : 'なし' }}
                  </span>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [class.disabled]="!row.hasLicense"
              ></tr>
            </table>
            <ng-container *ngIf="this.users.length === 0">
              <div class="empty">該当なし</div>
            </ng-container>
          </div>
        </div>
        <div class="input-error" *ngIf="isShowError && !this.userSelected()">
          対象ユーザーが選択されていません
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <button
      type="button"
      rectangle
      variant="secondary"
      (click)="closeSettingDialog()"
    >
      キャンセル
    </button>
    <button
      rectangle
      variant="primary"
      (click)="onOutboundIntegrationSettingSave()"
    >
      保存
    </button>
  </div>
</div>
