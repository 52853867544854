{
  "name": "xp-gpt-client",
  "version": "0.0.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:local": "ng serve --configuration=local --host 127.0.0.1",
    "build": "ng build --configuration=development",
    "build:local": "ng build --configuration=local",
    "build:staging": "ng build --configuration=staging",
    "build:prod": "ng build --configuration=production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "eslint && stylelint src/**/*.scss",
    "lint:fix": "eslint --fix && stylelint --fix src/**/*.scss && prettier --write src/",
    "codegen": "graphql-codegen --config codegen.ts -r dotenv/config"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.3",
    "@angular/cdk": "^18.1.3",
    "@angular/common": "^18.1.3",
    "@angular/compiler": "^18.1.3",
    "@angular/core": "^18.1.3",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.1.3",
    "@angular/material": "^18.1.3",
    "@angular/material-moment-adapter": "^18.1.3",
    "@angular/platform-browser": "^18.1.3",
    "@angular/platform-browser-dynamic": "^18.1.3",
    "@angular/router": "^18.1.3",
    "@apollo/client": "^3.11.4",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngrx/component-store": "^18.0.2",
    "@sentry/angular": "^8.33.1",
    "@zxcvbn-ts/core": "^3.0.4",
    "angular-eslint": "^18.2.0",
    "apollo-angular": "^7.0.2",
    "dotenv": "^16.4.5",
    "encoding-japanese": "^2.2.0",
    "graphql": "^16",
    "highcharts": "^11.4.8",
    "highcharts-angular": "^4.0.1",
    "lodash.isequal": "^4.5.0",
    "marked": "^14.0.0",
    "moment": "^2.30.1",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-spinner": "^17.0.0",
    "papaparse": "^5.4.1",
    "rxjs": "~7.8.0",
    "tslib": "^2.6.3",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.3",
    "@angular-eslint/builder": "^18.1.3",
    "@angular-eslint/eslint-plugin": "^18.1.3",
    "@angular-eslint/eslint-plugin-template": "^18.1.3",
    "@angular-eslint/schematics": "^18.1.3",
    "@angular-eslint/template-parser": "^18.1.3",
    "@angular/cli": "^18.1.3",
    "@angular/compiler-cli": "^18.1.3",
    "@eslint/js": "^9.8.0",
    "@graphql-codegen/cli": "^5.0.2",
    "@graphql-codegen/typescript": "^4.0.6",
    "@graphql-codegen/typescript-apollo-angular": "^4.0.0",
    "@graphql-codegen/typescript-operations": "^4.2.0",
    "@types/encoding-japanese": "^2.2.1",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.5",
    "@types/moment": "^2.13.0",
    "@types/papaparse": "^5.3.14",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "8.0.1",
    "@typescript-eslint/parser": "8.0.1",
    "eslint": "^9.8.0",
    "eslint-config-prettier": "^9.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.3.3",
    "stylelint": "^16.8.1",
    "stylelint-config-recess-order": "^5.0.0",
    "stylelint-config-recommended-scss": "^14.1.0",
    "stylelint-config-standard": "^36.0.0",
    "typescript": "~5.5.4",
    "typescript-eslint": "^8.0.1"
  }
}
