import { Component, Inject, ViewChild, ElementRef } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'

import {
  CsvUploadErrorComponent,
  CsvUploadErrorDialogParam,
  CsvFileUploadErrorItem,
} from '../messages/csv-upload-error/csv-upload-error.component'
import { BusinessMeeting } from '../bulk-execution.component'
import { License } from '../../../../services/license/license.mapping'

import { CsvExportService } from '../../../../services/csv-export/csv-export.service'
import { readFileAsArrayBuffer } from '../../../../util/file/reader'
import { detectEncode } from '../../../../util/file/encoding'
import { splitArrayBuffer } from '../../../../util/file/binary'
import { csvToJson } from '../../../../util/file/reader'
import { verifyCSVFormat } from '../../../../util/file/reader'
import { validateEncoding } from '../../../../util/file/encoding'
import { MessageDialogService } from '../../../../components/message-dialog/message-dialog.service'

export type CsvBusinessMeeting = {
  row: number
  fileName: string
  name: string
  accountName: string
  recordDate: Date | null
  userEmail: string
}

export type BusinessMeetingCsvImportDialogParam = {
  businessMeetings: BusinessMeeting[]
  licenses: License[]
}

const MAX_ROW_COUNT = 1000
const CSV_HEADERS = [
  'ファイル名',
  '商談名',
  '顧客会社',
  '記録日',
  '対象ユーザーメールアドレス',
]

@Component({
  selector: 'app-business-meeting-csv-import',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './business-meeting-csv-import.component.html',
  styleUrls: ['./business-meeting-csv-import.component.scss'],
})
export class BusinessMeetingCsvImportComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>

  private businessMeetings: BusinessMeeting[] = []
  private licenses: License[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) param: BusinessMeetingCsvImportDialogParam,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<BusinessMeetingCsvImportComponent>,
    private csvExportService: CsvExportService,
    private messageDialogService: MessageDialogService,
  ) {
    this.businessMeetings = param.businessMeetings
    this.licenses = param.licenses
  }

  onUploadButtonClick(): void {
    this.fileInput.nativeElement.click()
  }

  async onFileChange(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target.files?.length) {
      this.fileInput.nativeElement.value = ''
      return
    }
    const files = Array.from(target.files)
    if (files.length === 0) {
      this.fileInput.nativeElement.value = ''
      return
    }

    const file = files[0]
    this.fileInput.nativeElement.value = ''
    if (file.name.indexOf('.csv') === -1) {
      this.messageDialogService.showError('CSVファイルを選択してください。', {
        title: '形式エラー',
      })
      return
    }

    if (!verifyCSVFormat(file)) {
      this.messageDialogService.showError('CSVファイルの形式が不正です。', {
        title: '形式エラー',
      })
      return
    }

    const arrayBuffer = await readFileAsArrayBuffer(file)
    const encoding = await validateEncoding(arrayBuffer, ['UTF8', 'SJIS'])
    if (!encoding) {
      this.messageDialogService.showError(
        'CSVファイルのエンコードが不正です。',
        {
          title: 'エンコードエラー',
        },
      )
      return
    }

    this.readCsv(file)
  }

  close(): void {
    this.dialogRef.close()
  }

  downloadCsvTemplate(): void {
    this.csvExportService.downloadCsv(
      'アセスメント一括実行_テンプレート.csv',
      [
        { label: 'ファイル名', key: 'filename' },
        { label: '商談名', key: 'name' },
        { label: '顧客会社', key: 'accountName' },
        { label: '記録日', key: 'recordDate' },
        { label: '対象ユーザーメールアドレス', key: 'userEmail' },
      ],
      this.businessMeetings.map((bm) => ({
        filename: bm.fileName,
        name: bm.name,
        accountName: bm.accountName,
        recordDate: bm.recordDate,
        userEmail: bm.license?.user?.email ?? '',
      })),
    )
  }

  async readCsv(file: File): Promise<void> {
    const importedResults = await this.importCsv(file)
    if (importedResults.type === 'error') {
      return
    }

    const errors = this.validateCsv(importedResults.businessMeetings)
    if (errors.length > 0) {
      this.openErrorDialog(errors)
      return
    }

    this.dialogRef.close(importedResults.businessMeetings)
  }

  private async importCsv(file: File): Promise<
    | {
        type: 'success'
        businessMeetings: CsvBusinessMeeting[]
      }
    | {
        type: 'error'
      }
  > {
    const result = await readFileAsArrayBuffer(file)
    const codes = new Uint8Array(result)
    const detectedEncoding = await detectEncode(codes)

    const selectedEncode = detectedEncoding === 'SJIS' ? 'shift-jis' : 'utf-8'

    const rawData = splitArrayBuffer(result, 1024 * 1024)

    const csvString = new TextDecoder(selectedEncode).decode(rawData)

    const parsedData = csvToJson(csvString)
    if (parsedData.length > MAX_ROW_COUNT) {
      this.messageDialogService.showError(
        `CSVファイルの行数が${MAX_ROW_COUNT}行を超えています。${MAX_ROW_COUNT}行以下にしてください。`,
        {
          title: '行数エラー',
        },
      )
      return { type: 'error' }
    }

    const headers = Object.keys(parsedData[0])
    const invalidHeaders = CSV_HEADERS.filter((h) => !headers.includes(h))
    if (invalidHeaders.length > 0) {
      this.messageDialogService.showError(
        `CSVファイルのヘッダーが不正です。${invalidHeaders.join('、')}が必要です。`,
        {
          title: 'ヘッダーエラー',
        },
      )
      return { type: 'error' }
    }

    const businessMeetings = parsedData.map((csvRow, index) =>
      this.mappingToCsvBusinessMeetings(csvRow, index),
    )

    return { type: 'success', businessMeetings }
  }

  private mappingToCsvBusinessMeetings(
    csvRow: Record<string, string>,
    row: number,
  ): CsvBusinessMeeting {
    return {
      row,
      fileName: csvRow['ファイル名'],
      name: csvRow['商談名'],
      accountName: csvRow['顧客会社'],
      recordDate: csvRow['記録日'] ? new Date(csvRow['記録日']) : null,
      userEmail: csvRow['対象ユーザーメールアドレス'],
    }
  }

  private validateCsv(
    csvBusinessMeetings: CsvBusinessMeeting[],
  ): CsvFileUploadErrorItem[] {
    const errors: CsvFileUploadErrorItem[] = []

    csvBusinessMeetings.forEach((csvBusinessMeeting, index) => {
      const businessMeeting = this.businessMeetings.find(
        (bm) => bm.fileName === csvBusinessMeeting.fileName,
      )
      if (csvBusinessMeeting.fileName && !businessMeeting) {
        errors.push({
          rowNo: index + 1,
          fileName: csvBusinessMeeting.fileName,
          message: '一致するファイル名が見つかりませんでした。',
        })
        return
      }

      if (!businessMeeting) return

      if (csvBusinessMeeting.userEmail) {
        const existLicense = this.licenses.find(
          (license) => license.user?.email === csvBusinessMeeting.userEmail,
        )

        if (!existLicense) {
          errors.push({
            rowNo: index + 1,
            fileName: csvBusinessMeeting.fileName,
            message: `一致するユーザーが見つかりませんでした。（${csvBusinessMeeting.userEmail}）`,
          })
        }
      }
    })

    return errors
  }

  private openErrorDialog(errors: CsvFileUploadErrorItem[]): void {
    this.dialog.open<CsvUploadErrorComponent, CsvUploadErrorDialogParam>(
      CsvUploadErrorComponent,
      {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        data: {
          items: errors,
        },
      },
    )
  }
}
