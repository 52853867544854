export const isAmptalkTranscriptFormat = (transcript: string): boolean => {
  const lines = transcript.split('\n').filter((line) => line.trim() !== '')
  if (lines.length < 2) return false

  // Amptalkの特徴的な形式をチェック
  // - 時間（HH:mm:ss または mm:ss）で始まる
  // - その後に話者名が続く
  const timeRegex = /^(?:\d{2}:)?\d{2}:\d{2}\s+[^\n]+$/
  const firstLine = lines[0].trim()

  return (
    timeRegex.test(firstLine) &&
    !firstLine.includes('[') && // Zoom形式ではない
    !firstLine.includes(']') // Zoom形式ではない
  )
}

export const validateAmptalkTranscriptFormat = (transcript: string) => {
  const lines = transcript.split('\n').filter((line) => line.trim() !== '')
  // mm:ss または HH:mm:ss 形式の時間表現に対応する正規表現
  const timeRegex = /^(?:(\d{2}):)?(\d{2}):(\d{2})/

  for (let i = 0; i < lines.length; i += 2) {
    // 奇数行（話者と時間）のチェック
    const speakerLine = lines[i]?.trim()
    if (!speakerLine) return false

    // 時間形式のチェック
    const timeMatch = speakerLine.match(timeRegex)
    if (!timeMatch) return false

    // 時間の妥当性チェック
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, hours, minutes, seconds] = timeMatch.map((n) =>
      n ? Number(n) : 0,
    )
    if (
      (hours !== undefined && (hours < 0 || hours > 23)) ||
      minutes < 0 ||
      minutes > 59 ||
      seconds < 0 ||
      seconds > 59
    ) {
      return false
    }

    // 話者名が存在することを確認
    const speakerName = speakerLine.substring(timeMatch[0].length).trim()
    if (!speakerName) return false

    // 発言内容の存在チェック
    const contentLine = lines[i + 1]?.trim()
    if (!contentLine) return false
  }

  return true
}

export const calculateAmptalkTextDuration = (transcript: string): number => {
  const lines = transcript.split('\n').filter((line) => line.trim() !== '')
  const timeRegex = /^(?:(\d{2}):)?(\d{2}):(\d{2})/

  const times = lines
    .filter((_, index) => index % 2 === 0) // 時間を含む行のみ抽出
    .map((line) => {
      const match = line.match(timeRegex)
      if (match) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, hours, minutes, seconds] = match.map((n) =>
          n ? Number(n) : 0,
        )
        return hours * 60 * 60 + minutes * 60 + seconds
      }
      return null
    })
    .filter((time): time is number => time !== null)

  if (times.length <= 1) return 0

  return times[times.length - 1] - times[0]
}
