import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

@Component({
  selector: 'app-amptalk-integration-success',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './amptalk-integration-success.component.html',
  styleUrls: ['./amptalk-integration-success.component.scss'],
})
export class AmptalkIntegrationSuccessComponent {
  constructor(
    private dialogRef: MatDialogRef<AmptalkIntegrationSuccessComponent>,
  ) {}

  close(): void {
    this.dialogRef.close()
  }
}
