import { LicensePlan } from '../../../_generated/graphql'

export function mappingUserLicense(license: FetchedLicense): License {
  const extraChargeLimitSeconds = license.extraCharges.reduce(
    (acc, extraCharge) => acc + extraCharge.limitSeconds,
    0,
  )
  return {
    id: license.id,
    user: license.user
      ? {
          id: license.user.id,
          orgId: license.user.orgId,
          username: license.user.name,
          email: license.user.email,
          thumbnailPath: license.user.thumbnailPath || null,
          departmentName: license.user.departmentName || null,
          reportTo: license.user.reportTo?.name || null,
          license: license.user.license || null,
        }
      : null,
    businessMeetingCount: license.assessmentsCount,
    usedSeconds: license.usedSeconds,
    limitSeconds: license.userMonthlyLimitSeconds + extraChargeLimitSeconds,
    extraChargeCount: license.extraCharges.length,
  }
}

export interface FetchedExtraCharge {
  limitSeconds: number
}

export interface UserLicense {
  plan: LicensePlan
  userMonthlyLimitSeconds: number
  extraChargeLimitSeconds: number
  usedSeconds: number
}

export interface FetchedLicense {
  id: string
  userMonthlyLimitSeconds: number
  usedSeconds: number
  extraCharges: FetchedExtraCharge[]
  user?: {
    id: string
    orgId: string
    name: string
    email: string
    departmentName?: string | null
    thumbnailPath?: string | null
    reportTo?: { name: string } | null
    license?: UserLicense | null
  } | null
  assessmentsCount: number
}

export interface License {
  id: string
  user: {
    id: string
    orgId: string
    username: string
    email: string
    thumbnailPath: string | null
    departmentName: string | null
    reportTo: string | null
    license: UserLicense | null
  } | null
  businessMeetingCount: number
  usedSeconds: number
  limitSeconds: number
  extraChargeCount: number
}
