<div class="container">
  <h1 class="header">
    <div class="title">
      <mat-icon>error_outline</mat-icon>
      連携設定が失敗しました
    </div>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="main">
    <p>エラーを確認し、修正してください。</p>
    <div class="footer">
      <button rectangle variant="primary" (click)="this.close()">OK</button>
    </div>
  </div>
</div>
