import { Injectable } from '@angular/core'

import { ComponentStore } from '@ngrx/component-store'
import { tap } from 'rxjs'

import { UserInfo, UserService } from '../services/user/user.service'
import { BulkCreateUserParam } from '../../_generated/graphql'

interface UserState {
  ctxUser: UserInfo | null
}

@Injectable({
  providedIn: 'root',
})
export class UserStore extends ComponentStore<UserState> {
  constructor(private userService: UserService) {
    super({
      ctxUser: null,
    })
  }

  getOneSelf(option?: { useCache: boolean }) {
    return this.userService.getOneSelf(option?.useCache ?? false).pipe(
      tap((user) => {
        this.patchState({ ctxUser: user })
      }),
    )
  }

  getUser(userId: string) {
    return this.userService.getUser(userId)
  }

  searchUsers(
    query: string | null,
    departmentNames: string[],
    pagination: { limit: number; offset: number },
    sort?: { sortField: string; sortOrder: 'asc' | 'desc' },
    useCache?: boolean,
  ) {
    return this.userService.searchUsers(
      query ?? '',
      departmentNames,
      pagination,
      sort,
      useCache,
    )
  }

  searchUsersForAdmin(
    query: string | null,
    departmentNames: string[],
    pagination?: { limit: number; offset: number },
    sort?: { sortField: string; sortOrder: 'asc' | 'desc' },
  ) {
    return this.userService.searchUsersForAdmin(
      query ?? '',
      departmentNames,
      pagination,
      sort,
    )
  }

  deleteUsers(userIds: string[]) {
    return this.userService.deleteUsers(userIds)
  }

  ctxUser(): UserInfo | null {
    return this.get((state) => state.ctxUser)
  }

  isAdmin(): boolean {
    return (
      this.get((state) =>
        state.ctxUser?.roles.some((role) => role.code === 'SUPER_ADMIN'),
      ) ?? false
    )
  }

  getAdminOrg() {
    return this.userService.getAdminOrg()
  }

  getRoles() {
    return this.userService.getRoles()
  }

  assignLicense(userId: string, licenseId: string) {
    return this.userService.assignLicense(userId, licenseId)
  }

  revokeLicense(userId: string) {
    return this.userService.revokeLicense(userId)
  }

  updateUser(userInput: {
    id: string
    name: string
    email: string
    departmentName: string
    reportToEmail: string | null
    roleCodes: string[]
    licenseId: string | null
    requiredMfa: boolean
  }) {
    return this.userService.updateUser(userInput)
  }

  createUser(userInput: {
    name: string
    email: string
    departmentName: string
    reportToEmail: string | null
    roleCodes: string[]
    licenseId: string
    requiredMfa: boolean
  }) {
    return this.userService.createUser(userInput)
  }

  bulkCreateUser(params: BulkCreateUserParam[]) {
    return this.userService.bulkCreateUser(params)
  }

  sendInvitations(userIds: string[]) {
    return this.userService.sendInvitations(userIds)
  }

  getDepartmentNames() {
    return this.userService.getDepartmentNames()
  }

  getWeeklyUserScores(
    userId: string,
    startDate: Date,
    endDate: Date,
    skillMapId: string,
  ) {
    return this.userService.getWeeklyUserScores(
      userId,
      startDate,
      endDate,
      skillMapId,
    )
  }

  getWeeklyScores(skillMapId: string, startDate: Date, endDate: Date) {
    return this.userService.getWeeklyScores(skillMapId, startDate, endDate)
  }
}
