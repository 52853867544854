import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatIcon } from '@angular/material/icon'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { LoadingComponent } from '../../components/loading/loading.component'
import { ButtonComponent } from '../../components/button/button.component'
import { AuthService } from '../../services/auth.service'
import { MfaService } from '../../services/mfa.service'
import { getTitle } from '../../util/accessibility'
import { invalidStringValidator } from '../../directives/validator/custom-validator'

const INTERNATIONAL_PHONE_NUMBER_REGEX = /^\+[1-9]\d{1,14}$/

@Component({
  selector: 'app-mfa-phone-setup-page',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    ButtonComponent,
    MatIcon,
    ReactiveFormsModule,
  ],
  templateUrl: './mfa-phone-setup-page.component.html',
  styleUrl: './mfa-phone-setup-page.component.scss',
})
export class MfaPhoneSetupPageComponent implements OnInit {
  isLoading = false
  isMfaVerified = false
  phoneNumber = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      invalidStringValidator(INTERNATIONAL_PHONE_NUMBER_REGEX),
    ],
  })

  setPhoneNumberForm = new FormGroup({
    phoneNumber: this.phoneNumber,
  })

  constructor(
    private router: Router,
    private authService: AuthService,
    private mfaService: MfaService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('電話番号の設定'))
  }

  ngOnInit() {
    const loginResult = this.authService.getLoginResult()
    if (!loginResult || !loginResult.user) {
      this.router.navigate(['/login'])
      return
    }

    if (loginResult.mfaVerified) {
      this.router.navigate(['/home'])
      return
    }

    this.isMfaVerified = !!loginResult.mfaVerified
  }

  backToLogin() {
    this.router.navigate(['/login'])
  }

  sendPhoneNumberSubmit = async (event: Event) => {
    event.preventDefault()

    if (this.setPhoneNumberForm.invalid) {
      return
    }

    const phoneNumber = this.setPhoneNumberForm.value.phoneNumber || ''
    const loginResult = this.authService.getLoginResult()

    if (loginResult?.needsEnrollment && loginResult.user) {
      this.isLoading = true
      try {
        const verificationId = await this.mfaService.enrollPhoneNumber(
          loginResult.user,
          phoneNumber,
        )
        this.authService.setPhoneNumber(phoneNumber)
        this.authService.setVerificationId(verificationId)
      } catch (error) {
        alert('エラーが発生しました。')
        throw error
      } finally {
        this.isLoading = false
      }
    } else {
      this.router.navigate(['/login'])
      return
    }

    this.router.navigate(['/auth/phone-setup/verify'])
  }
}
