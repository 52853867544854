import { Injectable, inject } from '@angular/core'
import {
  Auth,
  User,
  MultiFactorResolver,
  RecaptchaVerifier,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  multiFactor,
  UserCredential,
} from '@angular/fire/auth'
import { Observable, lastValueFrom, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class MfaService {
  private auth = inject(Auth)

  constructor() {}

  async sendSmsCode(resolver: MultiFactorResolver): Promise<string> {
    const recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      'recaptcha-container-id',
      undefined,
    )

    try {
      if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        }

        const phoneAuthProvider = new PhoneAuthProvider(this.auth)

        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier,
        )

        return verificationId
      } else {
        throw new Error('Phone authentication is not set up for this user')
      }
    } finally {
      recaptchaVerifier.clear()
    }
  }

  async enrollPhoneNumber(user: User, phoneNumber: string): Promise<string> {
    const recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      'recaptcha-container-id',
      undefined,
    )

    try {
      const multiFactorUser = multiFactor(user)
      const phoneAuthProvider = new PhoneAuthProvider(this.auth)
      const session = await multiFactorUser.getSession()
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          phoneNumber: phoneNumber,
          session: session,
        },
        recaptchaVerifier,
      )

      return verificationId
    } finally {
      recaptchaVerifier.clear()
    }
  }

  async sendSmsToExistingNumber(user: User): Promise<string> {
    const recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      'recaptcha-container-id',
      undefined,
    )

    try {
      const multiFactorUser = multiFactor(user)
      const enrolledFactors = multiFactorUser.enrolledFactors

      if (enrolledFactors.length === 0) {
        throw new Error('Phone authentication is not set up for this user')
      }

      const session = await multiFactorUser.getSession()
      const phoneAuthProvider = new PhoneAuthProvider(this.auth)

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          session: session,
        },
        recaptchaVerifier,
      )

      return verificationId
    } finally {
      recaptchaVerifier.clear()
    }
  }

  async verifyCode(
    resolver: MultiFactorResolver,
    verificationId: string,
    verificationCode: string,
  ): Promise<UserCredential> {
    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      )
      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(credential)
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion)

      await lastValueFrom(this.completeMfaVerification())

      return userCredential
    } catch (error) {
      console.error('SMS verification failed:', error)
      throw error
    }
  }

  async confirmEnrollment(
    user: User,
    verificationId: string,
    verificationCode: string,
  ): Promise<void> {
    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      )
      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(credential)
      const multiFactorUser = multiFactor(user)
      await multiFactorUser.enroll(multiFactorAssertion, 'Phone Number')

      await lastValueFrom(this.completeMfaVerification())
    } catch (error) {
      console.error('Phone enrollment/update failed:', error)
      throw error
    }
  }

  private completeMfaVerification(): Observable<{
    success: boolean
  }> {
    return of({
      success: true,
    })
  }
}
