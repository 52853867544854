import { Injectable, inject } from '@angular/core'
import {
  Auth,
  GoogleAuthProvider,
  User,
  authState,
  signInWithPopup,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  getIdTokenResult,
  confirmPasswordReset,
  verifyPasswordResetCode,
  MultiFactorResolver,
  getMultiFactorResolver,
  PhoneMultiFactorInfo,
  multiFactor,
} from '@angular/fire/auth'

import { map, tap, lastValueFrom } from 'rxjs'

import { UserService } from './user/user.service'

export interface AuthUser {
  id: string
  name: string | null
  photoUrl: string | null
}

export interface LoginResult {
  success: boolean
  user?: User
  resolver?: MultiFactorResolver
  requiresMfa?: boolean
  mfaVerified?: boolean
  needsEnrollment?: boolean
  phoneNumber?: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth = inject(Auth)

  user: AuthUser | null = null
  user$ = authState(this.auth).pipe(
    map((user) => {
      return user ? this.mappingUser(user) : null
    }),
    tap((user) => {
      this.user = user
    }),
  )

  constructor(private userService: UserService) {}

  private loginResult: LoginResult | null = null
  private verificationId: string = ''
  private phoneNumber: string = ''

  setLoginResult(result: LoginResult): void {
    this.loginResult = result
  }

  getLoginResult(): LoginResult | null {
    return this.loginResult
  }

  setPhoneNumber(phoneNumber: string): void {
    this.phoneNumber = phoneNumber
  }

  getPhoneNumber(): string {
    return this.phoneNumber || this.getLoginResult()?.phoneNumber || ''
  }

  setVerificationId(id: string): void {
    this.verificationId = id
  }

  getVerificationId(): string {
    return this.verificationId
  }

  loginWithGoogle = async () => {
    const result = await signInWithPopup(this.auth, new GoogleAuthProvider())

    const user = result.user

    const idTokenResult = await getIdTokenResult(user)

    const claims = idTokenResult.claims
    if (claims.org_id && claims.user_id) {
      return true
    } else {
      this.auth.signOut()
      return false
    }
  }

  loginWithEmailAndPassword = async (
    email: string,
    password: string,
  ): Promise<LoginResult> => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password,
      )
      const user = userCredential.user
      const enrolledFactors = multiFactor(user).enrolledFactors

      try {
        const mfaStatus = await lastValueFrom(this.userService.checkMfaStatus())
        const result: LoginResult = {
          success: true,
          user,
        }

        if (mfaStatus.requiredForUser) {
          result.requiresMfa = true
          result.phoneNumber = mfaStatus.phoneNumber
          result.needsEnrollment = enrolledFactors.length === 0
        } else {
          result.requiresMfa = false
        }

        return result
      } catch (gqlError) {
        console.error('GQL MFA status check failed:', gqlError)
        // login success unless MFA status check fails
        return {
          success: true,
          user,
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(this.auth, error)
        const phoneFactor = resolver.hints.find(
          (hint) => hint.factorId === 'phone',
        ) as PhoneMultiFactorInfo
        const phoneNumber = phoneFactor.phoneNumber || ''
        return {
          success: false,
          resolver: resolver,
          requiresMfa: true,
          mfaVerified: false,
          phoneNumber,
        }
      }

      throw error
    }
  }

  sendPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(this.auth, email)
  }

  logout = () => {
    this.clearLoginState()
    return signOut(this.auth)
  }

  clearLoginState(): void {
    this.loginResult = null
    this.verificationId = ''
    this.phoneNumber = ''
  }

  verifyPasswordResetCode = (oobCode: string): Promise<string> => {
    return verifyPasswordResetCode(this.auth, oobCode)
  }

  confirmPasswordReset = (
    oobCode: string,
    newPassword: string,
  ): Promise<void> => {
    return confirmPasswordReset(this.auth, oobCode, newPassword)
  }

  hasPhoneMultiFactor(resolver: MultiFactorResolver): boolean {
    return resolver.hints.some((hint) => hint.factorId === 'phone')
  }

  private mappingUser = (user: User): AuthUser => {
    return {
      id: user.uid,
      name: user.displayName,
      photoUrl: user.photoURL,
    }
  }
}
