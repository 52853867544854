import { parse } from 'papaparse'

export const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target) {
        resolve(event.target.result as ArrayBuffer)
      } else {
        reject(new Error('Failed to read file'))
      }
    }
    reader.onerror = () => {
      reject(new Error('Failed to read file'))
    }
    reader.readAsArrayBuffer(file)
  })
}

export const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target) {
        resolve(event.target.result as string)
      } else {
        reject(new Error('Failed to read file'))
      }
    }
    reader.onerror = () => {
      reject(new Error('Failed to read file'))
    }
    reader.readAsText(file)
  })
}

export interface ValidationResult {
  isValid: boolean
  errors: { code: string; row?: number }[]
}

export const csvToJson = (csvString: string): Record<string, string>[] => {
  return parse(csvString, {
    header: true,
    quoteChar: '"',
    skipEmptyLines: true,
  }).data as Record<string, string>[]
}

export const verifyCSVFormat = (file: File): Promise<ValidationResult> => {
  return new Promise((resolve) => {
    parse(file, {
      header: true,
      quoteChar: '"',
      skipEmptyLines: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      complete: (results: any) => {
        const validationResult: ValidationResult = {
          isValid: true,
          errors: [],
        }
        if (results.errors.length > 0) {
          validationResult.isValid = false
          validationResult.errors = results.errors
        }

        resolve(validationResult)
      },
      error: () => {
        resolve({
          isValid: false,
          errors: [{ code: 'UnknownError' }],
        } as ValidationResult)
      },
    })
  })
}
