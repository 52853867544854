import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

@Component({
  selector: 'app-setting-save-error',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './setting-save-error.component.html',
  styleUrls: ['./setting-save-error.component.scss'],
})
export class SettingSaveErrorComponent {
  constructor(private dialogRef: MatDialogRef<SettingSaveErrorComponent>) {}

  close(): void {
    this.dialogRef.close()
  }
}
