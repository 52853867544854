import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'

import { MatIcon } from '@angular/material/icon'
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'

import { ButtonComponent } from '../../../components/button/button.component'
import {
  SelectComponent,
  Option,
} from '../../../components/select/select.component'
import {
  MultipleSelectComponent,
  Item,
} from '../../../components/multiple-select/multiple-select.component'
import { AvatarComponent } from '../../../components/avatar/avatar.component'

import {
  AmptalkIntegrationComponent,
  AmptalkIntegrationData,
} from './amptalk-integration/amptalk-integration.component'
import { AmptalkIntegrationSuccessComponent } from './messages/amptalk-integration-success/amptalk-integration-success.component'
import { AmptalkIntegrationResetWarningComponent } from './messages/amptalk-integration-reset-warning/amptalk-integration-reset-warning.component'
import { AssessmentSettingSaveErrorComponent } from './messages/assessment-setting-save-error/assessment-setting-save-errorcomponent'

import { getTitle } from '../../../util/accessibility'
import { SkillMap } from '../../../services/assessment/assessment.mapping'
import { AssessmentStore } from '../../../stores/assessment.store'
import { UserStore } from '../../../stores/user.store'
import { UserForAdmin } from '../../../services/user/user.mapping'

interface UserRow {
  selected: boolean
  name: string
  email: string
  thumbnailPath: string | null
  departmentName: string
  reportTo: string
  remainingHours: number | null
  hasLicense: boolean
}

@Component({
  selector: 'app-external-integration-page',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatCheckbox,
    MatSlideToggle,
    MatTableModule,
    ButtonComponent,
    SelectComponent,
    MultipleSelectComponent,
    AvatarComponent,
  ],
  templateUrl: './external-integration-page.component.html',
  styleUrl: './external-integration-page.component.scss',
})
export class ExternalIntegrationPageComponent implements OnInit {
  isAmptalkIntegrationEnabled = false
  hasAmptalkIntegrationError = false
  isShowError = false

  amptalkSetting: {
    apiKey: string
    authKey: string
  } = {
    apiKey: '',
    authKey: '',
  }

  displayedColumns: string[] = [
    'select',
    'userName',
    'userDepartmentName',
    'reportTo',
    'remainingHours',
    'license',
  ]
  dataSource: UserRow[] = []

  skillMaps: SkillMap[] = []
  skillMapOptions: Option[] = []
  selectedSkillMap: Option = { id: '', name: '' }
  phaseItems: Item[] = []

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userStore: UserStore,
    private assessmentStore: AssessmentStore,
  ) {
    this.titleService.setTitle(getTitle('外部連携'))
  }

  ngOnInit() {
    this.assessmentStore.getSkillMaps().subscribe((skillMaps) => {
      this.skillMaps = skillMaps
      this.skillMapOptions = skillMaps.map((skillMap) => ({
        id: skillMap.id,
        name: skillMap.name,
      }))
    })

    this.userStore.searchUsersForAdmin(null, []).subscribe((users) => {
      this.dataSource = users.map((user) => this.toUserRow(user))
    })
  }

  onAmptalkToggleChange(event: MatSlideToggleChange, slider: MatSlideToggle) {
    if (event.checked) {
      this.openAmptalkIntegrationDialog()
    } else {
      this.openAmptalkResetWarningDialog(slider)
    }
  }

  onUpdateAmptalkIntegration() {
    this.openAmptalkIntegrationDialog()
  }

  onAssessmentSettingSave() {
    this.isShowError = true

    if (
      !this.skillMapSelected() ||
      !this.phaseSelected() ||
      !this.userSelected()
    ) {
      this.openAssessmentSettingSaveErrorDialog()
      return
    }

    this.snackBar.open(
      '設定が正常に保存されました。設定に基づいて、今後のアセスメントや同期が実行されます。',
      '閉じる',
    )
  }

  changeSkillMap(option: Option): void {
    this.selectedSkillMap = this.skillMapOptions.find(
      (op) => op.id === option.id,
    ) ?? { id: '', name: '' }
    this.phaseItems =
      this.skillMaps
        .find((skillMap) => skillMap.id === option.id)
        ?.phases.map((phase) => ({
          id: phase.id,
          label: phase.name,
          checked: false,
        })) ?? []
  }

  changeSelectedPhases(items: Item[]): void {
    this.phaseItems = items
  }

  get checkedCount(): number {
    return this.dataSource.filter((row) => row.selected)?.length
  }
  get checkedState(): 'CHECKED' | 'UNCHECKED' | 'INDETERMINATE' {
    const checkedCount =
      this.dataSource.filter((assessment) => assessment.selected)?.length ?? 0
    if (checkedCount === 0) {
      return 'UNCHECKED'
    } else if (checkedCount === this.dataSource.length) {
      return 'CHECKED'
    } else {
      return 'INDETERMINATE'
    }
  }

  skillMapSelected(): boolean {
    return this.selectedSkillMap.id !== ''
  }

  phaseSelected(): boolean {
    return this.phaseItems.filter((item) => item.checked).length > 0
  }

  userSelected(): boolean {
    return this.dataSource.filter((user) => user.selected).length > 0
  }

  checkAllUsers = (checked: boolean) => {
    this.dataSource.forEach((user) => {
      if (checked && !user.selected) {
        if (user.hasLicense) {
          user.selected = checked
        }
      } else if (!checked && user.selected) {
        user.selected = checked
      }
    })
  }

  checkChanged = (user: UserRow) => {
    user.selected = !user.selected
  }

  private openAmptalkIntegrationDialog() {
    this.dialog
      .open<AmptalkIntegrationComponent, AmptalkIntegrationData>(
        AmptalkIntegrationComponent,
        {
          disableClose: true,
          panelClass: 'unset-mat-dialog-padding',
          data: {
            apiKey: this.amptalkSetting.apiKey,
            authKey: this.amptalkSetting.authKey,
          },
        },
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          this.isAmptalkIntegrationEnabled = false
          return
        }
        this.isAmptalkIntegrationEnabled = true
        this.amptalkSetting.apiKey = result.apiKey
        this.amptalkSetting.authKey = result.authKey
        this.dialog.open(AmptalkIntegrationSuccessComponent)
      })
  }

  private openAmptalkResetWarningDialog(slider: MatSlideToggle) {
    this.dialog
      .open(AmptalkIntegrationResetWarningComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          slider.checked = false
          this.isAmptalkIntegrationEnabled = false
          return
        }

        slider.checked = true
        this.isAmptalkIntegrationEnabled = true
      })
  }

  private openAssessmentSettingSaveErrorDialog() {
    this.dialog.open(AssessmentSettingSaveErrorComponent, {
      disableClose: true,
      panelClass: 'unset-mat-dialog-padding',
    })
  }

  private toUserRow(user: UserForAdmin): UserRow {
    let remainingHours = null
    if (user.license) {
      const remainingSeconds =
        user.license.userMonthlyLimitSeconds -
        user.license.usedSeconds +
        user.license.extraChargeLimitSeconds
      remainingHours = Math.floor(remainingSeconds / 3600)
    }

    return {
      selected: false,
      name: user.name,
      email: user.email,
      thumbnailPath: user.thumbnailPath || null,
      departmentName: user.departmentName,
      reportTo: user.reportTo?.name || '',
      remainingHours: remainingHours,
      hasLicense: user.license !== null,
    }
  }
}
