/* eslint-disable indent,key-spacing */
/**
 * Encoding conversion table for UTF-8 to JIS X 0212:1990 (Hojo-Kanji)
 */
module.exports = {
  0xCB98: 0x222F,
  0xCB87: 0x2230,
  0xC2B8: 0x2231,
  0xCB99: 0x2232,
  0xCB9D: 0x2233,
  0xC2AF: 0x2234,
  0xCB9B: 0x2235,
  0xCB9A: 0x2236,
  0x7E: 0x2237,
  0xCE84: 0x2238,
  0xCE85: 0x2239,
  0xC2A1: 0x2242,
  0xC2A6: 0x2243,
  0xC2BF: 0x2244,
  0xC2BA: 0x226B,
  0xC2AA: 0x226C,
  0xC2A9: 0x226D,
  0xC2AE: 0x226E,
  0xE284A2: 0x226F,
  0xC2A4: 0x2270,
  0xE28496: 0x2271,
  0xCE86: 0x2661,
  0xCE88: 0x2662,
  0xCE89: 0x2663,
  0xCE8A: 0x2664,
  0xCEAA: 0x2665,
  0xCE8C: 0x2667,
  0xCE8E: 0x2669,
  0xCEAB: 0x266A,
  0xCE8F: 0x266C,
  0xCEAC: 0x2671,
  0xCEAD: 0x2672,
  0xCEAE: 0x2673,
  0xCEAF: 0x2674,
  0xCF8A: 0x2675,
  0xCE90: 0x2676,
  0xCF8C: 0x2677,
  0xCF82: 0x2678,
  0xCF8D: 0x2679,
  0xCF8B: 0x267A,
  0xCEB0: 0x267B,
  0xCF8E: 0x267C,
  0xD082: 0x2742,
  0xD083: 0x2743,
  0xD084: 0x2744,
  0xD085: 0x2745,
  0xD086: 0x2746,
  0xD087: 0x2747,
  0xD088: 0x2748,
  0xD089: 0x2749,
  0xD08A: 0x274A,
  0xD08B: 0x274B,
  0xD08C: 0x274C,
  0xD08E: 0x274D,
  0xD08F: 0x274E,
  0xD192: 0x2772,
  0xD193: 0x2773,
  0xD194: 0x2774,
  0xD195: 0x2775,
  0xD196: 0x2776,
  0xD197: 0x2777,
  0xD198: 0x2778,
  0xD199: 0x2779,
  0xD19A: 0x277A,
  0xD19B: 0x277B,
  0xD19C: 0x277C,
  0xD19E: 0x277D,
  0xD19F: 0x277E,
  0xC386: 0x2921,
  0xC490: 0x2922,
  0xC4A6: 0x2924,
  0xC4B2: 0x2926,
  0xC581: 0x2928,
  0xC4BF: 0x2929,
  0xC58A: 0x292B,
  0xC398: 0x292C,
  0xC592: 0x292D,
  0xC5A6: 0x292F,
  0xC39E: 0x2930,
  0xC3A6: 0x2941,
  0xC491: 0x2942,
  0xC3B0: 0x2943,
  0xC4A7: 0x2944,
  0xC4B1: 0x2945,
  0xC4B3: 0x2946,
  0xC4B8: 0x2947,
  0xC582: 0x2948,
  0xC580: 0x2949,
  0xC589: 0x294A,
  0xC58B: 0x294B,
  0xC3B8: 0x294C,
  0xC593: 0x294D,
  0xC39F: 0x294E,
  0xC5A7: 0x294F,
  0xC3BE: 0x2950,
  0xC381: 0x2A21,
  0xC380: 0x2A22,
  0xC384: 0x2A23,
  0xC382: 0x2A24,
  0xC482: 0x2A25,
  0xC78D: 0x2A26,
  0xC480: 0x2A27,
  0xC484: 0x2A28,
  0xC385: 0x2A29,
  0xC383: 0x2A2A,
  0xC486: 0x2A2B,
  0xC488: 0x2A2C,
  0xC48C: 0x2A2D,
  0xC387: 0x2A2E,
  0xC48A: 0x2A2F,
  0xC48E: 0x2A30,
  0xC389: 0x2A31,
  0xC388: 0x2A32,
  0xC38B: 0x2A33,
  0xC38A: 0x2A34,
  0xC49A: 0x2A35,
  0xC496: 0x2A36,
  0xC492: 0x2A37,
  0xC498: 0x2A38,
  0xC49C: 0x2A3A,
  0xC49E: 0x2A3B,
  0xC4A2: 0x2A3C,
  0xC4A0: 0x2A3D,
  0xC4A4: 0x2A3E,
  0xC38D: 0x2A3F,
  0xC38C: 0x2A40,
  0xC38F: 0x2A41,
  0xC38E: 0x2A42,
  0xC78F: 0x2A43,
  0xC4B0: 0x2A44,
  0xC4AA: 0x2A45,
  0xC4AE: 0x2A46,
  0xC4A8: 0x2A47,
  0xC4B4: 0x2A48,
  0xC4B6: 0x2A49,
  0xC4B9: 0x2A4A,
  0xC4BD: 0x2A4B,
  0xC4BB: 0x2A4C,
  0xC583: 0x2A4D,
  0xC587: 0x2A4E,
  0xC585: 0x2A4F,
  0xC391: 0x2A50,
  0xC393: 0x2A51,
  0xC392: 0x2A52,
  0xC396: 0x2A53,
  0xC394: 0x2A54,
  0xC791: 0x2A55,
  0xC590: 0x2A56,
  0xC58C: 0x2A57,
  0xC395: 0x2A58,
  0xC594: 0x2A59,
  0xC598: 0x2A5A,
  0xC596: 0x2A5B,
  0xC59A: 0x2A5C,
  0xC59C: 0x2A5D,
  0xC5A0: 0x2A5E,
  0xC59E: 0x2A5F,
  0xC5A4: 0x2A60,
  0xC5A2: 0x2A61,
  0xC39A: 0x2A62,
  0xC399: 0x2A63,
  0xC39C: 0x2A64,
  0xC39B: 0x2A65,
  0xC5AC: 0x2A66,
  0xC793: 0x2A67,
  0xC5B0: 0x2A68,
  0xC5AA: 0x2A69,
  0xC5B2: 0x2A6A,
  0xC5AE: 0x2A6B,
  0xC5A8: 0x2A6C,
  0xC797: 0x2A6D,
  0xC79B: 0x2A6E,
  0xC799: 0x2A6F,
  0xC795: 0x2A70,
  0xC5B4: 0x2A71,
  0xC39D: 0x2A72,
  0xC5B8: 0x2A73,
  0xC5B6: 0x2A74,
  0xC5B9: 0x2A75,
  0xC5BD: 0x2A76,
  0xC5BB: 0x2A77,
  0xC3A1: 0x2B21,
  0xC3A0: 0x2B22,
  0xC3A4: 0x2B23,
  0xC3A2: 0x2B24,
  0xC483: 0x2B25,
  0xC78E: 0x2B26,
  0xC481: 0x2B27,
  0xC485: 0x2B28,
  0xC3A5: 0x2B29,
  0xC3A3: 0x2B2A,
  0xC487: 0x2B2B,
  0xC489: 0x2B2C,
  0xC48D: 0x2B2D,
  0xC3A7: 0x2B2E,
  0xC48B: 0x2B2F,
  0xC48F: 0x2B30,
  0xC3A9: 0x2B31,
  0xC3A8: 0x2B32,
  0xC3AB: 0x2B33,
  0xC3AA: 0x2B34,
  0xC49B: 0x2B35,
  0xC497: 0x2B36,
  0xC493: 0x2B37,
  0xC499: 0x2B38,
  0xC7B5: 0x2B39,
  0xC49D: 0x2B3A,
  0xC49F: 0x2B3B,
  0xC4A1: 0x2B3D,
  0xC4A5: 0x2B3E,
  0xC3AD: 0x2B3F,
  0xC3AC: 0x2B40,
  0xC3AF: 0x2B41,
  0xC3AE: 0x2B42,
  0xC790: 0x2B43,
  0xC4AB: 0x2B45,
  0xC4AF: 0x2B46,
  0xC4A9: 0x2B47,
  0xC4B5: 0x2B48,
  0xC4B7: 0x2B49,
  0xC4BA: 0x2B4A,
  0xC4BE: 0x2B4B,
  0xC4BC: 0x2B4C,
  0xC584: 0x2B4D,
  0xC588: 0x2B4E,
  0xC586: 0x2B4F,
  0xC3B1: 0x2B50,
  0xC3B3: 0x2B51,
  0xC3B2: 0x2B52,
  0xC3B6: 0x2B53,
  0xC3B4: 0x2B54,
  0xC792: 0x2B55,
  0xC591: 0x2B56,
  0xC58D: 0x2B57,
  0xC3B5: 0x2B58,
  0xC595: 0x2B59,
  0xC599: 0x2B5A,
  0xC597: 0x2B5B,
  0xC59B: 0x2B5C,
  0xC59D: 0x2B5D,
  0xC5A1: 0x2B5E,
  0xC59F: 0x2B5F,
  0xC5A5: 0x2B60,
  0xC5A3: 0x2B61,
  0xC3BA: 0x2B62,
  0xC3B9: 0x2B63,
  0xC3BC: 0x2B64,
  0xC3BB: 0x2B65,
  0xC5AD: 0x2B66,
  0xC794: 0x2B67,
  0xC5B1: 0x2B68,
  0xC5AB: 0x2B69,
  0xC5B3: 0x2B6A,
  0xC5AF: 0x2B6B,
  0xC5A9: 0x2B6C,
  0xC798: 0x2B6D,
  0xC79C: 0x2B6E,
  0xC79A: 0x2B6F,
  0xC796: 0x2B70,
  0xC5B5: 0x2B71,
  0xC3BD: 0x2B72,
  0xC3BF: 0x2B73,
  0xC5B7: 0x2B74,
  0xC5BA: 0x2B75,
  0xC5BE: 0x2B76,
  0xC5BC: 0x2B77,
  0xE4B882: 0x3021,
  0xE4B884: 0x3022,
  0xE4B885: 0x3023,
  0xE4B88C: 0x3024,
  0xE4B892: 0x3025,
  0xE4B89F: 0x3026,
  0xE4B8A3: 0x3027,
  0xE4B8A4: 0x3028,
  0xE4B8A8: 0x3029,
  0xE4B8AB: 0x302A,
  0xE4B8AE: 0x302B,
  0xE4B8AF: 0x302C,
  0xE4B8B0: 0x302D,
  0xE4B8B5: 0x302E,
  0xE4B980: 0x302F,
  0xE4B981: 0x3030,
  0xE4B984: 0x3031,
  0xE4B987: 0x3032,
  0xE4B991: 0x3033,
  0xE4B99A: 0x3034,
  0xE4B99C: 0x3035,
  0xE4B9A3: 0x3036,
  0xE4B9A8: 0x3037,
  0xE4B9A9: 0x3038,
  0xE4B9B4: 0x3039,
  0xE4B9B5: 0x303A,
  0xE4B9B9: 0x303B,
  0xE4B9BF: 0x303C,
  0xE4BA8D: 0x303D,
  0xE4BA96: 0x303E,
  0xE4BA97: 0x303F,
  0xE4BA9D: 0x3040,
  0xE4BAAF: 0x3041,
  0xE4BAB9: 0x3042,
  0xE4BB83: 0x3043,
  0xE4BB90: 0x3044,
  0xE4BB9A: 0x3045,
  0xE4BB9B: 0x3046,
  0xE4BBA0: 0x3047,
  0xE4BBA1: 0x3048,
  0xE4BBA2: 0x3049,
  0xE4BBA8: 0x304A,
  0xE4BBAF: 0x304B,
  0xE4BBB1: 0x304C,
  0xE4BBB3: 0x304D,
  0xE4BBB5: 0x304E,
  0xE4BBBD: 0x304F,
  0xE4BBBE: 0x3050,
  0xE4BBBF: 0x3051,
  0xE4BC80: 0x3052,
  0xE4BC82: 0x3053,
  0xE4BC83: 0x3054,
  0xE4BC88: 0x3055,
  0xE4BC8B: 0x3056,
  0xE4BC8C: 0x3057,
  0xE4BC92: 0x3058,
  0xE4BC95: 0x3059,
  0xE4BC96: 0x305A,
  0xE4BC97: 0x305B,
  0xE4BC99: 0x305C,
  0xE4BCAE: 0x305D,
  0xE4BCB1: 0x305E,
  0xE4BDA0: 0x305F,
  0xE4BCB3: 0x3060,
  0xE4BCB5: 0x3061,
  0xE4BCB7: 0x3062,
  0xE4BCB9: 0x3063,
  0xE4BCBB: 0x3064,
  0xE4BCBE: 0x3065,
  0xE4BD80: 0x3066,
  0xE4BD82: 0x3067,
  0xE4BD88: 0x3068,
  0xE4BD89: 0x3069,
  0xE4BD8B: 0x306A,
  0xE4BD8C: 0x306B,
  0xE4BD92: 0x306C,
  0xE4BD94: 0x306D,
  0xE4BD96: 0x306E,
  0xE4BD98: 0x306F,
  0xE4BD9F: 0x3070,
  0xE4BDA3: 0x3071,
  0xE4BDAA: 0x3072,
  0xE4BDAC: 0x3073,
  0xE4BDAE: 0x3074,
  0xE4BDB1: 0x3075,
  0xE4BDB7: 0x3076,
  0xE4BDB8: 0x3077,
  0xE4BDB9: 0x3078,
  0xE4BDBA: 0x3079,
  0xE4BDBD: 0x307A,
  0xE4BDBE: 0x307B,
  0xE4BE81: 0x307C,
  0xE4BE82: 0x307D,
  0xE4BE84: 0x307E,
  0xE4BE85: 0x3121,
  0xE4BE89: 0x3122,
  0xE4BE8A: 0x3123,
  0xE4BE8C: 0x3124,
  0xE4BE8E: 0x3125,
  0xE4BE90: 0x3126,
  0xE4BE92: 0x3127,
  0xE4BE93: 0x3128,
  0xE4BE94: 0x3129,
  0xE4BE97: 0x312A,
  0xE4BE99: 0x312B,
  0xE4BE9A: 0x312C,
  0xE4BE9E: 0x312D,
  0xE4BE9F: 0x312E,
  0xE4BEB2: 0x312F,
  0xE4BEB7: 0x3130,
  0xE4BEB9: 0x3131,
  0xE4BEBB: 0x3132,
  0xE4BEBC: 0x3133,
  0xE4BEBD: 0x3134,
  0xE4BEBE: 0x3135,
  0xE4BF80: 0x3136,
  0xE4BF81: 0x3137,
  0xE4BF85: 0x3138,
  0xE4BF86: 0x3139,
  0xE4BF88: 0x313A,
  0xE4BF89: 0x313B,
  0xE4BF8B: 0x313C,
  0xE4BF8C: 0x313D,
  0xE4BF8D: 0x313E,
  0xE4BF8F: 0x313F,
  0xE4BF92: 0x3140,
  0xE4BF9C: 0x3141,
  0xE4BFA0: 0x3142,
  0xE4BFA2: 0x3143,
  0xE4BFB0: 0x3144,
  0xE4BFB2: 0x3145,
  0xE4BFBC: 0x3146,
  0xE4BFBD: 0x3147,
  0xE4BFBF: 0x3148,
  0xE58080: 0x3149,
  0xE58081: 0x314A,
  0xE58084: 0x314B,
  0xE58087: 0x314C,
  0xE5808A: 0x314D,
  0xE5808C: 0x314E,
  0xE5808E: 0x314F,
  0xE58090: 0x3150,
  0xE58093: 0x3151,
  0xE58097: 0x3152,
  0xE58098: 0x3153,
  0xE5809B: 0x3154,
  0xE5809C: 0x3155,
  0xE5809D: 0x3156,
  0xE5809E: 0x3157,
  0xE580A2: 0x3158,
  0xE580A7: 0x3159,
  0xE580AE: 0x315A,
  0xE580B0: 0x315B,
  0xE580B2: 0x315C,
  0xE580B3: 0x315D,
  0xE580B5: 0x315E,
  0xE58180: 0x315F,
  0xE58181: 0x3160,
  0xE58182: 0x3161,
  0xE58185: 0x3162,
  0xE58186: 0x3163,
  0xE5818A: 0x3164,
  0xE5818C: 0x3165,
  0xE5818E: 0x3166,
  0xE58191: 0x3167,
  0xE58192: 0x3168,
  0xE58193: 0x3169,
  0xE58197: 0x316A,
  0xE58199: 0x316B,
  0xE5819F: 0x316C,
  0xE581A0: 0x316D,
  0xE581A2: 0x316E,
  0xE581A3: 0x316F,
  0xE581A6: 0x3170,
  0xE581A7: 0x3171,
  0xE581AA: 0x3172,
  0xE581AD: 0x3173,
  0xE581B0: 0x3174,
  0xE581B1: 0x3175,
  0xE580BB: 0x3176,
  0xE58281: 0x3177,
  0xE58283: 0x3178,
  0xE58284: 0x3179,
  0xE58286: 0x317A,
  0xE5828A: 0x317B,
  0xE5828E: 0x317C,
  0xE5828F: 0x317D,
  0xE58290: 0x317E,
  0xE58292: 0x3221,
  0xE58293: 0x3222,
  0xE58294: 0x3223,
  0xE58296: 0x3224,
  0xE5829B: 0x3225,
  0xE5829C: 0x3226,
  0xE5829E: 0x3227,
  0xE5829F: 0x3228,
  0xE582A0: 0x3229,
  0xE582A1: 0x322A,
  0xE582A2: 0x322B,
  0xE582AA: 0x322C,
  0xE582AF: 0x322D,
  0xE582B0: 0x322E,
  0xE582B9: 0x322F,
  0xE582BA: 0x3230,
  0xE582BD: 0x3231,
  0xE58380: 0x3232,
  0xE58383: 0x3233,
  0xE58384: 0x3234,
  0xE58387: 0x3235,
  0xE5838C: 0x3236,
  0xE5838E: 0x3237,
  0xE58390: 0x3238,
  0xE58393: 0x3239,
  0xE58394: 0x323A,
  0xE58398: 0x323B,
  0xE5839C: 0x323C,
  0xE5839D: 0x323D,
  0xE5839F: 0x323E,
  0xE583A2: 0x323F,
  0xE583A4: 0x3240,
  0xE583A6: 0x3241,
  0xE583A8: 0x3242,
  0xE583A9: 0x3243,
  0xE583AF: 0x3244,
  0xE583B1: 0x3245,
  0xE583B6: 0x3246,
  0xE583BA: 0x3247,
  0xE583BE: 0x3248,
  0xE58483: 0x3249,
  0xE58486: 0x324A,
  0xE58487: 0x324B,
  0xE58488: 0x324C,
  0xE5848B: 0x324D,
  0xE5848C: 0x324E,
  0xE5848D: 0x324F,
  0xE5848E: 0x3250,
  0xE583B2: 0x3251,
  0xE58490: 0x3252,
  0xE58497: 0x3253,
  0xE58499: 0x3254,
  0xE5849B: 0x3255,
  0xE5849C: 0x3256,
  0xE5849D: 0x3257,
  0xE5849E: 0x3258,
  0xE584A3: 0x3259,
  0xE584A7: 0x325A,
  0xE584A8: 0x325B,
  0xE584AC: 0x325C,
  0xE584AD: 0x325D,
  0xE584AF: 0x325E,
  0xE584B1: 0x325F,
  0xE584B3: 0x3260,
  0xE584B4: 0x3261,
  0xE584B5: 0x3262,
  0xE584B8: 0x3263,
  0xE584B9: 0x3264,
  0xE58582: 0x3265,
  0xE5858A: 0x3266,
  0xE5858F: 0x3267,
  0xE58593: 0x3268,
  0xE58595: 0x3269,
  0xE58597: 0x326A,
  0xE58598: 0x326B,
  0xE5859F: 0x326C,
  0xE585A4: 0x326D,
  0xE585A6: 0x326E,
  0xE585BE: 0x326F,
  0xE58683: 0x3270,
  0xE58684: 0x3271,
  0xE5868B: 0x3272,
  0xE5868E: 0x3273,
  0xE58698: 0x3274,
  0xE5869D: 0x3275,
  0xE586A1: 0x3276,
  0xE586A3: 0x3277,
  0xE586AD: 0x3278,
  0xE586B8: 0x3279,
  0xE586BA: 0x327A,
  0xE586BC: 0x327B,
  0xE586BE: 0x327C,
  0xE586BF: 0x327D,
  0xE58782: 0x327E,
  0xE58788: 0x3321,
  0xE5878F: 0x3322,
  0xE58791: 0x3323,
  0xE58792: 0x3324,
  0xE58793: 0x3325,
  0xE58795: 0x3326,
  0xE58798: 0x3327,
  0xE5879E: 0x3328,
  0xE587A2: 0x3329,
  0xE587A5: 0x332A,
  0xE587AE: 0x332B,
  0xE587B2: 0x332C,
  0xE587B3: 0x332D,
  0xE587B4: 0x332E,
  0xE587B7: 0x332F,
  0xE58881: 0x3330,
  0xE58882: 0x3331,
  0xE58885: 0x3332,
  0xE58892: 0x3333,
  0xE58893: 0x3334,
  0xE58895: 0x3335,
  0xE58896: 0x3336,
  0xE58898: 0x3337,
  0xE588A2: 0x3338,
  0xE588A8: 0x3339,
  0xE588B1: 0x333A,
  0xE588B2: 0x333B,
  0xE588B5: 0x333C,
  0xE588BC: 0x333D,
  0xE58985: 0x333E,
  0xE58989: 0x333F,
  0xE58995: 0x3340,
  0xE58997: 0x3341,
  0xE58998: 0x3342,
  0xE5899A: 0x3343,
  0xE5899C: 0x3344,
  0xE5899F: 0x3345,
  0xE589A0: 0x3346,
  0xE589A1: 0x3347,
  0xE589A6: 0x3348,
  0xE589AE: 0x3349,
  0xE589B7: 0x334A,
  0xE589B8: 0x334B,
  0xE589B9: 0x334C,
  0xE58A80: 0x334D,
  0xE58A82: 0x334E,
  0xE58A85: 0x334F,
  0xE58A8A: 0x3350,
  0xE58A8C: 0x3351,
  0xE58A93: 0x3352,
  0xE58A95: 0x3353,
  0xE58A96: 0x3354,
  0xE58A97: 0x3355,
  0xE58A98: 0x3356,
  0xE58A9A: 0x3357,
  0xE58A9C: 0x3358,
  0xE58AA4: 0x3359,
  0xE58AA5: 0x335A,
  0xE58AA6: 0x335B,
  0xE58AA7: 0x335C,
  0xE58AAF: 0x335D,
  0xE58AB0: 0x335E,
  0xE58AB6: 0x335F,
  0xE58AB7: 0x3360,
  0xE58AB8: 0x3361,
  0xE58ABA: 0x3362,
  0xE58ABB: 0x3363,
  0xE58ABD: 0x3364,
  0xE58B80: 0x3365,
  0xE58B84: 0x3366,
  0xE58B86: 0x3367,
  0xE58B88: 0x3368,
  0xE58B8C: 0x3369,
  0xE58B8F: 0x336A,
  0xE58B91: 0x336B,
  0xE58B94: 0x336C,
  0xE58B96: 0x336D,
  0xE58B9B: 0x336E,
  0xE58B9C: 0x336F,
  0xE58BA1: 0x3370,
  0xE58BA5: 0x3371,
  0xE58BA8: 0x3372,
  0xE58BA9: 0x3373,
  0xE58BAA: 0x3374,
  0xE58BAC: 0x3375,
  0xE58BB0: 0x3376,
  0xE58BB1: 0x3377,
  0xE58BB4: 0x3378,
  0xE58BB6: 0x3379,
  0xE58BB7: 0x337A,
  0xE58C80: 0x337B,
  0xE58C83: 0x337C,
  0xE58C8A: 0x337D,
  0xE58C8B: 0x337E,
  0xE58C8C: 0x3421,
  0xE58C91: 0x3422,
  0xE58C93: 0x3423,
  0xE58C98: 0x3424,
  0xE58C9B: 0x3425,
  0xE58C9C: 0x3426,
  0xE58C9E: 0x3427,
  0xE58C9F: 0x3428,
  0xE58CA5: 0x3429,
  0xE58CA7: 0x342A,
  0xE58CA8: 0x342B,
  0xE58CA9: 0x342C,
  0xE58CAB: 0x342D,
  0xE58CAC: 0x342E,
  0xE58CAD: 0x342F,
  0xE58CB0: 0x3430,
  0xE58CB2: 0x3431,
  0xE58CB5: 0x3432,
  0xE58CBC: 0x3433,
  0xE58CBD: 0x3434,
  0xE58CBE: 0x3435,
  0xE58D82: 0x3436,
  0xE58D8C: 0x3437,
  0xE58D8B: 0x3438,
  0xE58D99: 0x3439,
  0xE58D9B: 0x343A,
  0xE58DA1: 0x343B,
  0xE58DA3: 0x343C,
  0xE58DA5: 0x343D,
  0xE58DAC: 0x343E,
  0xE58DAD: 0x343F,
  0xE58DB2: 0x3440,
  0xE58DB9: 0x3441,
  0xE58DBE: 0x3442,
  0xE58E83: 0x3443,
  0xE58E87: 0x3444,
  0xE58E88: 0x3445,
  0xE58E8E: 0x3446,
  0xE58E93: 0x3447,
  0xE58E94: 0x3448,
  0xE58E99: 0x3449,
  0xE58E9D: 0x344A,
  0xE58EA1: 0x344B,
  0xE58EA4: 0x344C,
  0xE58EAA: 0x344D,
  0xE58EAB: 0x344E,
  0xE58EAF: 0x344F,
  0xE58EB2: 0x3450,
  0xE58EB4: 0x3451,
  0xE58EB5: 0x3452,
  0xE58EB7: 0x3453,
  0xE58EB8: 0x3454,
  0xE58EBA: 0x3455,
  0xE58EBD: 0x3456,
  0xE58F80: 0x3457,
  0xE58F85: 0x3458,
  0xE58F8F: 0x3459,
  0xE58F92: 0x345A,
  0xE58F93: 0x345B,
  0xE58F95: 0x345C,
  0xE58F9A: 0x345D,
  0xE58F9D: 0x345E,
  0xE58F9E: 0x345F,
  0xE58FA0: 0x3460,
  0xE58FA6: 0x3461,
  0xE58FA7: 0x3462,
  0xE58FB5: 0x3463,
  0xE59082: 0x3464,
  0xE59093: 0x3465,
  0xE5909A: 0x3466,
  0xE590A1: 0x3467,
  0xE590A7: 0x3468,
  0xE590A8: 0x3469,
  0xE590AA: 0x346A,
  0xE590AF: 0x346B,
  0xE590B1: 0x346C,
  0xE590B4: 0x346D,
  0xE590B5: 0x346E,
  0xE59183: 0x346F,
  0xE59184: 0x3470,
  0xE59187: 0x3471,
  0xE5918D: 0x3472,
  0xE5918F: 0x3473,
  0xE5919E: 0x3474,
  0xE591A2: 0x3475,
  0xE591A4: 0x3476,
  0xE591A6: 0x3477,
  0xE591A7: 0x3478,
  0xE591A9: 0x3479,
  0xE591AB: 0x347A,
  0xE591AD: 0x347B,
  0xE591AE: 0x347C,
  0xE591B4: 0x347D,
  0xE591BF: 0x347E,
  0xE59281: 0x3521,
  0xE59283: 0x3522,
  0xE59285: 0x3523,
  0xE59288: 0x3524,
  0xE59289: 0x3525,
  0xE5928D: 0x3526,
  0xE59291: 0x3527,
  0xE59295: 0x3528,
  0xE59296: 0x3529,
  0xE5929C: 0x352A,
  0xE5929F: 0x352B,
  0xE592A1: 0x352C,
  0xE592A6: 0x352D,
  0xE592A7: 0x352E,
  0xE592A9: 0x352F,
  0xE592AA: 0x3530,
  0xE592AD: 0x3531,
  0xE592AE: 0x3532,
  0xE592B1: 0x3533,
  0xE592B7: 0x3534,
  0xE592B9: 0x3535,
  0xE592BA: 0x3536,
  0xE592BB: 0x3537,
  0xE592BF: 0x3538,
  0xE59386: 0x3539,
  0xE5938A: 0x353A,
  0xE5938D: 0x353B,
  0xE5938E: 0x353C,
  0xE593A0: 0x353D,
  0xE593AA: 0x353E,
  0xE593AC: 0x353F,
  0xE593AF: 0x3540,
  0xE593B6: 0x3541,
  0xE593BC: 0x3542,
  0xE593BE: 0x3543,
  0xE593BF: 0x3544,
  0xE59480: 0x3545,
  0xE59481: 0x3546,
  0xE59485: 0x3547,
  0xE59488: 0x3548,
  0xE59489: 0x3549,
  0xE5948C: 0x354A,
  0xE5948D: 0x354B,
  0xE5948E: 0x354C,
  0xE59495: 0x354D,
  0xE594AA: 0x354E,
  0xE594AB: 0x354F,
  0xE594B2: 0x3550,
  0xE594B5: 0x3551,
  0xE594B6: 0x3552,
  0xE594BB: 0x3553,
  0xE594BC: 0x3554,
  0xE594BD: 0x3555,
  0xE59581: 0x3556,
  0xE59587: 0x3557,
  0xE59589: 0x3558,
  0xE5958A: 0x3559,
  0xE5958D: 0x355A,
  0xE59590: 0x355B,
  0xE59591: 0x355C,
  0xE59598: 0x355D,
  0xE5959A: 0x355E,
  0xE5959B: 0x355F,
  0xE5959E: 0x3560,
  0xE595A0: 0x3561,
  0xE595A1: 0x3562,
  0xE595A4: 0x3563,
  0xE595A6: 0x3564,
  0xE595BF: 0x3565,
  0xE59681: 0x3566,
  0xE59682: 0x3567,
  0xE59686: 0x3568,
  0xE59688: 0x3569,
  0xE5968E: 0x356A,
  0xE5968F: 0x356B,
  0xE59691: 0x356C,
  0xE59692: 0x356D,
  0xE59693: 0x356E,
  0xE59694: 0x356F,
  0xE59697: 0x3570,
  0xE596A3: 0x3571,
  0xE596A4: 0x3572,
  0xE596AD: 0x3573,
  0xE596B2: 0x3574,
  0xE596BF: 0x3575,
  0xE59781: 0x3576,
  0xE59783: 0x3577,
  0xE59786: 0x3578,
  0xE59789: 0x3579,
  0xE5978B: 0x357A,
  0xE5978C: 0x357B,
  0xE5978E: 0x357C,
  0xE59791: 0x357D,
  0xE59792: 0x357E,
  0xE59793: 0x3621,
  0xE59797: 0x3622,
  0xE59798: 0x3623,
  0xE5979B: 0x3624,
  0xE5979E: 0x3625,
  0xE597A2: 0x3626,
  0xE597A9: 0x3627,
  0xE597B6: 0x3628,
  0xE597BF: 0x3629,
  0xE59885: 0x362A,
  0xE59888: 0x362B,
  0xE5988A: 0x362C,
  0xE5988D: 0x362D,
  0xE5988E: 0x362E,
  0xE5988F: 0x362F,
  0xE59890: 0x3630,
  0xE59891: 0x3631,
  0xE59892: 0x3632,
  0xE59899: 0x3633,
  0xE598AC: 0x3634,
  0xE598B0: 0x3635,
  0xE598B3: 0x3636,
  0xE598B5: 0x3637,
  0xE598B7: 0x3638,
  0xE598B9: 0x3639,
  0xE598BB: 0x363A,
  0xE598BC: 0x363B,
  0xE598BD: 0x363C,
  0xE598BF: 0x363D,
  0xE59980: 0x363E,
  0xE59981: 0x363F,
  0xE59983: 0x3640,
  0xE59984: 0x3641,
  0xE59986: 0x3642,
  0xE59989: 0x3643,
  0xE5998B: 0x3644,
  0xE5998D: 0x3645,
  0xE5998F: 0x3646,
  0xE59994: 0x3647,
  0xE5999E: 0x3648,
  0xE599A0: 0x3649,
  0xE599A1: 0x364A,
  0xE599A2: 0x364B,
  0xE599A3: 0x364C,
  0xE599A6: 0x364D,
  0xE599A9: 0x364E,
  0xE599AD: 0x364F,
  0xE599AF: 0x3650,
  0xE599B1: 0x3651,
  0xE599B2: 0x3652,
  0xE599B5: 0x3653,
  0xE59A84: 0x3654,
  0xE59A85: 0x3655,
  0xE59A88: 0x3656,
  0xE59A8B: 0x3657,
  0xE59A8C: 0x3658,
  0xE59A95: 0x3659,
  0xE59A99: 0x365A,
  0xE59A9A: 0x365B,
  0xE59A9D: 0x365C,
  0xE59A9E: 0x365D,
  0xE59A9F: 0x365E,
  0xE59AA6: 0x365F,
  0xE59AA7: 0x3660,
  0xE59AA8: 0x3661,
  0xE59AA9: 0x3662,
  0xE59AAB: 0x3663,
  0xE59AAC: 0x3664,
  0xE59AAD: 0x3665,
  0xE59AB1: 0x3666,
  0xE59AB3: 0x3667,
  0xE59AB7: 0x3668,
  0xE59ABE: 0x3669,
  0xE59B85: 0x366A,
  0xE59B89: 0x366B,
  0xE59B8A: 0x366C,
  0xE59B8B: 0x366D,
  0xE59B8F: 0x366E,
  0xE59B90: 0x366F,
  0xE59B8C: 0x3670,
  0xE59B8D: 0x3671,
  0xE59B99: 0x3672,
  0xE59B9C: 0x3673,
  0xE59B9D: 0x3674,
  0xE59B9F: 0x3675,
  0xE59BA1: 0x3676,
  0xE59BA4: 0x3677,
  0xE59BA5: 0x3678,
  0xE59BA6: 0x3679,
  0xE59BA7: 0x367A,
  0xE59BA8: 0x367B,
  0xE59BB1: 0x367C,
  0xE59BAB: 0x367D,
  0xE59BAD: 0x367E,
  0xE59BB6: 0x3721,
  0xE59BB7: 0x3722,
  0xE59C81: 0x3723,
  0xE59C82: 0x3724,
  0xE59C87: 0x3725,
  0xE59C8A: 0x3726,
  0xE59C8C: 0x3727,
  0xE59C91: 0x3728,
  0xE59C95: 0x3729,
  0xE59C9A: 0x372A,
  0xE59C9B: 0x372B,
  0xE59C9D: 0x372C,
  0xE59CA0: 0x372D,
  0xE59CA2: 0x372E,
  0xE59CA3: 0x372F,
  0xE59CA4: 0x3730,
  0xE59CA5: 0x3731,
  0xE59CA9: 0x3732,
  0xE59CAA: 0x3733,
  0xE59CAC: 0x3734,
  0xE59CAE: 0x3735,
  0xE59CAF: 0x3736,
  0xE59CB3: 0x3737,
  0xE59CB4: 0x3738,
  0xE59CBD: 0x3739,
  0xE59CBE: 0x373A,
  0xE59CBF: 0x373B,
  0xE59D85: 0x373C,
  0xE59D86: 0x373D,
  0xE59D8C: 0x373E,
  0xE59D8D: 0x373F,
  0xE59D92: 0x3740,
  0xE59DA2: 0x3741,
  0xE59DA5: 0x3742,
  0xE59DA7: 0x3743,
  0xE59DA8: 0x3744,
  0xE59DAB: 0x3745,
  0xE59DAD: 0x3746,
  0xE59DAE: 0x3747,
  0xE59DAF: 0x3748,
  0xE59DB0: 0x3749,
  0xE59DB1: 0x374A,
  0xE59DB3: 0x374B,
  0xE59DB4: 0x374C,
  0xE59DB5: 0x374D,
  0xE59DB7: 0x374E,
  0xE59DB9: 0x374F,
  0xE59DBA: 0x3750,
  0xE59DBB: 0x3751,
  0xE59DBC: 0x3752,
  0xE59DBE: 0x3753,
  0xE59E81: 0x3754,
  0xE59E83: 0x3755,
  0xE59E8C: 0x3756,
  0xE59E94: 0x3757,
  0xE59E97: 0x3758,
  0xE59E99: 0x3759,
  0xE59E9A: 0x375A,
  0xE59E9C: 0x375B,
  0xE59E9D: 0x375C,
  0xE59E9E: 0x375D,
  0xE59E9F: 0x375E,
  0xE59EA1: 0x375F,
  0xE59E95: 0x3760,
  0xE59EA7: 0x3761,
  0xE59EA8: 0x3762,
  0xE59EA9: 0x3763,
  0xE59EAC: 0x3764,
  0xE59EB8: 0x3765,
  0xE59EBD: 0x3766,
  0xE59F87: 0x3767,
  0xE59F88: 0x3768,
  0xE59F8C: 0x3769,
  0xE59F8F: 0x376A,
  0xE59F95: 0x376B,
  0xE59F9D: 0x376C,
  0xE59F9E: 0x376D,
  0xE59FA4: 0x376E,
  0xE59FA6: 0x376F,
  0xE59FA7: 0x3770,
  0xE59FA9: 0x3771,
  0xE59FAD: 0x3772,
  0xE59FB0: 0x3773,
  0xE59FB5: 0x3774,
  0xE59FB6: 0x3775,
  0xE59FB8: 0x3776,
  0xE59FBD: 0x3777,
  0xE59FBE: 0x3778,
  0xE59FBF: 0x3779,
  0xE5A083: 0x377A,
  0xE5A084: 0x377B,
  0xE5A088: 0x377C,
  0xE5A089: 0x377D,
  0xE59FA1: 0x377E,
  0xE5A08C: 0x3821,
  0xE5A08D: 0x3822,
  0xE5A09B: 0x3823,
  0xE5A09E: 0x3824,
  0xE5A09F: 0x3825,
  0xE5A0A0: 0x3826,
  0xE5A0A6: 0x3827,
  0xE5A0A7: 0x3828,
  0xE5A0AD: 0x3829,
  0xE5A0B2: 0x382A,
  0xE5A0B9: 0x382B,
  0xE5A0BF: 0x382C,
  0xE5A189: 0x382D,
  0xE5A18C: 0x382E,
  0xE5A18D: 0x382F,
  0xE5A18F: 0x3830,
  0xE5A190: 0x3831,
  0xE5A195: 0x3832,
  0xE5A19F: 0x3833,
  0xE5A1A1: 0x3834,
  0xE5A1A4: 0x3835,
  0xE5A1A7: 0x3836,
  0xE5A1A8: 0x3837,
  0xE5A1B8: 0x3838,
  0xE5A1BC: 0x3839,
  0xE5A1BF: 0x383A,
  0xE5A280: 0x383B,
  0xE5A281: 0x383C,
  0xE5A287: 0x383D,
  0xE5A288: 0x383E,
  0xE5A289: 0x383F,
  0xE5A28A: 0x3840,
  0xE5A28C: 0x3841,
  0xE5A28D: 0x3842,
  0xE5A28F: 0x3843,
  0xE5A290: 0x3844,
  0xE5A294: 0x3845,
  0xE5A296: 0x3846,
  0xE5A29D: 0x3847,
  0xE5A2A0: 0x3848,
  0xE5A2A1: 0x3849,
  0xE5A2A2: 0x384A,
  0xE5A2A6: 0x384B,
  0xE5A2A9: 0x384C,
  0xE5A2B1: 0x384D,
  0xE5A2B2: 0x384E,
  0xE5A384: 0x384F,
  0xE5A2BC: 0x3850,
  0xE5A382: 0x3851,
  0xE5A388: 0x3852,
  0xE5A38D: 0x3853,
  0xE5A38E: 0x3854,
  0xE5A390: 0x3855,
  0xE5A392: 0x3856,
  0xE5A394: 0x3857,
  0xE5A396: 0x3858,
  0xE5A39A: 0x3859,
  0xE5A39D: 0x385A,
  0xE5A3A1: 0x385B,
  0xE5A3A2: 0x385C,
  0xE5A3A9: 0x385D,
  0xE5A3B3: 0x385E,
  0xE5A485: 0x385F,
  0xE5A486: 0x3860,
  0xE5A48B: 0x3861,
  0xE5A48C: 0x3862,
  0xE5A492: 0x3863,
  0xE5A493: 0x3864,
  0xE5A494: 0x3865,
  0xE89981: 0x3866,
  0xE5A49D: 0x3867,
  0xE5A4A1: 0x3868,
  0xE5A4A3: 0x3869,
  0xE5A4A4: 0x386A,
  0xE5A4A8: 0x386B,
  0xE5A4AF: 0x386C,
  0xE5A4B0: 0x386D,
  0xE5A4B3: 0x386E,
  0xE5A4B5: 0x386F,
  0xE5A4B6: 0x3870,
  0xE5A4BF: 0x3871,
  0xE5A583: 0x3872,
  0xE5A586: 0x3873,
  0xE5A592: 0x3874,
  0xE5A593: 0x3875,
  0xE5A599: 0x3876,
  0xE5A59B: 0x3877,
  0xE5A59D: 0x3878,
  0xE5A59E: 0x3879,
  0xE5A59F: 0x387A,
  0xE5A5A1: 0x387B,
  0xE5A5A3: 0x387C,
  0xE5A5AB: 0x387D,
  0xE5A5AD: 0x387E,
  0xE5A5AF: 0x3921,
  0xE5A5B2: 0x3922,
  0xE5A5B5: 0x3923,
  0xE5A5B6: 0x3924,
  0xE5A5B9: 0x3925,
  0xE5A5BB: 0x3926,
  0xE5A5BC: 0x3927,
  0xE5A68B: 0x3928,
  0xE5A68C: 0x3929,
  0xE5A68E: 0x392A,
  0xE5A692: 0x392B,
  0xE5A695: 0x392C,
  0xE5A697: 0x392D,
  0xE5A69F: 0x392E,
  0xE5A6A4: 0x392F,
  0xE5A6A7: 0x3930,
  0xE5A6AD: 0x3931,
  0xE5A6AE: 0x3932,
  0xE5A6AF: 0x3933,
  0xE5A6B0: 0x3934,
  0xE5A6B3: 0x3935,
  0xE5A6B7: 0x3936,
  0xE5A6BA: 0x3937,
  0xE5A6BC: 0x3938,
  0xE5A781: 0x3939,
  0xE5A783: 0x393A,
  0xE5A784: 0x393B,
  0xE5A788: 0x393C,
  0xE5A78A: 0x393D,
  0xE5A78D: 0x393E,
  0xE5A792: 0x393F,
  0xE5A79D: 0x3940,
  0xE5A79E: 0x3941,
  0xE5A79F: 0x3942,
  0xE5A7A3: 0x3943,
  0xE5A7A4: 0x3944,
  0xE5A7A7: 0x3945,
  0xE5A7AE: 0x3946,
  0xE5A7AF: 0x3947,
  0xE5A7B1: 0x3948,
  0xE5A7B2: 0x3949,
  0xE5A7B4: 0x394A,
  0xE5A7B7: 0x394B,
  0xE5A880: 0x394C,
  0xE5A884: 0x394D,
  0xE5A88C: 0x394E,
  0xE5A88D: 0x394F,
  0xE5A88E: 0x3950,
  0xE5A892: 0x3951,
  0xE5A893: 0x3952,
  0xE5A89E: 0x3953,
  0xE5A8A3: 0x3954,
  0xE5A8A4: 0x3955,
  0xE5A8A7: 0x3956,
  0xE5A8A8: 0x3957,
  0xE5A8AA: 0x3958,
  0xE5A8AD: 0x3959,
  0xE5A8B0: 0x395A,
  0xE5A984: 0x395B,
  0xE5A985: 0x395C,
  0xE5A987: 0x395D,
  0xE5A988: 0x395E,
  0xE5A98C: 0x395F,
  0xE5A990: 0x3960,
  0xE5A995: 0x3961,
  0xE5A99E: 0x3962,
  0xE5A9A3: 0x3963,
  0xE5A9A5: 0x3964,
  0xE5A9A7: 0x3965,
  0xE5A9AD: 0x3966,
  0xE5A9B7: 0x3967,
  0xE5A9BA: 0x3968,
  0xE5A9BB: 0x3969,
  0xE5A9BE: 0x396A,
  0xE5AA8B: 0x396B,
  0xE5AA90: 0x396C,
  0xE5AA93: 0x396D,
  0xE5AA96: 0x396E,
  0xE5AA99: 0x396F,
  0xE5AA9C: 0x3970,
  0xE5AA9E: 0x3971,
  0xE5AA9F: 0x3972,
  0xE5AAA0: 0x3973,
  0xE5AAA2: 0x3974,
  0xE5AAA7: 0x3975,
  0xE5AAAC: 0x3976,
  0xE5AAB1: 0x3977,
  0xE5AAB2: 0x3978,
  0xE5AAB3: 0x3979,
  0xE5AAB5: 0x397A,
  0xE5AAB8: 0x397B,
  0xE5AABA: 0x397C,
  0xE5AABB: 0x397D,
  0xE5AABF: 0x397E,
  0xE5AB84: 0x3A21,
  0xE5AB86: 0x3A22,
  0xE5AB88: 0x3A23,
  0xE5AB8F: 0x3A24,
  0xE5AB9A: 0x3A25,
  0xE5AB9C: 0x3A26,
  0xE5ABA0: 0x3A27,
  0xE5ABA5: 0x3A28,
  0xE5ABAA: 0x3A29,
  0xE5ABAE: 0x3A2A,
  0xE5ABB5: 0x3A2B,
  0xE5ABB6: 0x3A2C,
  0xE5ABBD: 0x3A2D,
  0xE5AC80: 0x3A2E,
  0xE5AC81: 0x3A2F,
  0xE5AC88: 0x3A30,
  0xE5AC97: 0x3A31,
  0xE5ACB4: 0x3A32,
  0xE5AC99: 0x3A33,
  0xE5AC9B: 0x3A34,
  0xE5AC9D: 0x3A35,
  0xE5ACA1: 0x3A36,
  0xE5ACA5: 0x3A37,
  0xE5ACAD: 0x3A38,
  0xE5ACB8: 0x3A39,
  0xE5AD81: 0x3A3A,
  0xE5AD8B: 0x3A3B,
  0xE5AD8C: 0x3A3C,
  0xE5AD92: 0x3A3D,
  0xE5AD96: 0x3A3E,
  0xE5AD9E: 0x3A3F,
  0xE5ADA8: 0x3A40,
  0xE5ADAE: 0x3A41,
  0xE5ADAF: 0x3A42,
  0xE5ADBC: 0x3A43,
  0xE5ADBD: 0x3A44,
  0xE5ADBE: 0x3A45,
  0xE5ADBF: 0x3A46,
  0xE5AE81: 0x3A47,
  0xE5AE84: 0x3A48,
  0xE5AE86: 0x3A49,
  0xE5AE8A: 0x3A4A,
  0xE5AE8E: 0x3A4B,
  0xE5AE90: 0x3A4C,
  0xE5AE91: 0x3A4D,
  0xE5AE93: 0x3A4E,
  0xE5AE94: 0x3A4F,
  0xE5AE96: 0x3A50,
  0xE5AEA8: 0x3A51,
  0xE5AEA9: 0x3A52,
  0xE5AEAC: 0x3A53,
  0xE5AEAD: 0x3A54,
  0xE5AEAF: 0x3A55,
  0xE5AEB1: 0x3A56,
  0xE5AEB2: 0x3A57,
  0xE5AEB7: 0x3A58,
  0xE5AEBA: 0x3A59,
  0xE5AEBC: 0x3A5A,
  0xE5AF80: 0x3A5B,
  0xE5AF81: 0x3A5C,
  0xE5AF8D: 0x3A5D,
  0xE5AF8F: 0x3A5E,
  0xE5AF96: 0x3A5F,
  0xE5AF97: 0x3A60,
  0xE5AF98: 0x3A61,
  0xE5AF99: 0x3A62,
  0xE5AF9A: 0x3A63,
  0xE5AFA0: 0x3A64,
  0xE5AFAF: 0x3A65,
  0xE5AFB1: 0x3A66,
  0xE5AFB4: 0x3A67,
  0xE5AFBD: 0x3A68,
  0xE5B08C: 0x3A69,
  0xE5B097: 0x3A6A,
  0xE5B09E: 0x3A6B,
  0xE5B09F: 0x3A6C,
  0xE5B0A3: 0x3A6D,
  0xE5B0A6: 0x3A6E,
  0xE5B0A9: 0x3A6F,
  0xE5B0AB: 0x3A70,
  0xE5B0AC: 0x3A71,
  0xE5B0AE: 0x3A72,
  0xE5B0B0: 0x3A73,
  0xE5B0B2: 0x3A74,
  0xE5B0B5: 0x3A75,
  0xE5B0B6: 0x3A76,
  0xE5B199: 0x3A77,
  0xE5B19A: 0x3A78,
  0xE5B19C: 0x3A79,
  0xE5B1A2: 0x3A7A,
  0xE5B1A3: 0x3A7B,
  0xE5B1A7: 0x3A7C,
  0xE5B1A8: 0x3A7D,
  0xE5B1A9: 0x3A7E,
  0xE5B1AD: 0x3B21,
  0xE5B1B0: 0x3B22,
  0xE5B1B4: 0x3B23,
  0xE5B1B5: 0x3B24,
  0xE5B1BA: 0x3B25,
  0xE5B1BB: 0x3B26,
  0xE5B1BC: 0x3B27,
  0xE5B1BD: 0x3B28,
  0xE5B287: 0x3B29,
  0xE5B288: 0x3B2A,
  0xE5B28A: 0x3B2B,
  0xE5B28F: 0x3B2C,
  0xE5B292: 0x3B2D,
  0xE5B29D: 0x3B2E,
  0xE5B29F: 0x3B2F,
  0xE5B2A0: 0x3B30,
  0xE5B2A2: 0x3B31,
  0xE5B2A3: 0x3B32,
  0xE5B2A6: 0x3B33,
  0xE5B2AA: 0x3B34,
  0xE5B2B2: 0x3B35,
  0xE5B2B4: 0x3B36,
  0xE5B2B5: 0x3B37,
  0xE5B2BA: 0x3B38,
  0xE5B389: 0x3B39,
  0xE5B38B: 0x3B3A,
  0xE5B392: 0x3B3B,
  0xE5B39D: 0x3B3C,
  0xE5B397: 0x3B3D,
  0xE5B3AE: 0x3B3E,
  0xE5B3B1: 0x3B3F,
  0xE5B3B2: 0x3B40,
  0xE5B3B4: 0x3B41,
  0xE5B481: 0x3B42,
  0xE5B486: 0x3B43,
  0xE5B48D: 0x3B44,
  0xE5B492: 0x3B45,
  0xE5B4AB: 0x3B46,
  0xE5B4A3: 0x3B47,
  0xE5B4A4: 0x3B48,
  0xE5B4A6: 0x3B49,
  0xE5B4A7: 0x3B4A,
  0xE5B4B1: 0x3B4B,
  0xE5B4B4: 0x3B4C,
  0xE5B4B9: 0x3B4D,
  0xE5B4BD: 0x3B4E,
  0xE5B4BF: 0x3B4F,
  0xE5B582: 0x3B50,
  0xE5B583: 0x3B51,
  0xE5B586: 0x3B52,
  0xE5B588: 0x3B53,
  0xE5B595: 0x3B54,
  0xE5B591: 0x3B55,
  0xE5B599: 0x3B56,
  0xE5B58A: 0x3B57,
  0xE5B59F: 0x3B58,
  0xE5B5A0: 0x3B59,
  0xE5B5A1: 0x3B5A,
  0xE5B5A2: 0x3B5B,
  0xE5B5A4: 0x3B5C,
  0xE5B5AA: 0x3B5D,
  0xE5B5AD: 0x3B5E,
  0xE5B5B0: 0x3B5F,
  0xE5B5B9: 0x3B60,
  0xE5B5BA: 0x3B61,
  0xE5B5BE: 0x3B62,
  0xE5B5BF: 0x3B63,
  0xE5B681: 0x3B64,
  0xE5B683: 0x3B65,
  0xE5B688: 0x3B66,
  0xE5B68A: 0x3B67,
  0xE5B692: 0x3B68,
  0xE5B693: 0x3B69,
  0xE5B694: 0x3B6A,
  0xE5B695: 0x3B6B,
  0xE5B699: 0x3B6C,
  0xE5B69B: 0x3B6D,
  0xE5B69F: 0x3B6E,
  0xE5B6A0: 0x3B6F,
  0xE5B6A7: 0x3B70,
  0xE5B6AB: 0x3B71,
  0xE5B6B0: 0x3B72,
  0xE5B6B4: 0x3B73,
  0xE5B6B8: 0x3B74,
  0xE5B6B9: 0x3B75,
  0xE5B783: 0x3B76,
  0xE5B787: 0x3B77,
  0xE5B78B: 0x3B78,
  0xE5B790: 0x3B79,
  0xE5B78E: 0x3B7A,
  0xE5B798: 0x3B7B,
  0xE5B799: 0x3B7C,
  0xE5B7A0: 0x3B7D,
  0xE5B7A4: 0x3B7E,
  0xE5B7A9: 0x3C21,
  0xE5B7B8: 0x3C22,
  0xE5B7B9: 0x3C23,
  0xE5B880: 0x3C24,
  0xE5B887: 0x3C25,
  0xE5B88D: 0x3C26,
  0xE5B892: 0x3C27,
  0xE5B894: 0x3C28,
  0xE5B895: 0x3C29,
  0xE5B898: 0x3C2A,
  0xE5B89F: 0x3C2B,
  0xE5B8A0: 0x3C2C,
  0xE5B8AE: 0x3C2D,
  0xE5B8A8: 0x3C2E,
  0xE5B8B2: 0x3C2F,
  0xE5B8B5: 0x3C30,
  0xE5B8BE: 0x3C31,
  0xE5B98B: 0x3C32,
  0xE5B990: 0x3C33,
  0xE5B989: 0x3C34,
  0xE5B991: 0x3C35,
  0xE5B996: 0x3C36,
  0xE5B998: 0x3C37,
  0xE5B99B: 0x3C38,
  0xE5B99C: 0x3C39,
  0xE5B99E: 0x3C3A,
  0xE5B9A8: 0x3C3B,
  0xE5B9AA: 0x3C3C,
  0xE5B9AB: 0x3C3D,
  0xE5B9AC: 0x3C3E,
  0xE5B9AD: 0x3C3F,
  0xE5B9AE: 0x3C40,
  0xE5B9B0: 0x3C41,
  0xE5BA80: 0x3C42,
  0xE5BA8B: 0x3C43,
  0xE5BA8E: 0x3C44,
  0xE5BAA2: 0x3C45,
  0xE5BAA4: 0x3C46,
  0xE5BAA5: 0x3C47,
  0xE5BAA8: 0x3C48,
  0xE5BAAA: 0x3C49,
  0xE5BAAC: 0x3C4A,
  0xE5BAB1: 0x3C4B,
  0xE5BAB3: 0x3C4C,
  0xE5BABD: 0x3C4D,
  0xE5BABE: 0x3C4E,
  0xE5BABF: 0x3C4F,
  0xE5BB86: 0x3C50,
  0xE5BB8C: 0x3C51,
  0xE5BB8B: 0x3C52,
  0xE5BB8E: 0x3C53,
  0xE5BB91: 0x3C54,
  0xE5BB92: 0x3C55,
  0xE5BB94: 0x3C56,
  0xE5BB95: 0x3C57,
  0xE5BB9C: 0x3C58,
  0xE5BB9E: 0x3C59,
  0xE5BBA5: 0x3C5A,
  0xE5BBAB: 0x3C5B,
  0xE5BC82: 0x3C5C,
  0xE5BC86: 0x3C5D,
  0xE5BC87: 0x3C5E,
  0xE5BC88: 0x3C5F,
  0xE5BC8E: 0x3C60,
  0xE5BC99: 0x3C61,
  0xE5BC9C: 0x3C62,
  0xE5BC9D: 0x3C63,
  0xE5BCA1: 0x3C64,
  0xE5BCA2: 0x3C65,
  0xE5BCA3: 0x3C66,
  0xE5BCA4: 0x3C67,
  0xE5BCA8: 0x3C68,
  0xE5BCAB: 0x3C69,
  0xE5BCAC: 0x3C6A,
  0xE5BCAE: 0x3C6B,
  0xE5BCB0: 0x3C6C,
  0xE5BCB4: 0x3C6D,
  0xE5BCB6: 0x3C6E,
  0xE5BCBB: 0x3C6F,
  0xE5BCBD: 0x3C70,
  0xE5BCBF: 0x3C71,
  0xE5BD80: 0x3C72,
  0xE5BD84: 0x3C73,
  0xE5BD85: 0x3C74,
  0xE5BD87: 0x3C75,
  0xE5BD8D: 0x3C76,
  0xE5BD90: 0x3C77,
  0xE5BD94: 0x3C78,
  0xE5BD98: 0x3C79,
  0xE5BD9B: 0x3C7A,
  0xE5BDA0: 0x3C7B,
  0xE5BDA3: 0x3C7C,
  0xE5BDA4: 0x3C7D,
  0xE5BDA7: 0x3C7E,
  0xE5BDAF: 0x3D21,
  0xE5BDB2: 0x3D22,
  0xE5BDB4: 0x3D23,
  0xE5BDB5: 0x3D24,
  0xE5BDB8: 0x3D25,
  0xE5BDBA: 0x3D26,
  0xE5BDBD: 0x3D27,
  0xE5BDBE: 0x3D28,
  0xE5BE89: 0x3D29,
  0xE5BE8D: 0x3D2A,
  0xE5BE8F: 0x3D2B,
  0xE5BE96: 0x3D2C,
  0xE5BE9C: 0x3D2D,
  0xE5BE9D: 0x3D2E,
  0xE5BEA2: 0x3D2F,
  0xE5BEA7: 0x3D30,
  0xE5BEAB: 0x3D31,
  0xE5BEA4: 0x3D32,
  0xE5BEAC: 0x3D33,
  0xE5BEAF: 0x3D34,
  0xE5BEB0: 0x3D35,
  0xE5BEB1: 0x3D36,
  0xE5BEB8: 0x3D37,
  0xE5BF84: 0x3D38,
  0xE5BF87: 0x3D39,
  0xE5BF88: 0x3D3A,
  0xE5BF89: 0x3D3B,
  0xE5BF8B: 0x3D3C,
  0xE5BF90: 0x3D3D,
  0xE5BF91: 0x3D3E,
  0xE5BF92: 0x3D3F,
  0xE5BF93: 0x3D40,
  0xE5BF94: 0x3D41,
  0xE5BF9E: 0x3D42,
  0xE5BFA1: 0x3D43,
  0xE5BFA2: 0x3D44,
  0xE5BFA8: 0x3D45,
  0xE5BFA9: 0x3D46,
  0xE5BFAA: 0x3D47,
  0xE5BFAC: 0x3D48,
  0xE5BFAD: 0x3D49,
  0xE5BFAE: 0x3D4A,
  0xE5BFAF: 0x3D4B,
  0xE5BFB2: 0x3D4C,
  0xE5BFB3: 0x3D4D,
  0xE5BFB6: 0x3D4E,
  0xE5BFBA: 0x3D4F,
  0xE5BFBC: 0x3D50,
  0xE68087: 0x3D51,
  0xE6808A: 0x3D52,
  0xE6808D: 0x3D53,
  0xE68093: 0x3D54,
  0xE68094: 0x3D55,
  0xE68097: 0x3D56,
  0xE68098: 0x3D57,
  0xE6809A: 0x3D58,
  0xE6809F: 0x3D59,
  0xE680A4: 0x3D5A,
  0xE680AD: 0x3D5B,
  0xE680B3: 0x3D5C,
  0xE680B5: 0x3D5D,
  0xE68180: 0x3D5E,
  0xE68187: 0x3D5F,
  0xE68188: 0x3D60,
  0xE68189: 0x3D61,
  0xE6818C: 0x3D62,
  0xE68191: 0x3D63,
  0xE68194: 0x3D64,
  0xE68196: 0x3D65,
  0xE68197: 0x3D66,
  0xE6819D: 0x3D67,
  0xE681A1: 0x3D68,
  0xE681A7: 0x3D69,
  0xE681B1: 0x3D6A,
  0xE681BE: 0x3D6B,
  0xE681BF: 0x3D6C,
  0xE68282: 0x3D6D,
  0xE68286: 0x3D6E,
  0xE68288: 0x3D6F,
  0xE6828A: 0x3D70,
  0xE6828E: 0x3D71,
  0xE68291: 0x3D72,
  0xE68293: 0x3D73,
  0xE68295: 0x3D74,
  0xE68298: 0x3D75,
  0xE6829D: 0x3D76,
  0xE6829E: 0x3D77,
  0xE682A2: 0x3D78,
  0xE682A4: 0x3D79,
  0xE682A5: 0x3D7A,
  0xE682A8: 0x3D7B,
  0xE682B0: 0x3D7C,
  0xE682B1: 0x3D7D,
  0xE682B7: 0x3D7E,
  0xE682BB: 0x3E21,
  0xE682BE: 0x3E22,
  0xE68382: 0x3E23,
  0xE68384: 0x3E24,
  0xE68388: 0x3E25,
  0xE68389: 0x3E26,
  0xE6838A: 0x3E27,
  0xE6838B: 0x3E28,
  0xE6838E: 0x3E29,
  0xE6838F: 0x3E2A,
  0xE68394: 0x3E2B,
  0xE68395: 0x3E2C,
  0xE68399: 0x3E2D,
  0xE6839B: 0x3E2E,
  0xE6839D: 0x3E2F,
  0xE6839E: 0x3E30,
  0xE683A2: 0x3E31,
  0xE683A5: 0x3E32,
  0xE683B2: 0x3E33,
  0xE683B5: 0x3E34,
  0xE683B8: 0x3E35,
  0xE683BC: 0x3E36,
  0xE683BD: 0x3E37,
  0xE68482: 0x3E38,
  0xE68487: 0x3E39,
  0xE6848A: 0x3E3A,
  0xE6848C: 0x3E3B,
  0xE68490: 0x3E3C,
  0xE68491: 0x3E3D,
  0xE68492: 0x3E3E,
  0xE68493: 0x3E3F,
  0xE68494: 0x3E40,
  0xE68496: 0x3E41,
  0xE68497: 0x3E42,
  0xE68499: 0x3E43,
  0xE6849C: 0x3E44,
  0xE6849E: 0x3E45,
  0xE684A2: 0x3E46,
  0xE684AA: 0x3E47,
  0xE684AB: 0x3E48,
  0xE684B0: 0x3E49,
  0xE684B1: 0x3E4A,
  0xE684B5: 0x3E4B,
  0xE684B6: 0x3E4C,
  0xE684B7: 0x3E4D,
  0xE684B9: 0x3E4E,
  0xE68581: 0x3E4F,
  0xE68585: 0x3E50,
  0xE68586: 0x3E51,
  0xE68589: 0x3E52,
  0xE6859E: 0x3E53,
  0xE685A0: 0x3E54,
  0xE685AC: 0x3E55,
  0xE685B2: 0x3E56,
  0xE685B8: 0x3E57,
  0xE685BB: 0x3E58,
  0xE685BC: 0x3E59,
  0xE685BF: 0x3E5A,
  0xE68680: 0x3E5B,
  0xE68681: 0x3E5C,
  0xE68683: 0x3E5D,
  0xE68684: 0x3E5E,
  0xE6868B: 0x3E5F,
  0xE6868D: 0x3E60,
  0xE68692: 0x3E61,
  0xE68693: 0x3E62,
  0xE68697: 0x3E63,
  0xE68698: 0x3E64,
  0xE6869C: 0x3E65,
  0xE6869D: 0x3E66,
  0xE6869F: 0x3E67,
  0xE686A0: 0x3E68,
  0xE686A5: 0x3E69,
  0xE686A8: 0x3E6A,
  0xE686AA: 0x3E6B,
  0xE686AD: 0x3E6C,
  0xE686B8: 0x3E6D,
  0xE686B9: 0x3E6E,
  0xE686BC: 0x3E6F,
  0xE68780: 0x3E70,
  0xE68781: 0x3E71,
  0xE68782: 0x3E72,
  0xE6878E: 0x3E73,
  0xE6878F: 0x3E74,
  0xE68795: 0x3E75,
  0xE6879C: 0x3E76,
  0xE6879D: 0x3E77,
  0xE6879E: 0x3E78,
  0xE6879F: 0x3E79,
  0xE687A1: 0x3E7A,
  0xE687A2: 0x3E7B,
  0xE687A7: 0x3E7C,
  0xE687A9: 0x3E7D,
  0xE687A5: 0x3E7E,
  0xE687AC: 0x3F21,
  0xE687AD: 0x3F22,
  0xE687AF: 0x3F23,
  0xE68881: 0x3F24,
  0xE68883: 0x3F25,
  0xE68884: 0x3F26,
  0xE68887: 0x3F27,
  0xE68893: 0x3F28,
  0xE68895: 0x3F29,
  0xE6889C: 0x3F2A,
  0xE688A0: 0x3F2B,
  0xE688A2: 0x3F2C,
  0xE688A3: 0x3F2D,
  0xE688A7: 0x3F2E,
  0xE688A9: 0x3F2F,
  0xE688AB: 0x3F30,
  0xE688B9: 0x3F31,
  0xE688BD: 0x3F32,
  0xE68982: 0x3F33,
  0xE68983: 0x3F34,
  0xE68984: 0x3F35,
  0xE68986: 0x3F36,
  0xE6898C: 0x3F37,
  0xE68990: 0x3F38,
  0xE68991: 0x3F39,
  0xE68992: 0x3F3A,
  0xE68994: 0x3F3B,
  0xE68996: 0x3F3C,
  0xE6899A: 0x3F3D,
  0xE6899C: 0x3F3E,
  0xE689A4: 0x3F3F,
  0xE689AD: 0x3F40,
  0xE689AF: 0x3F41,
  0xE689B3: 0x3F42,
  0xE689BA: 0x3F43,
  0xE689BD: 0x3F44,
  0xE68A8D: 0x3F45,
  0xE68A8E: 0x3F46,
  0xE68A8F: 0x3F47,
  0xE68A90: 0x3F48,
  0xE68AA6: 0x3F49,
  0xE68AA8: 0x3F4A,
  0xE68AB3: 0x3F4B,
  0xE68AB6: 0x3F4C,
  0xE68AB7: 0x3F4D,
  0xE68ABA: 0x3F4E,
  0xE68ABE: 0x3F4F,
  0xE68ABF: 0x3F50,
  0xE68B84: 0x3F51,
  0xE68B8E: 0x3F52,
  0xE68B95: 0x3F53,
  0xE68B96: 0x3F54,
  0xE68B9A: 0x3F55,
  0xE68BAA: 0x3F56,
  0xE68BB2: 0x3F57,
  0xE68BB4: 0x3F58,
  0xE68BBC: 0x3F59,
  0xE68BBD: 0x3F5A,
  0xE68C83: 0x3F5B,
  0xE68C84: 0x3F5C,
  0xE68C8A: 0x3F5D,
  0xE68C8B: 0x3F5E,
  0xE68C8D: 0x3F5F,
  0xE68C90: 0x3F60,
  0xE68C93: 0x3F61,
  0xE68C96: 0x3F62,
  0xE68C98: 0x3F63,
  0xE68CA9: 0x3F64,
  0xE68CAA: 0x3F65,
  0xE68CAD: 0x3F66,
  0xE68CB5: 0x3F67,
  0xE68CB6: 0x3F68,
  0xE68CB9: 0x3F69,
  0xE68CBC: 0x3F6A,
  0xE68D81: 0x3F6B,
  0xE68D82: 0x3F6C,
  0xE68D83: 0x3F6D,
  0xE68D84: 0x3F6E,
  0xE68D86: 0x3F6F,
  0xE68D8A: 0x3F70,
  0xE68D8B: 0x3F71,
  0xE68D8E: 0x3F72,
  0xE68D92: 0x3F73,
  0xE68D93: 0x3F74,
  0xE68D94: 0x3F75,
  0xE68D98: 0x3F76,
  0xE68D9B: 0x3F77,
  0xE68DA5: 0x3F78,
  0xE68DA6: 0x3F79,
  0xE68DAC: 0x3F7A,
  0xE68DAD: 0x3F7B,
  0xE68DB1: 0x3F7C,
  0xE68DB4: 0x3F7D,
  0xE68DB5: 0x3F7E,
  0xE68DB8: 0x4021,
  0xE68DBC: 0x4022,
  0xE68DBD: 0x4023,
  0xE68DBF: 0x4024,
  0xE68E82: 0x4025,
  0xE68E84: 0x4026,
  0xE68E87: 0x4027,
  0xE68E8A: 0x4028,
  0xE68E90: 0x4029,
  0xE68E94: 0x402A,
  0xE68E95: 0x402B,
  0xE68E99: 0x402C,
  0xE68E9A: 0x402D,
  0xE68E9E: 0x402E,
  0xE68EA4: 0x402F,
  0xE68EA6: 0x4030,
  0xE68EAD: 0x4031,
  0xE68EAE: 0x4032,
  0xE68EAF: 0x4033,
  0xE68EBD: 0x4034,
  0xE68F81: 0x4035,
  0xE68F85: 0x4036,
  0xE68F88: 0x4037,
  0xE68F8E: 0x4038,
  0xE68F91: 0x4039,
  0xE68F93: 0x403A,
  0xE68F94: 0x403B,
  0xE68F95: 0x403C,
  0xE68F9C: 0x403D,
  0xE68FA0: 0x403E,
  0xE68FA5: 0x403F,
  0xE68FAA: 0x4040,
  0xE68FAC: 0x4041,
  0xE68FB2: 0x4042,
  0xE68FB3: 0x4043,
  0xE68FB5: 0x4044,
  0xE68FB8: 0x4045,
  0xE68FB9: 0x4046,
  0xE69089: 0x4047,
  0xE6908A: 0x4048,
  0xE69090: 0x4049,
  0xE69092: 0x404A,
  0xE69094: 0x404B,
  0xE69098: 0x404C,
  0xE6909E: 0x404D,
  0xE690A0: 0x404E,
  0xE690A2: 0x404F,
  0xE690A4: 0x4050,
  0xE690A5: 0x4051,
  0xE690A9: 0x4052,
  0xE690AA: 0x4053,
  0xE690AF: 0x4054,
  0xE690B0: 0x4055,
  0xE690B5: 0x4056,
  0xE690BD: 0x4057,
  0xE690BF: 0x4058,
  0xE6918B: 0x4059,
  0xE6918F: 0x405A,
  0xE69191: 0x405B,
  0xE69192: 0x405C,
  0xE69193: 0x405D,
  0xE69194: 0x405E,
  0xE6919A: 0x405F,
  0xE6919B: 0x4060,
  0xE6919C: 0x4061,
  0xE6919D: 0x4062,
  0xE6919F: 0x4063,
  0xE691A0: 0x4064,
  0xE691A1: 0x4065,
  0xE691A3: 0x4066,
  0xE691AD: 0x4067,
  0xE691B3: 0x4068,
  0xE691B4: 0x4069,
  0xE691BB: 0x406A,
  0xE691BD: 0x406B,
  0xE69285: 0x406C,
  0xE69287: 0x406D,
  0xE6928F: 0x406E,
  0xE69290: 0x406F,
  0xE69291: 0x4070,
  0xE69298: 0x4071,
  0xE69299: 0x4072,
  0xE6929B: 0x4073,
  0xE6929D: 0x4074,
  0xE6929F: 0x4075,
  0xE692A1: 0x4076,
  0xE692A3: 0x4077,
  0xE692A6: 0x4078,
  0xE692A8: 0x4079,
  0xE692AC: 0x407A,
  0xE692B3: 0x407B,
  0xE692BD: 0x407C,
  0xE692BE: 0x407D,
  0xE692BF: 0x407E,
  0xE69384: 0x4121,
  0xE69389: 0x4122,
  0xE6938A: 0x4123,
  0xE6938B: 0x4124,
  0xE6938C: 0x4125,
  0xE6938E: 0x4126,
  0xE69390: 0x4127,
  0xE69391: 0x4128,
  0xE69395: 0x4129,
  0xE69397: 0x412A,
  0xE693A4: 0x412B,
  0xE693A5: 0x412C,
  0xE693A9: 0x412D,
  0xE693AA: 0x412E,
  0xE693AD: 0x412F,
  0xE693B0: 0x4130,
  0xE693B5: 0x4131,
  0xE693B7: 0x4132,
  0xE693BB: 0x4133,
  0xE693BF: 0x4134,
  0xE69481: 0x4135,
  0xE69484: 0x4136,
  0xE69488: 0x4137,
  0xE69489: 0x4138,
  0xE6948A: 0x4139,
  0xE6948F: 0x413A,
  0xE69493: 0x413B,
  0xE69494: 0x413C,
  0xE69496: 0x413D,
  0xE69499: 0x413E,
  0xE6949B: 0x413F,
  0xE6949E: 0x4140,
  0xE6949F: 0x4141,
  0xE694A2: 0x4142,
  0xE694A6: 0x4143,
  0xE694A9: 0x4144,
  0xE694AE: 0x4145,
  0xE694B1: 0x4146,
  0xE694BA: 0x4147,
  0xE694BC: 0x4148,
  0xE694BD: 0x4149,
  0xE69583: 0x414A,
  0xE69587: 0x414B,
  0xE69589: 0x414C,
  0xE69590: 0x414D,
  0xE69592: 0x414E,
  0xE69594: 0x414F,
  0xE6959F: 0x4150,
  0xE695A0: 0x4151,
  0xE695A7: 0x4152,
  0xE695AB: 0x4153,
  0xE695BA: 0x4154,
  0xE695BD: 0x4155,
  0xE69681: 0x4156,
  0xE69685: 0x4157,
  0xE6968A: 0x4158,
  0xE69692: 0x4159,
  0xE69695: 0x415A,
  0xE69698: 0x415B,
  0xE6969D: 0x415C,
  0xE696A0: 0x415D,
  0xE696A3: 0x415E,
  0xE696A6: 0x415F,
  0xE696AE: 0x4160,
  0xE696B2: 0x4161,
  0xE696B3: 0x4162,
  0xE696B4: 0x4163,
  0xE696BF: 0x4164,
  0xE69782: 0x4165,
  0xE69788: 0x4166,
  0xE69789: 0x4167,
  0xE6978E: 0x4168,
  0xE69790: 0x4169,
  0xE69794: 0x416A,
  0xE69796: 0x416B,
  0xE69798: 0x416C,
  0xE6979F: 0x416D,
  0xE697B0: 0x416E,
  0xE697B2: 0x416F,
  0xE697B4: 0x4170,
  0xE697B5: 0x4171,
  0xE697B9: 0x4172,
  0xE697BE: 0x4173,
  0xE697BF: 0x4174,
  0xE69880: 0x4175,
  0xE69884: 0x4176,
  0xE69888: 0x4177,
  0xE69889: 0x4178,
  0xE6988D: 0x4179,
  0xE69891: 0x417A,
  0xE69892: 0x417B,
  0xE69895: 0x417C,
  0xE69896: 0x417D,
  0xE6989D: 0x417E,
  0xE6989E: 0x4221,
  0xE698A1: 0x4222,
  0xE698A2: 0x4223,
  0xE698A3: 0x4224,
  0xE698A4: 0x4225,
  0xE698A6: 0x4226,
  0xE698A9: 0x4227,
  0xE698AA: 0x4228,
  0xE698AB: 0x4229,
  0xE698AC: 0x422A,
  0xE698AE: 0x422B,
  0xE698B0: 0x422C,
  0xE698B1: 0x422D,
  0xE698B3: 0x422E,
  0xE698B9: 0x422F,
  0xE698B7: 0x4230,
  0xE69980: 0x4231,
  0xE69985: 0x4232,
  0xE69986: 0x4233,
  0xE6998A: 0x4234,
  0xE6998C: 0x4235,
  0xE69991: 0x4236,
  0xE6998E: 0x4237,
  0xE69997: 0x4238,
  0xE69998: 0x4239,
  0xE69999: 0x423A,
  0xE6999B: 0x423B,
  0xE6999C: 0x423C,
  0xE699A0: 0x423D,
  0xE699A1: 0x423E,
  0xE69BBB: 0x423F,
  0xE699AA: 0x4240,
  0xE699AB: 0x4241,
  0xE699AC: 0x4242,
  0xE699BE: 0x4243,
  0xE699B3: 0x4244,
  0xE699B5: 0x4245,
  0xE699BF: 0x4246,
  0xE699B7: 0x4247,
  0xE699B8: 0x4248,
  0xE699B9: 0x4249,
  0xE699BB: 0x424A,
  0xE69A80: 0x424B,
  0xE699BC: 0x424C,
  0xE69A8B: 0x424D,
  0xE69A8C: 0x424E,
  0xE69A8D: 0x424F,
  0xE69A90: 0x4250,
  0xE69A92: 0x4251,
  0xE69A99: 0x4252,
  0xE69A9A: 0x4253,
  0xE69A9B: 0x4254,
  0xE69A9C: 0x4255,
  0xE69A9F: 0x4256,
  0xE69AA0: 0x4257,
  0xE69AA4: 0x4258,
  0xE69AAD: 0x4259,
  0xE69AB1: 0x425A,
  0xE69AB2: 0x425B,
  0xE69AB5: 0x425C,
  0xE69ABB: 0x425D,
  0xE69ABF: 0x425E,
  0xE69B80: 0x425F,
  0xE69B82: 0x4260,
  0xE69B83: 0x4261,
  0xE69B88: 0x4262,
  0xE69B8C: 0x4263,
  0xE69B8E: 0x4264,
  0xE69B8F: 0x4265,
  0xE69B94: 0x4266,
  0xE69B9B: 0x4267,
  0xE69B9F: 0x4268,
  0xE69BA8: 0x4269,
  0xE69BAB: 0x426A,
  0xE69BAC: 0x426B,
  0xE69BAE: 0x426C,
  0xE69BBA: 0x426D,
  0xE69C85: 0x426E,
  0xE69C87: 0x426F,
  0xE69C8E: 0x4270,
  0xE69C93: 0x4271,
  0xE69C99: 0x4272,
  0xE69C9C: 0x4273,
  0xE69CA0: 0x4274,
  0xE69CA2: 0x4275,
  0xE69CB3: 0x4276,
  0xE69CBE: 0x4277,
  0xE69D85: 0x4278,
  0xE69D87: 0x4279,
  0xE69D88: 0x427A,
  0xE69D8C: 0x427B,
  0xE69D94: 0x427C,
  0xE69D95: 0x427D,
  0xE69D9D: 0x427E,
  0xE69DA6: 0x4321,
  0xE69DAC: 0x4322,
  0xE69DAE: 0x4323,
  0xE69DB4: 0x4324,
  0xE69DB6: 0x4325,
  0xE69DBB: 0x4326,
  0xE69E81: 0x4327,
  0xE69E84: 0x4328,
  0xE69E8E: 0x4329,
  0xE69E8F: 0x432A,
  0xE69E91: 0x432B,
  0xE69E93: 0x432C,
  0xE69E96: 0x432D,
  0xE69E98: 0x432E,
  0xE69E99: 0x432F,
  0xE69E9B: 0x4330,
  0xE69EB0: 0x4331,
  0xE69EB1: 0x4332,
  0xE69EB2: 0x4333,
  0xE69EB5: 0x4334,
  0xE69EBB: 0x4335,
  0xE69EBC: 0x4336,
  0xE69EBD: 0x4337,
  0xE69FB9: 0x4338,
  0xE69F80: 0x4339,
  0xE69F82: 0x433A,
  0xE69F83: 0x433B,
  0xE69F85: 0x433C,
  0xE69F88: 0x433D,
  0xE69F89: 0x433E,
  0xE69F92: 0x433F,
  0xE69F97: 0x4340,
  0xE69F99: 0x4341,
  0xE69F9C: 0x4342,
  0xE69FA1: 0x4343,
  0xE69FA6: 0x4344,
  0xE69FB0: 0x4345,
  0xE69FB2: 0x4346,
  0xE69FB6: 0x4347,
  0xE69FB7: 0x4348,
  0xE6A192: 0x4349,
  0xE6A094: 0x434A,
  0xE6A099: 0x434B,
  0xE6A09D: 0x434C,
  0xE6A09F: 0x434D,
  0xE6A0A8: 0x434E,
  0xE6A0A7: 0x434F,
  0xE6A0AC: 0x4350,
  0xE6A0AD: 0x4351,
  0xE6A0AF: 0x4352,
  0xE6A0B0: 0x4353,
  0xE6A0B1: 0x4354,
  0xE6A0B3: 0x4355,
  0xE6A0BB: 0x4356,
  0xE6A0BF: 0x4357,
  0xE6A184: 0x4358,
  0xE6A185: 0x4359,
  0xE6A18A: 0x435A,
  0xE6A18C: 0x435B,
  0xE6A195: 0x435C,
  0xE6A197: 0x435D,
  0xE6A198: 0x435E,
  0xE6A19B: 0x435F,
  0xE6A1AB: 0x4360,
  0xE6A1AE: 0x4361,
  0xE6A1AF: 0x4362,
  0xE6A1B0: 0x4363,
  0xE6A1B1: 0x4364,
  0xE6A1B2: 0x4365,
  0xE6A1B5: 0x4366,
  0xE6A1B9: 0x4367,
  0xE6A1BA: 0x4368,
  0xE6A1BB: 0x4369,
  0xE6A1BC: 0x436A,
  0xE6A282: 0x436B,
  0xE6A284: 0x436C,
  0xE6A286: 0x436D,
  0xE6A288: 0x436E,
  0xE6A296: 0x436F,
  0xE6A298: 0x4370,
  0xE6A29A: 0x4371,
  0xE6A29C: 0x4372,
  0xE6A2A1: 0x4373,
  0xE6A2A3: 0x4374,
  0xE6A2A5: 0x4375,
  0xE6A2A9: 0x4376,
  0xE6A2AA: 0x4377,
  0xE6A2AE: 0x4378,
  0xE6A2B2: 0x4379,
  0xE6A2BB: 0x437A,
  0xE6A385: 0x437B,
  0xE6A388: 0x437C,
  0xE6A38C: 0x437D,
  0xE6A38F: 0x437E,
  0xE6A390: 0x4421,
  0xE6A391: 0x4422,
  0xE6A393: 0x4423,
  0xE6A396: 0x4424,
  0xE6A399: 0x4425,
  0xE6A39C: 0x4426,
  0xE6A39D: 0x4427,
  0xE6A3A5: 0x4428,
  0xE6A3A8: 0x4429,
  0xE6A3AA: 0x442A,
  0xE6A3AB: 0x442B,
  0xE6A3AC: 0x442C,
  0xE6A3AD: 0x442D,
  0xE6A3B0: 0x442E,
  0xE6A3B1: 0x442F,
  0xE6A3B5: 0x4430,
  0xE6A3B6: 0x4431,
  0xE6A3BB: 0x4432,
  0xE6A3BC: 0x4433,
  0xE6A3BD: 0x4434,
  0xE6A486: 0x4435,
  0xE6A489: 0x4436,
  0xE6A48A: 0x4437,
  0xE6A490: 0x4438,
  0xE6A491: 0x4439,
  0xE6A493: 0x443A,
  0xE6A496: 0x443B,
  0xE6A497: 0x443C,
  0xE6A4B1: 0x443D,
  0xE6A4B3: 0x443E,
  0xE6A4B5: 0x443F,
  0xE6A4B8: 0x4440,
  0xE6A4BB: 0x4441,
  0xE6A582: 0x4442,
  0xE6A585: 0x4443,
  0xE6A589: 0x4444,
  0xE6A58E: 0x4445,
  0xE6A597: 0x4446,
  0xE6A59B: 0x4447,
  0xE6A5A3: 0x4448,
  0xE6A5A4: 0x4449,
  0xE6A5A5: 0x444A,
  0xE6A5A6: 0x444B,
  0xE6A5A8: 0x444C,
  0xE6A5A9: 0x444D,
  0xE6A5AC: 0x444E,
  0xE6A5B0: 0x444F,
  0xE6A5B1: 0x4450,
  0xE6A5B2: 0x4451,
  0xE6A5BA: 0x4452,
  0xE6A5BB: 0x4453,
  0xE6A5BF: 0x4454,
  0xE6A680: 0x4455,
  0xE6A68D: 0x4456,
  0xE6A692: 0x4457,
  0xE6A696: 0x4458,
  0xE6A698: 0x4459,
  0xE6A6A1: 0x445A,
  0xE6A6A5: 0x445B,
  0xE6A6A6: 0x445C,
  0xE6A6A8: 0x445D,
  0xE6A6AB: 0x445E,
  0xE6A6AD: 0x445F,
  0xE6A6AF: 0x4460,
  0xE6A6B7: 0x4461,
  0xE6A6B8: 0x4462,
  0xE6A6BA: 0x4463,
  0xE6A6BC: 0x4464,
  0xE6A785: 0x4465,
  0xE6A788: 0x4466,
  0xE6A791: 0x4467,
  0xE6A796: 0x4468,
  0xE6A797: 0x4469,
  0xE6A7A2: 0x446A,
  0xE6A7A5: 0x446B,
  0xE6A7AE: 0x446C,
  0xE6A7AF: 0x446D,
  0xE6A7B1: 0x446E,
  0xE6A7B3: 0x446F,
  0xE6A7B5: 0x4470,
  0xE6A7BE: 0x4471,
  0xE6A880: 0x4472,
  0xE6A881: 0x4473,
  0xE6A883: 0x4474,
  0xE6A88F: 0x4475,
  0xE6A891: 0x4476,
  0xE6A895: 0x4477,
  0xE6A89A: 0x4478,
  0xE6A89D: 0x4479,
  0xE6A8A0: 0x447A,
  0xE6A8A4: 0x447B,
  0xE6A8A8: 0x447C,
  0xE6A8B0: 0x447D,
  0xE6A8B2: 0x447E,
  0xE6A8B4: 0x4521,
  0xE6A8B7: 0x4522,
  0xE6A8BB: 0x4523,
  0xE6A8BE: 0x4524,
  0xE6A8BF: 0x4525,
  0xE6A985: 0x4526,
  0xE6A986: 0x4527,
  0xE6A989: 0x4528,
  0xE6A98A: 0x4529,
  0xE6A98E: 0x452A,
  0xE6A990: 0x452B,
  0xE6A991: 0x452C,
  0xE6A992: 0x452D,
  0xE6A995: 0x452E,
  0xE6A996: 0x452F,
  0xE6A99B: 0x4530,
  0xE6A9A4: 0x4531,
  0xE6A9A7: 0x4532,
  0xE6A9AA: 0x4533,
  0xE6A9B1: 0x4534,
  0xE6A9B3: 0x4535,
  0xE6A9BE: 0x4536,
  0xE6AA81: 0x4537,
  0xE6AA83: 0x4538,
  0xE6AA86: 0x4539,
  0xE6AA87: 0x453A,
  0xE6AA89: 0x453B,
  0xE6AA8B: 0x453C,
  0xE6AA91: 0x453D,
  0xE6AA9B: 0x453E,
  0xE6AA9D: 0x453F,
  0xE6AA9E: 0x4540,
  0xE6AA9F: 0x4541,
  0xE6AAA5: 0x4542,
  0xE6AAAB: 0x4543,
  0xE6AAAF: 0x4544,
  0xE6AAB0: 0x4545,
  0xE6AAB1: 0x4546,
  0xE6AAB4: 0x4547,
  0xE6AABD: 0x4548,
  0xE6AABE: 0x4549,
  0xE6AABF: 0x454A,
  0xE6AB86: 0x454B,
  0xE6AB89: 0x454C,
  0xE6AB88: 0x454D,
  0xE6AB8C: 0x454E,
  0xE6AB90: 0x454F,
  0xE6AB94: 0x4550,
  0xE6AB95: 0x4551,
  0xE6AB96: 0x4552,
  0xE6AB9C: 0x4553,
  0xE6AB9D: 0x4554,
  0xE6ABA4: 0x4555,
  0xE6ABA7: 0x4556,
  0xE6ABAC: 0x4557,
  0xE6ABB0: 0x4558,
  0xE6ABB1: 0x4559,
  0xE6ABB2: 0x455A,
  0xE6ABBC: 0x455B,
  0xE6ABBD: 0x455C,
  0xE6AC82: 0x455D,
  0xE6AC83: 0x455E,
  0xE6AC86: 0x455F,
  0xE6AC87: 0x4560,
  0xE6AC89: 0x4561,
  0xE6AC8F: 0x4562,
  0xE6AC90: 0x4563,
  0xE6AC91: 0x4564,
  0xE6AC97: 0x4565,
  0xE6AC9B: 0x4566,
  0xE6AC9E: 0x4567,
  0xE6ACA4: 0x4568,
  0xE6ACA8: 0x4569,
  0xE6ACAB: 0x456A,
  0xE6ACAC: 0x456B,
  0xE6ACAF: 0x456C,
  0xE6ACB5: 0x456D,
  0xE6ACB6: 0x456E,
  0xE6ACBB: 0x456F,
  0xE6ACBF: 0x4570,
  0xE6AD86: 0x4571,
  0xE6AD8A: 0x4572,
  0xE6AD8D: 0x4573,
  0xE6AD92: 0x4574,
  0xE6AD96: 0x4575,
  0xE6AD98: 0x4576,
  0xE6AD9D: 0x4577,
  0xE6ADA0: 0x4578,
  0xE6ADA7: 0x4579,
  0xE6ADAB: 0x457A,
  0xE6ADAE: 0x457B,
  0xE6ADB0: 0x457C,
  0xE6ADB5: 0x457D,
  0xE6ADBD: 0x457E,
  0xE6ADBE: 0x4621,
  0xE6AE82: 0x4622,
  0xE6AE85: 0x4623,
  0xE6AE97: 0x4624,
  0xE6AE9B: 0x4625,
  0xE6AE9F: 0x4626,
  0xE6AEA0: 0x4627,
  0xE6AEA2: 0x4628,
  0xE6AEA3: 0x4629,
  0xE6AEA8: 0x462A,
  0xE6AEA9: 0x462B,
  0xE6AEAC: 0x462C,
  0xE6AEAD: 0x462D,
  0xE6AEAE: 0x462E,
  0xE6AEB0: 0x462F,
  0xE6AEB8: 0x4630,
  0xE6AEB9: 0x4631,
  0xE6AEBD: 0x4632,
  0xE6AEBE: 0x4633,
  0xE6AF83: 0x4634,
  0xE6AF84: 0x4635,
  0xE6AF89: 0x4636,
  0xE6AF8C: 0x4637,
  0xE6AF96: 0x4638,
  0xE6AF9A: 0x4639,
  0xE6AFA1: 0x463A,
  0xE6AFA3: 0x463B,
  0xE6AFA6: 0x463C,
  0xE6AFA7: 0x463D,
  0xE6AFAE: 0x463E,
  0xE6AFB1: 0x463F,
  0xE6AFB7: 0x4640,
  0xE6AFB9: 0x4641,
  0xE6AFBF: 0x4642,
  0xE6B082: 0x4643,
  0xE6B084: 0x4644,
  0xE6B085: 0x4645,
  0xE6B089: 0x4646,
  0xE6B08D: 0x4647,
  0xE6B08E: 0x4648,
  0xE6B090: 0x4649,
  0xE6B092: 0x464A,
  0xE6B099: 0x464B,
  0xE6B09F: 0x464C,
  0xE6B0A6: 0x464D,
  0xE6B0A7: 0x464E,
  0xE6B0A8: 0x464F,
  0xE6B0AC: 0x4650,
  0xE6B0AE: 0x4651,
  0xE6B0B3: 0x4652,
  0xE6B0B5: 0x4653,
  0xE6B0B6: 0x4654,
  0xE6B0BA: 0x4655,
  0xE6B0BB: 0x4656,
  0xE6B0BF: 0x4657,
  0xE6B18A: 0x4658,
  0xE6B18B: 0x4659,
  0xE6B18D: 0x465A,
  0xE6B18F: 0x465B,
  0xE6B192: 0x465C,
  0xE6B194: 0x465D,
  0xE6B199: 0x465E,
  0xE6B19B: 0x465F,
  0xE6B19C: 0x4660,
  0xE6B1AB: 0x4661,
  0xE6B1AD: 0x4662,
  0xE6B1AF: 0x4663,
  0xE6B1B4: 0x4664,
  0xE6B1B6: 0x4665,
  0xE6B1B8: 0x4666,
  0xE6B1B9: 0x4667,
  0xE6B1BB: 0x4668,
  0xE6B285: 0x4669,
  0xE6B286: 0x466A,
  0xE6B287: 0x466B,
  0xE6B289: 0x466C,
  0xE6B294: 0x466D,
  0xE6B295: 0x466E,
  0xE6B297: 0x466F,
  0xE6B298: 0x4670,
  0xE6B29C: 0x4671,
  0xE6B29F: 0x4672,
  0xE6B2B0: 0x4673,
  0xE6B2B2: 0x4674,
  0xE6B2B4: 0x4675,
  0xE6B382: 0x4676,
  0xE6B386: 0x4677,
  0xE6B38D: 0x4678,
  0xE6B38F: 0x4679,
  0xE6B390: 0x467A,
  0xE6B391: 0x467B,
  0xE6B392: 0x467C,
  0xE6B394: 0x467D,
  0xE6B396: 0x467E,
  0xE6B39A: 0x4721,
  0xE6B39C: 0x4722,
  0xE6B3A0: 0x4723,
  0xE6B3A7: 0x4724,
  0xE6B3A9: 0x4725,
  0xE6B3AB: 0x4726,
  0xE6B3AC: 0x4727,
  0xE6B3AE: 0x4728,
  0xE6B3B2: 0x4729,
  0xE6B3B4: 0x472A,
  0xE6B484: 0x472B,
  0xE6B487: 0x472C,
  0xE6B48A: 0x472D,
  0xE6B48E: 0x472E,
  0xE6B48F: 0x472F,
  0xE6B491: 0x4730,
  0xE6B493: 0x4731,
  0xE6B49A: 0x4732,
  0xE6B4A6: 0x4733,
  0xE6B4A7: 0x4734,
  0xE6B4A8: 0x4735,
  0xE6B1A7: 0x4736,
  0xE6B4AE: 0x4737,
  0xE6B4AF: 0x4738,
  0xE6B4B1: 0x4739,
  0xE6B4B9: 0x473A,
  0xE6B4BC: 0x473B,
  0xE6B4BF: 0x473C,
  0xE6B597: 0x473D,
  0xE6B59E: 0x473E,
  0xE6B59F: 0x473F,
  0xE6B5A1: 0x4740,
  0xE6B5A5: 0x4741,
  0xE6B5A7: 0x4742,
  0xE6B5AF: 0x4743,
  0xE6B5B0: 0x4744,
  0xE6B5BC: 0x4745,
  0xE6B682: 0x4746,
  0xE6B687: 0x4747,
  0xE6B691: 0x4748,
  0xE6B692: 0x4749,
  0xE6B694: 0x474A,
  0xE6B696: 0x474B,
  0xE6B697: 0x474C,
  0xE6B698: 0x474D,
  0xE6B6AA: 0x474E,
  0xE6B6AC: 0x474F,
  0xE6B6B4: 0x4750,
  0xE6B6B7: 0x4751,
  0xE6B6B9: 0x4752,
  0xE6B6BD: 0x4753,
  0xE6B6BF: 0x4754,
  0xE6B784: 0x4755,
  0xE6B788: 0x4756,
  0xE6B78A: 0x4757,
  0xE6B78E: 0x4758,
  0xE6B78F: 0x4759,
  0xE6B796: 0x475A,
  0xE6B79B: 0x475B,
  0xE6B79D: 0x475C,
  0xE6B79F: 0x475D,
  0xE6B7A0: 0x475E,
  0xE6B7A2: 0x475F,
  0xE6B7A5: 0x4760,
  0xE6B7A9: 0x4761,
  0xE6B7AF: 0x4762,
  0xE6B7B0: 0x4763,
  0xE6B7B4: 0x4764,
  0xE6B7B6: 0x4765,
  0xE6B7BC: 0x4766,
  0xE6B880: 0x4767,
  0xE6B884: 0x4768,
  0xE6B89E: 0x4769,
  0xE6B8A2: 0x476A,
  0xE6B8A7: 0x476B,
  0xE6B8B2: 0x476C,
  0xE6B8B6: 0x476D,
  0xE6B8B9: 0x476E,
  0xE6B8BB: 0x476F,
  0xE6B8BC: 0x4770,
  0xE6B984: 0x4771,
  0xE6B985: 0x4772,
  0xE6B988: 0x4773,
  0xE6B989: 0x4774,
  0xE6B98B: 0x4775,
  0xE6B98F: 0x4776,
  0xE6B991: 0x4777,
  0xE6B992: 0x4778,
  0xE6B993: 0x4779,
  0xE6B994: 0x477A,
  0xE6B997: 0x477B,
  0xE6B99C: 0x477C,
  0xE6B99D: 0x477D,
  0xE6B99E: 0x477E,
  0xE6B9A2: 0x4821,
  0xE6B9A3: 0x4822,
  0xE6B9A8: 0x4823,
  0xE6B9B3: 0x4824,
  0xE6B9BB: 0x4825,
  0xE6B9BD: 0x4826,
  0xE6BA8D: 0x4827,
  0xE6BA93: 0x4828,
  0xE6BA99: 0x4829,
  0xE6BAA0: 0x482A,
  0xE6BAA7: 0x482B,
  0xE6BAAD: 0x482C,
  0xE6BAAE: 0x482D,
  0xE6BAB1: 0x482E,
  0xE6BAB3: 0x482F,
  0xE6BABB: 0x4830,
  0xE6BABF: 0x4831,
  0xE6BB80: 0x4832,
  0xE6BB81: 0x4833,
  0xE6BB83: 0x4834,
  0xE6BB87: 0x4835,
  0xE6BB88: 0x4836,
  0xE6BB8A: 0x4837,
  0xE6BB8D: 0x4838,
  0xE6BB8E: 0x4839,
  0xE6BB8F: 0x483A,
  0xE6BBAB: 0x483B,
  0xE6BBAD: 0x483C,
  0xE6BBAE: 0x483D,
  0xE6BBB9: 0x483E,
  0xE6BBBB: 0x483F,
  0xE6BBBD: 0x4840,
  0xE6BC84: 0x4841,
  0xE6BC88: 0x4842,
  0xE6BC8A: 0x4843,
  0xE6BC8C: 0x4844,
  0xE6BC8D: 0x4845,
  0xE6BC96: 0x4846,
  0xE6BC98: 0x4847,
  0xE6BC9A: 0x4848,
  0xE6BC9B: 0x4849,
  0xE6BCA6: 0x484A,
  0xE6BCA9: 0x484B,
  0xE6BCAA: 0x484C,
  0xE6BCAF: 0x484D,
  0xE6BCB0: 0x484E,
  0xE6BCB3: 0x484F,
  0xE6BCB6: 0x4850,
  0xE6BCBB: 0x4851,
  0xE6BCBC: 0x4852,
  0xE6BCAD: 0x4853,
  0xE6BD8F: 0x4854,
  0xE6BD91: 0x4855,
  0xE6BD92: 0x4856,
  0xE6BD93: 0x4857,
  0xE6BD97: 0x4858,
  0xE6BD99: 0x4859,
  0xE6BD9A: 0x485A,
  0xE6BD9D: 0x485B,
  0xE6BD9E: 0x485C,
  0xE6BDA1: 0x485D,
  0xE6BDA2: 0x485E,
  0xE6BDA8: 0x485F,
  0xE6BDAC: 0x4860,
  0xE6BDBD: 0x4861,
  0xE6BDBE: 0x4862,
  0xE6BE83: 0x4863,
  0xE6BE87: 0x4864,
  0xE6BE88: 0x4865,
  0xE6BE8B: 0x4866,
  0xE6BE8C: 0x4867,
  0xE6BE8D: 0x4868,
  0xE6BE90: 0x4869,
  0xE6BE92: 0x486A,
  0xE6BE93: 0x486B,
  0xE6BE94: 0x486C,
  0xE6BE96: 0x486D,
  0xE6BE9A: 0x486E,
  0xE6BE9F: 0x486F,
  0xE6BEA0: 0x4870,
  0xE6BEA5: 0x4871,
  0xE6BEA6: 0x4872,
  0xE6BEA7: 0x4873,
  0xE6BEA8: 0x4874,
  0xE6BEAE: 0x4875,
  0xE6BEAF: 0x4876,
  0xE6BEB0: 0x4877,
  0xE6BEB5: 0x4878,
  0xE6BEB6: 0x4879,
  0xE6BEBC: 0x487A,
  0xE6BF85: 0x487B,
  0xE6BF87: 0x487C,
  0xE6BF88: 0x487D,
  0xE6BF8A: 0x487E,
  0xE6BF9A: 0x4921,
  0xE6BF9E: 0x4922,
  0xE6BFA8: 0x4923,
  0xE6BFA9: 0x4924,
  0xE6BFB0: 0x4925,
  0xE6BFB5: 0x4926,
  0xE6BFB9: 0x4927,
  0xE6BFBC: 0x4928,
  0xE6BFBD: 0x4929,
  0xE78080: 0x492A,
  0xE78085: 0x492B,
  0xE78086: 0x492C,
  0xE78087: 0x492D,
  0xE7808D: 0x492E,
  0xE78097: 0x492F,
  0xE780A0: 0x4930,
  0xE780A3: 0x4931,
  0xE780AF: 0x4932,
  0xE780B4: 0x4933,
  0xE780B7: 0x4934,
  0xE780B9: 0x4935,
  0xE780BC: 0x4936,
  0xE78183: 0x4937,
  0xE78184: 0x4938,
  0xE78188: 0x4939,
  0xE78189: 0x493A,
  0xE7818A: 0x493B,
  0xE7818B: 0x493C,
  0xE78194: 0x493D,
  0xE78195: 0x493E,
  0xE7819D: 0x493F,
  0xE7819E: 0x4940,
  0xE7818E: 0x4941,
  0xE781A4: 0x4942,
  0xE781A5: 0x4943,
  0xE781AC: 0x4944,
  0xE781AE: 0x4945,
  0xE781B5: 0x4946,
  0xE781B6: 0x4947,
  0xE781BE: 0x4948,
  0xE78281: 0x4949,
  0xE78285: 0x494A,
  0xE78286: 0x494B,
  0xE78294: 0x494C,
  0xE78295: 0x494D,
  0xE78296: 0x494E,
  0xE78297: 0x494F,
  0xE78298: 0x4950,
  0xE7829B: 0x4951,
  0xE782A4: 0x4952,
  0xE782AB: 0x4953,
  0xE782B0: 0x4954,
  0xE782B1: 0x4955,
  0xE782B4: 0x4956,
  0xE782B7: 0x4957,
  0xE7838A: 0x4958,
  0xE78391: 0x4959,
  0xE78393: 0x495A,
  0xE78394: 0x495B,
  0xE78395: 0x495C,
  0xE78396: 0x495D,
  0xE78398: 0x495E,
  0xE7839C: 0x495F,
  0xE783A4: 0x4960,
  0xE783BA: 0x4961,
  0xE78483: 0x4962,
  0xE78484: 0x4963,
  0xE78485: 0x4964,
  0xE78486: 0x4965,
  0xE78487: 0x4966,
  0xE7848B: 0x4967,
  0xE7848C: 0x4968,
  0xE7848F: 0x4969,
  0xE7849E: 0x496A,
  0xE784A0: 0x496B,
  0xE784AB: 0x496C,
  0xE784AD: 0x496D,
  0xE784AF: 0x496E,
  0xE784B0: 0x496F,
  0xE784B1: 0x4970,
  0xE784B8: 0x4971,
  0xE78581: 0x4972,
  0xE78585: 0x4973,
  0xE78586: 0x4974,
  0xE78587: 0x4975,
  0xE7858A: 0x4976,
  0xE7858B: 0x4977,
  0xE78590: 0x4978,
  0xE78592: 0x4979,
  0xE78597: 0x497A,
  0xE7859A: 0x497B,
  0xE7859C: 0x497C,
  0xE7859E: 0x497D,
  0xE785A0: 0x497E,
  0xE785A8: 0x4A21,
  0xE785B9: 0x4A22,
  0xE78680: 0x4A23,
  0xE78685: 0x4A24,
  0xE78687: 0x4A25,
  0xE7868C: 0x4A26,
  0xE78692: 0x4A27,
  0xE7869A: 0x4A28,
  0xE7869B: 0x4A29,
  0xE786A0: 0x4A2A,
  0xE786A2: 0x4A2B,
  0xE786AF: 0x4A2C,
  0xE786B0: 0x4A2D,
  0xE786B2: 0x4A2E,
  0xE786B3: 0x4A2F,
  0xE786BA: 0x4A30,
  0xE786BF: 0x4A31,
  0xE78780: 0x4A32,
  0xE78781: 0x4A33,
  0xE78784: 0x4A34,
  0xE7878B: 0x4A35,
  0xE7878C: 0x4A36,
  0xE78793: 0x4A37,
  0xE78796: 0x4A38,
  0xE78799: 0x4A39,
  0xE7879A: 0x4A3A,
  0xE7879C: 0x4A3B,
  0xE787B8: 0x4A3C,
  0xE787BE: 0x4A3D,
  0xE78880: 0x4A3E,
  0xE78887: 0x4A3F,
  0xE78888: 0x4A40,
  0xE78889: 0x4A41,
  0xE78893: 0x4A42,
  0xE78897: 0x4A43,
  0xE7889A: 0x4A44,
  0xE7889D: 0x4A45,
  0xE7889F: 0x4A46,
  0xE788A4: 0x4A47,
  0xE788AB: 0x4A48,
  0xE788AF: 0x4A49,
  0xE788B4: 0x4A4A,
  0xE788B8: 0x4A4B,
  0xE788B9: 0x4A4C,
  0xE78981: 0x4A4D,
  0xE78982: 0x4A4E,
  0xE78983: 0x4A4F,
  0xE78985: 0x4A50,
  0xE7898E: 0x4A51,
  0xE7898F: 0x4A52,
  0xE78990: 0x4A53,
  0xE78993: 0x4A54,
  0xE78995: 0x4A55,
  0xE78996: 0x4A56,
  0xE7899A: 0x4A57,
  0xE7899C: 0x4A58,
  0xE7899E: 0x4A59,
  0xE789A0: 0x4A5A,
  0xE789A3: 0x4A5B,
  0xE789A8: 0x4A5C,
  0xE789AB: 0x4A5D,
  0xE789AE: 0x4A5E,
  0xE789AF: 0x4A5F,
  0xE789B1: 0x4A60,
  0xE789B7: 0x4A61,
  0xE789B8: 0x4A62,
  0xE789BB: 0x4A63,
  0xE789BC: 0x4A64,
  0xE789BF: 0x4A65,
  0xE78A84: 0x4A66,
  0xE78A89: 0x4A67,
  0xE78A8D: 0x4A68,
  0xE78A8E: 0x4A69,
  0xE78A93: 0x4A6A,
  0xE78A9B: 0x4A6B,
  0xE78AA8: 0x4A6C,
  0xE78AAD: 0x4A6D,
  0xE78AAE: 0x4A6E,
  0xE78AB1: 0x4A6F,
  0xE78AB4: 0x4A70,
  0xE78ABE: 0x4A71,
  0xE78B81: 0x4A72,
  0xE78B87: 0x4A73,
  0xE78B89: 0x4A74,
  0xE78B8C: 0x4A75,
  0xE78B95: 0x4A76,
  0xE78B96: 0x4A77,
  0xE78B98: 0x4A78,
  0xE78B9F: 0x4A79,
  0xE78BA5: 0x4A7A,
  0xE78BB3: 0x4A7B,
  0xE78BB4: 0x4A7C,
  0xE78BBA: 0x4A7D,
  0xE78BBB: 0x4A7E,
  0xE78BBE: 0x4B21,
  0xE78C82: 0x4B22,
  0xE78C84: 0x4B23,
  0xE78C85: 0x4B24,
  0xE78C87: 0x4B25,
  0xE78C8B: 0x4B26,
  0xE78C8D: 0x4B27,
  0xE78C92: 0x4B28,
  0xE78C93: 0x4B29,
  0xE78C98: 0x4B2A,
  0xE78C99: 0x4B2B,
  0xE78C9E: 0x4B2C,
  0xE78CA2: 0x4B2D,
  0xE78CA4: 0x4B2E,
  0xE78CA7: 0x4B2F,
  0xE78CA8: 0x4B30,
  0xE78CAC: 0x4B31,
  0xE78CB1: 0x4B32,
  0xE78CB2: 0x4B33,
  0xE78CB5: 0x4B34,
  0xE78CBA: 0x4B35,
  0xE78CBB: 0x4B36,
  0xE78CBD: 0x4B37,
  0xE78D83: 0x4B38,
  0xE78D8D: 0x4B39,
  0xE78D90: 0x4B3A,
  0xE78D92: 0x4B3B,
  0xE78D96: 0x4B3C,
  0xE78D98: 0x4B3D,
  0xE78D9D: 0x4B3E,
  0xE78D9E: 0x4B3F,
  0xE78D9F: 0x4B40,
  0xE78DA0: 0x4B41,
  0xE78DA6: 0x4B42,
  0xE78DA7: 0x4B43,
  0xE78DA9: 0x4B44,
  0xE78DAB: 0x4B45,
  0xE78DAC: 0x4B46,
  0xE78DAE: 0x4B47,
  0xE78DAF: 0x4B48,
  0xE78DB1: 0x4B49,
  0xE78DB7: 0x4B4A,
  0xE78DB9: 0x4B4B,
  0xE78DBC: 0x4B4C,
  0xE78E80: 0x4B4D,
  0xE78E81: 0x4B4E,
  0xE78E83: 0x4B4F,
  0xE78E85: 0x4B50,
  0xE78E86: 0x4B51,
  0xE78E8E: 0x4B52,
  0xE78E90: 0x4B53,
  0xE78E93: 0x4B54,
  0xE78E95: 0x4B55,
  0xE78E97: 0x4B56,
  0xE78E98: 0x4B57,
  0xE78E9C: 0x4B58,
  0xE78E9E: 0x4B59,
  0xE78E9F: 0x4B5A,
  0xE78EA0: 0x4B5B,
  0xE78EA2: 0x4B5C,
  0xE78EA5: 0x4B5D,
  0xE78EA6: 0x4B5E,
  0xE78EAA: 0x4B5F,
  0xE78EAB: 0x4B60,
  0xE78EAD: 0x4B61,
  0xE78EB5: 0x4B62,
  0xE78EB7: 0x4B63,
  0xE78EB9: 0x4B64,
  0xE78EBC: 0x4B65,
  0xE78EBD: 0x4B66,
  0xE78EBF: 0x4B67,
  0xE78F85: 0x4B68,
  0xE78F86: 0x4B69,
  0xE78F89: 0x4B6A,
  0xE78F8B: 0x4B6B,
  0xE78F8C: 0x4B6C,
  0xE78F8F: 0x4B6D,
  0xE78F92: 0x4B6E,
  0xE78F93: 0x4B6F,
  0xE78F96: 0x4B70,
  0xE78F99: 0x4B71,
  0xE78F9D: 0x4B72,
  0xE78FA1: 0x4B73,
  0xE78FA3: 0x4B74,
  0xE78FA6: 0x4B75,
  0xE78FA7: 0x4B76,
  0xE78FA9: 0x4B77,
  0xE78FB4: 0x4B78,
  0xE78FB5: 0x4B79,
  0xE78FB7: 0x4B7A,
  0xE78FB9: 0x4B7B,
  0xE78FBA: 0x4B7C,
  0xE78FBB: 0x4B7D,
  0xE78FBD: 0x4B7E,
  0xE78FBF: 0x4C21,
  0xE79080: 0x4C22,
  0xE79081: 0x4C23,
  0xE79084: 0x4C24,
  0xE79087: 0x4C25,
  0xE7908A: 0x4C26,
  0xE79091: 0x4C27,
  0xE7909A: 0x4C28,
  0xE7909B: 0x4C29,
  0xE790A4: 0x4C2A,
  0xE790A6: 0x4C2B,
  0xE790A8: 0x4C2C,
  0xE790A9: 0x4C2D,
  0xE790AA: 0x4C2E,
  0xE790AB: 0x4C2F,
  0xE790AC: 0x4C30,
  0xE790AD: 0x4C31,
  0xE790AE: 0x4C32,
  0xE790AF: 0x4C33,
  0xE790B0: 0x4C34,
  0xE790B1: 0x4C35,
  0xE790B9: 0x4C36,
  0xE79180: 0x4C37,
  0xE79183: 0x4C38,
  0xE79184: 0x4C39,
  0xE79186: 0x4C3A,
  0xE79187: 0x4C3B,
  0xE7918B: 0x4C3C,
  0xE7918D: 0x4C3D,
  0xE79191: 0x4C3E,
  0xE79192: 0x4C3F,
  0xE79197: 0x4C40,
  0xE7919D: 0x4C41,
  0xE791A2: 0x4C42,
  0xE791A6: 0x4C43,
  0xE791A7: 0x4C44,
  0xE791A8: 0x4C45,
  0xE791AB: 0x4C46,
  0xE791AD: 0x4C47,
  0xE791AE: 0x4C48,
  0xE791B1: 0x4C49,
  0xE791B2: 0x4C4A,
  0xE79280: 0x4C4B,
  0xE79281: 0x4C4C,
  0xE79285: 0x4C4D,
  0xE79286: 0x4C4E,
  0xE79287: 0x4C4F,
  0xE79289: 0x4C50,
  0xE7928F: 0x4C51,
  0xE79290: 0x4C52,
  0xE79291: 0x4C53,
  0xE79292: 0x4C54,
  0xE79298: 0x4C55,
  0xE79299: 0x4C56,
  0xE7929A: 0x4C57,
  0xE7929C: 0x4C58,
  0xE7929F: 0x4C59,
  0xE792A0: 0x4C5A,
  0xE792A1: 0x4C5B,
  0xE792A3: 0x4C5C,
  0xE792A6: 0x4C5D,
  0xE792A8: 0x4C5E,
  0xE792A9: 0x4C5F,
  0xE792AA: 0x4C60,
  0xE792AB: 0x4C61,
  0xE792AE: 0x4C62,
  0xE792AF: 0x4C63,
  0xE792B1: 0x4C64,
  0xE792B2: 0x4C65,
  0xE792B5: 0x4C66,
  0xE792B9: 0x4C67,
  0xE792BB: 0x4C68,
  0xE792BF: 0x4C69,
  0xE79388: 0x4C6A,
  0xE79389: 0x4C6B,
  0xE7938C: 0x4C6C,
  0xE79390: 0x4C6D,
  0xE79393: 0x4C6E,
  0xE79398: 0x4C6F,
  0xE7939A: 0x4C70,
  0xE7939B: 0x4C71,
  0xE7939E: 0x4C72,
  0xE7939F: 0x4C73,
  0xE793A4: 0x4C74,
  0xE793A8: 0x4C75,
  0xE793AA: 0x4C76,
  0xE793AB: 0x4C77,
  0xE793AF: 0x4C78,
  0xE793B4: 0x4C79,
  0xE793BA: 0x4C7A,
  0xE793BB: 0x4C7B,
  0xE793BC: 0x4C7C,
  0xE793BF: 0x4C7D,
  0xE79486: 0x4C7E,
  0xE79492: 0x4D21,
  0xE79496: 0x4D22,
  0xE79497: 0x4D23,
  0xE794A0: 0x4D24,
  0xE794A1: 0x4D25,
  0xE794A4: 0x4D26,
  0xE794A7: 0x4D27,
  0xE794A9: 0x4D28,
  0xE794AA: 0x4D29,
  0xE794AF: 0x4D2A,
  0xE794B6: 0x4D2B,
  0xE794B9: 0x4D2C,
  0xE794BD: 0x4D2D,
  0xE794BE: 0x4D2E,
  0xE794BF: 0x4D2F,
  0xE79580: 0x4D30,
  0xE79583: 0x4D31,
  0xE79587: 0x4D32,
  0xE79588: 0x4D33,
  0xE7958E: 0x4D34,
  0xE79590: 0x4D35,
  0xE79592: 0x4D36,
  0xE79597: 0x4D37,
  0xE7959E: 0x4D38,
  0xE7959F: 0x4D39,
  0xE795A1: 0x4D3A,
  0xE795AF: 0x4D3B,
  0xE795B1: 0x4D3C,
  0xE795B9: 0x4D3D,
  0xE795BA: 0x4D3E,
  0xE795BB: 0x4D3F,
  0xE795BC: 0x4D40,
  0xE795BD: 0x4D41,
  0xE795BE: 0x4D42,
  0xE79681: 0x4D43,
  0xE79685: 0x4D44,
  0xE79690: 0x4D45,
  0xE79692: 0x4D46,
  0xE79693: 0x4D47,
  0xE79695: 0x4D48,
  0xE79699: 0x4D49,
  0xE7969C: 0x4D4A,
  0xE796A2: 0x4D4B,
  0xE796A4: 0x4D4C,
  0xE796B4: 0x4D4D,
  0xE796BA: 0x4D4E,
  0xE796BF: 0x4D4F,
  0xE79780: 0x4D50,
  0xE79781: 0x4D51,
  0xE79784: 0x4D52,
  0xE79786: 0x4D53,
  0xE7978C: 0x4D54,
  0xE7978E: 0x4D55,
  0xE7978F: 0x4D56,
  0xE79797: 0x4D57,
  0xE7979C: 0x4D58,
  0xE7979F: 0x4D59,
  0xE797A0: 0x4D5A,
  0xE797A1: 0x4D5B,
  0xE797A4: 0x4D5C,
  0xE797A7: 0x4D5D,
  0xE797AC: 0x4D5E,
  0xE797AE: 0x4D5F,
  0xE797AF: 0x4D60,
  0xE797B1: 0x4D61,
  0xE797B9: 0x4D62,
  0xE79880: 0x4D63,
  0xE79882: 0x4D64,
  0xE79883: 0x4D65,
  0xE79884: 0x4D66,
  0xE79887: 0x4D67,
  0xE79888: 0x4D68,
  0xE7988A: 0x4D69,
  0xE7988C: 0x4D6A,
  0xE7988F: 0x4D6B,
  0xE79892: 0x4D6C,
  0xE79893: 0x4D6D,
  0xE79895: 0x4D6E,
  0xE79896: 0x4D6F,
  0xE79899: 0x4D70,
  0xE7989B: 0x4D71,
  0xE7989C: 0x4D72,
  0xE7989D: 0x4D73,
  0xE7989E: 0x4D74,
  0xE798A3: 0x4D75,
  0xE798A5: 0x4D76,
  0xE798A6: 0x4D77,
  0xE798A9: 0x4D78,
  0xE798AD: 0x4D79,
  0xE798B2: 0x4D7A,
  0xE798B3: 0x4D7B,
  0xE798B5: 0x4D7C,
  0xE798B8: 0x4D7D,
  0xE798B9: 0x4D7E,
  0xE798BA: 0x4E21,
  0xE798BC: 0x4E22,
  0xE7998A: 0x4E23,
  0xE79980: 0x4E24,
  0xE79981: 0x4E25,
  0xE79983: 0x4E26,
  0xE79984: 0x4E27,
  0xE79985: 0x4E28,
  0xE79989: 0x4E29,
  0xE7998B: 0x4E2A,
  0xE79995: 0x4E2B,
  0xE79999: 0x4E2C,
  0xE7999F: 0x4E2D,
  0xE799A4: 0x4E2E,
  0xE799A5: 0x4E2F,
  0xE799AD: 0x4E30,
  0xE799AE: 0x4E31,
  0xE799AF: 0x4E32,
  0xE799B1: 0x4E33,
  0xE799B4: 0x4E34,
  0xE79A81: 0x4E35,
  0xE79A85: 0x4E36,
  0xE79A8C: 0x4E37,
  0xE79A8D: 0x4E38,
  0xE79A95: 0x4E39,
  0xE79A9B: 0x4E3A,
  0xE79A9C: 0x4E3B,
  0xE79A9D: 0x4E3C,
  0xE79A9F: 0x4E3D,
  0xE79AA0: 0x4E3E,
  0xE79AA2: 0x4E3F,
  0xE79AA3: 0x4E40,
  0xE79AA4: 0x4E41,
  0xE79AA5: 0x4E42,
  0xE79AA6: 0x4E43,
  0xE79AA7: 0x4E44,
  0xE79AA8: 0x4E45,
  0xE79AAA: 0x4E46,
  0xE79AAD: 0x4E47,
  0xE79ABD: 0x4E48,
  0xE79B81: 0x4E49,
  0xE79B85: 0x4E4A,
  0xE79B89: 0x4E4B,
  0xE79B8B: 0x4E4C,
  0xE79B8C: 0x4E4D,
  0xE79B8E: 0x4E4E,
  0xE79B94: 0x4E4F,
  0xE79B99: 0x4E50,
  0xE79BA0: 0x4E51,
  0xE79BA6: 0x4E52,
  0xE79BA8: 0x4E53,
  0xE79BAC: 0x4E54,
  0xE79BB0: 0x4E55,
  0xE79BB1: 0x4E56,
  0xE79BB6: 0x4E57,
  0xE79BB9: 0x4E58,
  0xE79BBC: 0x4E59,
  0xE79C80: 0x4E5A,
  0xE79C86: 0x4E5B,
  0xE79C8A: 0x4E5C,
  0xE79C8E: 0x4E5D,
  0xE79C92: 0x4E5E,
  0xE79C94: 0x4E5F,
  0xE79C95: 0x4E60,
  0xE79C97: 0x4E61,
  0xE79C99: 0x4E62,
  0xE79C9A: 0x4E63,
  0xE79C9C: 0x4E64,
  0xE79CA2: 0x4E65,
  0xE79CA8: 0x4E66,
  0xE79CAD: 0x4E67,
  0xE79CAE: 0x4E68,
  0xE79CAF: 0x4E69,
  0xE79CB4: 0x4E6A,
  0xE79CB5: 0x4E6B,
  0xE79CB6: 0x4E6C,
  0xE79CB9: 0x4E6D,
  0xE79CBD: 0x4E6E,
  0xE79CBE: 0x4E6F,
  0xE79D82: 0x4E70,
  0xE79D85: 0x4E71,
  0xE79D86: 0x4E72,
  0xE79D8A: 0x4E73,
  0xE79D8D: 0x4E74,
  0xE79D8E: 0x4E75,
  0xE79D8F: 0x4E76,
  0xE79D92: 0x4E77,
  0xE79D96: 0x4E78,
  0xE79D97: 0x4E79,
  0xE79D9C: 0x4E7A,
  0xE79D9E: 0x4E7B,
  0xE79D9F: 0x4E7C,
  0xE79DA0: 0x4E7D,
  0xE79DA2: 0x4E7E,
  0xE79DA4: 0x4F21,
  0xE79DA7: 0x4F22,
  0xE79DAA: 0x4F23,
  0xE79DAC: 0x4F24,
  0xE79DB0: 0x4F25,
  0xE79DB2: 0x4F26,
  0xE79DB3: 0x4F27,
  0xE79DB4: 0x4F28,
  0xE79DBA: 0x4F29,
  0xE79DBD: 0x4F2A,
  0xE79E80: 0x4F2B,
  0xE79E84: 0x4F2C,
  0xE79E8C: 0x4F2D,
  0xE79E8D: 0x4F2E,
  0xE79E94: 0x4F2F,
  0xE79E95: 0x4F30,
  0xE79E96: 0x4F31,
  0xE79E9A: 0x4F32,
  0xE79E9F: 0x4F33,
  0xE79EA2: 0x4F34,
  0xE79EA7: 0x4F35,
  0xE79EAA: 0x4F36,
  0xE79EAE: 0x4F37,
  0xE79EAF: 0x4F38,
  0xE79EB1: 0x4F39,
  0xE79EB5: 0x4F3A,
  0xE79EBE: 0x4F3B,
  0xE79F83: 0x4F3C,
  0xE79F89: 0x4F3D,
  0xE79F91: 0x4F3E,
  0xE79F92: 0x4F3F,
  0xE79F95: 0x4F40,
  0xE79F99: 0x4F41,
  0xE79F9E: 0x4F42,
  0xE79F9F: 0x4F43,
  0xE79FA0: 0x4F44,
  0xE79FA4: 0x4F45,
  0xE79FA6: 0x4F46,
  0xE79FAA: 0x4F47,
  0xE79FAC: 0x4F48,
  0xE79FB0: 0x4F49,
  0xE79FB1: 0x4F4A,
  0xE79FB4: 0x4F4B,
  0xE79FB8: 0x4F4C,
  0xE79FBB: 0x4F4D,
  0xE7A085: 0x4F4E,
  0xE7A086: 0x4F4F,
  0xE7A089: 0x4F50,
  0xE7A08D: 0x4F51,
  0xE7A08E: 0x4F52,
  0xE7A091: 0x4F53,
  0xE7A09D: 0x4F54,
  0xE7A0A1: 0x4F55,
  0xE7A0A2: 0x4F56,
  0xE7A0A3: 0x4F57,
  0xE7A0AD: 0x4F58,
  0xE7A0AE: 0x4F59,
  0xE7A0B0: 0x4F5A,
  0xE7A0B5: 0x4F5B,
  0xE7A0B7: 0x4F5C,
  0xE7A183: 0x4F5D,
  0xE7A184: 0x4F5E,
  0xE7A187: 0x4F5F,
  0xE7A188: 0x4F60,
  0xE7A18C: 0x4F61,
  0xE7A18E: 0x4F62,
  0xE7A192: 0x4F63,
  0xE7A19C: 0x4F64,
  0xE7A19E: 0x4F65,
  0xE7A1A0: 0x4F66,
  0xE7A1A1: 0x4F67,
  0xE7A1A3: 0x4F68,
  0xE7A1A4: 0x4F69,
  0xE7A1A8: 0x4F6A,
  0xE7A1AA: 0x4F6B,
  0xE7A1AE: 0x4F6C,
  0xE7A1BA: 0x4F6D,
  0xE7A1BE: 0x4F6E,
  0xE7A28A: 0x4F6F,
  0xE7A28F: 0x4F70,
  0xE7A294: 0x4F71,
  0xE7A298: 0x4F72,
  0xE7A2A1: 0x4F73,
  0xE7A29D: 0x4F74,
  0xE7A29E: 0x4F75,
  0xE7A29F: 0x4F76,
  0xE7A2A4: 0x4F77,
  0xE7A2A8: 0x4F78,
  0xE7A2AC: 0x4F79,
  0xE7A2AD: 0x4F7A,
  0xE7A2B0: 0x4F7B,
  0xE7A2B1: 0x4F7C,
  0xE7A2B2: 0x4F7D,
  0xE7A2B3: 0x4F7E,
  0xE7A2BB: 0x5021,
  0xE7A2BD: 0x5022,
  0xE7A2BF: 0x5023,
  0xE7A387: 0x5024,
  0xE7A388: 0x5025,
  0xE7A389: 0x5026,
  0xE7A38C: 0x5027,
  0xE7A38E: 0x5028,
  0xE7A392: 0x5029,
  0xE7A393: 0x502A,
  0xE7A395: 0x502B,
  0xE7A396: 0x502C,
  0xE7A3A4: 0x502D,
  0xE7A39B: 0x502E,
  0xE7A39F: 0x502F,
  0xE7A3A0: 0x5030,
  0xE7A3A1: 0x5031,
  0xE7A3A6: 0x5032,
  0xE7A3AA: 0x5033,
  0xE7A3B2: 0x5034,
  0xE7A3B3: 0x5035,
  0xE7A480: 0x5036,
  0xE7A3B6: 0x5037,
  0xE7A3B7: 0x5038,
  0xE7A3BA: 0x5039,
  0xE7A3BB: 0x503A,
  0xE7A3BF: 0x503B,
  0xE7A486: 0x503C,
  0xE7A48C: 0x503D,
  0xE7A490: 0x503E,
  0xE7A49A: 0x503F,
  0xE7A49C: 0x5040,
  0xE7A49E: 0x5041,
  0xE7A49F: 0x5042,
  0xE7A4A0: 0x5043,
  0xE7A4A5: 0x5044,
  0xE7A4A7: 0x5045,
  0xE7A4A9: 0x5046,
  0xE7A4AD: 0x5047,
  0xE7A4B1: 0x5048,
  0xE7A4B4: 0x5049,
  0xE7A4B5: 0x504A,
  0xE7A4BB: 0x504B,
  0xE7A4BD: 0x504C,
  0xE7A4BF: 0x504D,
  0xE7A584: 0x504E,
  0xE7A585: 0x504F,
  0xE7A586: 0x5050,
  0xE7A58A: 0x5051,
  0xE7A58B: 0x5052,
  0xE7A58F: 0x5053,
  0xE7A591: 0x5054,
  0xE7A594: 0x5055,
  0xE7A598: 0x5056,
  0xE7A59B: 0x5057,
  0xE7A59C: 0x5058,
  0xE7A5A7: 0x5059,
  0xE7A5A9: 0x505A,
  0xE7A5AB: 0x505B,
  0xE7A5B2: 0x505C,
  0xE7A5B9: 0x505D,
  0xE7A5BB: 0x505E,
  0xE7A5BC: 0x505F,
  0xE7A5BE: 0x5060,
  0xE7A68B: 0x5061,
  0xE7A68C: 0x5062,
  0xE7A691: 0x5063,
  0xE7A693: 0x5064,
  0xE7A694: 0x5065,
  0xE7A695: 0x5066,
  0xE7A696: 0x5067,
  0xE7A698: 0x5068,
  0xE7A69B: 0x5069,
  0xE7A69C: 0x506A,
  0xE7A6A1: 0x506B,
  0xE7A6A8: 0x506C,
  0xE7A6A9: 0x506D,
  0xE7A6AB: 0x506E,
  0xE7A6AF: 0x506F,
  0xE7A6B1: 0x5070,
  0xE7A6B4: 0x5071,
  0xE7A6B8: 0x5072,
  0xE7A6BB: 0x5073,
  0xE7A782: 0x5074,
  0xE7A784: 0x5075,
  0xE7A787: 0x5076,
  0xE7A788: 0x5077,
  0xE7A78A: 0x5078,
  0xE7A78F: 0x5079,
  0xE7A794: 0x507A,
  0xE7A796: 0x507B,
  0xE7A79A: 0x507C,
  0xE7A79D: 0x507D,
  0xE7A79E: 0x507E,
  0xE7A7A0: 0x5121,
  0xE7A7A2: 0x5122,
  0xE7A7A5: 0x5123,
  0xE7A7AA: 0x5124,
  0xE7A7AB: 0x5125,
  0xE7A7AD: 0x5126,
  0xE7A7B1: 0x5127,
  0xE7A7B8: 0x5128,
  0xE7A7BC: 0x5129,
  0xE7A882: 0x512A,
  0xE7A883: 0x512B,
  0xE7A887: 0x512C,
  0xE7A889: 0x512D,
  0xE7A88A: 0x512E,
  0xE7A88C: 0x512F,
  0xE7A891: 0x5130,
  0xE7A895: 0x5131,
  0xE7A89B: 0x5132,
  0xE7A89E: 0x5133,
  0xE7A8A1: 0x5134,
  0xE7A8A7: 0x5135,
  0xE7A8AB: 0x5136,
  0xE7A8AD: 0x5137,
  0xE7A8AF: 0x5138,
  0xE7A8B0: 0x5139,
  0xE7A8B4: 0x513A,
  0xE7A8B5: 0x513B,
  0xE7A8B8: 0x513C,
  0xE7A8B9: 0x513D,
  0xE7A8BA: 0x513E,
  0xE7A984: 0x513F,
  0xE7A985: 0x5140,
  0xE7A987: 0x5141,
  0xE7A988: 0x5142,
  0xE7A98C: 0x5143,
  0xE7A995: 0x5144,
  0xE7A996: 0x5145,
  0xE7A999: 0x5146,
  0xE7A99C: 0x5147,
  0xE7A99D: 0x5148,
  0xE7A99F: 0x5149,
  0xE7A9A0: 0x514A,
  0xE7A9A5: 0x514B,
  0xE7A9A7: 0x514C,
  0xE7A9AA: 0x514D,
  0xE7A9AD: 0x514E,
  0xE7A9B5: 0x514F,
  0xE7A9B8: 0x5150,
  0xE7A9BE: 0x5151,
  0xE7AA80: 0x5152,
  0xE7AA82: 0x5153,
  0xE7AA85: 0x5154,
  0xE7AA86: 0x5155,
  0xE7AA8A: 0x5156,
  0xE7AA8B: 0x5157,
  0xE7AA90: 0x5158,
  0xE7AA91: 0x5159,
  0xE7AA94: 0x515A,
  0xE7AA9E: 0x515B,
  0xE7AAA0: 0x515C,
  0xE7AAA3: 0x515D,
  0xE7AAAC: 0x515E,
  0xE7AAB3: 0x515F,
  0xE7AAB5: 0x5160,
  0xE7AAB9: 0x5161,
  0xE7AABB: 0x5162,
  0xE7AABC: 0x5163,
  0xE7AB86: 0x5164,
  0xE7AB89: 0x5165,
  0xE7AB8C: 0x5166,
  0xE7AB8E: 0x5167,
  0xE7AB91: 0x5168,
  0xE7AB9B: 0x5169,
  0xE7ABA8: 0x516A,
  0xE7ABA9: 0x516B,
  0xE7ABAB: 0x516C,
  0xE7ABAC: 0x516D,
  0xE7ABB1: 0x516E,
  0xE7ABB4: 0x516F,
  0xE7ABBB: 0x5170,
  0xE7ABBD: 0x5171,
  0xE7ABBE: 0x5172,
  0xE7AC87: 0x5173,
  0xE7AC94: 0x5174,
  0xE7AC9F: 0x5175,
  0xE7ACA3: 0x5176,
  0xE7ACA7: 0x5177,
  0xE7ACA9: 0x5178,
  0xE7ACAA: 0x5179,
  0xE7ACAB: 0x517A,
  0xE7ACAD: 0x517B,
  0xE7ACAE: 0x517C,
  0xE7ACAF: 0x517D,
  0xE7ACB0: 0x517E,
  0xE7ACB1: 0x5221,
  0xE7ACB4: 0x5222,
  0xE7ACBD: 0x5223,
  0xE7ACBF: 0x5224,
  0xE7AD80: 0x5225,
  0xE7AD81: 0x5226,
  0xE7AD87: 0x5227,
  0xE7AD8E: 0x5228,
  0xE7AD95: 0x5229,
  0xE7ADA0: 0x522A,
  0xE7ADA4: 0x522B,
  0xE7ADA6: 0x522C,
  0xE7ADA9: 0x522D,
  0xE7ADAA: 0x522E,
  0xE7ADAD: 0x522F,
  0xE7ADAF: 0x5230,
  0xE7ADB2: 0x5231,
  0xE7ADB3: 0x5232,
  0xE7ADB7: 0x5233,
  0xE7AE84: 0x5234,
  0xE7AE89: 0x5235,
  0xE7AE8E: 0x5236,
  0xE7AE90: 0x5237,
  0xE7AE91: 0x5238,
  0xE7AE96: 0x5239,
  0xE7AE9B: 0x523A,
  0xE7AE9E: 0x523B,
  0xE7AEA0: 0x523C,
  0xE7AEA5: 0x523D,
  0xE7AEAC: 0x523E,
  0xE7AEAF: 0x523F,
  0xE7AEB0: 0x5240,
  0xE7AEB2: 0x5241,
  0xE7AEB5: 0x5242,
  0xE7AEB6: 0x5243,
  0xE7AEBA: 0x5244,
  0xE7AEBB: 0x5245,
  0xE7AEBC: 0x5246,
  0xE7AEBD: 0x5247,
  0xE7AF82: 0x5248,
  0xE7AF85: 0x5249,
  0xE7AF88: 0x524A,
  0xE7AF8A: 0x524B,
  0xE7AF94: 0x524C,
  0xE7AF96: 0x524D,
  0xE7AF97: 0x524E,
  0xE7AF99: 0x524F,
  0xE7AF9A: 0x5250,
  0xE7AF9B: 0x5251,
  0xE7AFA8: 0x5252,
  0xE7AFAA: 0x5253,
  0xE7AFB2: 0x5254,
  0xE7AFB4: 0x5255,
  0xE7AFB5: 0x5256,
  0xE7AFB8: 0x5257,
  0xE7AFB9: 0x5258,
  0xE7AFBA: 0x5259,
  0xE7AFBC: 0x525A,
  0xE7AFBE: 0x525B,
  0xE7B081: 0x525C,
  0xE7B082: 0x525D,
  0xE7B083: 0x525E,
  0xE7B084: 0x525F,
  0xE7B086: 0x5260,
  0xE7B089: 0x5261,
  0xE7B08B: 0x5262,
  0xE7B08C: 0x5263,
  0xE7B08E: 0x5264,
  0xE7B08F: 0x5265,
  0xE7B099: 0x5266,
  0xE7B09B: 0x5267,
  0xE7B0A0: 0x5268,
  0xE7B0A5: 0x5269,
  0xE7B0A6: 0x526A,
  0xE7B0A8: 0x526B,
  0xE7B0AC: 0x526C,
  0xE7B0B1: 0x526D,
  0xE7B0B3: 0x526E,
  0xE7B0B4: 0x526F,
  0xE7B0B6: 0x5270,
  0xE7B0B9: 0x5271,
  0xE7B0BA: 0x5272,
  0xE7B186: 0x5273,
  0xE7B18A: 0x5274,
  0xE7B195: 0x5275,
  0xE7B191: 0x5276,
  0xE7B192: 0x5277,
  0xE7B193: 0x5278,
  0xE7B199: 0x5279,
  0xE7B19A: 0x527A,
  0xE7B19B: 0x527B,
  0xE7B19C: 0x527C,
  0xE7B19D: 0x527D,
  0xE7B19E: 0x527E,
  0xE7B1A1: 0x5321,
  0xE7B1A3: 0x5322,
  0xE7B1A7: 0x5323,
  0xE7B1A9: 0x5324,
  0xE7B1AD: 0x5325,
  0xE7B1AE: 0x5326,
  0xE7B1B0: 0x5327,
  0xE7B1B2: 0x5328,
  0xE7B1B9: 0x5329,
  0xE7B1BC: 0x532A,
  0xE7B1BD: 0x532B,
  0xE7B286: 0x532C,
  0xE7B287: 0x532D,
  0xE7B28F: 0x532E,
  0xE7B294: 0x532F,
  0xE7B29E: 0x5330,
  0xE7B2A0: 0x5331,
  0xE7B2A6: 0x5332,
  0xE7B2B0: 0x5333,
  0xE7B2B6: 0x5334,
  0xE7B2B7: 0x5335,
  0xE7B2BA: 0x5336,
  0xE7B2BB: 0x5337,
  0xE7B2BC: 0x5338,
  0xE7B2BF: 0x5339,
  0xE7B384: 0x533A,
  0xE7B387: 0x533B,
  0xE7B388: 0x533C,
  0xE7B389: 0x533D,
  0xE7B38D: 0x533E,
  0xE7B38F: 0x533F,
  0xE7B393: 0x5340,
  0xE7B394: 0x5341,
  0xE7B395: 0x5342,
  0xE7B397: 0x5343,
  0xE7B399: 0x5344,
  0xE7B39A: 0x5345,
  0xE7B39D: 0x5346,
  0xE7B3A6: 0x5347,
  0xE7B3A9: 0x5348,
  0xE7B3AB: 0x5349,
  0xE7B3B5: 0x534A,
  0xE7B483: 0x534B,
  0xE7B487: 0x534C,
  0xE7B488: 0x534D,
  0xE7B489: 0x534E,
  0xE7B48F: 0x534F,
  0xE7B491: 0x5350,
  0xE7B492: 0x5351,
  0xE7B493: 0x5352,
  0xE7B496: 0x5353,
  0xE7B49D: 0x5354,
  0xE7B49E: 0x5355,
  0xE7B4A3: 0x5356,
  0xE7B4A6: 0x5357,
  0xE7B4AA: 0x5358,
  0xE7B4AD: 0x5359,
  0xE7B4B1: 0x535A,
  0xE7B4BC: 0x535B,
  0xE7B4BD: 0x535C,
  0xE7B4BE: 0x535D,
  0xE7B580: 0x535E,
  0xE7B581: 0x535F,
  0xE7B587: 0x5360,
  0xE7B588: 0x5361,
  0xE7B58D: 0x5362,
  0xE7B591: 0x5363,
  0xE7B593: 0x5364,
  0xE7B597: 0x5365,
  0xE7B599: 0x5366,
  0xE7B59A: 0x5367,
  0xE7B59C: 0x5368,
  0xE7B59D: 0x5369,
  0xE7B5A5: 0x536A,
  0xE7B5A7: 0x536B,
  0xE7B5AA: 0x536C,
  0xE7B5B0: 0x536D,
  0xE7B5B8: 0x536E,
  0xE7B5BA: 0x536F,
  0xE7B5BB: 0x5370,
  0xE7B5BF: 0x5371,
  0xE7B681: 0x5372,
  0xE7B682: 0x5373,
  0xE7B683: 0x5374,
  0xE7B685: 0x5375,
  0xE7B686: 0x5376,
  0xE7B688: 0x5377,
  0xE7B68B: 0x5378,
  0xE7B68C: 0x5379,
  0xE7B68D: 0x537A,
  0xE7B691: 0x537B,
  0xE7B696: 0x537C,
  0xE7B697: 0x537D,
  0xE7B69D: 0x537E,
  0xE7B69E: 0x5421,
  0xE7B6A6: 0x5422,
  0xE7B6A7: 0x5423,
  0xE7B6AA: 0x5424,
  0xE7B6B3: 0x5425,
  0xE7B6B6: 0x5426,
  0xE7B6B7: 0x5427,
  0xE7B6B9: 0x5428,
  0xE7B782: 0x5429,
  0xE7B783: 0x542A,
  0xE7B784: 0x542B,
  0xE7B785: 0x542C,
  0xE7B786: 0x542D,
  0xE7B78C: 0x542E,
  0xE7B78D: 0x542F,
  0xE7B78E: 0x5430,
  0xE7B797: 0x5431,
  0xE7B799: 0x5432,
  0xE7B880: 0x5433,
  0xE7B7A2: 0x5434,
  0xE7B7A5: 0x5435,
  0xE7B7A6: 0x5436,
  0xE7B7AA: 0x5437,
  0xE7B7AB: 0x5438,
  0xE7B7AD: 0x5439,
  0xE7B7B1: 0x543A,
  0xE7B7B5: 0x543B,
  0xE7B7B6: 0x543C,
  0xE7B7B9: 0x543D,
  0xE7B7BA: 0x543E,
  0xE7B888: 0x543F,
  0xE7B890: 0x5440,
  0xE7B891: 0x5441,
  0xE7B895: 0x5442,
  0xE7B897: 0x5443,
  0xE7B89C: 0x5444,
  0xE7B89D: 0x5445,
  0xE7B8A0: 0x5446,
  0xE7B8A7: 0x5447,
  0xE7B8A8: 0x5448,
  0xE7B8AC: 0x5449,
  0xE7B8AD: 0x544A,
  0xE7B8AF: 0x544B,
  0xE7B8B3: 0x544C,
  0xE7B8B6: 0x544D,
  0xE7B8BF: 0x544E,
  0xE7B984: 0x544F,
  0xE7B985: 0x5450,
  0xE7B987: 0x5451,
  0xE7B98E: 0x5452,
  0xE7B990: 0x5453,
  0xE7B992: 0x5454,
  0xE7B998: 0x5455,
  0xE7B99F: 0x5456,
  0xE7B9A1: 0x5457,
  0xE7B9A2: 0x5458,
  0xE7B9A5: 0x5459,
  0xE7B9AB: 0x545A,
  0xE7B9AE: 0x545B,
  0xE7B9AF: 0x545C,
  0xE7B9B3: 0x545D,
  0xE7B9B8: 0x545E,
  0xE7B9BE: 0x545F,
  0xE7BA81: 0x5460,
  0xE7BA86: 0x5461,
  0xE7BA87: 0x5462,
  0xE7BA8A: 0x5463,
  0xE7BA8D: 0x5464,
  0xE7BA91: 0x5465,
  0xE7BA95: 0x5466,
  0xE7BA98: 0x5467,
  0xE7BA9A: 0x5468,
  0xE7BA9D: 0x5469,
  0xE7BA9E: 0x546A,
  0xE7BCBC: 0x546B,
  0xE7BCBB: 0x546C,
  0xE7BCBD: 0x546D,
  0xE7BCBE: 0x546E,
  0xE7BCBF: 0x546F,
  0xE7BD83: 0x5470,
  0xE7BD84: 0x5471,
  0xE7BD87: 0x5472,
  0xE7BD8F: 0x5473,
  0xE7BD92: 0x5474,
  0xE7BD93: 0x5475,
  0xE7BD9B: 0x5476,
  0xE7BD9C: 0x5477,
  0xE7BD9D: 0x5478,
  0xE7BDA1: 0x5479,
  0xE7BDA3: 0x547A,
  0xE7BDA4: 0x547B,
  0xE7BDA5: 0x547C,
  0xE7BDA6: 0x547D,
  0xE7BDAD: 0x547E,
  0xE7BDB1: 0x5521,
  0xE7BDBD: 0x5522,
  0xE7BDBE: 0x5523,
  0xE7BDBF: 0x5524,
  0xE7BE80: 0x5525,
  0xE7BE8B: 0x5526,
  0xE7BE8D: 0x5527,
  0xE7BE8F: 0x5528,
  0xE7BE90: 0x5529,
  0xE7BE91: 0x552A,
  0xE7BE96: 0x552B,
  0xE7BE97: 0x552C,
  0xE7BE9C: 0x552D,
  0xE7BEA1: 0x552E,
  0xE7BEA2: 0x552F,
  0xE7BEA6: 0x5530,
  0xE7BEAA: 0x5531,
  0xE7BEAD: 0x5532,
  0xE7BEB4: 0x5533,
  0xE7BEBC: 0x5534,
  0xE7BEBF: 0x5535,
  0xE7BF80: 0x5536,
  0xE7BF83: 0x5537,
  0xE7BF88: 0x5538,
  0xE7BF8E: 0x5539,
  0xE7BF8F: 0x553A,
  0xE7BF9B: 0x553B,
  0xE7BF9F: 0x553C,
  0xE7BFA3: 0x553D,
  0xE7BFA5: 0x553E,
  0xE7BFA8: 0x553F,
  0xE7BFAC: 0x5540,
  0xE7BFAE: 0x5541,
  0xE7BFAF: 0x5542,
  0xE7BFB2: 0x5543,
  0xE7BFBA: 0x5544,
  0xE7BFBD: 0x5545,
  0xE7BFBE: 0x5546,
  0xE7BFBF: 0x5547,
  0xE88087: 0x5548,
  0xE88088: 0x5549,
  0xE8808A: 0x554A,
  0xE8808D: 0x554B,
  0xE8808E: 0x554C,
  0xE8808F: 0x554D,
  0xE88091: 0x554E,
  0xE88093: 0x554F,
  0xE88094: 0x5550,
  0xE88096: 0x5551,
  0xE8809D: 0x5552,
  0xE8809E: 0x5553,
  0xE8809F: 0x5554,
  0xE880A0: 0x5555,
  0xE880A4: 0x5556,
  0xE880A6: 0x5557,
  0xE880AC: 0x5558,
  0xE880AE: 0x5559,
  0xE880B0: 0x555A,
  0xE880B4: 0x555B,
  0xE880B5: 0x555C,
  0xE880B7: 0x555D,
  0xE880B9: 0x555E,
  0xE880BA: 0x555F,
  0xE880BC: 0x5560,
  0xE880BE: 0x5561,
  0xE88180: 0x5562,
  0xE88184: 0x5563,
  0xE881A0: 0x5564,
  0xE881A4: 0x5565,
  0xE881A6: 0x5566,
  0xE881AD: 0x5567,
  0xE881B1: 0x5568,
  0xE881B5: 0x5569,
  0xE88281: 0x556A,
  0xE88288: 0x556B,
  0xE8828E: 0x556C,
  0xE8829C: 0x556D,
  0xE8829E: 0x556E,
  0xE882A6: 0x556F,
  0xE882A7: 0x5570,
  0xE882AB: 0x5571,
  0xE882B8: 0x5572,
  0xE882B9: 0x5573,
  0xE88388: 0x5574,
  0xE8838D: 0x5575,
  0xE8838F: 0x5576,
  0xE88392: 0x5577,
  0xE88394: 0x5578,
  0xE88395: 0x5579,
  0xE88397: 0x557A,
  0xE88398: 0x557B,
  0xE883A0: 0x557C,
  0xE883AD: 0x557D,
  0xE883AE: 0x557E,
  0xE883B0: 0x5621,
  0xE883B2: 0x5622,
  0xE883B3: 0x5623,
  0xE883B6: 0x5624,
  0xE883B9: 0x5625,
  0xE883BA: 0x5626,
  0xE883BE: 0x5627,
  0xE88483: 0x5628,
  0xE8848B: 0x5629,
  0xE88496: 0x562A,
  0xE88497: 0x562B,
  0xE88498: 0x562C,
  0xE8849C: 0x562D,
  0xE8849E: 0x562E,
  0xE884A0: 0x562F,
  0xE884A4: 0x5630,
  0xE884A7: 0x5631,
  0xE884AC: 0x5632,
  0xE884B0: 0x5633,
  0xE884B5: 0x5634,
  0xE884BA: 0x5635,
  0xE884BC: 0x5636,
  0xE88585: 0x5637,
  0xE88587: 0x5638,
  0xE8858A: 0x5639,
  0xE8858C: 0x563A,
  0xE88592: 0x563B,
  0xE88597: 0x563C,
  0xE885A0: 0x563D,
  0xE885A1: 0x563E,
  0xE885A7: 0x563F,
  0xE885A8: 0x5640,
  0xE885A9: 0x5641,
  0xE885AD: 0x5642,
  0xE885AF: 0x5643,
  0xE885B7: 0x5644,
  0xE88681: 0x5645,
  0xE88690: 0x5646,
  0xE88684: 0x5647,
  0xE88685: 0x5648,
  0xE88686: 0x5649,
  0xE8868B: 0x564A,
  0xE8868E: 0x564B,
  0xE88696: 0x564C,
  0xE88698: 0x564D,
  0xE8869B: 0x564E,
  0xE8869E: 0x564F,
  0xE886A2: 0x5650,
  0xE886AE: 0x5651,
  0xE886B2: 0x5652,
  0xE886B4: 0x5653,
  0xE886BB: 0x5654,
  0xE8878B: 0x5655,
  0xE88783: 0x5656,
  0xE88785: 0x5657,
  0xE8878A: 0x5658,
  0xE8878E: 0x5659,
  0xE8878F: 0x565A,
  0xE88795: 0x565B,
  0xE88797: 0x565C,
  0xE8879B: 0x565D,
  0xE8879D: 0x565E,
  0xE8879E: 0x565F,
  0xE887A1: 0x5660,
  0xE887A4: 0x5661,
  0xE887AB: 0x5662,
  0xE887AC: 0x5663,
  0xE887B0: 0x5664,
  0xE887B1: 0x5665,
  0xE887B2: 0x5666,
  0xE887B5: 0x5667,
  0xE887B6: 0x5668,
  0xE887B8: 0x5669,
  0xE887B9: 0x566A,
  0xE887BD: 0x566B,
  0xE887BF: 0x566C,
  0xE88880: 0x566D,
  0xE88883: 0x566E,
  0xE8888F: 0x566F,
  0xE88893: 0x5670,
  0xE88894: 0x5671,
  0xE88899: 0x5672,
  0xE8889A: 0x5673,
  0xE8889D: 0x5674,
  0xE888A1: 0x5675,
  0xE888A2: 0x5676,
  0xE888A8: 0x5677,
  0xE888B2: 0x5678,
  0xE888B4: 0x5679,
  0xE888BA: 0x567A,
  0xE88983: 0x567B,
  0xE88984: 0x567C,
  0xE88985: 0x567D,
  0xE88986: 0x567E,
  0xE8898B: 0x5721,
  0xE8898E: 0x5722,
  0xE8898F: 0x5723,
  0xE88991: 0x5724,
  0xE88996: 0x5725,
  0xE8899C: 0x5726,
  0xE889A0: 0x5727,
  0xE889A3: 0x5728,
  0xE889A7: 0x5729,
  0xE889AD: 0x572A,
  0xE889B4: 0x572B,
  0xE889BB: 0x572C,
  0xE889BD: 0x572D,
  0xE889BF: 0x572E,
  0xE88A80: 0x572F,
  0xE88A81: 0x5730,
  0xE88A83: 0x5731,
  0xE88A84: 0x5732,
  0xE88A87: 0x5733,
  0xE88A89: 0x5734,
  0xE88A8A: 0x5735,
  0xE88A8E: 0x5736,
  0xE88A91: 0x5737,
  0xE88A94: 0x5738,
  0xE88A96: 0x5739,
  0xE88A98: 0x573A,
  0xE88A9A: 0x573B,
  0xE88A9B: 0x573C,
  0xE88AA0: 0x573D,
  0xE88AA1: 0x573E,
  0xE88AA3: 0x573F,
  0xE88AA4: 0x5740,
  0xE88AA7: 0x5741,
  0xE88AA8: 0x5742,
  0xE88AA9: 0x5743,
  0xE88AAA: 0x5744,
  0xE88AAE: 0x5745,
  0xE88AB0: 0x5746,
  0xE88AB2: 0x5747,
  0xE88AB4: 0x5748,
  0xE88AB7: 0x5749,
  0xE88ABA: 0x574A,
  0xE88ABC: 0x574B,
  0xE88ABE: 0x574C,
  0xE88ABF: 0x574D,
  0xE88B86: 0x574E,
  0xE88B90: 0x574F,
  0xE88B95: 0x5750,
  0xE88B9A: 0x5751,
  0xE88BA0: 0x5752,
  0xE88BA2: 0x5753,
  0xE88BA4: 0x5754,
  0xE88BA8: 0x5755,
  0xE88BAA: 0x5756,
  0xE88BAD: 0x5757,
  0xE88BAF: 0x5758,
  0xE88BB6: 0x5759,
  0xE88BB7: 0x575A,
  0xE88BBD: 0x575B,
  0xE88BBE: 0x575C,
  0xE88C80: 0x575D,
  0xE88C81: 0x575E,
  0xE88C87: 0x575F,
  0xE88C88: 0x5760,
  0xE88C8A: 0x5761,
  0xE88C8B: 0x5762,
  0xE88D94: 0x5763,
  0xE88C9B: 0x5764,
  0xE88C9D: 0x5765,
  0xE88C9E: 0x5766,
  0xE88C9F: 0x5767,
  0xE88CA1: 0x5768,
  0xE88CA2: 0x5769,
  0xE88CAC: 0x576A,
  0xE88CAD: 0x576B,
  0xE88CAE: 0x576C,
  0xE88CB0: 0x576D,
  0xE88CB3: 0x576E,
  0xE88CB7: 0x576F,
  0xE88CBA: 0x5770,
  0xE88CBC: 0x5771,
  0xE88CBD: 0x5772,
  0xE88D82: 0x5773,
  0xE88D83: 0x5774,
  0xE88D84: 0x5775,
  0xE88D87: 0x5776,
  0xE88D8D: 0x5777,
  0xE88D8E: 0x5778,
  0xE88D91: 0x5779,
  0xE88D95: 0x577A,
  0xE88D96: 0x577B,
  0xE88D97: 0x577C,
  0xE88DB0: 0x577D,
  0xE88DB8: 0x577E,
  0xE88DBD: 0x5821,
  0xE88DBF: 0x5822,
  0xE88E80: 0x5823,
  0xE88E82: 0x5824,
  0xE88E84: 0x5825,
  0xE88E86: 0x5826,
  0xE88E8D: 0x5827,
  0xE88E92: 0x5828,
  0xE88E94: 0x5829,
  0xE88E95: 0x582A,
  0xE88E98: 0x582B,
  0xE88E99: 0x582C,
  0xE88E9B: 0x582D,
  0xE88E9C: 0x582E,
  0xE88E9D: 0x582F,
  0xE88EA6: 0x5830,
  0xE88EA7: 0x5831,
  0xE88EA9: 0x5832,
  0xE88EAC: 0x5833,
  0xE88EBE: 0x5834,
  0xE88EBF: 0x5835,
  0xE88F80: 0x5836,
  0xE88F87: 0x5837,
  0xE88F89: 0x5838,
  0xE88F8F: 0x5839,
  0xE88F90: 0x583A,
  0xE88F91: 0x583B,
  0xE88F94: 0x583C,
  0xE88F9D: 0x583D,
  0xE88D93: 0x583E,
  0xE88FA8: 0x583F,
  0xE88FAA: 0x5840,
  0xE88FB6: 0x5841,
  0xE88FB8: 0x5842,
  0xE88FB9: 0x5843,
  0xE88FBC: 0x5844,
  0xE89081: 0x5845,
  0xE89086: 0x5846,
  0xE8908A: 0x5847,
  0xE8908F: 0x5848,
  0xE89091: 0x5849,
  0xE89095: 0x584A,
  0xE89099: 0x584B,
  0xE88EAD: 0x584C,
  0xE890AF: 0x584D,
  0xE890B9: 0x584E,
  0xE89185: 0x584F,
  0xE89187: 0x5850,
  0xE89188: 0x5851,
  0xE8918A: 0x5852,
  0xE8918D: 0x5853,
  0xE8918F: 0x5854,
  0xE89191: 0x5855,
  0xE89192: 0x5856,
  0xE89196: 0x5857,
  0xE89198: 0x5858,
  0xE89199: 0x5859,
  0xE8919A: 0x585A,
  0xE8919C: 0x585B,
  0xE891A0: 0x585C,
  0xE891A4: 0x585D,
  0xE891A5: 0x585E,
  0xE891A7: 0x585F,
  0xE891AA: 0x5860,
  0xE891B0: 0x5861,
  0xE891B3: 0x5862,
  0xE891B4: 0x5863,
  0xE891B6: 0x5864,
  0xE891B8: 0x5865,
  0xE891BC: 0x5866,
  0xE891BD: 0x5867,
  0xE89281: 0x5868,
  0xE89285: 0x5869,
  0xE89292: 0x586A,
  0xE89293: 0x586B,
  0xE89295: 0x586C,
  0xE8929E: 0x586D,
  0xE892A6: 0x586E,
  0xE892A8: 0x586F,
  0xE892A9: 0x5870,
  0xE892AA: 0x5871,
  0xE892AF: 0x5872,
  0xE892B1: 0x5873,
  0xE892B4: 0x5874,
  0xE892BA: 0x5875,
  0xE892BD: 0x5876,
  0xE892BE: 0x5877,
  0xE89380: 0x5878,
  0xE89382: 0x5879,
  0xE89387: 0x587A,
  0xE89388: 0x587B,
  0xE8938C: 0x587C,
  0xE8938F: 0x587D,
  0xE89393: 0x587E,
  0xE8939C: 0x5921,
  0xE893A7: 0x5922,
  0xE893AA: 0x5923,
  0xE893AF: 0x5924,
  0xE893B0: 0x5925,
  0xE893B1: 0x5926,
  0xE893B2: 0x5927,
  0xE893B7: 0x5928,
  0xE894B2: 0x5929,
  0xE893BA: 0x592A,
  0xE893BB: 0x592B,
  0xE893BD: 0x592C,
  0xE89482: 0x592D,
  0xE89483: 0x592E,
  0xE89487: 0x592F,
  0xE8948C: 0x5930,
  0xE8948E: 0x5931,
  0xE89490: 0x5932,
  0xE8949C: 0x5933,
  0xE8949E: 0x5934,
  0xE894A2: 0x5935,
  0xE894A3: 0x5936,
  0xE894A4: 0x5937,
  0xE894A5: 0x5938,
  0xE894A7: 0x5939,
  0xE894AA: 0x593A,
  0xE894AB: 0x593B,
  0xE894AF: 0x593C,
  0xE894B3: 0x593D,
  0xE894B4: 0x593E,
  0xE894B6: 0x593F,
  0xE894BF: 0x5940,
  0xE89586: 0x5941,
  0xE8958F: 0x5942,
  0xE89590: 0x5943,
  0xE89591: 0x5944,
  0xE89592: 0x5945,
  0xE89593: 0x5946,
  0xE89596: 0x5947,
  0xE89599: 0x5948,
  0xE8959C: 0x5949,
  0xE8959D: 0x594A,
  0xE8959E: 0x594B,
  0xE8959F: 0x594C,
  0xE895A0: 0x594D,
  0xE895A1: 0x594E,
  0xE895A2: 0x594F,
  0xE895A4: 0x5950,
  0xE895AB: 0x5951,
  0xE895AF: 0x5952,
  0xE895B9: 0x5953,
  0xE895BA: 0x5954,
  0xE895BB: 0x5955,
  0xE895BD: 0x5956,
  0xE895BF: 0x5957,
  0xE89681: 0x5958,
  0xE89685: 0x5959,
  0xE89686: 0x595A,
  0xE89689: 0x595B,
  0xE8968B: 0x595C,
  0xE8968C: 0x595D,
  0xE8968F: 0x595E,
  0xE89693: 0x595F,
  0xE89698: 0x5960,
  0xE8969D: 0x5961,
  0xE8969F: 0x5962,
  0xE896A0: 0x5963,
  0xE896A2: 0x5964,
  0xE896A5: 0x5965,
  0xE896A7: 0x5966,
  0xE896B4: 0x5967,
  0xE896B6: 0x5968,
  0xE896B7: 0x5969,
  0xE896B8: 0x596A,
  0xE896BC: 0x596B,
  0xE896BD: 0x596C,
  0xE896BE: 0x596D,
  0xE896BF: 0x596E,
  0xE89782: 0x596F,
  0xE89787: 0x5970,
  0xE8978A: 0x5971,
  0xE8978B: 0x5972,
  0xE8978E: 0x5973,
  0xE896AD: 0x5974,
  0xE89798: 0x5975,
  0xE8979A: 0x5976,
  0xE8979F: 0x5977,
  0xE897A0: 0x5978,
  0xE897A6: 0x5979,
  0xE897A8: 0x597A,
  0xE897AD: 0x597B,
  0xE897B3: 0x597C,
  0xE897B6: 0x597D,
  0xE897BC: 0x597E,
  0xE897BF: 0x5A21,
  0xE89880: 0x5A22,
  0xE89884: 0x5A23,
  0xE89885: 0x5A24,
  0xE8988D: 0x5A25,
  0xE8988E: 0x5A26,
  0xE89890: 0x5A27,
  0xE89891: 0x5A28,
  0xE89892: 0x5A29,
  0xE89898: 0x5A2A,
  0xE89899: 0x5A2B,
  0xE8989B: 0x5A2C,
  0xE8989E: 0x5A2D,
  0xE898A1: 0x5A2E,
  0xE898A7: 0x5A2F,
  0xE898A9: 0x5A30,
  0xE898B6: 0x5A31,
  0xE898B8: 0x5A32,
  0xE898BA: 0x5A33,
  0xE898BC: 0x5A34,
  0xE898BD: 0x5A35,
  0xE89980: 0x5A36,
  0xE89982: 0x5A37,
  0xE89986: 0x5A38,
  0xE89992: 0x5A39,
  0xE89993: 0x5A3A,
  0xE89996: 0x5A3B,
  0xE89997: 0x5A3C,
  0xE89998: 0x5A3D,
  0xE89999: 0x5A3E,
  0xE8999D: 0x5A3F,
  0xE899A0: 0x5A40,
  0xE899A1: 0x5A41,
  0xE899A2: 0x5A42,
  0xE899A3: 0x5A43,
  0xE899A4: 0x5A44,
  0xE899A9: 0x5A45,
  0xE899AC: 0x5A46,
  0xE899AF: 0x5A47,
  0xE899B5: 0x5A48,
  0xE899B6: 0x5A49,
  0xE899B7: 0x5A4A,
  0xE899BA: 0x5A4B,
  0xE89A8D: 0x5A4C,
  0xE89A91: 0x5A4D,
  0xE89A96: 0x5A4E,
  0xE89A98: 0x5A4F,
  0xE89A9A: 0x5A50,
  0xE89A9C: 0x5A51,
  0xE89AA1: 0x5A52,
  0xE89AA6: 0x5A53,
  0xE89AA7: 0x5A54,
  0xE89AA8: 0x5A55,
  0xE89AAD: 0x5A56,
  0xE89AB1: 0x5A57,
  0xE89AB3: 0x5A58,
  0xE89AB4: 0x5A59,
  0xE89AB5: 0x5A5A,
  0xE89AB7: 0x5A5B,
  0xE89AB8: 0x5A5C,
  0xE89AB9: 0x5A5D,
  0xE89ABF: 0x5A5E,
  0xE89B80: 0x5A5F,
  0xE89B81: 0x5A60,
  0xE89B83: 0x5A61,
  0xE89B85: 0x5A62,
  0xE89B91: 0x5A63,
  0xE89B92: 0x5A64,
  0xE89B95: 0x5A65,
  0xE89B97: 0x5A66,
  0xE89B9A: 0x5A67,
  0xE89B9C: 0x5A68,
  0xE89BA0: 0x5A69,
  0xE89BA3: 0x5A6A,
  0xE89BA5: 0x5A6B,
  0xE89BA7: 0x5A6C,
  0xE89A88: 0x5A6D,
  0xE89BBA: 0x5A6E,
  0xE89BBC: 0x5A6F,
  0xE89BBD: 0x5A70,
  0xE89C84: 0x5A71,
  0xE89C85: 0x5A72,
  0xE89C87: 0x5A73,
  0xE89C8B: 0x5A74,
  0xE89C8E: 0x5A75,
  0xE89C8F: 0x5A76,
  0xE89C90: 0x5A77,
  0xE89C93: 0x5A78,
  0xE89C94: 0x5A79,
  0xE89C99: 0x5A7A,
  0xE89C9E: 0x5A7B,
  0xE89C9F: 0x5A7C,
  0xE89CA1: 0x5A7D,
  0xE89CA3: 0x5A7E,
  0xE89CA8: 0x5B21,
  0xE89CAE: 0x5B22,
  0xE89CAF: 0x5B23,
  0xE89CB1: 0x5B24,
  0xE89CB2: 0x5B25,
  0xE89CB9: 0x5B26,
  0xE89CBA: 0x5B27,
  0xE89CBC: 0x5B28,
  0xE89CBD: 0x5B29,
  0xE89CBE: 0x5B2A,
  0xE89D80: 0x5B2B,
  0xE89D83: 0x5B2C,
  0xE89D85: 0x5B2D,
  0xE89D8D: 0x5B2E,
  0xE89D98: 0x5B2F,
  0xE89D9D: 0x5B30,
  0xE89DA1: 0x5B31,
  0xE89DA4: 0x5B32,
  0xE89DA5: 0x5B33,
  0xE89DAF: 0x5B34,
  0xE89DB1: 0x5B35,
  0xE89DB2: 0x5B36,
  0xE89DBB: 0x5B37,
  0xE89E83: 0x5B38,
  0xE89E84: 0x5B39,
  0xE89E85: 0x5B3A,
  0xE89E86: 0x5B3B,
  0xE89E87: 0x5B3C,
  0xE89E88: 0x5B3D,
  0xE89E89: 0x5B3E,
  0xE89E8B: 0x5B3F,
  0xE89E8C: 0x5B40,
  0xE89E90: 0x5B41,
  0xE89E93: 0x5B42,
  0xE89E95: 0x5B43,
  0xE89E97: 0x5B44,
  0xE89E98: 0x5B45,
  0xE89E99: 0x5B46,
  0xE89E9E: 0x5B47,
  0xE89EA0: 0x5B48,
  0xE89EA3: 0x5B49,
  0xE89EA7: 0x5B4A,
  0xE89EAC: 0x5B4B,
  0xE89EAD: 0x5B4C,
  0xE89EAE: 0x5B4D,
  0xE89EB1: 0x5B4E,
  0xE89EB5: 0x5B4F,
  0xE89EBE: 0x5B50,
  0xE89EBF: 0x5B51,
  0xE89F81: 0x5B52,
  0xE89F88: 0x5B53,
  0xE89F89: 0x5B54,
  0xE89F8A: 0x5B55,
  0xE89F8E: 0x5B56,
  0xE89F95: 0x5B57,
  0xE89F96: 0x5B58,
  0xE89F99: 0x5B59,
  0xE89F9A: 0x5B5A,
  0xE89F9C: 0x5B5B,
  0xE89F9F: 0x5B5C,
  0xE89FA2: 0x5B5D,
  0xE89FA3: 0x5B5E,
  0xE89FA4: 0x5B5F,
  0xE89FAA: 0x5B60,
  0xE89FAB: 0x5B61,
  0xE89FAD: 0x5B62,
  0xE89FB1: 0x5B63,
  0xE89FB3: 0x5B64,
  0xE89FB8: 0x5B65,
  0xE89FBA: 0x5B66,
  0xE89FBF: 0x5B67,
  0xE8A081: 0x5B68,
  0xE8A083: 0x5B69,
  0xE8A086: 0x5B6A,
  0xE8A089: 0x5B6B,
  0xE8A08A: 0x5B6C,
  0xE8A08B: 0x5B6D,
  0xE8A090: 0x5B6E,
  0xE8A099: 0x5B6F,
  0xE8A092: 0x5B70,
  0xE8A093: 0x5B71,
  0xE8A094: 0x5B72,
  0xE8A098: 0x5B73,
  0xE8A09A: 0x5B74,
  0xE8A09B: 0x5B75,
  0xE8A09C: 0x5B76,
  0xE8A09E: 0x5B77,
  0xE8A09F: 0x5B78,
  0xE8A0A8: 0x5B79,
  0xE8A0AD: 0x5B7A,
  0xE8A0AE: 0x5B7B,
  0xE8A0B0: 0x5B7C,
  0xE8A0B2: 0x5B7D,
  0xE8A0B5: 0x5B7E,
  0xE8A0BA: 0x5C21,
  0xE8A0BC: 0x5C22,
  0xE8A181: 0x5C23,
  0xE8A183: 0x5C24,
  0xE8A185: 0x5C25,
  0xE8A188: 0x5C26,
  0xE8A189: 0x5C27,
  0xE8A18A: 0x5C28,
  0xE8A18B: 0x5C29,
  0xE8A18E: 0x5C2A,
  0xE8A191: 0x5C2B,
  0xE8A195: 0x5C2C,
  0xE8A196: 0x5C2D,
  0xE8A198: 0x5C2E,
  0xE8A19A: 0x5C2F,
  0xE8A19C: 0x5C30,
  0xE8A19F: 0x5C31,
  0xE8A1A0: 0x5C32,
  0xE8A1A4: 0x5C33,
  0xE8A1A9: 0x5C34,
  0xE8A1B1: 0x5C35,
  0xE8A1B9: 0x5C36,
  0xE8A1BB: 0x5C37,
  0xE8A280: 0x5C38,
  0xE8A298: 0x5C39,
  0xE8A29A: 0x5C3A,
  0xE8A29B: 0x5C3B,
  0xE8A29C: 0x5C3C,
  0xE8A29F: 0x5C3D,
  0xE8A2A0: 0x5C3E,
  0xE8A2A8: 0x5C3F,
  0xE8A2AA: 0x5C40,
  0xE8A2BA: 0x5C41,
  0xE8A2BD: 0x5C42,
  0xE8A2BE: 0x5C43,
  0xE8A380: 0x5C44,
  0xE8A38A: 0x5C45,
  0xE8A38B: 0x5C46,
  0xE8A38C: 0x5C47,
  0xE8A38D: 0x5C48,
  0xE8A38E: 0x5C49,
  0xE8A391: 0x5C4A,
  0xE8A392: 0x5C4B,
  0xE8A393: 0x5C4C,
  0xE8A39B: 0x5C4D,
  0xE8A39E: 0x5C4E,
  0xE8A3A7: 0x5C4F,
  0xE8A3AF: 0x5C50,
  0xE8A3B0: 0x5C51,
  0xE8A3B1: 0x5C52,
  0xE8A3B5: 0x5C53,
  0xE8A3B7: 0x5C54,
  0xE8A481: 0x5C55,
  0xE8A486: 0x5C56,
  0xE8A48D: 0x5C57,
  0xE8A48E: 0x5C58,
  0xE8A48F: 0x5C59,
  0xE8A495: 0x5C5A,
  0xE8A496: 0x5C5B,
  0xE8A498: 0x5C5C,
  0xE8A499: 0x5C5D,
  0xE8A49A: 0x5C5E,
  0xE8A49C: 0x5C5F,
  0xE8A4A0: 0x5C60,
  0xE8A4A6: 0x5C61,
  0xE8A4A7: 0x5C62,
  0xE8A4A8: 0x5C63,
  0xE8A4B0: 0x5C64,
  0xE8A4B1: 0x5C65,
  0xE8A4B2: 0x5C66,
  0xE8A4B5: 0x5C67,
  0xE8A4B9: 0x5C68,
  0xE8A4BA: 0x5C69,
  0xE8A4BE: 0x5C6A,
  0xE8A580: 0x5C6B,
  0xE8A582: 0x5C6C,
  0xE8A585: 0x5C6D,
  0xE8A586: 0x5C6E,
  0xE8A589: 0x5C6F,
  0xE8A58F: 0x5C70,
  0xE8A592: 0x5C71,
  0xE8A597: 0x5C72,
  0xE8A59A: 0x5C73,
  0xE8A59B: 0x5C74,
  0xE8A59C: 0x5C75,
  0xE8A5A1: 0x5C76,
  0xE8A5A2: 0x5C77,
  0xE8A5A3: 0x5C78,
  0xE8A5AB: 0x5C79,
  0xE8A5AE: 0x5C7A,
  0xE8A5B0: 0x5C7B,
  0xE8A5B3: 0x5C7C,
  0xE8A5B5: 0x5C7D,
  0xE8A5BA: 0x5C7E,
  0xE8A5BB: 0x5D21,
  0xE8A5BC: 0x5D22,
  0xE8A5BD: 0x5D23,
  0xE8A689: 0x5D24,
  0xE8A68D: 0x5D25,
  0xE8A690: 0x5D26,
  0xE8A694: 0x5D27,
  0xE8A695: 0x5D28,
  0xE8A69B: 0x5D29,
  0xE8A69C: 0x5D2A,
  0xE8A69F: 0x5D2B,
  0xE8A6A0: 0x5D2C,
  0xE8A6A5: 0x5D2D,
  0xE8A6B0: 0x5D2E,
  0xE8A6B4: 0x5D2F,
  0xE8A6B5: 0x5D30,
  0xE8A6B6: 0x5D31,
  0xE8A6B7: 0x5D32,
  0xE8A6BC: 0x5D33,
  0xE8A794: 0x5D34,
  0xE8A795: 0x5D35,
  0xE8A796: 0x5D36,
  0xE8A797: 0x5D37,
  0xE8A798: 0x5D38,
  0xE8A7A5: 0x5D39,
  0xE8A7A9: 0x5D3A,
  0xE8A7AB: 0x5D3B,
  0xE8A7AD: 0x5D3C,
  0xE8A7B1: 0x5D3D,
  0xE8A7B3: 0x5D3E,
  0xE8A7B6: 0x5D3F,
  0xE8A7B9: 0x5D40,
  0xE8A7BD: 0x5D41,
  0xE8A7BF: 0x5D42,
  0xE8A884: 0x5D43,
  0xE8A885: 0x5D44,
  0xE8A887: 0x5D45,
  0xE8A88F: 0x5D46,
  0xE8A891: 0x5D47,
  0xE8A892: 0x5D48,
  0xE8A894: 0x5D49,
  0xE8A895: 0x5D4A,
  0xE8A89E: 0x5D4B,
  0xE8A8A0: 0x5D4C,
  0xE8A8A2: 0x5D4D,
  0xE8A8A4: 0x5D4E,
  0xE8A8A6: 0x5D4F,
  0xE8A8AB: 0x5D50,
  0xE8A8AC: 0x5D51,
  0xE8A8AF: 0x5D52,
  0xE8A8B5: 0x5D53,
  0xE8A8B7: 0x5D54,
  0xE8A8BD: 0x5D55,
  0xE8A8BE: 0x5D56,
  0xE8A980: 0x5D57,
  0xE8A983: 0x5D58,
  0xE8A985: 0x5D59,
  0xE8A987: 0x5D5A,
  0xE8A989: 0x5D5B,
  0xE8A98D: 0x5D5C,
  0xE8A98E: 0x5D5D,
  0xE8A993: 0x5D5E,
  0xE8A996: 0x5D5F,
  0xE8A997: 0x5D60,
  0xE8A998: 0x5D61,
  0xE8A99C: 0x5D62,
  0xE8A99D: 0x5D63,
  0xE8A9A1: 0x5D64,
  0xE8A9A5: 0x5D65,
  0xE8A9A7: 0x5D66,
  0xE8A9B5: 0x5D67,
  0xE8A9B6: 0x5D68,
  0xE8A9B7: 0x5D69,
  0xE8A9B9: 0x5D6A,
  0xE8A9BA: 0x5D6B,
  0xE8A9BB: 0x5D6C,
  0xE8A9BE: 0x5D6D,
  0xE8A9BF: 0x5D6E,
  0xE8AA80: 0x5D6F,
  0xE8AA83: 0x5D70,
  0xE8AA86: 0x5D71,
  0xE8AA8B: 0x5D72,
  0xE8AA8F: 0x5D73,
  0xE8AA90: 0x5D74,
  0xE8AA92: 0x5D75,
  0xE8AA96: 0x5D76,
  0xE8AA97: 0x5D77,
  0xE8AA99: 0x5D78,
  0xE8AA9F: 0x5D79,
  0xE8AAA7: 0x5D7A,
  0xE8AAA9: 0x5D7B,
  0xE8AAAE: 0x5D7C,
  0xE8AAAF: 0x5D7D,
  0xE8AAB3: 0x5D7E,
  0xE8AAB6: 0x5E21,
  0xE8AAB7: 0x5E22,
  0xE8AABB: 0x5E23,
  0xE8AABE: 0x5E24,
  0xE8AB83: 0x5E25,
  0xE8AB86: 0x5E26,
  0xE8AB88: 0x5E27,
  0xE8AB89: 0x5E28,
  0xE8AB8A: 0x5E29,
  0xE8AB91: 0x5E2A,
  0xE8AB93: 0x5E2B,
  0xE8AB94: 0x5E2C,
  0xE8AB95: 0x5E2D,
  0xE8AB97: 0x5E2E,
  0xE8AB9D: 0x5E2F,
  0xE8AB9F: 0x5E30,
  0xE8ABAC: 0x5E31,
  0xE8ABB0: 0x5E32,
  0xE8ABB4: 0x5E33,
  0xE8ABB5: 0x5E34,
  0xE8ABB6: 0x5E35,
  0xE8ABBC: 0x5E36,
  0xE8ABBF: 0x5E37,
  0xE8AC85: 0x5E38,
  0xE8AC86: 0x5E39,
  0xE8AC8B: 0x5E3A,
  0xE8AC91: 0x5E3B,
  0xE8AC9C: 0x5E3C,
  0xE8AC9E: 0x5E3D,
  0xE8AC9F: 0x5E3E,
  0xE8AC8A: 0x5E3F,
  0xE8ACAD: 0x5E40,
  0xE8ACB0: 0x5E41,
  0xE8ACB7: 0x5E42,
  0xE8ACBC: 0x5E43,
  0xE8AD82: 0x5E44,
  0xE8AD83: 0x5E45,
  0xE8AD84: 0x5E46,
  0xE8AD85: 0x5E47,
  0xE8AD86: 0x5E48,
  0xE8AD88: 0x5E49,
  0xE8AD92: 0x5E4A,
  0xE8AD93: 0x5E4B,
  0xE8AD94: 0x5E4C,
  0xE8AD99: 0x5E4D,
  0xE8AD8D: 0x5E4E,
  0xE8AD9E: 0x5E4F,
  0xE8ADA3: 0x5E50,
  0xE8ADAD: 0x5E51,
  0xE8ADB6: 0x5E52,
  0xE8ADB8: 0x5E53,
  0xE8ADB9: 0x5E54,
  0xE8ADBC: 0x5E55,
  0xE8ADBE: 0x5E56,
  0xE8AE81: 0x5E57,
  0xE8AE84: 0x5E58,
  0xE8AE85: 0x5E59,
  0xE8AE8B: 0x5E5A,
  0xE8AE8D: 0x5E5B,
  0xE8AE8F: 0x5E5C,
  0xE8AE94: 0x5E5D,
  0xE8AE95: 0x5E5E,
  0xE8AE9C: 0x5E5F,
  0xE8AE9E: 0x5E60,
  0xE8AE9F: 0x5E61,
  0xE8B0B8: 0x5E62,
  0xE8B0B9: 0x5E63,
  0xE8B0BD: 0x5E64,
  0xE8B0BE: 0x5E65,
  0xE8B185: 0x5E66,
  0xE8B187: 0x5E67,
  0xE8B189: 0x5E68,
  0xE8B18B: 0x5E69,
  0xE8B18F: 0x5E6A,
  0xE8B191: 0x5E6B,
  0xE8B193: 0x5E6C,
  0xE8B194: 0x5E6D,
  0xE8B197: 0x5E6E,
  0xE8B198: 0x5E6F,
  0xE8B19B: 0x5E70,
  0xE8B19D: 0x5E71,
  0xE8B199: 0x5E72,
  0xE8B1A3: 0x5E73,
  0xE8B1A4: 0x5E74,
  0xE8B1A6: 0x5E75,
  0xE8B1A8: 0x5E76,
  0xE8B1A9: 0x5E77,
  0xE8B1AD: 0x5E78,
  0xE8B1B3: 0x5E79,
  0xE8B1B5: 0x5E7A,
  0xE8B1B6: 0x5E7B,
  0xE8B1BB: 0x5E7C,
  0xE8B1BE: 0x5E7D,
  0xE8B286: 0x5E7E,
  0xE8B287: 0x5F21,
  0xE8B28B: 0x5F22,
  0xE8B290: 0x5F23,
  0xE8B292: 0x5F24,
  0xE8B293: 0x5F25,
  0xE8B299: 0x5F26,
  0xE8B29B: 0x5F27,
  0xE8B29C: 0x5F28,
  0xE8B2A4: 0x5F29,
  0xE8B2B9: 0x5F2A,
  0xE8B2BA: 0x5F2B,
  0xE8B385: 0x5F2C,
  0xE8B386: 0x5F2D,
  0xE8B389: 0x5F2E,
  0xE8B38B: 0x5F2F,
  0xE8B38F: 0x5F30,
  0xE8B396: 0x5F31,
  0xE8B395: 0x5F32,
  0xE8B399: 0x5F33,
  0xE8B39D: 0x5F34,
  0xE8B3A1: 0x5F35,
  0xE8B3A8: 0x5F36,
  0xE8B3AC: 0x5F37,
  0xE8B3AF: 0x5F38,
  0xE8B3B0: 0x5F39,
  0xE8B3B2: 0x5F3A,
  0xE8B3B5: 0x5F3B,
  0xE8B3B7: 0x5F3C,
  0xE8B3B8: 0x5F3D,
  0xE8B3BE: 0x5F3E,
  0xE8B3BF: 0x5F3F,
  0xE8B481: 0x5F40,
  0xE8B483: 0x5F41,
  0xE8B489: 0x5F42,
  0xE8B492: 0x5F43,
  0xE8B497: 0x5F44,
  0xE8B49B: 0x5F45,
  0xE8B5A5: 0x5F46,
  0xE8B5A9: 0x5F47,
  0xE8B5AC: 0x5F48,
  0xE8B5AE: 0x5F49,
  0xE8B5BF: 0x5F4A,
  0xE8B682: 0x5F4B,
  0xE8B684: 0x5F4C,
  0xE8B688: 0x5F4D,
  0xE8B68D: 0x5F4E,
  0xE8B690: 0x5F4F,
  0xE8B691: 0x5F50,
  0xE8B695: 0x5F51,
  0xE8B69E: 0x5F52,
  0xE8B69F: 0x5F53,
  0xE8B6A0: 0x5F54,
  0xE8B6A6: 0x5F55,
  0xE8B6AB: 0x5F56,
  0xE8B6AC: 0x5F57,
  0xE8B6AF: 0x5F58,
  0xE8B6B2: 0x5F59,
  0xE8B6B5: 0x5F5A,
  0xE8B6B7: 0x5F5B,
  0xE8B6B9: 0x5F5C,
  0xE8B6BB: 0x5F5D,
  0xE8B780: 0x5F5E,
  0xE8B785: 0x5F5F,
  0xE8B786: 0x5F60,
  0xE8B787: 0x5F61,
  0xE8B788: 0x5F62,
  0xE8B78A: 0x5F63,
  0xE8B78E: 0x5F64,
  0xE8B791: 0x5F65,
  0xE8B794: 0x5F66,
  0xE8B795: 0x5F67,
  0xE8B797: 0x5F68,
  0xE8B799: 0x5F69,
  0xE8B7A4: 0x5F6A,
  0xE8B7A5: 0x5F6B,
  0xE8B7A7: 0x5F6C,
  0xE8B7AC: 0x5F6D,
  0xE8B7B0: 0x5F6E,
  0xE8B6BC: 0x5F6F,
  0xE8B7B1: 0x5F70,
  0xE8B7B2: 0x5F71,
  0xE8B7B4: 0x5F72,
  0xE8B7BD: 0x5F73,
  0xE8B881: 0x5F74,
  0xE8B884: 0x5F75,
  0xE8B885: 0x5F76,
  0xE8B886: 0x5F77,
  0xE8B88B: 0x5F78,
  0xE8B891: 0x5F79,
  0xE8B894: 0x5F7A,
  0xE8B896: 0x5F7B,
  0xE8B8A0: 0x5F7C,
  0xE8B8A1: 0x5F7D,
  0xE8B8A2: 0x5F7E,
  0xE8B8A3: 0x6021,
  0xE8B8A6: 0x6022,
  0xE8B8A7: 0x6023,
  0xE8B8B1: 0x6024,
  0xE8B8B3: 0x6025,
  0xE8B8B6: 0x6026,
  0xE8B8B7: 0x6027,
  0xE8B8B8: 0x6028,
  0xE8B8B9: 0x6029,
  0xE8B8BD: 0x602A,
  0xE8B980: 0x602B,
  0xE8B981: 0x602C,
  0xE8B98B: 0x602D,
  0xE8B98D: 0x602E,
  0xE8B98E: 0x602F,
  0xE8B98F: 0x6030,
  0xE8B994: 0x6031,
  0xE8B99B: 0x6032,
  0xE8B99C: 0x6033,
  0xE8B99D: 0x6034,
  0xE8B99E: 0x6035,
  0xE8B9A1: 0x6036,
  0xE8B9A2: 0x6037,
  0xE8B9A9: 0x6038,
  0xE8B9AC: 0x6039,
  0xE8B9AD: 0x603A,
  0xE8B9AF: 0x603B,
  0xE8B9B0: 0x603C,
  0xE8B9B1: 0x603D,
  0xE8B9B9: 0x603E,
  0xE8B9BA: 0x603F,
  0xE8B9BB: 0x6040,
  0xE8BA82: 0x6041,
  0xE8BA83: 0x6042,
  0xE8BA89: 0x6043,
  0xE8BA90: 0x6044,
  0xE8BA92: 0x6045,
  0xE8BA95: 0x6046,
  0xE8BA9A: 0x6047,
  0xE8BA9B: 0x6048,
  0xE8BA9D: 0x6049,
  0xE8BA9E: 0x604A,
  0xE8BAA2: 0x604B,
  0xE8BAA7: 0x604C,
  0xE8BAA9: 0x604D,
  0xE8BAAD: 0x604E,
  0xE8BAAE: 0x604F,
  0xE8BAB3: 0x6050,
  0xE8BAB5: 0x6051,
  0xE8BABA: 0x6052,
  0xE8BABB: 0x6053,
  0xE8BB80: 0x6054,
  0xE8BB81: 0x6055,
  0xE8BB83: 0x6056,
  0xE8BB84: 0x6057,
  0xE8BB87: 0x6058,
  0xE8BB8F: 0x6059,
  0xE8BB91: 0x605A,
  0xE8BB94: 0x605B,
  0xE8BB9C: 0x605C,
  0xE8BBA8: 0x605D,
  0xE8BBAE: 0x605E,
  0xE8BBB0: 0x605F,
  0xE8BBB1: 0x6060,
  0xE8BBB7: 0x6061,
  0xE8BBB9: 0x6062,
  0xE8BBBA: 0x6063,
  0xE8BBAD: 0x6064,
  0xE8BC80: 0x6065,
  0xE8BC82: 0x6066,
  0xE8BC87: 0x6067,
  0xE8BC88: 0x6068,
  0xE8BC8F: 0x6069,
  0xE8BC90: 0x606A,
  0xE8BC96: 0x606B,
  0xE8BC97: 0x606C,
  0xE8BC98: 0x606D,
  0xE8BC9E: 0x606E,
  0xE8BCA0: 0x606F,
  0xE8BCA1: 0x6070,
  0xE8BCA3: 0x6071,
  0xE8BCA5: 0x6072,
  0xE8BCA7: 0x6073,
  0xE8BCA8: 0x6074,
  0xE8BCAC: 0x6075,
  0xE8BCAD: 0x6076,
  0xE8BCAE: 0x6077,
  0xE8BCB4: 0x6078,
  0xE8BCB5: 0x6079,
  0xE8BCB6: 0x607A,
  0xE8BCB7: 0x607B,
  0xE8BCBA: 0x607C,
  0xE8BD80: 0x607D,
  0xE8BD81: 0x607E,
  0xE8BD83: 0x6121,
  0xE8BD87: 0x6122,
  0xE8BD8F: 0x6123,
  0xE8BD91: 0x6124,
  0xE8BD92: 0x6125,
  0xE8BD93: 0x6126,
  0xE8BD94: 0x6127,
  0xE8BD95: 0x6128,
  0xE8BD98: 0x6129,
  0xE8BD9D: 0x612A,
  0xE8BD9E: 0x612B,
  0xE8BDA5: 0x612C,
  0xE8BE9D: 0x612D,
  0xE8BEA0: 0x612E,
  0xE8BEA1: 0x612F,
  0xE8BEA4: 0x6130,
  0xE8BEA5: 0x6131,
  0xE8BEA6: 0x6132,
  0xE8BEB5: 0x6133,
  0xE8BEB6: 0x6134,
  0xE8BEB8: 0x6135,
  0xE8BEBE: 0x6136,
  0xE8BF80: 0x6137,
  0xE8BF81: 0x6138,
  0xE8BF86: 0x6139,
  0xE8BF8A: 0x613A,
  0xE8BF8B: 0x613B,
  0xE8BF8D: 0x613C,
  0xE8BF90: 0x613D,
  0xE8BF92: 0x613E,
  0xE8BF93: 0x613F,
  0xE8BF95: 0x6140,
  0xE8BFA0: 0x6141,
  0xE8BFA3: 0x6142,
  0xE8BFA4: 0x6143,
  0xE8BFA8: 0x6144,
  0xE8BFAE: 0x6145,
  0xE8BFB1: 0x6146,
  0xE8BFB5: 0x6147,
  0xE8BFB6: 0x6148,
  0xE8BFBB: 0x6149,
  0xE8BFBE: 0x614A,
  0xE98082: 0x614B,
  0xE98084: 0x614C,
  0xE98088: 0x614D,
  0xE9808C: 0x614E,
  0xE98098: 0x614F,
  0xE9809B: 0x6150,
  0xE980A8: 0x6151,
  0xE980A9: 0x6152,
  0xE980AF: 0x6153,
  0xE980AA: 0x6154,
  0xE980AC: 0x6155,
  0xE980AD: 0x6156,
  0xE980B3: 0x6157,
  0xE980B4: 0x6158,
  0xE980B7: 0x6159,
  0xE980BF: 0x615A,
  0xE98183: 0x615B,
  0xE98184: 0x615C,
  0xE9818C: 0x615D,
  0xE9819B: 0x615E,
  0xE9819D: 0x615F,
  0xE981A2: 0x6160,
  0xE981A6: 0x6161,
  0xE981A7: 0x6162,
  0xE981AC: 0x6163,
  0xE981B0: 0x6164,
  0xE981B4: 0x6165,
  0xE981B9: 0x6166,
  0xE98285: 0x6167,
  0xE98288: 0x6168,
  0xE9828B: 0x6169,
  0xE9828C: 0x616A,
  0xE9828E: 0x616B,
  0xE98290: 0x616C,
  0xE98295: 0x616D,
  0xE98297: 0x616E,
  0xE98298: 0x616F,
  0xE98299: 0x6170,
  0xE9829B: 0x6171,
  0xE982A0: 0x6172,
  0xE982A1: 0x6173,
  0xE982A2: 0x6174,
  0xE982A5: 0x6175,
  0xE982B0: 0x6176,
  0xE982B2: 0x6177,
  0xE982B3: 0x6178,
  0xE982B4: 0x6179,
  0xE982B6: 0x617A,
  0xE982BD: 0x617B,
  0xE9838C: 0x617C,
  0xE982BE: 0x617D,
  0xE98383: 0x617E,
  0xE98384: 0x6221,
  0xE98385: 0x6222,
  0xE98387: 0x6223,
  0xE98388: 0x6224,
  0xE98395: 0x6225,
  0xE98397: 0x6226,
  0xE98398: 0x6227,
  0xE98399: 0x6228,
  0xE9839C: 0x6229,
  0xE9839D: 0x622A,
  0xE9839F: 0x622B,
  0xE983A5: 0x622C,
  0xE98392: 0x622D,
  0xE983B6: 0x622E,
  0xE983AB: 0x622F,
  0xE983AF: 0x6230,
  0xE983B0: 0x6231,
  0xE983B4: 0x6232,
  0xE983BE: 0x6233,
  0xE983BF: 0x6234,
  0xE98480: 0x6235,
  0xE98484: 0x6236,
  0xE98485: 0x6237,
  0xE98486: 0x6238,
  0xE98488: 0x6239,
  0xE9848D: 0x623A,
  0xE98490: 0x623B,
  0xE98494: 0x623C,
  0xE98496: 0x623D,
  0xE98497: 0x623E,
  0xE98498: 0x623F,
  0xE9849A: 0x6240,
  0xE9849C: 0x6241,
  0xE9849E: 0x6242,
  0xE984A0: 0x6243,
  0xE984A5: 0x6244,
  0xE984A2: 0x6245,
  0xE984A3: 0x6246,
  0xE984A7: 0x6247,
  0xE984A9: 0x6248,
  0xE984AE: 0x6249,
  0xE984AF: 0x624A,
  0xE984B1: 0x624B,
  0xE984B4: 0x624C,
  0xE984B6: 0x624D,
  0xE984B7: 0x624E,
  0xE984B9: 0x624F,
  0xE984BA: 0x6250,
  0xE984BC: 0x6251,
  0xE984BD: 0x6252,
  0xE98583: 0x6253,
  0xE98587: 0x6254,
  0xE98588: 0x6255,
  0xE9858F: 0x6256,
  0xE98593: 0x6257,
  0xE98597: 0x6258,
  0xE98599: 0x6259,
  0xE9859A: 0x625A,
  0xE9859B: 0x625B,
  0xE985A1: 0x625C,
  0xE985A4: 0x625D,
  0xE985A7: 0x625E,
  0xE985AD: 0x625F,
  0xE985B4: 0x6260,
  0xE985B9: 0x6261,
  0xE985BA: 0x6262,
  0xE985BB: 0x6263,
  0xE98681: 0x6264,
  0xE98683: 0x6265,
  0xE98685: 0x6266,
  0xE98686: 0x6267,
  0xE9868A: 0x6268,
  0xE9868E: 0x6269,
  0xE98691: 0x626A,
  0xE98693: 0x626B,
  0xE98694: 0x626C,
  0xE98695: 0x626D,
  0xE98698: 0x626E,
  0xE9869E: 0x626F,
  0xE986A1: 0x6270,
  0xE986A6: 0x6271,
  0xE986A8: 0x6272,
  0xE986AC: 0x6273,
  0xE986AD: 0x6274,
  0xE986AE: 0x6275,
  0xE986B0: 0x6276,
  0xE986B1: 0x6277,
  0xE986B2: 0x6278,
  0xE986B3: 0x6279,
  0xE986B6: 0x627A,
  0xE986BB: 0x627B,
  0xE986BC: 0x627C,
  0xE986BD: 0x627D,
  0xE986BF: 0x627E,
  0xE98782: 0x6321,
  0xE98783: 0x6322,
  0xE98785: 0x6323,
  0xE98793: 0x6324,
  0xE98794: 0x6325,
  0xE98797: 0x6326,
  0xE98799: 0x6327,
  0xE9879A: 0x6328,
  0xE9879E: 0x6329,
  0xE987A4: 0x632A,
  0xE987A5: 0x632B,
  0xE987A9: 0x632C,
  0xE987AA: 0x632D,
  0xE987AC: 0x632E,
  0xE987AD: 0x632F,
  0xE987AE: 0x6330,
  0xE987AF: 0x6331,
  0xE987B0: 0x6332,
  0xE987B1: 0x6333,
  0xE987B7: 0x6334,
  0xE987B9: 0x6335,
  0xE987BB: 0x6336,
  0xE987BD: 0x6337,
  0xE98880: 0x6338,
  0xE98881: 0x6339,
  0xE98884: 0x633A,
  0xE98885: 0x633B,
  0xE98886: 0x633C,
  0xE98887: 0x633D,
  0xE98889: 0x633E,
  0xE9888A: 0x633F,
  0xE9888C: 0x6340,
  0xE98890: 0x6341,
  0xE98892: 0x6342,
  0xE98893: 0x6343,
  0xE98896: 0x6344,
  0xE98898: 0x6345,
  0xE9889C: 0x6346,
  0xE9889D: 0x6347,
  0xE988A3: 0x6348,
  0xE988A4: 0x6349,
  0xE988A5: 0x634A,
  0xE988A6: 0x634B,
  0xE988A8: 0x634C,
  0xE988AE: 0x634D,
  0xE988AF: 0x634E,
  0xE988B0: 0x634F,
  0xE988B3: 0x6350,
  0xE988B5: 0x6351,
  0xE988B6: 0x6352,
  0xE988B8: 0x6353,
  0xE988B9: 0x6354,
  0xE988BA: 0x6355,
  0xE988BC: 0x6356,
  0xE988BE: 0x6357,
  0xE98980: 0x6358,
  0xE98982: 0x6359,
  0xE98983: 0x635A,
  0xE98986: 0x635B,
  0xE98987: 0x635C,
  0xE9898A: 0x635D,
  0xE9898D: 0x635E,
  0xE9898E: 0x635F,
  0xE9898F: 0x6360,
  0xE98991: 0x6361,
  0xE98998: 0x6362,
  0xE98999: 0x6363,
  0xE9899C: 0x6364,
  0xE9899D: 0x6365,
  0xE989A0: 0x6366,
  0xE989A1: 0x6367,
  0xE989A5: 0x6368,
  0xE989A7: 0x6369,
  0xE989A8: 0x636A,
  0xE989A9: 0x636B,
  0xE989AE: 0x636C,
  0xE989AF: 0x636D,
  0xE989B0: 0x636E,
  0xE989B5: 0x636F,
  0xE989B6: 0x6370,
  0xE989B7: 0x6371,
  0xE989B8: 0x6372,
  0xE989B9: 0x6373,
  0xE989BB: 0x6374,
  0xE989BC: 0x6375,
  0xE989BD: 0x6376,
  0xE989BF: 0x6377,
  0xE98A88: 0x6378,
  0xE98A89: 0x6379,
  0xE98A8A: 0x637A,
  0xE98A8D: 0x637B,
  0xE98A8E: 0x637C,
  0xE98A92: 0x637D,
  0xE98A97: 0x637E,
  0xE98A99: 0x6421,
  0xE98A9F: 0x6422,
  0xE98AA0: 0x6423,
  0xE98AA4: 0x6424,
  0xE98AA5: 0x6425,
  0xE98AA7: 0x6426,
  0xE98AA8: 0x6427,
  0xE98AAB: 0x6428,
  0xE98AAF: 0x6429,
  0xE98AB2: 0x642A,
  0xE98AB6: 0x642B,
  0xE98AB8: 0x642C,
  0xE98ABA: 0x642D,
  0xE98ABB: 0x642E,
  0xE98ABC: 0x642F,
  0xE98ABD: 0x6430,
  0xE98ABF: 0x6431,
  0xE98B80: 0x6432,
  0xE98B81: 0x6433,
  0xE98B82: 0x6434,
  0xE98B83: 0x6435,
  0xE98B85: 0x6436,
  0xE98B86: 0x6437,
  0xE98B87: 0x6438,
  0xE98B88: 0x6439,
  0xE98B8B: 0x643A,
  0xE98B8C: 0x643B,
  0xE98B8D: 0x643C,
  0xE98B8E: 0x643D,
  0xE98B90: 0x643E,
  0xE98B93: 0x643F,
  0xE98B95: 0x6440,
  0xE98B97: 0x6441,
  0xE98B98: 0x6442,
  0xE98B99: 0x6443,
  0xE98B9C: 0x6444,
  0xE98B9D: 0x6445,
  0xE98B9F: 0x6446,
  0xE98BA0: 0x6447,
  0xE98BA1: 0x6448,
  0xE98BA3: 0x6449,
  0xE98BA5: 0x644A,
  0xE98BA7: 0x644B,
  0xE98BA8: 0x644C,
  0xE98BAC: 0x644D,
  0xE98BAE: 0x644E,
  0xE98BB0: 0x644F,
  0xE98BB9: 0x6450,
  0xE98BBB: 0x6451,
  0xE98BBF: 0x6452,
  0xE98C80: 0x6453,
  0xE98C82: 0x6454,
  0xE98C88: 0x6455,
  0xE98C8D: 0x6456,
  0xE98C91: 0x6457,
  0xE98C94: 0x6458,
  0xE98C95: 0x6459,
  0xE98C9C: 0x645A,
  0xE98C9D: 0x645B,
  0xE98C9E: 0x645C,
  0xE98C9F: 0x645D,
  0xE98CA1: 0x645E,
  0xE98CA4: 0x645F,
  0xE98CA5: 0x6460,
  0xE98CA7: 0x6461,
  0xE98CA9: 0x6462,
  0xE98CAA: 0x6463,
  0xE98CB3: 0x6464,
  0xE98CB4: 0x6465,
  0xE98CB6: 0x6466,
  0xE98CB7: 0x6467,
  0xE98D87: 0x6468,
  0xE98D88: 0x6469,
  0xE98D89: 0x646A,
  0xE98D90: 0x646B,
  0xE98D91: 0x646C,
  0xE98D92: 0x646D,
  0xE98D95: 0x646E,
  0xE98D97: 0x646F,
  0xE98D98: 0x6470,
  0xE98D9A: 0x6471,
  0xE98D9E: 0x6472,
  0xE98DA4: 0x6473,
  0xE98DA5: 0x6474,
  0xE98DA7: 0x6475,
  0xE98DA9: 0x6476,
  0xE98DAA: 0x6477,
  0xE98DAD: 0x6478,
  0xE98DAF: 0x6479,
  0xE98DB0: 0x647A,
  0xE98DB1: 0x647B,
  0xE98DB3: 0x647C,
  0xE98DB4: 0x647D,
  0xE98DB6: 0x647E,
  0xE98DBA: 0x6521,
  0xE98DBD: 0x6522,
  0xE98DBF: 0x6523,
  0xE98E80: 0x6524,
  0xE98E81: 0x6525,
  0xE98E82: 0x6526,
  0xE98E88: 0x6527,
  0xE98E8A: 0x6528,
  0xE98E8B: 0x6529,
  0xE98E8D: 0x652A,
  0xE98E8F: 0x652B,
  0xE98E92: 0x652C,
  0xE98E95: 0x652D,
  0xE98E98: 0x652E,
  0xE98E9B: 0x652F,
  0xE98E9E: 0x6530,
  0xE98EA1: 0x6531,
  0xE98EA3: 0x6532,
  0xE98EA4: 0x6533,
  0xE98EA6: 0x6534,
  0xE98EA8: 0x6535,
  0xE98EAB: 0x6536,
  0xE98EB4: 0x6537,
  0xE98EB5: 0x6538,
  0xE98EB6: 0x6539,
  0xE98EBA: 0x653A,
  0xE98EA9: 0x653B,
  0xE98F81: 0x653C,
  0xE98F84: 0x653D,
  0xE98F85: 0x653E,
  0xE98F86: 0x653F,
  0xE98F87: 0x6540,
  0xE98F89: 0x6541,
  0xE98F8A: 0x6542,
  0xE98F8B: 0x6543,
  0xE98F8C: 0x6544,
  0xE98F8D: 0x6545,
  0xE98F93: 0x6546,
  0xE98F99: 0x6547,
  0xE98F9C: 0x6548,
  0xE98F9E: 0x6549,
  0xE98F9F: 0x654A,
  0xE98FA2: 0x654B,
  0xE98FA6: 0x654C,
  0xE98FA7: 0x654D,
  0xE98FB9: 0x654E,
  0xE98FB7: 0x654F,
  0xE98FB8: 0x6550,
  0xE98FBA: 0x6551,
  0xE98FBB: 0x6552,
  0xE98FBD: 0x6553,
  0xE99081: 0x6554,
  0xE99082: 0x6555,
  0xE99084: 0x6556,
  0xE99088: 0x6557,
  0xE99089: 0x6558,
  0xE9908D: 0x6559,
  0xE9908E: 0x655A,
  0xE9908F: 0x655B,
  0xE99095: 0x655C,
  0xE99096: 0x655D,
  0xE99097: 0x655E,
  0xE9909F: 0x655F,
  0xE990AE: 0x6560,
  0xE990AF: 0x6561,
  0xE990B1: 0x6562,
  0xE990B2: 0x6563,
  0xE990B3: 0x6564,
  0xE990B4: 0x6565,
  0xE990BB: 0x6566,
  0xE990BF: 0x6567,
  0xE990BD: 0x6568,
  0xE99183: 0x6569,
  0xE99185: 0x656A,
  0xE99188: 0x656B,
  0xE9918A: 0x656C,
  0xE9918C: 0x656D,
  0xE99195: 0x656E,
  0xE99199: 0x656F,
  0xE9919C: 0x6570,
  0xE9919F: 0x6571,
  0xE991A1: 0x6572,
  0xE991A3: 0x6573,
  0xE991A8: 0x6574,
  0xE991AB: 0x6575,
  0xE991AD: 0x6576,
  0xE991AE: 0x6577,
  0xE991AF: 0x6578,
  0xE991B1: 0x6579,
  0xE991B2: 0x657A,
  0xE99284: 0x657B,
  0xE99283: 0x657C,
  0xE995B8: 0x657D,
  0xE995B9: 0x657E,
  0xE995BE: 0x6621,
  0xE99684: 0x6622,
  0xE99688: 0x6623,
  0xE9968C: 0x6624,
  0xE9968D: 0x6625,
  0xE9968E: 0x6626,
  0xE9969D: 0x6627,
  0xE9969E: 0x6628,
  0xE9969F: 0x6629,
  0xE996A1: 0x662A,
  0xE996A6: 0x662B,
  0xE996A9: 0x662C,
  0xE996AB: 0x662D,
  0xE996AC: 0x662E,
  0xE996B4: 0x662F,
  0xE996B6: 0x6630,
  0xE996BA: 0x6631,
  0xE996BD: 0x6632,
  0xE996BF: 0x6633,
  0xE99786: 0x6634,
  0xE99788: 0x6635,
  0xE99789: 0x6636,
  0xE9978B: 0x6637,
  0xE99790: 0x6638,
  0xE99791: 0x6639,
  0xE99792: 0x663A,
  0xE99793: 0x663B,
  0xE99799: 0x663C,
  0xE9979A: 0x663D,
  0xE9979D: 0x663E,
  0xE9979E: 0x663F,
  0xE9979F: 0x6640,
  0xE997A0: 0x6641,
  0xE997A4: 0x6642,
  0xE997A6: 0x6643,
  0xE9989D: 0x6644,
  0xE9989E: 0x6645,
  0xE998A2: 0x6646,
  0xE998A4: 0x6647,
  0xE998A5: 0x6648,
  0xE998A6: 0x6649,
  0xE998AC: 0x664A,
  0xE998B1: 0x664B,
  0xE998B3: 0x664C,
  0xE998B7: 0x664D,
  0xE998B8: 0x664E,
  0xE998B9: 0x664F,
  0xE998BA: 0x6650,
  0xE998BC: 0x6651,
  0xE998BD: 0x6652,
  0xE99981: 0x6653,
  0xE99992: 0x6654,
  0xE99994: 0x6655,
  0xE99996: 0x6656,
  0xE99997: 0x6657,
  0xE99998: 0x6658,
  0xE999A1: 0x6659,
  0xE999AE: 0x665A,
  0xE999B4: 0x665B,
  0xE999BB: 0x665C,
  0xE999BC: 0x665D,
  0xE999BE: 0x665E,
  0xE999BF: 0x665F,
  0xE99A81: 0x6660,
  0xE99A82: 0x6661,
  0xE99A83: 0x6662,
  0xE99A84: 0x6663,
  0xE99A89: 0x6664,
  0xE99A91: 0x6665,
  0xE99A96: 0x6666,
  0xE99A9A: 0x6667,
  0xE99A9D: 0x6668,
  0xE99A9F: 0x6669,
  0xE99AA4: 0x666A,
  0xE99AA5: 0x666B,
  0xE99AA6: 0x666C,
  0xE99AA9: 0x666D,
  0xE99AAE: 0x666E,
  0xE99AAF: 0x666F,
  0xE99AB3: 0x6670,
  0xE99ABA: 0x6671,
  0xE99B8A: 0x6672,
  0xE99B92: 0x6673,
  0xE5B6B2: 0x6674,
  0xE99B98: 0x6675,
  0xE99B9A: 0x6676,
  0xE99B9D: 0x6677,
  0xE99B9E: 0x6678,
  0xE99B9F: 0x6679,
  0xE99BA9: 0x667A,
  0xE99BAF: 0x667B,
  0xE99BB1: 0x667C,
  0xE99BBA: 0x667D,
  0xE99C82: 0x667E,
  0xE99C83: 0x6721,
  0xE99C85: 0x6722,
  0xE99C89: 0x6723,
  0xE99C9A: 0x6724,
  0xE99C9B: 0x6725,
  0xE99C9D: 0x6726,
  0xE99CA1: 0x6727,
  0xE99CA2: 0x6728,
  0xE99CA3: 0x6729,
  0xE99CA8: 0x672A,
  0xE99CB1: 0x672B,
  0xE99CB3: 0x672C,
  0xE99D81: 0x672D,
  0xE99D83: 0x672E,
  0xE99D8A: 0x672F,
  0xE99D8E: 0x6730,
  0xE99D8F: 0x6731,
  0xE99D95: 0x6732,
  0xE99D97: 0x6733,
  0xE99D98: 0x6734,
  0xE99D9A: 0x6735,
  0xE99D9B: 0x6736,
  0xE99DA3: 0x6737,
  0xE99DA7: 0x6738,
  0xE99DAA: 0x6739,
  0xE99DAE: 0x673A,
  0xE99DB3: 0x673B,
  0xE99DB6: 0x673C,
  0xE99DB7: 0x673D,
  0xE99DB8: 0x673E,
  0xE99DBB: 0x673F,
  0xE99DBD: 0x6740,
  0xE99DBF: 0x6741,
  0xE99E80: 0x6742,
  0xE99E89: 0x6743,
  0xE99E95: 0x6744,
  0xE99E96: 0x6745,
  0xE99E97: 0x6746,
  0xE99E99: 0x6747,
  0xE99E9A: 0x6748,
  0xE99E9E: 0x6749,
  0xE99E9F: 0x674A,
  0xE99EA2: 0x674B,
  0xE99EAC: 0x674C,
  0xE99EAE: 0x674D,
  0xE99EB1: 0x674E,
  0xE99EB2: 0x674F,
  0xE99EB5: 0x6750,
  0xE99EB6: 0x6751,
  0xE99EB8: 0x6752,
  0xE99EB9: 0x6753,
  0xE99EBA: 0x6754,
  0xE99EBC: 0x6755,
  0xE99EBE: 0x6756,
  0xE99EBF: 0x6757,
  0xE99F81: 0x6758,
  0xE99F84: 0x6759,
  0xE99F85: 0x675A,
  0xE99F87: 0x675B,
  0xE99F89: 0x675C,
  0xE99F8A: 0x675D,
  0xE99F8C: 0x675E,
  0xE99F8D: 0x675F,
  0xE99F8E: 0x6760,
  0xE99F90: 0x6761,
  0xE99F91: 0x6762,
  0xE99F94: 0x6763,
  0xE99F97: 0x6764,
  0xE99F98: 0x6765,
  0xE99F99: 0x6766,
  0xE99F9D: 0x6767,
  0xE99F9E: 0x6768,
  0xE99FA0: 0x6769,
  0xE99F9B: 0x676A,
  0xE99FA1: 0x676B,
  0xE99FA4: 0x676C,
  0xE99FAF: 0x676D,
  0xE99FB1: 0x676E,
  0xE99FB4: 0x676F,
  0xE99FB7: 0x6770,
  0xE99FB8: 0x6771,
  0xE99FBA: 0x6772,
  0xE9A087: 0x6773,
  0xE9A08A: 0x6774,
  0xE9A099: 0x6775,
  0xE9A08D: 0x6776,
  0xE9A08E: 0x6777,
  0xE9A094: 0x6778,
  0xE9A096: 0x6779,
  0xE9A09C: 0x677A,
  0xE9A09E: 0x677B,
  0xE9A0A0: 0x677C,
  0xE9A0A3: 0x677D,
  0xE9A0A6: 0x677E,
  0xE9A0AB: 0x6821,
  0xE9A0AE: 0x6822,
  0xE9A0AF: 0x6823,
  0xE9A0B0: 0x6824,
  0xE9A0B2: 0x6825,
  0xE9A0B3: 0x6826,
  0xE9A0B5: 0x6827,
  0xE9A0A5: 0x6828,
  0xE9A0BE: 0x6829,
  0xE9A184: 0x682A,
  0xE9A187: 0x682B,
  0xE9A18A: 0x682C,
  0xE9A191: 0x682D,
  0xE9A192: 0x682E,
  0xE9A193: 0x682F,
  0xE9A196: 0x6830,
  0xE9A197: 0x6831,
  0xE9A199: 0x6832,
  0xE9A19A: 0x6833,
  0xE9A1A2: 0x6834,
  0xE9A1A3: 0x6835,
  0xE9A1A5: 0x6836,
  0xE9A1A6: 0x6837,
  0xE9A1AA: 0x6838,
  0xE9A1AC: 0x6839,
  0xE9A2AB: 0x683A,
  0xE9A2AD: 0x683B,
  0xE9A2AE: 0x683C,
  0xE9A2B0: 0x683D,
  0xE9A2B4: 0x683E,
  0xE9A2B7: 0x683F,
  0xE9A2B8: 0x6840,
  0xE9A2BA: 0x6841,
  0xE9A2BB: 0x6842,
  0xE9A2BF: 0x6843,
  0xE9A382: 0x6844,
  0xE9A385: 0x6845,
  0xE9A388: 0x6846,
  0xE9A38C: 0x6847,
  0xE9A3A1: 0x6848,
  0xE9A3A3: 0x6849,
  0xE9A3A5: 0x684A,
  0xE9A3A6: 0x684B,
  0xE9A3A7: 0x684C,
  0xE9A3AA: 0x684D,
  0xE9A3B3: 0x684E,
  0xE9A3B6: 0x684F,
  0xE9A482: 0x6850,
  0xE9A487: 0x6851,
  0xE9A488: 0x6852,
  0xE9A491: 0x6853,
  0xE9A495: 0x6854,
  0xE9A496: 0x6855,
  0xE9A497: 0x6856,
  0xE9A49A: 0x6857,
  0xE9A49B: 0x6858,
  0xE9A49C: 0x6859,
  0xE9A49F: 0x685A,
  0xE9A4A2: 0x685B,
  0xE9A4A6: 0x685C,
  0xE9A4A7: 0x685D,
  0xE9A4AB: 0x685E,
  0xE9A4B1: 0x685F,
  0xE9A4B2: 0x6860,
  0xE9A4B3: 0x6861,
  0xE9A4B4: 0x6862,
  0xE9A4B5: 0x6863,
  0xE9A4B9: 0x6864,
  0xE9A4BA: 0x6865,
  0xE9A4BB: 0x6866,
  0xE9A4BC: 0x6867,
  0xE9A580: 0x6868,
  0xE9A581: 0x6869,
  0xE9A586: 0x686A,
  0xE9A587: 0x686B,
  0xE9A588: 0x686C,
  0xE9A58D: 0x686D,
  0xE9A58E: 0x686E,
  0xE9A594: 0x686F,
  0xE9A598: 0x6870,
  0xE9A599: 0x6871,
  0xE9A59B: 0x6872,
  0xE9A59C: 0x6873,
  0xE9A59E: 0x6874,
  0xE9A59F: 0x6875,
  0xE9A5A0: 0x6876,
  0xE9A69B: 0x6877,
  0xE9A69D: 0x6878,
  0xE9A69F: 0x6879,
  0xE9A6A6: 0x687A,
  0xE9A6B0: 0x687B,
  0xE9A6B1: 0x687C,
  0xE9A6B2: 0x687D,
  0xE9A6B5: 0x687E,
  0xE9A6B9: 0x6921,
  0xE9A6BA: 0x6922,
  0xE9A6BD: 0x6923,
  0xE9A6BF: 0x6924,
  0xE9A783: 0x6925,
  0xE9A789: 0x6926,
  0xE9A793: 0x6927,
  0xE9A794: 0x6928,
  0xE9A799: 0x6929,
  0xE9A79A: 0x692A,
  0xE9A79C: 0x692B,
  0xE9A79E: 0x692C,
  0xE9A7A7: 0x692D,
  0xE9A7AA: 0x692E,
  0xE9A7AB: 0x692F,
  0xE9A7AC: 0x6930,
  0xE9A7B0: 0x6931,
  0xE9A7B4: 0x6932,
  0xE9A7B5: 0x6933,
  0xE9A7B9: 0x6934,
  0xE9A7BD: 0x6935,
  0xE9A7BE: 0x6936,
  0xE9A882: 0x6937,
  0xE9A883: 0x6938,
  0xE9A884: 0x6939,
  0xE9A88B: 0x693A,
  0xE9A88C: 0x693B,
  0xE9A890: 0x693C,
  0xE9A891: 0x693D,
  0xE9A896: 0x693E,
  0xE9A89E: 0x693F,
  0xE9A8A0: 0x6940,
  0xE9A8A2: 0x6941,
  0xE9A8A3: 0x6942,
  0xE9A8A4: 0x6943,
  0xE9A8A7: 0x6944,
  0xE9A8AD: 0x6945,
  0xE9A8AE: 0x6946,
  0xE9A8B3: 0x6947,
  0xE9A8B5: 0x6948,
  0xE9A8B6: 0x6949,
  0xE9A8B8: 0x694A,
  0xE9A987: 0x694B,
  0xE9A981: 0x694C,
  0xE9A984: 0x694D,
  0xE9A98A: 0x694E,
  0xE9A98B: 0x694F,
  0xE9A98C: 0x6950,
  0xE9A98E: 0x6951,
  0xE9A991: 0x6952,
  0xE9A994: 0x6953,
  0xE9A996: 0x6954,
  0xE9A99D: 0x6955,
  0xE9AAAA: 0x6956,
  0xE9AAAC: 0x6957,
  0xE9AAAE: 0x6958,
  0xE9AAAF: 0x6959,
  0xE9AAB2: 0x695A,
  0xE9AAB4: 0x695B,
  0xE9AAB5: 0x695C,
  0xE9AAB6: 0x695D,
  0xE9AAB9: 0x695E,
  0xE9AABB: 0x695F,
  0xE9AABE: 0x6960,
  0xE9AABF: 0x6961,
  0xE9AB81: 0x6962,
  0xE9AB83: 0x6963,
  0xE9AB86: 0x6964,
  0xE9AB88: 0x6965,
  0xE9AB8E: 0x6966,
  0xE9AB90: 0x6967,
  0xE9AB92: 0x6968,
  0xE9AB95: 0x6969,
  0xE9AB96: 0x696A,
  0xE9AB97: 0x696B,
  0xE9AB9B: 0x696C,
  0xE9AB9C: 0x696D,
  0xE9ABA0: 0x696E,
  0xE9ABA4: 0x696F,
  0xE9ABA5: 0x6970,
  0xE9ABA7: 0x6971,
  0xE9ABA9: 0x6972,
  0xE9ABAC: 0x6973,
  0xE9ABB2: 0x6974,
  0xE9ABB3: 0x6975,
  0xE9ABB5: 0x6976,
  0xE9ABB9: 0x6977,
  0xE9ABBA: 0x6978,
  0xE9ABBD: 0x6979,
  0xE9ABBF: 0x697A,
  0xE9AC80: 0x697B,
  0xE9AC81: 0x697C,
  0xE9AC82: 0x697D,
  0xE9AC83: 0x697E,
  0xE9AC84: 0x6A21,
  0xE9AC85: 0x6A22,
  0xE9AC88: 0x6A23,
  0xE9AC89: 0x6A24,
  0xE9AC8B: 0x6A25,
  0xE9AC8C: 0x6A26,
  0xE9AC8D: 0x6A27,
  0xE9AC8E: 0x6A28,
  0xE9AC90: 0x6A29,
  0xE9AC92: 0x6A2A,
  0xE9AC96: 0x6A2B,
  0xE9AC99: 0x6A2C,
  0xE9AC9B: 0x6A2D,
  0xE9AC9C: 0x6A2E,
  0xE9ACA0: 0x6A2F,
  0xE9ACA6: 0x6A30,
  0xE9ACAB: 0x6A31,
  0xE9ACAD: 0x6A32,
  0xE9ACB3: 0x6A33,
  0xE9ACB4: 0x6A34,
  0xE9ACB5: 0x6A35,
  0xE9ACB7: 0x6A36,
  0xE9ACB9: 0x6A37,
  0xE9ACBA: 0x6A38,
  0xE9ACBD: 0x6A39,
  0xE9AD88: 0x6A3A,
  0xE9AD8B: 0x6A3B,
  0xE9AD8C: 0x6A3C,
  0xE9AD95: 0x6A3D,
  0xE9AD96: 0x6A3E,
  0xE9AD97: 0x6A3F,
  0xE9AD9B: 0x6A40,
  0xE9AD9E: 0x6A41,
  0xE9ADA1: 0x6A42,
  0xE9ADA3: 0x6A43,
  0xE9ADA5: 0x6A44,
  0xE9ADA6: 0x6A45,
  0xE9ADA8: 0x6A46,
  0xE9ADAA: 0x6A47,
  0xE9ADAB: 0x6A48,
  0xE9ADAC: 0x6A49,
  0xE9ADAD: 0x6A4A,
  0xE9ADAE: 0x6A4B,
  0xE9ADB3: 0x6A4C,
  0xE9ADB5: 0x6A4D,
  0xE9ADB7: 0x6A4E,
  0xE9ADB8: 0x6A4F,
  0xE9ADB9: 0x6A50,
  0xE9ADBF: 0x6A51,
  0xE9AE80: 0x6A52,
  0xE9AE84: 0x6A53,
  0xE9AE85: 0x6A54,
  0xE9AE86: 0x6A55,
  0xE9AE87: 0x6A56,
  0xE9AE89: 0x6A57,
  0xE9AE8A: 0x6A58,
  0xE9AE8B: 0x6A59,
  0xE9AE8D: 0x6A5A,
  0xE9AE8F: 0x6A5B,
  0xE9AE90: 0x6A5C,
  0xE9AE94: 0x6A5D,
  0xE9AE9A: 0x6A5E,
  0xE9AE9D: 0x6A5F,
  0xE9AE9E: 0x6A60,
  0xE9AEA6: 0x6A61,
  0xE9AEA7: 0x6A62,
  0xE9AEA9: 0x6A63,
  0xE9AEAC: 0x6A64,
  0xE9AEB0: 0x6A65,
  0xE9AEB1: 0x6A66,
  0xE9AEB2: 0x6A67,
  0xE9AEB7: 0x6A68,
  0xE9AEB8: 0x6A69,
  0xE9AEBB: 0x6A6A,
  0xE9AEBC: 0x6A6B,
  0xE9AEBE: 0x6A6C,
  0xE9AEBF: 0x6A6D,
  0xE9AF81: 0x6A6E,
  0xE9AF87: 0x6A6F,
  0xE9AF88: 0x6A70,
  0xE9AF8E: 0x6A71,
  0xE9AF90: 0x6A72,
  0xE9AF97: 0x6A73,
  0xE9AF98: 0x6A74,
  0xE9AF9D: 0x6A75,
  0xE9AF9F: 0x6A76,
  0xE9AFA5: 0x6A77,
  0xE9AFA7: 0x6A78,
  0xE9AFAA: 0x6A79,
  0xE9AFAB: 0x6A7A,
  0xE9AFAF: 0x6A7B,
  0xE9AFB3: 0x6A7C,
  0xE9AFB7: 0x6A7D,
  0xE9AFB8: 0x6A7E,
  0xE9AFB9: 0x6B21,
  0xE9AFBA: 0x6B22,
  0xE9AFBD: 0x6B23,
  0xE9AFBF: 0x6B24,
  0xE9B080: 0x6B25,
  0xE9B082: 0x6B26,
  0xE9B08B: 0x6B27,
  0xE9B08F: 0x6B28,
  0xE9B091: 0x6B29,
  0xE9B096: 0x6B2A,
  0xE9B098: 0x6B2B,
  0xE9B099: 0x6B2C,
  0xE9B09A: 0x6B2D,
  0xE9B09C: 0x6B2E,
  0xE9B09E: 0x6B2F,
  0xE9B0A2: 0x6B30,
  0xE9B0A3: 0x6B31,
  0xE9B0A6: 0x6B32,
  0xE9B0A7: 0x6B33,
  0xE9B0A8: 0x6B34,
  0xE9B0A9: 0x6B35,
  0xE9B0AA: 0x6B36,
  0xE9B0B1: 0x6B37,
  0xE9B0B5: 0x6B38,
  0xE9B0B6: 0x6B39,
  0xE9B0B7: 0x6B3A,
  0xE9B0BD: 0x6B3B,
  0xE9B181: 0x6B3C,
  0xE9B183: 0x6B3D,
  0xE9B184: 0x6B3E,
  0xE9B185: 0x6B3F,
  0xE9B189: 0x6B40,
  0xE9B18A: 0x6B41,
  0xE9B18E: 0x6B42,
  0xE9B18F: 0x6B43,
  0xE9B190: 0x6B44,
  0xE9B193: 0x6B45,
  0xE9B194: 0x6B46,
  0xE9B196: 0x6B47,
  0xE9B198: 0x6B48,
  0xE9B19B: 0x6B49,
  0xE9B19D: 0x6B4A,
  0xE9B19E: 0x6B4B,
  0xE9B19F: 0x6B4C,
  0xE9B1A3: 0x6B4D,
  0xE9B1A9: 0x6B4E,
  0xE9B1AA: 0x6B4F,
  0xE9B19C: 0x6B50,
  0xE9B1AB: 0x6B51,
  0xE9B1A8: 0x6B52,
  0xE9B1AE: 0x6B53,
  0xE9B1B0: 0x6B54,
  0xE9B1B2: 0x6B55,
  0xE9B1B5: 0x6B56,
  0xE9B1B7: 0x6B57,
  0xE9B1BB: 0x6B58,
  0xE9B3A6: 0x6B59,
  0xE9B3B2: 0x6B5A,
  0xE9B3B7: 0x6B5B,
  0xE9B3B9: 0x6B5C,
  0xE9B48B: 0x6B5D,
  0xE9B482: 0x6B5E,
  0xE9B491: 0x6B5F,
  0xE9B497: 0x6B60,
  0xE9B498: 0x6B61,
  0xE9B49C: 0x6B62,
  0xE9B49D: 0x6B63,
  0xE9B49E: 0x6B64,
  0xE9B4AF: 0x6B65,
  0xE9B4B0: 0x6B66,
  0xE9B4B2: 0x6B67,
  0xE9B4B3: 0x6B68,
  0xE9B4B4: 0x6B69,
  0xE9B4BA: 0x6B6A,
  0xE9B4BC: 0x6B6B,
  0xE9B585: 0x6B6C,
  0xE9B4BD: 0x6B6D,
  0xE9B582: 0x6B6E,
  0xE9B583: 0x6B6F,
  0xE9B587: 0x6B70,
  0xE9B58A: 0x6B71,
  0xE9B593: 0x6B72,
  0xE9B594: 0x6B73,
  0xE9B59F: 0x6B74,
  0xE9B5A3: 0x6B75,
  0xE9B5A2: 0x6B76,
  0xE9B5A5: 0x6B77,
  0xE9B5A9: 0x6B78,
  0xE9B5AA: 0x6B79,
  0xE9B5AB: 0x6B7A,
  0xE9B5B0: 0x6B7B,
  0xE9B5B6: 0x6B7C,
  0xE9B5B7: 0x6B7D,
  0xE9B5BB: 0x6B7E,
  0xE9B5BC: 0x6C21,
  0xE9B5BE: 0x6C22,
  0xE9B683: 0x6C23,
  0xE9B684: 0x6C24,
  0xE9B686: 0x6C25,
  0xE9B68A: 0x6C26,
  0xE9B68D: 0x6C27,
  0xE9B68E: 0x6C28,
  0xE9B692: 0x6C29,
  0xE9B693: 0x6C2A,
  0xE9B695: 0x6C2B,
  0xE9B696: 0x6C2C,
  0xE9B697: 0x6C2D,
  0xE9B698: 0x6C2E,
  0xE9B6A1: 0x6C2F,
  0xE9B6AA: 0x6C30,
  0xE9B6AC: 0x6C31,
  0xE9B6AE: 0x6C32,
  0xE9B6B1: 0x6C33,
  0xE9B6B5: 0x6C34,
  0xE9B6B9: 0x6C35,
  0xE9B6BC: 0x6C36,
  0xE9B6BF: 0x6C37,
  0xE9B783: 0x6C38,
  0xE9B787: 0x6C39,
  0xE9B789: 0x6C3A,
  0xE9B78A: 0x6C3B,
  0xE9B794: 0x6C3C,
  0xE9B795: 0x6C3D,
  0xE9B796: 0x6C3E,
  0xE9B797: 0x6C3F,
  0xE9B79A: 0x6C40,
  0xE9B79E: 0x6C41,
  0xE9B79F: 0x6C42,
  0xE9B7A0: 0x6C43,
  0xE9B7A5: 0x6C44,
  0xE9B7A7: 0x6C45,
  0xE9B7A9: 0x6C46,
  0xE9B7AB: 0x6C47,
  0xE9B7AE: 0x6C48,
  0xE9B7B0: 0x6C49,
  0xE9B7B3: 0x6C4A,
  0xE9B7B4: 0x6C4B,
  0xE9B7BE: 0x6C4C,
  0xE9B88A: 0x6C4D,
  0xE9B882: 0x6C4E,
  0xE9B887: 0x6C4F,
  0xE9B88E: 0x6C50,
  0xE9B890: 0x6C51,
  0xE9B891: 0x6C52,
  0xE9B892: 0x6C53,
  0xE9B895: 0x6C54,
  0xE9B896: 0x6C55,
  0xE9B899: 0x6C56,
  0xE9B89C: 0x6C57,
  0xE9B89D: 0x6C58,
  0xE9B9BA: 0x6C59,
  0xE9B9BB: 0x6C5A,
  0xE9B9BC: 0x6C5B,
  0xE9BA80: 0x6C5C,
  0xE9BA82: 0x6C5D,
  0xE9BA83: 0x6C5E,
  0xE9BA84: 0x6C5F,
  0xE9BA85: 0x6C60,
  0xE9BA87: 0x6C61,
  0xE9BA8E: 0x6C62,
  0xE9BA8F: 0x6C63,
  0xE9BA96: 0x6C64,
  0xE9BA98: 0x6C65,
  0xE9BA9B: 0x6C66,
  0xE9BA9E: 0x6C67,
  0xE9BAA4: 0x6C68,
  0xE9BAA8: 0x6C69,
  0xE9BAAC: 0x6C6A,
  0xE9BAAE: 0x6C6B,
  0xE9BAAF: 0x6C6C,
  0xE9BAB0: 0x6C6D,
  0xE9BAB3: 0x6C6E,
  0xE9BAB4: 0x6C6F,
  0xE9BAB5: 0x6C70,
  0xE9BB86: 0x6C71,
  0xE9BB88: 0x6C72,
  0xE9BB8B: 0x6C73,
  0xE9BB95: 0x6C74,
  0xE9BB9F: 0x6C75,
  0xE9BBA4: 0x6C76,
  0xE9BBA7: 0x6C77,
  0xE9BBAC: 0x6C78,
  0xE9BBAD: 0x6C79,
  0xE9BBAE: 0x6C7A,
  0xE9BBB0: 0x6C7B,
  0xE9BBB1: 0x6C7C,
  0xE9BBB2: 0x6C7D,
  0xE9BBB5: 0x6C7E,
  0xE9BBB8: 0x6D21,
  0xE9BBBF: 0x6D22,
  0xE9BC82: 0x6D23,
  0xE9BC83: 0x6D24,
  0xE9BC89: 0x6D25,
  0xE9BC8F: 0x6D26,
  0xE9BC90: 0x6D27,
  0xE9BC91: 0x6D28,
  0xE9BC92: 0x6D29,
  0xE9BC94: 0x6D2A,
  0xE9BC96: 0x6D2B,
  0xE9BC97: 0x6D2C,
  0xE9BC99: 0x6D2D,
  0xE9BC9A: 0x6D2E,
  0xE9BC9B: 0x6D2F,
  0xE9BC9F: 0x6D30,
  0xE9BCA2: 0x6D31,
  0xE9BCA6: 0x6D32,
  0xE9BCAA: 0x6D33,
  0xE9BCAB: 0x6D34,
  0xE9BCAF: 0x6D35,
  0xE9BCB1: 0x6D36,
  0xE9BCB2: 0x6D37,
  0xE9BCB4: 0x6D38,
  0xE9BCB7: 0x6D39,
  0xE9BCB9: 0x6D3A,
  0xE9BCBA: 0x6D3B,
  0xE9BCBC: 0x6D3C,
  0xE9BCBD: 0x6D3D,
  0xE9BCBF: 0x6D3E,
  0xE9BD81: 0x6D3F,
  0xE9BD83: 0x6D40,
  0xE9BD84: 0x6D41,
  0xE9BD85: 0x6D42,
  0xE9BD86: 0x6D43,
  0xE9BD87: 0x6D44,
  0xE9BD93: 0x6D45,
  0xE9BD95: 0x6D46,
  0xE9BD96: 0x6D47,
  0xE9BD97: 0x6D48,
  0xE9BD98: 0x6D49,
  0xE9BD9A: 0x6D4A,
  0xE9BD9D: 0x6D4B,
  0xE9BD9E: 0x6D4C,
  0xE9BDA8: 0x6D4D,
  0xE9BDA9: 0x6D4E,
  0xE9BDAD: 0x6D4F,
  0xE9BDAE: 0x6D50,
  0xE9BDAF: 0x6D51,
  0xE9BDB0: 0x6D52,
  0xE9BDB1: 0x6D53,
  0xE9BDB3: 0x6D54,
  0xE9BDB5: 0x6D55,
  0xE9BDBA: 0x6D56,
  0xE9BDBD: 0x6D57,
  0xE9BE8F: 0x6D58,
  0xE9BE90: 0x6D59,
  0xE9BE91: 0x6D5A,
  0xE9BE92: 0x6D5B,
  0xE9BE94: 0x6D5C,
  0xE9BE96: 0x6D5D,
  0xE9BE97: 0x6D5E,
  0xE9BE9E: 0x6D5F,
  0xE9BEA1: 0x6D60,
  0xE9BEA2: 0x6D61,
  0xE9BEA3: 0x6D62,
  0xE9BEA5: 0x6D63,
  //FIXME: mojibake
  0xE3809C: 0x2141
};