<div class="wrapper">
  <div class="container">
    <div class="page-header">
      <h1 class="heading">外部連携</h1>
    </div>
    <div class="amptalk-integration">
      <div class="card">
        <div class="section-header">
          <h2 class="heading">amptalkを連携</h2>
          <div class="caption">
            amptalkを連携することで、ユーザーはamptalkのコールに対して自動的にアセスメントを行えるようになります。
          </div>
        </div>
        <mat-slide-toggle
          #slider
          [checked]="isAmptalkIntegrationEnabled"
          (change)="onAmptalkToggleChange($event, slider)"
        ></mat-slide-toggle>
      </div>
      <ng-container *ngIf="isAmptalkIntegrationEnabled">
        <div class="alert" *ngIf="hasAmptalkIntegrationError">
          <mat-icon fontIcon="warning_amber" class="icon"></mat-icon>
          <div class="message">
            <span class="strong">amptalk連携が切断されました。</span
            >APIキーまたは認証キーの有効期限が切れた可能性があります。認証情報を確認し、更新を行ってください。
          </div>
          <button mat-icon-button class="close-button">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <p>
          amptalkの認証キーは定期的に更新される必要があります。期限が切れると、amptalkとの連携が自動的に解除され、アセスメントの自動同期が停止します。認証キーを更新するには、以下からクリックしてください。
        </p>
        <div>
          <button rectangle (click)="onUpdateAmptalkIntegration()">
            <mat-icon>sync</mat-icon>
            認証キーを更新
          </button>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="isAmptalkIntegrationEnabled">
      <hr class="separator" />
      <div class="assessment-setting">
        <h2 class="heading">アセスメント設定</h2>
      </div>
      <div class="form">
        <div class="field">
          <label class="label" for="skill-map">スキルマップ * :</label>
          <div class="input-with-error">
            <div class="value">
              <app-select
                id="skill-map"
                [options]="this.skillMapOptions"
                (selectedOptionChange)="this.changeSkillMap($event)"
                [selectedOption]="this.selectedSkillMap"
                [showError]="isShowError && !this.skillMapSelected()"
              ></app-select>
            </div>
            <div
              class="input-error"
              *ngIf="isShowError && !this.skillMapSelected()"
            >
              スキルマップが選択されていません
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label" for="applicable-phases">フェーズ * :</label>
          <div class="input-with-error">
            <div class="value">
              <app-multiple-select
                id="applicable-phases"
                [items]="this.phaseItems"
                (changeSelected)="changeSelectedPhases($event)"
                [showError]="isShowError && !this.phaseSelected()"
              ></app-multiple-select>
            </div>
            <div
              class="input-error"
              *ngIf="isShowError && !this.phaseSelected()"
            >
              フェーズが選択されていません
            </div>
          </div>
        </div>
        <div class="field label-fixed">
          <label class="label" for="applicable-phases">対象ユーザー * :</label>
          <div class="value">
            <div class="user-list">
              <div class="table-container">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox
                        [checked]="this.checkedState === 'CHECKED'"
                        [indeterminate]="this.checkedState === 'INDETERMINATE'"
                        (change)="this.checkAllUsers($event.checked)"
                      ></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let user">
                      <mat-checkbox
                        [disabled]="!user.hasLicense"
                        [checked]="user.selected"
                        (change)="this.checkChanged(user)"
                      ></mat-checkbox>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="userName">
                    <th mat-header-cell *matHeaderCellDef>名前</th>
                    <td mat-cell *matCellDef="let user">
                      <div class="user-info">
                        <div class="avatar">
                          <app-avatar
                            [thumbnailUrl]="user.thumbnailPath ?? ''"
                          ></app-avatar>
                        </div>
                        <div class="user-name-email">
                          <div class="name">{{ user.name }}</div>
                          <div class="email">{{ user.email }}</div>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="userDepartmentName">
                    <th mat-header-cell *matHeaderCellDef>部署</th>
                    <td mat-cell *matCellDef="let user">
                      {{ user.departmentName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="reportTo">
                    <th mat-header-cell *matHeaderCellDef>上司</th>
                    <td mat-cell *matCellDef="let user">
                      {{ user.reportTo ?? '' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="remainingHours">
                    <th mat-header-cell *matHeaderCellDef>残りの利用時間</th>
                    <td mat-cell *matCellDef="let user">
                      {{ user.hasLicense ? user.remainingHours + '時間' : '' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="license">
                    <th mat-header-cell *matHeaderCellDef>ライセンス</th>
                    <td mat-cell *matCellDef="let user">
                      <span class="chip" [class.disabled]="!user.hasLicense">
                        {{ user.hasLicense ? 'あり' : 'なし' }}
                      </span>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.disabled]="!row.hasLicense"
                  ></tr>
                </table>
                <ng-container *ngIf="this.dataSource.length === 0">
                  <div class="empty">該当なし</div>
                </ng-container>
              </div>
            </div>
            <div
              class="input-error"
              *ngIf="isShowError && !this.userSelected()"
            >
              対象ユーザーが選択されていません
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button rectangle variant="primary" (click)="onAssessmentSettingSave()">
          保存
        </button>
      </div>
    </ng-container>
  </div>
</div>
