<div class="container">
  <h1 class="title">
    CSVからファイルをアップロード
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="steps">
    <div class="step">
      <h2 class="step-title">1. テンプレートのダウンロード</h2>
      <div class="step-explanation">
        <ol>
          <li>「ダウンロード」をクリックしてテンプレートを取得します</li>
          <li>
            テンプレートはコンピューターのアクセスしやすい場所に保管してください
          </li>
        </ol>
      </div>
      <div class="step-action">
        <button type="button" (click)="downloadCsvTemplate()">
          ダウンロード
        </button>
      </div>
    </div>
    <div class="step">
      <h2 class="step-title">2. ファイルの編集</h2>
      <div class="step-explanation">
        <ol>
          <li>ダウンロードしたテンプレートを開きます</li>
          <li>各行につきファイルの情報を正確に入力してください</li>
        </ol>
      </div>
    </div>
    <div class="step">
      <h2 class="step-title">3. CSVファイルのアップロード</h2>
      <div class="step-explanation">
        <ol>
          <li>
            「アップロード」をクリックし、コンピュータより編集済みのCSVファイルを選択します
          </li>
          <li>ファイルの選択後、自動的にファイルのインポートが開始されます</li>
        </ol>
      </div>
      <div class="step-action">
        <button type="button" (click)="onUploadButtonClick()">
          アップロード
        </button>
        <input
          type="file"
          #fileInput
          hidden
          accept=".csv"
          (change)="onFileChange($event)"
        />
      </div>
    </div>
  </div>
</div>
