import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import {
  ReactiveFormsModule,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { MatIcon } from '@angular/material/icon'
import { Router, RouterLink } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { ButtonComponent } from '../../components/button/button.component'
import { invalidStringValidator } from '../../directives/validator/custom-validator'
import { AuthService } from '../../services/auth.service'
import { EMAIL_REGEXP } from '../../services/user/user.service'
import { LoadingComponent } from '../../components/loading/loading.component'
import { getTitle } from '../../util/accessibility'

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    MatIcon,
    ButtonComponent,
    LoadingComponent,
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {
  email = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, invalidStringValidator(EMAIL_REGEXP)],
  })
  password = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required],
  })

  loginForm = new FormGroup({
    email: this.email,
    password: this.password,
  })

  isLoading = false

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('ログイン'))
  }

  loginWithGoogleButtonClick = () => {
    this.loginWithGoogle()
  }

  loginWithGoogleButtonKeyDown = () => {
    this.loginWithGoogle()
  }

  loginWithGoogle() {
    return this.authService
      .loginWithGoogle()
      .then((result) => {
        if (!result) {
          alert('ログインに失敗しました。')
          return
        }
        this.router.navigate(['home'])
      })
      .catch((error) => {
        let errorMessage = ''
        let unhandledError = false

        switch (error.code) {
          case 'auth/popup-closed-by-user':
            break
          case 'auth/cancelled-popup-request':
            break
          case 'auth/popup-blocked':
            errorMessage =
              'ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください'
            break
          default:
            errorMessage = `${error.code}: ${error.message}`
            unhandledError = true
        }

        if (errorMessage) {
          alert(errorMessage)
        }
        if (unhandledError) {
          throw error
        }
      })
  }

  loginWithEmailAndPasswordSubmit = (event: Event) => {
    event.preventDefault()
    this.loginWithEmailAndPassword()
  }

  loginWithEmailAndPassword = () => {
    this.isLoading = true
    return this.authService
      .loginWithEmailAndPassword(this.email.value, this.password.value)
      .then(
        () => {
          this.router.navigate(['home'])
        },
        (error) => {
          let errorMessage = ''
          let unhandledError = false
          if (error.code === 'auth/invalid-credential') {
            errorMessage = 'メールアドレスまたはパスワードが間違っています。'
          } else if (error.code === 'auth/too-many-requests') {
            errorMessage =
              'このアカウントへのアクセスは、多くのログイン試行が失敗したため、一時的に無効化されています。すぐにアカウントを復元するには、パスワードをリセットするか、後で再試行してください。'
          } else {
            errorMessage = `${error.code}: ${error.message}`
            unhandledError = true
          }
          alert(errorMessage)

          if (unhandledError) {
            throw error
          }
        },
      )
      .finally(() => {
        this.isLoading = false
      })
  }
}
