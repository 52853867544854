import { Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root',
})
export class MatIconRegisterService {
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {}

  register(): void {
    this.addSvgIcon('donuts', '/assets/icons/donuts.svg')
    this.addSvgIcon('settings', '/assets/icons/settings.svg')
    this.addSvgIcon('arrow-down', '/assets/icons/arrow-down.svg')
    this.addSvgIcon('arrow-right', '/assets/icons/arrow-right.svg')
    this.addSvgIcon('radio-checked', '/assets/icons/radio-checked.svg')
    this.addSvgIcon('radio-unchecked', '/assets/icons/radio-unchecked.svg')
    this.addSvgIcon('download', '/assets/icons/download.svg')
    this.addSvgIcon('loading', '/assets/icons/loading.svg')
    this.addSvgIcon('company', '/assets/icons/company.svg')
  }

  private addSvgIcon = (iconName: string, path: string): MatIconRegistry => {
    return this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path),
    )
  }
}
