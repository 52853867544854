<div class="container">
  <h1 class="header">
    <div class="title">
      <mat-icon>error_outline</mat-icon>
      ファイルのアップロードに失敗しました
    </div>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="main">
    <p>
      アップロードされたファイルに問題があります。以下の内容を確認し、再度お試しください。
    </p>
    <table>
      <thead>
        <tr>
          <th>行</th>
          <th>
            {{
              param?.type === 'businessMeeting' ? 'ファイル名' : 'ユーザー名'
            }}
          </th>
          <th>エラー内容</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of this.param?.items; let i = index">
          <td class="index">
            {{ item.rowNo }}
          </td>
          <td class="file-name">{{ item.name }}</td>
          <td>{{ item.message }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="footer">
    <button rectangle variant="primary" (click)="this.close()">OK</button>
  </div>
</div>
