<div class="container">
  <div class="main">
    <div class="header">
      <div class="icon"><mat-icon>donut_large</mat-icon></div>
      <h2 class="title">AI Sales Coach</h2>
    </div>
    <div class="content">
      <div class="navigation">
        <button class="back-button" (click)="backToPhoneSetup()">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      </div>
      <h3 class="title">認証コードの検証</h3>
      <p class="message">電話番号に送信された認証コードを入力してください。</p>
      <form
        [formGroup]="smsVerificationForm"
        (submit)="sendVerificationCodeSubmit($event)"
      >
        <div class="inputs">
          <div class="input-wrapper">
            <input
              id="verificationCode"
              name="verificationCode"
              class="verificationCode"
              placeholder="検証コード"
              formControlName="verificationCode"
              required
            />
          </div>
        </div>
        <div class="button-wrapper">
          <button
            type="submit"
            rectangle
            class="primary"
            [disabled]="smsVerificationForm.invalid"
          >
            送信
          </button>
        </div>
      </form>
    </div>
    <app-loading [showLoading]="isLoading" [fullScreen]="false"></app-loading>
  </div>
</div>
