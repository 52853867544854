import { Environment } from '../common/type'

export const environment = {
  production: false,
  graphqlUrl: 'https://api.xp-gpt-dev.enablement-app.dev/',
  firebase: {
    apiKey: 'AIzaSyDjyY7oeesSx47pOGEh_Gnk_pfiy8CihMU',
    authDomain: 'xp-gpt-dev.firebaseapp.com',
    projectId: 'xp-gpt-dev',
    storageBucket: 'xp-gpt-dev.appspot.com',
    messagingSenderId: '574343961271',
    appId: '1:574343961271:web:24c22913c8691ff3694b32',
    measurementId: 'G-H6F1XVEYK0',
  },
  sentryDsn:
    'https://8e198f2541a80743c861150b791a893f@o4504478889541632.ingest.us.sentry.io/4507842041544704',
  gaCode: '',
  baseUrl: 'https://xp-gpt-dev.enablement-app.dev/',
  zoomClientId: 'EwPMYtG3S6ajfG42FgNOg',
  sfOAuth: {
    clientId:
      '3MVG97quAmFZJfVxSwLqj7Xax7DFuIzIS4FrNdCJ_NHfTIWmEQm3g73pYvZYvYbeMey_sI0kuiKmHeVwaUvhe',
    redirectUri:
      'https://xp-gpt-dev.enablement-app.dev/auth/salesforce/callback',
    scope: 'api openid refresh_token',
  },
} satisfies Environment
