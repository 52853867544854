import {
  GetAutoAssessmentSettingQuery,
  GetOutboundIntegrationSettingQuery,
  ValidateSalesforceIntegrationSettingQuery,
} from '../../../_generated/graphql'

export const mappingAutoAssessmentSetting = (
  fetchedData: GetAutoAssessmentSettingQuery['getAutoAssessmentSetting'],
): AutoAssessmentSetting | null => {
  if (!fetchedData) {
    return null
  }

  return {
    id: fetchedData.id,
    targetUsers: fetchedData.targetUsers.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      departmentName: user.departmentName || null,
      thumbnailPath: user.thumbnailPath || null,
      reportTo: user.reportTo
        ? {
            id: user.reportTo.id,
            name: user.reportTo.name,
          }
        : null,
      license: user.license
        ? {
            id: user.license.id,
            plan: user.license.plan,
            userMonthlyLimitSeconds: user.license.userMonthlyLimitSeconds,
            extraChargeLimitSeconds: user.license.extraChargeLimitSeconds,
            usedSeconds: user.license.usedSeconds,
          }
        : null,
    })),
    targetSkillMap: {
      id: fetchedData.targetSkillMap.id,
      name: fetchedData.targetSkillMap.name,
    },
    targetPhaseIds: fetchedData.targetPhaseIds,
    minTransferDurationSeconds:
      fetchedData.minTransferDurationSeconds != null
        ? fetchedData.minTransferDurationSeconds
        : null,
    syncStartDate: new Date(fetchedData.syncStartDate),
    whiteList: fetchedData.whiteList,
    blackList: fetchedData.blackList,
  }
}

export const mappingOutboundIntegrationSetting = (
  fetchedData: GetOutboundIntegrationSettingQuery['getOutboundIntegrationSetting'],
): OutboundIntegrationSetting | null => {
  if (!fetchedData) {
    return null
  }

  return {
    id: fetchedData.id,
    targetUsers: fetchedData.targetUsers.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      departmentName: user.departmentName || null,
      thumbnailPath: user.thumbnailPath || null,
      reportTo: user.reportTo
        ? {
            id: user.reportTo.id,
            name: user.reportTo.name,
          }
        : null,
      license: user.license
        ? {
            id: user.license.id,
            plan: user.license.plan,
            userMonthlyLimitSeconds: user.license.userMonthlyLimitSeconds,
            extraChargeLimitSeconds: user.license.extraChargeLimitSeconds,
            usedSeconds: user.license.usedSeconds,
          }
        : null,
    })),
  }
}

export const mappingSalesforceIntegrationSetting = (
  fetchedData: ValidateSalesforceIntegrationSettingQuery['validateSalesforceIntegrationSetting'],
): SalesforceValidationResult => {
  return {
    isValid: fetchedData.isValid,
    errors: fetchedData.errors.map((error) => ({
      code: error.code,
      message: error.message,
      objectName: error.objectName || undefined,
      fieldName: error.fieldName || undefined,
      expectedType: error.expectedType || undefined,
      actualType: error.actualType || undefined,
      minLength: error.minLength || undefined,
    })),
  }
}

export interface TargetUser {
  id: string
  name: string
  email: string
  departmentName: string | null
  thumbnailPath: string | null
  reportTo: {
    id: string
    name: string
  } | null
  license: {
    id: string
    plan: string
    userMonthlyLimitSeconds: number
    extraChargeLimitSeconds: number
    usedSeconds: number
  } | null
}

export interface TargetSkillMap {
  id: string
  name: string
}

export interface AutoAssessmentSetting {
  id: string
  targetUsers: TargetUser[]
  targetSkillMap: TargetSkillMap
  targetPhaseIds: string[]
  minTransferDurationSeconds: number | null
  syncStartDate: Date
  whiteList: string[]
  blackList: string[]
}

export interface OutboundIntegrationSetting {
  id: string
  targetUsers: TargetUser[]
}

export interface AmptalkKeys {
  apiKey: string
  authKey: string
}

export interface SalesforceValidationError {
  code: string
  message: string
  objectName?: string
  fieldName?: string
  expectedType?: string
  actualType?: string
  minLength?: number
}

export interface SalesforceValidationResult {
  isValid: boolean
  errors: SalesforceValidationError[]
}
