<div class="container">
  <h1 class="header">
    <div class="title">amptalkの連携</div>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <form class="main" (submit)="onSubmit($event)">
    <div class="form">
      <div class="field">
        <label class="label" for="api-key">APIキー * :</label>
        <div class="input-with-error">
          <div class="value">
            <input
              id="api-key"
              class="input"
              [class.error]="this.apiKey.touched && this.apiKey.invalid"
              placeholder="APIキーを入力してください"
              [formControl]="this.apiKey"
            />
          </div>
          <div
            class="input-error"
            *ngIf="this.apiKey.touched && this.apiKey.hasError('required')"
          >
            APIキーは必須です
          </div>
          <div
            class="input-error"
            *ngIf="this.apiKey.touched && this.apiKey.hasError('invalidString')"
          >
            APIキーの形式が正しくありません
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label" for="auth-key">認証キー * :</label>
        <div class="input-with-error">
          <div class="value">
            <input
              id="auth-key"
              class="input"
              [class.error]="this.authKey.touched && this.authKey.invalid"
              placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              [formControl]="this.authKey"
            />
          </div>
          <div
            class="input-error"
            *ngIf="this.authKey.touched && this.authKey.hasError('required')"
          >
            認証キーは必須です
          </div>
          <div
            class="input-error"
            *ngIf="
              this.authKey.touched && this.authKey.hasError('invalidString')
            "
          >
            認証キーの形式が正しくありません
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button
        type="button"
        rectangle
        variant="secondary"
        (click)="this.close()"
      >
        キャンセル
      </button>
      <button
        type="submit"
        rectangle
        variant="primary"
        [disabled]="this.formGroup.invalid"
      >
        OK
      </button>
    </div>
  </form>
</div>
