import { Component, OnInit } from '@angular/core'
import { AvatarComponent } from '../../../components/avatar/avatar.component'
import {
  Row,
  TimeSeriesComponent,
} from '../time-series-chart/time-series-chart.component'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from '../../../services/auth.service'
import { UserStore } from '../../../stores/user.store'
import { PeriodKeyActionScore } from '../../../services/user/user.mapping'
import { CommonModule } from '@angular/common'
import {
  Option,
  SelectComponent,
} from '../../../components/select/select.component'
import { MatIconModule } from '@angular/material/icon'
import { Title } from '@angular/platform-browser'
import { getTitle } from '../../../util/accessibility'
import { LoadingComponent } from '../../../components/loading/loading.component'
import { SkillMap } from '../../../services/assessment/assessment.mapping'
import { switchMap } from 'rxjs'
import { AssessmentStore } from '../../../stores/assessment.store'

@Component({
  selector: 'app-individual-dashboard-page',
  standalone: true,
  imports: [
    AvatarComponent,
    TimeSeriesComponent,
    CommonModule,
    SelectComponent,
    MatIconModule,
    LoadingComponent,
  ],
  templateUrl: './individual.component.html',
  styleUrl: './individual.component.scss',
})
export class IndividualComponent implements OnInit {
  user: {
    name: string
    departmentName: string
    thumbnailPath: string | null
  } | null = null
  skillMaps: SkillMap[] = []
  skillMapOptions: Option[] = []
  selectedSkillMap!: Option
  latestAverageScore: number | null = null
  startDate: Date | null = null
  endDate: Date = new Date()
  rowData: PeriodKeyActionScore[] = []
  data: Row[] = []
  isLoading = true
  rangeOptions: Option[] = [
    { id: '1', name: '1ヵ月', isDefault: true },
    { id: '2', name: '2ヵ月' },
    { id: '3', name: '3ヵ月' },
    { id: '6', name: '6ヵ月' },
    { id: '9', name: '9ヵ月' },
    { id: '12', name: '1年' },
  ]
  selectedRange =
    this.rangeOptions.find((option) => option.isDefault) ?? this.rangeOptions[0]

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userStore: UserStore,
    private assessmentStore: AssessmentStore,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('個人スコア推移'))
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        switchMap(() => {
          return this.assessmentStore.getSkillMaps()
        }),
      )
      .subscribe(async (skillMaps) => {
        this.skillMaps = skillMaps
        this.skillMaps = skillMaps
        this.skillMapOptions = skillMaps.map((skillMap, index) => ({
          id: skillMap.id,
          name: skillMap.name,
          isDefault: index === 0,
        }))
        this.selectedSkillMap = this.skillMapOptions[0]
        this.onRangeChange(this.selectedRange)
      })
  }

  onRangeChange(range: Option) {
    this.isLoading = true
    this.selectedRange = range
    this.fetchData()
  }

  onSkillMapChange(skillMap: Option) {
    this.selectedSkillMap = skillMap
    this.fetchData()
  }

  private fetchData() {
    this.startDate = this.getMonthsAgoDate(Number(this.selectedRange.id))
    this.userStore
      .getWeeklyUserScores(
        this.activatedRoute.snapshot.queryParamMap.get('user-id') ??
          this.authService.user?.id ??
          '',
        this.startDate,
        new Date(),
        this.selectedSkillMap.id,
      )
      .subscribe((data) => {
        this.rowData = data.periodKeyActionScores
        this.data = this.transformToHighChartFormat(data.periodKeyActionScores)
        this.latestAverageScore = data.keyActionRecentlyAverageScores.filter(
          (a) => a.score,
        ).length
          ? data.keyActionRecentlyAverageScores.reduce(
              (acc, { score }) => acc + (score ?? 0),
              0,
            ) /
            data.keyActionRecentlyAverageScores.filter((a) => a.score).length
          : null
        this.user = {
          name: data.name,
          departmentName: data.departmentName,
          thumbnailPath: data.thumbnailPath,
        }
        this.isLoading = false
      })
  }

  private getMonthsAgoDate(monthsAgo: number) {
    const dt = new Date()
    dt.setMonth(dt.getMonth() - monthsAgo)
    return dt
  }

  private transformToHighChartFormat(data: PeriodKeyActionScore[]) {
    const resultMap = new Map<string, [number, number | null][]>()
    const weekScoresMap = new Map()

    data.forEach(({ keyAction, weeklyScores }) => {
      if (!resultMap.has(keyAction.name)) {
        resultMap.set(keyAction.name, [])
      }

      const seriesData = resultMap.get(keyAction.name) ?? []

      weeklyScores.forEach(({ weekStartDate, score }) => {
        seriesData.push([
          weekStartDate.getTime(),
          score === null ? null : Math.floor(score * 100) / 100,
        ])

        const weekTime = weekStartDate.getTime()
        if (!weekScoresMap.has(weekTime)) {
          weekScoresMap.set(weekTime, { totalScore: 0, count: 0 })
        }
        const weekData = weekScoresMap.get(weekTime)
        weekData.totalScore += score ?? 0
        if (score) weekData.count++
        weekData.weekStartDate = weekStartDate
      })
    })

    const averageData: [number, number | null][] = []
    weekScoresMap.forEach(({ totalScore, weekStartDate, count }) => {
      averageData.push([
        weekStartDate.getTime(),
        totalScore ? Math.floor((totalScore / count) * 100) / 100 : null,
      ])
    })

    resultMap.set('総合スコア', averageData)

    return Array.from(resultMap.entries()).map(([name, data]) => ({
      name,
      data,
    }))
  }
}
