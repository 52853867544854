import { Component, ViewChild, ElementRef } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { BulkCreateUserParam } from '../../../../../../_generated/graphql'
import { MessageDialogService } from '../../../../../components/message-dialog/message-dialog.service'
import { CsvExportService } from '../../../../../services/csv-export/csv-export.service'
import { splitArrayBuffer } from '../../../../../util/file/binary'
import {
  validateEncoding,
  detectEncode,
} from '../../../../../util/file/encoding'
import {
  verifyCSVFormat,
  readFileAsArrayBuffer,
  csvToJson,
} from '../../../../../util/file/reader'
import {
  CsvFileUploadErrorItem,
  CsvUploadErrorComponent,
  CsvUploadErrorDialogParam,
} from '../../../../assessment/bulk-execution/messages/csv-upload-error/csv-upload-error.component'
import { RoleCodeJapanese, roleMapping } from '../../../../../utils/types'
import { EMAIL_REGEXP } from '../../../../../services/user/user.service'

export type CsvBulkCreateUser = {
  row: number
  name: string
  email: string
  departmentName: string
  // stringで受け取ってバリデーション時にRoleの値かどうかをチェックしている
  roles: string[]
  reportToEmail?: string
  // stringで受け取って値を返す時にbooleanにしている
  isInviteByMail?: string
}

const MAX_ROW_COUNT = 500
const CSV_HEADER_MAPPING = [
  { label: '名前', key: 'name' },
  { label: 'メールアドレス', key: 'email' },
  { label: '部署名', key: 'departmentName' },
  {
    label: 'ロール（全権管理者、イネーブラー、ユーザー）',
    key: 'roles',
  },
  { label: '上司のメールアドレス', key: 'reportToEmail' },
  {
    label: '招待メール送信（送信する場合は 「送信」 と入力）',
    key: 'isInviteByMail',
  },
]

const CSV_HEADERS = CSV_HEADER_MAPPING.map((header) => header.label)

@Component({
  selector: 'app-admin-user-csv-import',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './admin-user-csv-import.component.html',
  styleUrls: ['./admin-user-csv-import.component.scss'],
})
export class AdminUserCsvImportComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AdminUserCsvImportComponent>,
    private csvExportService: CsvExportService,
    private messageDialogService: MessageDialogService,
  ) {}

  onUploadButtonClick(): void {
    this.fileInput.nativeElement.click()
  }

  async onFileChange(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target.files?.length) {
      this.fileInput.nativeElement.value = ''
      return
    }
    const files = Array.from(target.files)
    if (files.length === 0) {
      this.fileInput.nativeElement.value = ''
      return
    }

    const file = files[0]
    this.fileInput.nativeElement.value = ''
    if (!file.name.toLowerCase().endsWith('.csv')) {
      this.messageDialogService.showError('CSVファイルを選択してください。', {
        title: '形式エラー',
      })
      return
    }

    if (!verifyCSVFormat(file)) {
      this.messageDialogService.showError('CSVファイルの形式が不正です。', {
        title: '形式エラー',
      })
      return
    }

    const arrayBuffer = await readFileAsArrayBuffer(file)
    const encoding = await validateEncoding(arrayBuffer, ['UTF8', 'SJIS'])
    if (!encoding) {
      this.messageDialogService.showError(
        'CSVファイルのエンコードが不正です。',
        {
          title: 'エンコードエラー',
        },
      )
      return
    }

    this.readCsv(file)
  }

  close(): void {
    this.dialogRef.close()
  }

  downloadCsvTemplate(): void {
    this.csvExportService.downloadCsv(
      'ユーザー一括登録_テンプレート.csv',
      CSV_HEADER_MAPPING,
      [],
    )
  }

  async readCsv(file: File): Promise<void> {
    const importedResults = await this.importCsv(file)
    if (importedResults.type === 'error') {
      return
    }

    const errors = this.validateCsv(importedResults.createUsers)
    if (errors.length > 0) {
      this.openErrorDialog(errors)
      return
    }

    // バリデーション通過後にBulkCreateUserParamの配列に変換する
    const bulkCreateUserParams: BulkCreateUserParam[] =
      importedResults.createUsers.map((csvUser) => {
        return {
          name: csvUser.name,
          email: csvUser.email,
          departmentName: csvUser.departmentName,
          // バリデーション通過しているのでキャストしている
          roles: csvUser.roles.map(
            (role) => roleMapping[role as RoleCodeJapanese],
          ),
          reportToEmail: csvUser.reportToEmail,
          isInviteByMail:
            csvUser.isInviteByMail?.trim().toLowerCase() === '送信',
        }
      })

    this.dialogRef.close(bulkCreateUserParams)
  }

  private async importCsv(file: File): Promise<
    | {
        type: 'success'
        createUsers: CsvBulkCreateUser[]
      }
    | {
        type: 'error'
      }
  > {
    const result = await readFileAsArrayBuffer(file)
    const codes = new Uint8Array(result)
    const detectedEncoding = await detectEncode(codes)

    const selectedEncode = detectedEncoding === 'SJIS' ? 'shift-jis' : 'utf-8'

    const rawData = splitArrayBuffer(result, 1024 * 1024)

    const csvString = new TextDecoder(selectedEncode).decode(rawData)

    const parsedData = csvToJson(csvString)
    // 値を削除したセルに空文字が入ることがあるので、空文字以外のデータを抽出する
    const nonEmptyParsedData = parsedData.filter((row) =>
      Object.values(row).some((value) => value != null && value.trim() !== ''),
    )

    if (nonEmptyParsedData.length === 0) {
      this.messageDialogService.showError(
        'CSVファイルに有効なデータがありません。',
        { title: 'データエラー' },
      )
      return { type: 'error' }
    }

    if (nonEmptyParsedData.length > MAX_ROW_COUNT) {
      this.messageDialogService.showError(
        `CSVファイルの行数が${MAX_ROW_COUNT}行を超えています。${MAX_ROW_COUNT}行以下にしてください。`,
        { title: '行数エラー' },
      )
      return { type: 'error' }
    }

    const headers = Object.keys(nonEmptyParsedData[0])
    const invalidHeaders = CSV_HEADERS.filter((h) => !headers.includes(h))
    if (invalidHeaders.length > 0) {
      this.messageDialogService.showError(
        `CSVファイルのヘッダーが不正です。${invalidHeaders.join('、')}が必要です。`,
        { title: 'ヘッダーエラー' },
      )
      return { type: 'error' }
    }

    const createUsers = nonEmptyParsedData.map((csvRow, index) =>
      this.mappingToCsvRows(csvRow, index),
    )

    return { type: 'success', createUsers }
  }

  private mappingToCsvRows(
    csvRow: Record<string, string>,
    row: number,
  ): CsvBulkCreateUser {
    const rolesString = csvRow['ロール（全権管理者、イネーブラー、ユーザー）']
    const roles: string[] = rolesString.split(/[,、]/).map((r) => r.trim())

    return {
      row,
      name: csvRow['名前'],
      email: csvRow['メールアドレス'],
      departmentName: csvRow['部署名'],
      roles,
      reportToEmail: csvRow['上司のメールアドレス'],
      isInviteByMail:
        csvRow['招待メール送信（送信する場合は 「送信」 と入力）'],
    }
  }

  private validateCsv(
    csvBulkCreateUsers: CsvBulkCreateUser[],
  ): CsvFileUploadErrorItem[] {
    const errors: CsvFileUploadErrorItem[] = []
    const validRoles: RoleCodeJapanese[] = [
      '全権管理者',
      'イネーブラー',
      'ユーザー',
    ]

    const seenEmails = new Map<string, number>()

    csvBulkCreateUsers.forEach((user, index) => {
      const rowNo = index + 2

      // メールアドレス重複チェック
      const trimmedEmail = user.email.trim()
      if (seenEmails.has(trimmedEmail)) {
        errors.push({
          rowNo,
          name: user.name,
          message: `${user.name} のメールアドレスが ${seenEmails.get(trimmedEmail)} 行目のユーザーと重複しています。`,
        })
      } else {
        seenEmails.set(trimmedEmail, rowNo)
      }

      if (!user.name) {
        errors.push({
          rowNo,
          name: user.name,
          message: `${rowNo} のユーザー名が入力されていません。`,
        })
      }
      if (!user.email) {
        errors.push({
          rowNo,
          name: user.name,
          message: `${user.name} のメールアドレスが入力されていません。`,
        })
      }
      if (user.email && !EMAIL_REGEXP.test(user.email)) {
        errors.push({
          rowNo,
          name: user.name,
          message: `${user.name} のメールアドレス形式が無効です。`,
        })
      }

      if (!user.roles || user.roles.length === 0) {
        errors.push({
          rowNo,
          name: user.name,
          message: `${user.name} のロールが入力されていません。`,
        })
      } else {
        // ユーザーのロールに無効な値が含まれていないかチェック
        const invalidRoles = user.roles.filter(
          (role) => !validRoles.includes(role as RoleCodeJapanese),
        )
        if (invalidRoles.length > 0) {
          errors.push({
            rowNo,
            name: user.name,
            message: `${user.name} のロールが無効です。`,
          })
        }
      }

      if (user.reportToEmail && user.reportToEmail.trim() !== '') {
        if (!EMAIL_REGEXP.test(user.reportToEmail)) {
          errors.push({
            rowNo,
            name: user.name,
            message: `${user.name} の上司のメールアドレス形式が無効です。`,
          })
        }
      }

      if (user.isInviteByMail && user.isInviteByMail.trim() !== '') {
        if (user.isInviteByMail.trim() !== '送信') {
          errors.push({
            rowNo,
            name: user.name,
            message: `${user.name} の招待メール送信の値が無効です。`,
          })
        }
      }
    })

    return errors
  }

  private openErrorDialog(errors: CsvFileUploadErrorItem[]): void {
    this.dialog.open<CsvUploadErrorComponent, CsvUploadErrorDialogParam>(
      CsvUploadErrorComponent,
      {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        data: {
          items: errors,
          type: 'users',
        },
      },
    )
  }
}
