import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { getTitle } from '../../util/accessibility'
@Component({
  selector: 'app-salesforce-auth',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './salesforce-auth.component.html',
  styleUrl: './salesforce-auth.component.scss',
})
export class SalesforceAuthComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle(getTitle('Salesforce認証'))
  }
}
