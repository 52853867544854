import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'

import { MatIcon } from '@angular/material/icon'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatTableModule } from '@angular/material/table'
import { MatTooltip } from '@angular/material/tooltip'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

import { finalize, forkJoin, mergeMap } from 'rxjs'
import { tap, catchError } from 'rxjs/operators'

import { OutboundConnectService } from '../../../../../_generated/graphql'

import { ButtonComponent } from '../../../../components/button/button.component'
import { AvatarComponent } from '../../../../components/avatar/avatar.component'
import { EllipsisTooltipDirective } from '../../../../directives/ellipsis-tooltip.directive'

import { ExternalIntegrationService } from '../../../../services/external-integration/external-integration.service'
import { UserForAdmin } from '../../../../services/user/user.mapping'
import { UserStore } from '../../../../stores/user.store'
import { LoadingComponent } from '../../../../components/loading/loading.component'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { SettingSaveErrorComponent } from '../messages/setting-save-error/setting-save-errorcomponent'

interface UserRow {
  selected: boolean
  id: string
  name: string
  email: string
  thumbnailPath: string | null
  departmentName: string
  reportTo: string
  remainingHours: number | null
  hasLicense: boolean
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatCheckbox,
    MatTableModule,
    MatTooltip,
    MatFormFieldModule,
    MatSelectModule,
    ButtonComponent,
    AvatarComponent,
    EllipsisTooltipDirective,
    LoadingComponent,
  ],
  templateUrl: './outbound-setting-dialog.component.html',
  styleUrl: './outbound-setting-dialog.component.scss',
})
export class OutboundSettingDialogComponent implements OnInit {
  isShowError = false
  isLoading = false

  displayedColumns: string[] = [
    'select',
    'userName',
    'userDepartmentName',
    'reportTo',
    'remainingHours',
    'license',
  ]
  users: UserRow[] = []

  id: string | undefined = undefined

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<OutboundSettingDialogComponent>,
    private snackBar: MatSnackBar,
    private userStore: UserStore,
    private externalIntegrationService: ExternalIntegrationService,
  ) {}

  ngOnInit() {
    this.isLoading = true

    const getOutboundIntegrationSetting = this.externalIntegrationService
      .getOutboundIntegrationSetting(OutboundConnectService.Salesforce)
      .pipe(
        tap((setting) => {
          if (setting === null) {
            return
          }

          this.id = setting.id

          const targetUsers = setting.targetUsers.map((user) => ({
            ...user,
            hasLicense: user.license !== null,
          }))
          targetUsers.forEach((user) => {
            const row = this.users.find((row) => row.email === user.email)
            if (row) {
              row.selected = true
            }
          })
        }),
      )

    forkJoin([
      this.userStore.searchUsersForAdmin(null, []).pipe(
        tap((users) => {
          this.users = users.map((user) => this.toUserRow(user))
        }),
      ),
    ])
      .pipe(
        mergeMap(() => getOutboundIntegrationSetting),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe()
  }

  onOutboundIntegrationSettingSave() {
    this.isShowError = true

    if (!this.userSelected()) {
      this.openOutboundIntegrationSettingSaveErrorDialog()
      return
    }

    this.isLoading = true

    this.externalIntegrationService
      .saveOutboundIntegrationSetting(
        {
          id: this.id,
          targetUserIds: this.users
            .filter((user) => user.selected)
            .map((user) => user.id),
        },
        OutboundConnectService.Salesforce,
      )
      .pipe(
        tap(() => {
          this.snackBar.open(
            '設定が正常に保存されました。設定に基づいて、今後の同期が実行されます。',
            '閉じる',
          )
          this.dialogRef.close()
        }),
        catchError(() => {
          this.snackBar.open(
            '設定の保存に失敗しました。再度お試しいただくか、管理者にお問い合わせください。',
            '閉じる',
          )
          return []
        }),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe()
  }

  get checkedCount(): number {
    return this.users.filter((row) => row.selected)?.length
  }

  get checkedState(): 'CHECKED' | 'UNCHECKED' | 'INDETERMINATE' {
    const checkedCount =
      this.users.filter((assessment) => assessment.selected)?.length ?? 0
    if (checkedCount === 0) {
      return 'UNCHECKED'
    } else if (checkedCount === this.users.length) {
      return 'CHECKED'
    } else {
      return 'INDETERMINATE'
    }
  }

  userSelected(): boolean {
    return this.users.filter((user) => user.selected).length > 0
  }

  checkAllUsers = (checked: boolean) => {
    this.users.forEach((user) => {
      if (checked && !user.selected) {
        if (user.hasLicense) {
          user.selected = checked
        }
      } else if (!checked && user.selected) {
        user.selected = checked
      }
    })
  }

  checkChanged = (user: UserRow) => {
    user.selected = !user.selected
  }

  closeSettingDialog() {
    this.dialogRef.close()
  }

  private toUserRow(user: UserForAdmin): UserRow {
    let remainingHours = null
    if (user.license) {
      const remainingSeconds =
        user.license.userMonthlyLimitSeconds -
        user.license.usedSeconds +
        user.license.extraChargeLimitSeconds
      remainingHours = Math.floor(remainingSeconds / 3600)
    }

    return {
      selected: false,
      id: user.id,
      name: user.name,
      email: user.email,
      thumbnailPath: user.thumbnailPath || null,
      departmentName: user.departmentName,
      reportTo: user.reportTo?.name || '',
      remainingHours: remainingHours,
      hasLicense: user.license !== null,
    }
  }

  private openOutboundIntegrationSettingSaveErrorDialog() {
    this.dialog.open(SettingSaveErrorComponent, {
      disableClose: true,
      panelClass: 'unset-mat-dialog-padding',
    })
  }
}
