import { Routes } from '@angular/router'

import { authGuard } from './guards/auth.guard'
import { licenseGuard } from './guards/license.guard'
import { roleGuard } from './guards/role.guard'
import { LicenseUsagePageComponent } from './pages/admin/license-usage-page/license-usage-page.component'
import { PrivacySettingPageComponent } from './pages/admin/privacy-setting-page/privacy-setting-page.component'
import { AdminUserListComponent } from './pages/admin/user/list/admin-user-list.component'
import { ExternalIntegrationPageComponent } from './pages/admin/external-integration-page/external-integration.component'
import { HomeComponent } from './pages/assessment/home/home.component'
import { AssessmentListPageComponent } from './pages/assessment-list-page/assessment-list-page.component'
import { AuthActionComponent } from './pages/auth_action/auth-action.component'
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component'
import { LoginPageComponent } from './pages/login-page/login-page.component'
import { MainPageComponent } from './pages/main-page/main-page.component'
import { NoLicensePageComponent } from './pages/no-license-page/no-license-page.component'
import { UserListPageComponent } from './pages/user-list-page/user-list-page.component'
import { IndividualComponent } from './pages/dashboard/individual/individual.component'
import { AssessmentBulkExecutionComponent } from './pages/assessment/bulk-execution/bulk-execution.component'
import { OrganizationComponent } from './pages/dashboard/organization/organization.component'

export const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPageComponent,
  },
  {
    path: 'no-license',
    component: NoLicensePageComponent,
  },
  {
    path: 'auth/action',
    component: AuthActionComponent,
  },
  {
    path: '',
    component: MainPageComponent,
    canActivate: [authGuard, roleGuard, licenseGuard],
    data: { roles: ['SUPER_ADMIN', 'ENABLER', 'MANAGER', 'USER'] },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'users',
        component: UserListPageComponent,
      },
      {
        path: 'assessments',
        children: [
          {
            path: '',
            component: AssessmentListPageComponent,
          },
          {
            path: 'bulk-execution',
            canActivate: [roleGuard],
            data: { roles: ['SUPER_ADMIN', 'ENABLER'] },
            component: AssessmentBulkExecutionComponent,
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            path: 'users',
            component: AdminUserListComponent,
            canActivate: [roleGuard],
            data: { roles: ['SUPER_ADMIN', 'ENABLER'] },
          },
          {
            path: 'license-usage',
            component: LicenseUsagePageComponent,
            canActivate: [roleGuard],
            data: { roles: ['SUPER_ADMIN', 'ENABLER'] },
          },
          {
            path: 'privacy-setting',
            component: PrivacySettingPageComponent,
            canActivate: [roleGuard],
            data: { roles: ['SUPER_ADMIN'] },
          },
          {
            path: 'external-integration',
            component: ExternalIntegrationPageComponent,
            canActivate: [roleGuard],
            data: { roles: ['SUPER_ADMIN', 'ENABLER'] },
          },
        ],
      },
      {
        path: 'dashboard',
        children: [
          {
            path: 'individual',
            component: IndividualComponent,
          },
          {
            path: 'organization',
            component: OrganizationComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'main',
  },
]
