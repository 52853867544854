export const splitArrayBuffer = (
  arrayBuffer: ArrayBuffer,
  chunkSize: number,
): ArrayBuffer => {
  const chunks = []
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < arrayBuffer.byteLength; i += chunkSize) {
    chunks.push(uint8Array.subarray(i, i + chunkSize))
  }
  const combined = new Uint8Array(arrayBuffer.byteLength)
  let offset = 0
  for (const chunk of chunks) {
    combined.set(chunk, offset)
    offset += chunk.byteLength
  }
  return combined.buffer
}
