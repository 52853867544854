import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  Date: { input: Date; output: Date; }
  Void: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type AdminOrg = {
  __typename?: 'AdminOrg';
  activationDate: Scalars['Date']['output'];
  departmentNames: Array<Scalars['String']['output']>;
  departments: Array<Department>;
  id: Scalars['String']['output'];
  licenseInfo: LicenseInfo;
  name: Scalars['String']['output'];
  periodKeyActionScores: Array<PeriodKeyActionScore>;
  privacySetting: PrivacySetting;
  requiredMfa: Scalars['Boolean']['output'];
  users: Array<User>;
};


export type AdminOrgPeriodKeyActionScoresArgs = {
  endDate: Scalars['Date']['input'];
  skillMapIds: Array<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
};

export type Assessment = {
  __typename?: 'Assessment';
  assessDate: Scalars['Date']['output'];
  businessMeeting: BusinessMeeting;
  createdAt: Scalars['Date']['output'];
  durationSeconds: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  keyActionEvaluations: Array<KeyActionEvaluation>;
  orgId: Scalars['String']['output'];
  skillMapName: Scalars['String']['output'];
  status: AssessmentStatus;
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type AssessmentCsvData = {
  __typename?: 'AssessmentCsvData';
  accountName?: Maybe<Scalars['String']['output']>;
  businessMeetingRecordDate: Scalars['Date']['output'];
  businessMeetingTitle: Scalars['String']['output'];
  departmentName?: Maybe<Scalars['String']['output']>;
  evaluation: Scalars['String']['output'];
  highlight: Scalars['String']['output'];
  keyActionName: Scalars['String']['output'];
  reportToName?: Maybe<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  userEmail: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export enum AssessmentExecutionType {
  Batch = 'BATCH',
  RealTime = 'REAL_TIME'
}

export enum AssessmentQuestionType {
  MultipleChoice = 'MULTIPLE_CHOICE',
  Scale = 'SCALE'
}

export enum AssessmentStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type AutoAssessmentSetting = {
  __typename?: 'AutoAssessmentSetting';
  blackList: Array<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  lastSyncDate?: Maybe<Scalars['Date']['output']>;
  minTransferDurationSeconds?: Maybe<Scalars['Int']['output']>;
  orgId: Scalars['String']['output'];
  syncStartDate: Scalars['Date']['output'];
  targetPhaseIds: Array<Scalars['String']['output']>;
  targetSkillMap: SkillMap;
  targetUsers: Array<User>;
  updatedAt: Scalars['Date']['output'];
  whiteList: Array<Scalars['String']['output']>;
};

export type BulkCreateUserParam = {
  departmentName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  isInviteByMail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  reportToEmail?: InputMaybe<Scalars['String']['input']>;
  roles: Array<Scalars['String']['input']>;
};

export type BusinessMeeting = {
  __typename?: 'BusinessMeeting';
  account?: Maybe<Account>;
  applicablePhaseNames: Array<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  filePath: Scalars['String']['output'];
  id: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  recordDate: Scalars['Date']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  transcript: Array<TranscriptEntry>;
  updatedAt: Scalars['Date']['output'];
};

export type Content = {
  __typename?: 'Content';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  url: Scalars['String']['output'];
};

export type CreateAssessmentInput = {
  accountName?: InputMaybe<Scalars['String']['input']>;
  assessmentExecutionType: AssessmentExecutionType;
  fileName: Scalars['String']['input'];
  phaseIds: Array<Scalars['String']['input']>;
  recordDate: Scalars['Date']['input'];
  skillMapId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateUserParam = {
  departmentName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  licenseId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  reportToEmail?: InputMaybe<Scalars['String']['input']>;
  requiredMfa: Scalars['Boolean']['input'];
  roles: Array<Scalars['String']['input']>;
  thumbnailPath?: InputMaybe<Scalars['String']['input']>;
};

export type Department = {
  __typename?: 'Department';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  periodKeyActionScores: Array<PeriodKeyActionScore>;
};


export type DepartmentPeriodKeyActionScoresArgs = {
  endDate: Scalars['Date']['input'];
  skillMapIds: Array<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
};

export type ExtraCharge = {
  __typename?: 'ExtraCharge';
  limitSeconds: Scalars['Int']['output'];
};

export enum InboundConnectService {
  AmpTalk = 'AMP_TALK',
  Zoom = 'ZOOM'
}

export type KeyAction = {
  __typename?: 'KeyAction';
  assessmentQuestionType: AssessmentQuestionType;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orderNo: Scalars['Int']['output'];
  orgId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type KeyActionEvaluation = {
  __typename?: 'KeyActionEvaluation';
  createdAt: Scalars['Date']['output'];
  evaluation: Scalars['String']['output'];
  highlight: Scalars['String']['output'];
  id: Scalars['String']['output'];
  keyAction: KeyAction;
  orgId: Scalars['String']['output'];
  recommendedContents: Array<RecommendedContent>;
  score: Scalars['Float']['output'];
  timeSeriesEvaluations: Array<TimeSeriesEvaluation>;
  updatedAt: Scalars['Date']['output'];
};

export type KeyActionRecentlyAverageScore = {
  __typename?: 'KeyActionRecentlyAverageScore';
  keyAction: KeyAction;
  recentlyAverageScore: Scalars['Float']['output'];
};

export type KeyActionScore = {
  __typename?: 'KeyActionScore';
  keyAction: KeyAction;
  score: Scalars['Float']['output'];
};

export type License = {
  __typename?: 'License';
  assessmentsCount: Scalars['Int']['output'];
  extraCharges: Array<ExtraCharge>;
  id: Scalars['String']['output'];
  usedSeconds: Scalars['Int']['output'];
  user?: Maybe<User>;
  userMonthlyLimitSeconds: Scalars['Int']['output'];
};

export type LicenseInfo = {
  __typename?: 'LicenseInfo';
  count: Scalars['Int']['output'];
  expiration: Scalars['Date']['output'];
  plan: LicensePlan;
  remainingLicenses: Array<License>;
  usedCount: Scalars['Int']['output'];
  userMonthlyLimitSeconds: Scalars['Int']['output'];
};

export enum LicensePlan {
  Advanced = 'ADVANCED',
  Basic = 'BASIC'
}

export type MaskedSecretKey = {
  __typename?: 'MaskedSecretKey';
  keyType: SecretKeyType;
  maskedKey: Scalars['String']['output'];
};

export type MfaStatus = {
  __typename?: 'MfaStatus';
  phoneNumber?: Maybe<Scalars['String']['output']>;
  requiredForUser: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveAssessment?: Maybe<Scalars['Void']['output']>;
  archiveAssessments?: Maybe<Scalars['Void']['output']>;
  assignLicense?: Maybe<Scalars['Void']['output']>;
  bulkCreateUser?: Maybe<Scalars['Void']['output']>;
  createAssessment: Scalars['String']['output'];
  createUser: Scalars['String']['output'];
  deleteUsers?: Maybe<Scalars['Void']['output']>;
  disconnectAmptalk?: Maybe<Scalars['Void']['output']>;
  disconnectSalesforce?: Maybe<Scalars['Void']['output']>;
  disconnectZoom?: Maybe<Scalars['Void']['output']>;
  markContentAsAccessed?: Maybe<Scalars['Void']['output']>;
  revokeLicense?: Maybe<Scalars['Void']['output']>;
  saveAmptalkKey?: Maybe<Scalars['Void']['output']>;
  saveAutoAssessmentSetting: Scalars['String']['output'];
  saveOutboundIntegrationSetting: Scalars['String']['output'];
  saveSalesforceToken?: Maybe<Scalars['Void']['output']>;
  saveZoomToken?: Maybe<Scalars['Void']['output']>;
  sendInvitationEmails?: Maybe<Scalars['Void']['output']>;
  updateAssessment: Scalars['String']['output'];
  updatePrivacySetting?: Maybe<Scalars['Void']['output']>;
  updateRequiredMfa?: Maybe<Scalars['Void']['output']>;
  updateUser: Scalars['String']['output'];
};


export type MutationArchiveAssessmentArgs = {
  assessmentId: Scalars['String']['input'];
};


export type MutationArchiveAssessmentsArgs = {
  assessmentIds: Array<Scalars['String']['input']>;
};


export type MutationAssignLicenseArgs = {
  licenseId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationBulkCreateUserArgs = {
  params: Array<BulkCreateUserParam>;
};


export type MutationCreateAssessmentArgs = {
  input: CreateAssessmentInput;
};


export type MutationCreateUserArgs = {
  param: CreateUserParam;
};


export type MutationDeleteUsersArgs = {
  userIds: Array<Scalars['String']['input']>;
};


export type MutationMarkContentAsAccessedArgs = {
  recommendedContentId: Scalars['String']['input'];
};


export type MutationRevokeLicenseArgs = {
  userId: Scalars['String']['input'];
};


export type MutationSaveAmptalkKeyArgs = {
  apiKey: Scalars['String']['input'];
  authKey: Scalars['String']['input'];
};


export type MutationSaveAutoAssessmentSettingArgs = {
  param: SaveAutoAssessmentSettingParam;
};


export type MutationSaveOutboundIntegrationSettingArgs = {
  param: SaveOutboundIntegrationSettingParam;
};


export type MutationSaveSalesforceTokenArgs = {
  code: Scalars['String']['input'];
};


export type MutationSaveZoomTokenArgs = {
  code: Scalars['String']['input'];
};


export type MutationSendInvitationEmailsArgs = {
  userIds: Array<Scalars['String']['input']>;
};


export type MutationUpdateAssessmentArgs = {
  input: UpdateAssessmentInput;
};


export type MutationUpdatePrivacySettingArgs = {
  privacySetting: PrivacySetting;
};


export type MutationUpdateRequiredMfaArgs = {
  requiredMfa: Scalars['Boolean']['input'];
};


export type MutationUpdateUserArgs = {
  param: UpdateUserParam;
};

export enum OutboundConnectService {
  Salesforce = 'SALESFORCE'
}

export type OutboundIntegrationSetting = {
  __typename?: 'OutboundIntegrationSetting';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  targetUsers: Array<User>;
  updatedAt: Scalars['Date']['output'];
};

export type Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PeriodKeyActionScore = {
  __typename?: 'PeriodKeyActionScore';
  endDate: Scalars['Date']['output'];
  keyAction: KeyAction;
  startDate: Scalars['Date']['output'];
  weeklyScores: Array<WeeklyScore>;
};

export type Phase = {
  __typename?: 'Phase';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  keyActions: Array<KeyAction>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export enum PrivacySetting {
  FullyClosed = 'FULLY_CLOSED',
  FullyOpen = 'FULLY_OPEN',
  PartiallyOpen = 'PARTIALLY_OPEN'
}

export type Query = {
  __typename?: 'Query';
  checkMfaStatus: MfaStatus;
  checkSecretKeyExistence: Scalars['Boolean']['output'];
  exportAssessments: Array<AssessmentCsvData>;
  getAccounts: Array<Account>;
  getAdminOrg: AdminOrg;
  getAssessment: Assessment;
  getAutoAssessmentSetting?: Maybe<AutoAssessmentSetting>;
  getLicenses: Array<License>;
  getMaskedKeys: Array<MaskedSecretKey>;
  getOutboundIntegrationSetting?: Maybe<OutboundIntegrationSetting>;
  getRoles: Array<Role>;
  getSkillMaps: Array<SkillMap>;
  getUserById?: Maybe<User>;
  getUserOneSelf?: Maybe<User>;
  getUsers: Array<User>;
  searchAssessments: Array<Assessment>;
  validateSalesforceIntegrationSetting: SalesforceValidationResult;
};


export type QueryCheckSecretKeyExistenceArgs = {
  secretKeyType: SecretKeyType;
};


export type QueryGetAssessmentArgs = {
  assessmentId: Scalars['String']['input'];
};


export type QueryGetAutoAssessmentSettingArgs = {
  connectService: InboundConnectService;
};


export type QueryGetLicensesArgs = {
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMaskedKeysArgs = {
  connectService: InboundConnectService;
};


export type QueryGetOutboundIntegrationSettingArgs = {
  connectService: OutboundConnectService;
};


export type QueryGetUserByIdArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUsersArgs = {
  departmentNames: Array<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  query: Scalars['String']['input'];
  sort?: InputMaybe<Sort>;
};


export type QuerySearchAssessmentsArgs = {
  pagination: Pagination;
  query: Scalars['String']['input'];
  sort?: InputMaybe<Sort>;
  status: SearchAssessmentStatus;
};

export type RecommendedContent = {
  __typename?: 'RecommendedContent';
  content: Content;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  isAccessed: Scalars['Boolean']['output'];
  orgId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Role = {
  __typename?: 'Role';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SalesforceValidationError = {
  __typename?: 'SalesforceValidationError';
  actualType?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  expectedType?: Maybe<Scalars['String']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  minLength?: Maybe<Scalars['Int']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
};

export type SalesforceValidationResult = {
  __typename?: 'SalesforceValidationResult';
  errors: Array<SalesforceValidationError>;
  isValid: Scalars['Boolean']['output'];
};

export type SaveAutoAssessmentSettingParam = {
  blackList: Array<Scalars['String']['input']>;
  connectServiceName: InboundConnectService;
  id?: InputMaybe<Scalars['String']['input']>;
  minTransferDurationSeconds?: InputMaybe<Scalars['Int']['input']>;
  phaseIds: Array<Scalars['String']['input']>;
  skillMapId: Scalars['String']['input'];
  syncStartDate: Scalars['Date']['input'];
  targetUserIds: Array<Scalars['String']['input']>;
  whiteList: Array<Scalars['String']['input']>;
};

export type SaveOutboundIntegrationSettingParam = {
  connectServiceName: OutboundConnectService;
  id?: InputMaybe<Scalars['String']['input']>;
  targetUserIds: Array<Scalars['String']['input']>;
};

export enum SearchAssessmentStatus {
  All = 'ALL',
  ProgressStatuses = 'PROGRESS_STATUSES'
}

export enum SecretKeyType {
  AmptalkApi = 'AMPTALK_API',
  AmptalkAuth = 'AMPTALK_AUTH',
  SalesforceRefreshToken = 'SALESFORCE_REFRESH_TOKEN',
  ZoomRefreshToken = 'ZOOM_REFRESH_TOKEN'
}

export type SkillMap = {
  __typename?: 'SkillMap';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  phases: Array<Phase>;
  updatedAt: Scalars['Date']['output'];
};

export type Sort = {
  sortField: Scalars['String']['input'];
  sortOrder: Scalars['String']['input'];
};

export type TimeSeriesEvaluation = {
  __typename?: 'TimeSeriesEvaluation';
  fromTime: Scalars['String']['output'];
  improvement: Scalars['String']['output'];
  quote: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  strength: Scalars['String']['output'];
  toTime: Scalars['String']['output'];
};

export type TranscriptEntry = {
  __typename?: 'TranscriptEntry';
  quote: Scalars['String']['output'];
  speakedAt: Scalars['String']['output'];
  speaker: Scalars['String']['output'];
};

export type UpdateAssessmentInput = {
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateUserParam = {
  departmentName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  licenseId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reportToEmail?: InputMaybe<Scalars['String']['input']>;
  requiredMfa: Scalars['Boolean']['input'];
  roles: Array<Scalars['String']['input']>;
  thumbnailPath?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  assessments: Array<Assessment>;
  createdAt: Scalars['Date']['output'];
  departmentName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isManager: Scalars['Boolean']['output'];
  keyActionRecentlyAverageScores: Array<KeyActionRecentlyAverageScore>;
  latestAssessment?: Maybe<Assessment>;
  license?: Maybe<UserLicense>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  periodKeyActionScores: Array<PeriodKeyActionScore>;
  reportTo?: Maybe<User>;
  requiredMfa: Scalars['Boolean']['output'];
  roles: Array<Role>;
  thumbnailPath?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};


export type UserKeyActionRecentlyAverageScoresArgs = {
  skillMapIds: Array<Scalars['String']['input']>;
};


export type UserPeriodKeyActionScoresArgs = {
  endDate: Scalars['Date']['input'];
  skillMapIds: Array<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
};

export type UserLicense = {
  __typename?: 'UserLicense';
  extraChargeLimitSeconds: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  plan: LicensePlan;
  usedSeconds: Scalars['Int']['output'];
  userMonthlyLimitSeconds: Scalars['Int']['output'];
};

export type WeeklyScore = {
  __typename?: 'WeeklyScore';
  score?: Maybe<Scalars['Float']['output']>;
  weekStartDate: Scalars['Date']['output'];
};

export type CreateAssessmentMutationVariables = Exact<{
  input: CreateAssessmentInput;
}>;


export type CreateAssessmentMutation = { __typename?: 'Mutation', createAssessment: string };

export type UpdateAssessmentMutationVariables = Exact<{
  input: UpdateAssessmentInput;
}>;


export type UpdateAssessmentMutation = { __typename?: 'Mutation', updateAssessment: string };

export type MarkContentAsAccessedMutationVariables = Exact<{
  recommendedContentId: Scalars['String']['input'];
}>;


export type MarkContentAsAccessedMutation = { __typename?: 'Mutation', markContentAsAccessed?: any | null };

export type ArchiveAssessmentMutationVariables = Exact<{
  assessmentId: Scalars['String']['input'];
}>;


export type ArchiveAssessmentMutation = { __typename?: 'Mutation', archiveAssessment?: any | null };

export type ArchiveAssessmentsMutationVariables = Exact<{
  assessmentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ArchiveAssessmentsMutation = { __typename?: 'Mutation', archiveAssessments?: any | null };

export type GetAssessmentQueryVariables = Exact<{
  assessmentId: Scalars['String']['input'];
}>;


export type GetAssessmentQuery = { __typename?: 'Query', getAssessment: { __typename?: 'Assessment', skillMapName: string, durationSeconds: number, id: string, orgId: string, createdAt: Date, updatedAt: Date, assessDate: Date, status: AssessmentStatus, keyActionEvaluations: Array<{ __typename?: 'KeyActionEvaluation', evaluation: string, highlight: string, id: string, orgId: string, createdAt: Date, updatedAt: Date, score: number, keyAction: { __typename?: 'KeyAction', id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string, orderNo: number, assessmentQuestionType: AssessmentQuestionType }, timeSeriesEvaluations: Array<{ __typename?: 'TimeSeriesEvaluation', fromTime: string, toTime: string, score: number, reason: string, improvement: string, strength: string, quote: string }>, recommendedContents: Array<{ __typename?: 'RecommendedContent', id: string, isAccessed: boolean, content: { __typename?: 'Content', name: string, url: string } }> }>, businessMeeting: { __typename?: 'BusinessMeeting', applicablePhaseNames: Array<string>, summary?: string | null, reference?: string | null, id: string, orgId: string, createdAt: Date, updatedAt: Date, recordDate: Date, filePath: string, title: string, transcript: Array<{ __typename?: 'TranscriptEntry', speaker: string, speakedAt: string, quote: string }>, account?: { __typename?: 'Account', id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string } | null }, user: { __typename?: 'User', id: string, orgId: string, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, reportTo?: { __typename?: 'User', id: string } | null } } };

export type SearchAssessmentsQueryVariables = Exact<{
  query: Scalars['String']['input'];
  pagination: Pagination;
  status: SearchAssessmentStatus;
  sort?: InputMaybe<Sort>;
}>;


export type SearchAssessmentsQuery = { __typename?: 'Query', searchAssessments: Array<{ __typename?: 'Assessment', id: string, orgId: string, createdAt: Date, updatedAt: Date, assessDate: Date, status: AssessmentStatus, keyActionEvaluations: Array<{ __typename?: 'KeyActionEvaluation', id: string, orgId: string, createdAt: Date, updatedAt: Date, score: number, recommendedContents: Array<{ __typename?: 'RecommendedContent', id: string, isAccessed: boolean, content: { __typename?: 'Content', name: string, url: string } }> }>, businessMeeting: { __typename?: 'BusinessMeeting', id: string, orgId: string, createdAt: Date, updatedAt: Date, recordDate: Date, filePath: string, title: string, account?: { __typename?: 'Account', id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string } | null }, user: { __typename?: 'User', id: string, orgId: string, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, reportTo?: { __typename?: 'User', id: string } | null } }> };

export type GetSkillMapsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSkillMapsQuery = { __typename?: 'Query', getSkillMaps: Array<{ __typename?: 'SkillMap', id: string, name: string, orgId: string, createdAt: Date, updatedAt: Date, phases: Array<{ __typename?: 'Phase', id: string, name: string, orgId: string, createdAt: Date, updatedAt: Date, keyActions: Array<{ __typename?: 'KeyAction', id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string, orderNo: number }> }> }> };

export type AssessmentFragmentFragment = { __typename?: 'Assessment', id: string, orgId: string, createdAt: Date, updatedAt: Date, assessDate: Date, status: AssessmentStatus, user: { __typename?: 'User', id: string, orgId: string, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, reportTo?: { __typename?: 'User', id: string } | null } };

export type BaseBusinessMeetingFragmentFragment = { __typename?: 'BusinessMeeting', id: string, orgId: string, createdAt: Date, updatedAt: Date, recordDate: Date, filePath: string, title: string, account?: { __typename?: 'Account', id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string } | null };

export type KeyActionEvaluationFragmentFragment = { __typename?: 'KeyActionEvaluation', id: string, orgId: string, createdAt: Date, updatedAt: Date, score: number, recommendedContents: Array<{ __typename?: 'RecommendedContent', id: string, isAccessed: boolean, content: { __typename?: 'Content', name: string, url: string } }> };

export type ExportAssessmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportAssessmentsQuery = { __typename?: 'Query', exportAssessments: Array<{ __typename?: 'AssessmentCsvData', userName: string, userEmail: string, departmentName?: string | null, reportToName?: string | null, businessMeetingTitle: string, accountName?: string | null, businessMeetingRecordDate: Date, keyActionName: string, score: number, evaluation: string }> };

export type SaveAmptalkKeyMutationVariables = Exact<{
  apiKey: Scalars['String']['input'];
  authKey: Scalars['String']['input'];
}>;


export type SaveAmptalkKeyMutation = { __typename?: 'Mutation', saveAmptalkKey?: any | null };

export type SaveZoomTokenMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type SaveZoomTokenMutation = { __typename?: 'Mutation', saveZoomToken?: any | null };

export type SaveSalesforceTokenMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type SaveSalesforceTokenMutation = { __typename?: 'Mutation', saveSalesforceToken?: any | null };

export type SaveAutoAssessmentSettingMutationVariables = Exact<{
  param: SaveAutoAssessmentSettingParam;
}>;


export type SaveAutoAssessmentSettingMutation = { __typename?: 'Mutation', saveAutoAssessmentSetting: string };

export type SaveOutboundIntegrationSettingMutationVariables = Exact<{
  param: SaveOutboundIntegrationSettingParam;
}>;


export type SaveOutboundIntegrationSettingMutation = { __typename?: 'Mutation', saveOutboundIntegrationSetting: string };

export type DisconnectAmptalkMutationVariables = Exact<{ [key: string]: never; }>;


export type DisconnectAmptalkMutation = { __typename?: 'Mutation', disconnectAmptalk?: any | null };

export type DisconnectZoomMutationVariables = Exact<{ [key: string]: never; }>;


export type DisconnectZoomMutation = { __typename?: 'Mutation', disconnectZoom?: any | null };

export type DisconnectSalesforceMutationVariables = Exact<{ [key: string]: never; }>;


export type DisconnectSalesforceMutation = { __typename?: 'Mutation', disconnectSalesforce?: any | null };

export type GetMaskedKeysQueryVariables = Exact<{
  connectService: InboundConnectService;
}>;


export type GetMaskedKeysQuery = { __typename?: 'Query', getMaskedKeys: Array<{ __typename?: 'MaskedSecretKey', maskedKey: string, keyType: SecretKeyType }> };

export type GetAutoAssessmentSettingQueryVariables = Exact<{
  connectService: InboundConnectService;
}>;


export type GetAutoAssessmentSettingQuery = { __typename?: 'Query', getAutoAssessmentSetting?: { __typename?: 'AutoAssessmentSetting', id: string, targetPhaseIds: Array<string>, minTransferDurationSeconds?: number | null, syncStartDate: Date, whiteList: Array<string>, blackList: Array<string>, targetUsers: Array<{ __typename?: 'User', id: string, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, reportTo?: { __typename?: 'User', id: string, name: string } | null, license?: { __typename?: 'UserLicense', id: string, plan: LicensePlan, userMonthlyLimitSeconds: number, extraChargeLimitSeconds: number, usedSeconds: number } | null }>, targetSkillMap: { __typename?: 'SkillMap', id: string, name: string } } | null };

export type GetOutboundIntegrationSettingQueryVariables = Exact<{
  connectService: OutboundConnectService;
}>;


export type GetOutboundIntegrationSettingQuery = { __typename?: 'Query', getOutboundIntegrationSetting?: { __typename?: 'OutboundIntegrationSetting', id: string, targetUsers: Array<{ __typename?: 'User', id: string, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, reportTo?: { __typename?: 'User', id: string, name: string } | null, license?: { __typename?: 'UserLicense', id: string, plan: LicensePlan, userMonthlyLimitSeconds: number, extraChargeLimitSeconds: number, usedSeconds: number } | null }> } | null };

export type CheckSecretKeyExistenceQueryVariables = Exact<{
  secretKeyType: SecretKeyType;
}>;


export type CheckSecretKeyExistenceQuery = { __typename?: 'Query', checkSecretKeyExistence: boolean };

export type ValidateSalesforceIntegrationSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateSalesforceIntegrationSettingQuery = { __typename?: 'Query', validateSalesforceIntegrationSetting: { __typename?: 'SalesforceValidationResult', isValid: boolean, errors: Array<{ __typename?: 'SalesforceValidationError', code: string, message: string, objectName?: string | null, fieldName?: string | null, expectedType?: string | null, actualType?: string | null, minLength?: number | null }> } };

export type GetLicensesQueryVariables = Exact<{
  yearMonth?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetLicensesQuery = { __typename?: 'Query', getLicenses: Array<{ __typename?: 'License', id: string, userMonthlyLimitSeconds: number, usedSeconds: number, assessmentsCount: number, extraCharges: Array<{ __typename?: 'ExtraCharge', limitSeconds: number }>, user?: { __typename?: 'User', id: string, orgId: string, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, reportTo?: { __typename?: 'User', name: string } | null, license?: { __typename?: 'UserLicense', plan: LicensePlan, userMonthlyLimitSeconds: number, extraChargeLimitSeconds: number, usedSeconds: number } | null } | null }> };

export type AssignLicenseMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  licenseId: Scalars['String']['input'];
}>;


export type AssignLicenseMutation = { __typename?: 'Mutation', assignLicense?: any | null };

export type RevokeLicenseMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type RevokeLicenseMutation = { __typename?: 'Mutation', revokeLicense?: any | null };

export type CreateUserMutationVariables = Exact<{
  param: CreateUserParam;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: string };

export type BulkCreateUserMutationVariables = Exact<{
  params: Array<BulkCreateUserParam> | BulkCreateUserParam;
}>;


export type BulkCreateUserMutation = { __typename?: 'Mutation', bulkCreateUser?: any | null };

export type UpdateUserMutationVariables = Exact<{
  param: UpdateUserParam;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: string };

export type DeleteUsersMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteUsersMutation = { __typename?: 'Mutation', deleteUsers?: any | null };

export type SendInvitationsMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SendInvitationsMutation = { __typename?: 'Mutation', sendInvitationEmails?: any | null };

export type UpdatePrivacySettingMutationVariables = Exact<{
  privacySetting: PrivacySetting;
}>;


export type UpdatePrivacySettingMutation = { __typename?: 'Mutation', updatePrivacySetting?: any | null };

export type UpdateRequiredMfaMutationVariables = Exact<{
  requiredMfa: Scalars['Boolean']['input'];
}>;


export type UpdateRequiredMfaMutation = { __typename?: 'Mutation', updateRequiredMfa?: any | null };

export type GetUserOneSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserOneSelfQuery = { __typename?: 'Query', getUserOneSelf?: { __typename?: 'User', isManager: boolean, id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, roles: Array<{ __typename?: 'Role', name: string, code: string }>, license?: { __typename?: 'UserLicense', plan: LicensePlan } | null } | null };

export type GetAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountsQuery = { __typename?: 'Query', getAccounts: Array<{ __typename?: 'Account', id: string, name: string }> };

export type SearchUsersForAdminQueryVariables = Exact<{
  query: Scalars['String']['input'];
  departmentNames: Array<Scalars['String']['input']> | Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type SearchUsersForAdminQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', requiredMfa: boolean, id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, reportTo?: { __typename?: 'User', id: string, name: string, email: string } | null, roles: Array<{ __typename?: 'Role', name: string, code: string }>, license?: { __typename?: 'UserLicense', id: string, plan: LicensePlan, userMonthlyLimitSeconds: number, extraChargeLimitSeconds: number, usedSeconds: number } | null }> };

export type SearchUsersQueryVariables = Exact<{
  query: Scalars['String']['input'];
  departmentNames: Array<Scalars['String']['input']> | Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type SearchUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, license?: { __typename?: 'UserLicense', id: string, plan: LicensePlan, userMonthlyLimitSeconds: number, extraChargeLimitSeconds: number, usedSeconds: number } | null, assessments: Array<{ __typename?: 'Assessment', id: string, status: AssessmentStatus, assessDate: Date, businessMeeting: { __typename?: 'BusinessMeeting', title: string, recordDate: Date } }>, latestAssessment?: { __typename?: 'Assessment', id: string, assessDate: Date, businessMeeting: { __typename?: 'BusinessMeeting', title: string, recordDate: Date }, keyActionEvaluations: Array<{ __typename?: 'KeyActionEvaluation', score: number }> } | null }> };

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserByIdQuery = { __typename?: 'Query', getUserById?: { __typename?: 'User', id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null, license?: { __typename?: 'UserLicense', id: string, plan: LicensePlan, userMonthlyLimitSeconds: number, extraChargeLimitSeconds: number, usedSeconds: number } | null, assessments: Array<{ __typename?: 'Assessment', id: string, status: AssessmentStatus, assessDate: Date, businessMeeting: { __typename?: 'BusinessMeeting', title: string, recordDate: Date } }>, reportTo?: { __typename?: 'User', id: string } | null } | null };

export type GetAdminOrgQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminOrgQuery = { __typename?: 'Query', getAdminOrg: { __typename?: 'AdminOrg', departmentNames: Array<string>, privacySetting: PrivacySetting, requiredMfa: boolean, licenseInfo: { __typename?: 'LicenseInfo', plan: LicensePlan, count: number, expiration: Date, userMonthlyLimitSeconds: number, usedCount: number, remainingLicenses: Array<{ __typename?: 'License', id: string, userMonthlyLimitSeconds: number, usedSeconds: number, extraCharges: Array<{ __typename?: 'ExtraCharge', limitSeconds: number }> }> } } };

export type GetWeeklyScoresQueryVariables = Exact<{
  skillMapIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;


export type GetWeeklyScoresQuery = { __typename?: 'Query', getAdminOrg: { __typename?: 'AdminOrg', name: string, periodKeyActionScores: Array<{ __typename?: 'PeriodKeyActionScore', keyAction: { __typename?: 'KeyAction', id: string, name: string }, weeklyScores: Array<{ __typename?: 'WeeklyScore', weekStartDate: Date, score?: number | null }> }>, departments: Array<{ __typename?: 'Department', name: string, periodKeyActionScores: Array<{ __typename?: 'PeriodKeyActionScore', keyAction: { __typename?: 'KeyAction', id: string, name: string }, weeklyScores: Array<{ __typename?: 'WeeklyScore', weekStartDate: Date, score?: number | null }> }> }>, users: Array<{ __typename?: 'User', departmentName?: string | null, keyActionRecentlyAverageScores: Array<{ __typename?: 'KeyActionRecentlyAverageScore', recentlyAverageScore: number, keyAction: { __typename?: 'KeyAction', id: string, name: string } }> }> } };

export type GetDepartmentNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDepartmentNamesQuery = { __typename?: 'Query', getAdminOrg: { __typename?: 'AdminOrg', departmentNames: Array<string> } };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', getRoles: Array<{ __typename?: 'Role', name: string, code: string }> };

export type GetWeeklyUserScoresQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  skillMapIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetWeeklyUserScoresQuery = { __typename?: 'Query', getUserById?: { __typename?: 'User', id: string, name: string, departmentName?: string | null, thumbnailPath?: string | null, keyActionRecentlyAverageScores: Array<{ __typename?: 'KeyActionRecentlyAverageScore', recentlyAverageScore: number, keyAction: { __typename?: 'KeyAction', id: string, name: string } }>, periodKeyActionScores: Array<{ __typename?: 'PeriodKeyActionScore', keyAction: { __typename?: 'KeyAction', id: string, name: string }, weeklyScores: Array<{ __typename?: 'WeeklyScore', weekStartDate: Date, score?: number | null }> }> } | null };

export type CheckMfaStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckMfaStatusQuery = { __typename?: 'Query', checkMfaStatus: { __typename?: 'MfaStatus', requiredForUser: boolean, phoneNumber?: string | null } };

export type UserFragmentFragment = { __typename?: 'User', id: string, orgId: string, createdAt: Date, updatedAt: Date, name: string, email: string, departmentName?: string | null, thumbnailPath?: string | null };

export const AssessmentFragmentFragmentDoc = gql`
    fragment assessmentFragment on Assessment {
  id
  orgId
  createdAt
  updatedAt
  user {
    id
    orgId
    name
    email
    departmentName
    thumbnailPath
    reportTo {
      id
    }
  }
  assessDate
  status
}
    `;
export const BaseBusinessMeetingFragmentFragmentDoc = gql`
    fragment baseBusinessMeetingFragment on BusinessMeeting {
  id
  orgId
  createdAt
  updatedAt
  account {
    id
    orgId
    createdAt
    updatedAt
    name
  }
  recordDate
  filePath
  title
}
    `;
export const KeyActionEvaluationFragmentFragmentDoc = gql`
    fragment keyActionEvaluationFragment on KeyActionEvaluation {
  id
  orgId
  createdAt
  updatedAt
  score
  recommendedContents {
    id
    isAccessed
    content {
      name
      url
    }
  }
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment userFragment on User {
  id
  orgId
  createdAt
  updatedAt
  name
  email
  departmentName
  thumbnailPath
}
    `;
export const CreateAssessmentDocument = gql`
    mutation CreateAssessment($input: CreateAssessmentInput!) {
  createAssessment(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAssessmentGQL extends Apollo.Mutation<CreateAssessmentMutation, CreateAssessmentMutationVariables> {
    document = CreateAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAssessmentDocument = gql`
    mutation UpdateAssessment($input: UpdateAssessmentInput!) {
  updateAssessment(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAssessmentGQL extends Apollo.Mutation<UpdateAssessmentMutation, UpdateAssessmentMutationVariables> {
    document = UpdateAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkContentAsAccessedDocument = gql`
    mutation markContentAsAccessed($recommendedContentId: String!) {
  markContentAsAccessed(recommendedContentId: $recommendedContentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkContentAsAccessedGQL extends Apollo.Mutation<MarkContentAsAccessedMutation, MarkContentAsAccessedMutationVariables> {
    document = MarkContentAsAccessedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveAssessmentDocument = gql`
    mutation archiveAssessment($assessmentId: String!) {
  archiveAssessment(assessmentId: $assessmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveAssessmentGQL extends Apollo.Mutation<ArchiveAssessmentMutation, ArchiveAssessmentMutationVariables> {
    document = ArchiveAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveAssessmentsDocument = gql`
    mutation ArchiveAssessments($assessmentIds: [String!]!) {
  archiveAssessments(assessmentIds: $assessmentIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveAssessmentsGQL extends Apollo.Mutation<ArchiveAssessmentsMutation, ArchiveAssessmentsMutationVariables> {
    document = ArchiveAssessmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAssessmentDocument = gql`
    query GetAssessment($assessmentId: String!) {
  getAssessment(assessmentId: $assessmentId) {
    ...assessmentFragment
    keyActionEvaluations {
      ...keyActionEvaluationFragment
      evaluation
      highlight
      keyAction {
        id
        orgId
        createdAt
        updatedAt
        name
        orderNo
        assessmentQuestionType
      }
      timeSeriesEvaluations {
        fromTime
        toTime
        score
        reason
        improvement
        strength
        quote
      }
    }
    skillMapName
    businessMeeting {
      ...baseBusinessMeetingFragment
      applicablePhaseNames
      transcript {
        speaker
        speakedAt
        quote
      }
      summary
      reference
    }
    durationSeconds
  }
}
    ${AssessmentFragmentFragmentDoc}
${KeyActionEvaluationFragmentFragmentDoc}
${BaseBusinessMeetingFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAssessmentGQL extends Apollo.Query<GetAssessmentQuery, GetAssessmentQueryVariables> {
    document = GetAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAssessmentsDocument = gql`
    query SearchAssessments($query: String!, $pagination: Pagination!, $status: SearchAssessmentStatus!, $sort: Sort) {
  searchAssessments(
    query: $query
    pagination: $pagination
    status: $status
    sort: $sort
  ) {
    ...assessmentFragment
    keyActionEvaluations {
      ...keyActionEvaluationFragment
    }
    businessMeeting {
      ...baseBusinessMeetingFragment
    }
  }
}
    ${AssessmentFragmentFragmentDoc}
${KeyActionEvaluationFragmentFragmentDoc}
${BaseBusinessMeetingFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAssessmentsGQL extends Apollo.Query<SearchAssessmentsQuery, SearchAssessmentsQueryVariables> {
    document = SearchAssessmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSkillMapsDocument = gql`
    query GetSkillMaps {
  getSkillMaps {
    id
    name
    orgId
    createdAt
    updatedAt
    phases {
      id
      name
      orgId
      createdAt
      updatedAt
      keyActions {
        id
        orgId
        createdAt
        updatedAt
        name
        orderNo
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSkillMapsGQL extends Apollo.Query<GetSkillMapsQuery, GetSkillMapsQueryVariables> {
    document = GetSkillMapsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExportAssessmentsDocument = gql`
    query exportAssessments {
  exportAssessments {
    userName
    userEmail
    departmentName
    reportToName
    businessMeetingTitle
    accountName
    businessMeetingRecordDate
    keyActionName
    score
    evaluation
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportAssessmentsGQL extends Apollo.Query<ExportAssessmentsQuery, ExportAssessmentsQueryVariables> {
    document = ExportAssessmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveAmptalkKeyDocument = gql`
    mutation SaveAmptalkKey($apiKey: String!, $authKey: String!) {
  saveAmptalkKey(apiKey: $apiKey, authKey: $authKey)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveAmptalkKeyGQL extends Apollo.Mutation<SaveAmptalkKeyMutation, SaveAmptalkKeyMutationVariables> {
    document = SaveAmptalkKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveZoomTokenDocument = gql`
    mutation SaveZoomToken($code: String!) {
  saveZoomToken(code: $code)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveZoomTokenGQL extends Apollo.Mutation<SaveZoomTokenMutation, SaveZoomTokenMutationVariables> {
    document = SaveZoomTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveSalesforceTokenDocument = gql`
    mutation SaveSalesforceToken($code: String!) {
  saveSalesforceToken(code: $code)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveSalesforceTokenGQL extends Apollo.Mutation<SaveSalesforceTokenMutation, SaveSalesforceTokenMutationVariables> {
    document = SaveSalesforceTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveAutoAssessmentSettingDocument = gql`
    mutation SaveAutoAssessmentSetting($param: SaveAutoAssessmentSettingParam!) {
  saveAutoAssessmentSetting(param: $param)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveAutoAssessmentSettingGQL extends Apollo.Mutation<SaveAutoAssessmentSettingMutation, SaveAutoAssessmentSettingMutationVariables> {
    document = SaveAutoAssessmentSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveOutboundIntegrationSettingDocument = gql`
    mutation SaveOutboundIntegrationSetting($param: SaveOutboundIntegrationSettingParam!) {
  saveOutboundIntegrationSetting(param: $param)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveOutboundIntegrationSettingGQL extends Apollo.Mutation<SaveOutboundIntegrationSettingMutation, SaveOutboundIntegrationSettingMutationVariables> {
    document = SaveOutboundIntegrationSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisconnectAmptalkDocument = gql`
    mutation DisconnectAmptalk {
  disconnectAmptalk
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisconnectAmptalkGQL extends Apollo.Mutation<DisconnectAmptalkMutation, DisconnectAmptalkMutationVariables> {
    document = DisconnectAmptalkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisconnectZoomDocument = gql`
    mutation DisconnectZoom {
  disconnectZoom
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisconnectZoomGQL extends Apollo.Mutation<DisconnectZoomMutation, DisconnectZoomMutationVariables> {
    document = DisconnectZoomDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisconnectSalesforceDocument = gql`
    mutation DisconnectSalesforce {
  disconnectSalesforce
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisconnectSalesforceGQL extends Apollo.Mutation<DisconnectSalesforceMutation, DisconnectSalesforceMutationVariables> {
    document = DisconnectSalesforceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaskedKeysDocument = gql`
    query GetMaskedKeys($connectService: InboundConnectService!) {
  getMaskedKeys(connectService: $connectService) {
    maskedKey
    keyType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaskedKeysGQL extends Apollo.Query<GetMaskedKeysQuery, GetMaskedKeysQueryVariables> {
    document = GetMaskedKeysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAutoAssessmentSettingDocument = gql`
    query GetAutoAssessmentSetting($connectService: InboundConnectService!) {
  getAutoAssessmentSetting(connectService: $connectService) {
    id
    targetUsers {
      id
      name
      email
      departmentName
      thumbnailPath
      reportTo {
        id
        name
      }
      license {
        id
        plan
        userMonthlyLimitSeconds
        extraChargeLimitSeconds
        usedSeconds
      }
    }
    targetSkillMap {
      id
      name
    }
    targetPhaseIds
    minTransferDurationSeconds
    syncStartDate
    whiteList
    blackList
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAutoAssessmentSettingGQL extends Apollo.Query<GetAutoAssessmentSettingQuery, GetAutoAssessmentSettingQueryVariables> {
    document = GetAutoAssessmentSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOutboundIntegrationSettingDocument = gql`
    query GetOutboundIntegrationSetting($connectService: OutboundConnectService!) {
  getOutboundIntegrationSetting(connectService: $connectService) {
    id
    targetUsers {
      id
      name
      email
      departmentName
      thumbnailPath
      reportTo {
        id
        name
      }
      license {
        id
        plan
        userMonthlyLimitSeconds
        extraChargeLimitSeconds
        usedSeconds
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOutboundIntegrationSettingGQL extends Apollo.Query<GetOutboundIntegrationSettingQuery, GetOutboundIntegrationSettingQueryVariables> {
    document = GetOutboundIntegrationSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckSecretKeyExistenceDocument = gql`
    query CheckSecretKeyExistence($secretKeyType: SecretKeyType!) {
  checkSecretKeyExistence(secretKeyType: $secretKeyType)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckSecretKeyExistenceGQL extends Apollo.Query<CheckSecretKeyExistenceQuery, CheckSecretKeyExistenceQueryVariables> {
    document = CheckSecretKeyExistenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateSalesforceIntegrationSettingDocument = gql`
    query ValidateSalesforceIntegrationSetting {
  validateSalesforceIntegrationSetting {
    isValid
    errors {
      code
      message
      objectName
      fieldName
      expectedType
      actualType
      minLength
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateSalesforceIntegrationSettingGQL extends Apollo.Query<ValidateSalesforceIntegrationSettingQuery, ValidateSalesforceIntegrationSettingQueryVariables> {
    document = ValidateSalesforceIntegrationSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLicensesDocument = gql`
    query GetLicenses($yearMonth: String) {
  getLicenses(yearMonth: $yearMonth) {
    id
    userMonthlyLimitSeconds
    extraCharges {
      limitSeconds
    }
    usedSeconds
    assessmentsCount
    user {
      id
      orgId
      name
      email
      departmentName
      thumbnailPath
      reportTo {
        name
      }
      license {
        plan
        userMonthlyLimitSeconds
        extraChargeLimitSeconds
        usedSeconds
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLicensesGQL extends Apollo.Query<GetLicensesQuery, GetLicensesQueryVariables> {
    document = GetLicensesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignLicenseDocument = gql`
    mutation AssignLicense($userId: String!, $licenseId: String!) {
  assignLicense(userId: $userId, licenseId: $licenseId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignLicenseGQL extends Apollo.Mutation<AssignLicenseMutation, AssignLicenseMutationVariables> {
    document = AssignLicenseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RevokeLicenseDocument = gql`
    mutation RevokeLicense($userId: String!) {
  revokeLicense(userId: $userId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RevokeLicenseGQL extends Apollo.Mutation<RevokeLicenseMutation, RevokeLicenseMutationVariables> {
    document = RevokeLicenseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($param: CreateUserParam!) {
  createUser(param: $param)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BulkCreateUserDocument = gql`
    mutation BulkCreateUser($params: [BulkCreateUserParam!]!) {
  bulkCreateUser(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BulkCreateUserGQL extends Apollo.Mutation<BulkCreateUserMutation, BulkCreateUserMutationVariables> {
    document = BulkCreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($param: UpdateUserParam!) {
  updateUser(param: $param)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUsersDocument = gql`
    mutation DeleteUsers($userIds: [String!]!) {
  deleteUsers(userIds: $userIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUsersGQL extends Apollo.Mutation<DeleteUsersMutation, DeleteUsersMutationVariables> {
    document = DeleteUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendInvitationsDocument = gql`
    mutation SendInvitations($userIds: [String!]!) {
  sendInvitationEmails(userIds: $userIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendInvitationsGQL extends Apollo.Mutation<SendInvitationsMutation, SendInvitationsMutationVariables> {
    document = SendInvitationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePrivacySettingDocument = gql`
    mutation UpdatePrivacySetting($privacySetting: PrivacySetting!) {
  updatePrivacySetting(privacySetting: $privacySetting)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePrivacySettingGQL extends Apollo.Mutation<UpdatePrivacySettingMutation, UpdatePrivacySettingMutationVariables> {
    document = UpdatePrivacySettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRequiredMfaDocument = gql`
    mutation UpdateRequiredMfa($requiredMfa: Boolean!) {
  updateRequiredMfa(requiredMfa: $requiredMfa)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRequiredMfaGQL extends Apollo.Mutation<UpdateRequiredMfaMutation, UpdateRequiredMfaMutationVariables> {
    document = UpdateRequiredMfaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserOneSelfDocument = gql`
    query GetUserOneSelf {
  getUserOneSelf {
    ...userFragment
    roles {
      name
      code
    }
    isManager
    license {
      plan
    }
  }
}
    ${UserFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserOneSelfGQL extends Apollo.Query<GetUserOneSelfQuery, GetUserOneSelfQueryVariables> {
    document = GetUserOneSelfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAccountsDocument = gql`
    query GetAccounts {
  getAccounts {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAccountsGQL extends Apollo.Query<GetAccountsQuery, GetAccountsQueryVariables> {
    document = GetAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchUsersForAdminDocument = gql`
    query SearchUsersForAdmin($query: String!, $departmentNames: [String!]!, $pagination: Pagination, $sort: Sort) {
  getUsers(
    query: $query
    departmentNames: $departmentNames
    pagination: $pagination
    sort: $sort
  ) {
    ...userFragment
    requiredMfa
    reportTo {
      id
      name
      email
    }
    roles {
      name
      code
    }
    license {
      id
      plan
      userMonthlyLimitSeconds
      extraChargeLimitSeconds
      usedSeconds
    }
  }
}
    ${UserFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUsersForAdminGQL extends Apollo.Query<SearchUsersForAdminQuery, SearchUsersForAdminQueryVariables> {
    document = SearchUsersForAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchUsersDocument = gql`
    query SearchUsers($query: String!, $departmentNames: [String!]!, $pagination: Pagination, $sort: Sort) {
  getUsers(
    query: $query
    departmentNames: $departmentNames
    pagination: $pagination
    sort: $sort
  ) {
    ...userFragment
    license {
      id
      plan
      userMonthlyLimitSeconds
      extraChargeLimitSeconds
      usedSeconds
    }
    assessments {
      id
      status
      assessDate
      businessMeeting {
        title
        recordDate
      }
    }
    latestAssessment {
      id
      assessDate
      businessMeeting {
        title
        recordDate
      }
      keyActionEvaluations {
        score
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUsersGQL extends Apollo.Query<SearchUsersQuery, SearchUsersQueryVariables> {
    document = SearchUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserByIdDocument = gql`
    query GetUserById($userId: String!) {
  getUserById(userId: $userId) {
    ...userFragment
    license {
      id
      plan
      userMonthlyLimitSeconds
      extraChargeLimitSeconds
      usedSeconds
    }
    assessments {
      id
      status
      assessDate
      businessMeeting {
        title
        recordDate
      }
    }
    reportTo {
      id
    }
  }
}
    ${UserFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserByIdGQL extends Apollo.Query<GetUserByIdQuery, GetUserByIdQueryVariables> {
    document = GetUserByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAdminOrgDocument = gql`
    query GetAdminOrg {
  getAdminOrg {
    licenseInfo {
      plan
      count
      expiration
      userMonthlyLimitSeconds
      usedCount
      remainingLicenses {
        id
        userMonthlyLimitSeconds
        extraCharges {
          limitSeconds
        }
        usedSeconds
      }
    }
    departmentNames
    privacySetting
    requiredMfa
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdminOrgGQL extends Apollo.Query<GetAdminOrgQuery, GetAdminOrgQueryVariables> {
    document = GetAdminOrgDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWeeklyScoresDocument = gql`
    query GetWeeklyScores($skillMapIds: [String!]!, $startDate: Date!, $endDate: Date!) {
  getAdminOrg {
    name
    periodKeyActionScores(
      startDate: $startDate
      endDate: $endDate
      skillMapIds: $skillMapIds
    ) {
      keyAction {
        id
        name
      }
      weeklyScores {
        weekStartDate
        score
      }
    }
    departments {
      name
      periodKeyActionScores(
        startDate: $startDate
        endDate: $endDate
        skillMapIds: $skillMapIds
      ) {
        keyAction {
          id
          name
        }
        weeklyScores {
          weekStartDate
          score
        }
      }
    }
    users {
      departmentName
      keyActionRecentlyAverageScores(skillMapIds: $skillMapIds) {
        keyAction {
          id
          name
        }
        recentlyAverageScore
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWeeklyScoresGQL extends Apollo.Query<GetWeeklyScoresQuery, GetWeeklyScoresQueryVariables> {
    document = GetWeeklyScoresDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDepartmentNamesDocument = gql`
    query GetDepartmentNames {
  getAdminOrg {
    departmentNames
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDepartmentNamesGQL extends Apollo.Query<GetDepartmentNamesQuery, GetDepartmentNamesQueryVariables> {
    document = GetDepartmentNamesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRolesDocument = gql`
    query GetRoles {
  getRoles {
    name
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRolesGQL extends Apollo.Query<GetRolesQuery, GetRolesQueryVariables> {
    document = GetRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWeeklyUserScoresDocument = gql`
    query GetWeeklyUserScores($userId: String!, $startDate: Date!, $endDate: Date!, $skillMapIds: [String!]!) {
  getUserById(userId: $userId) {
    id
    name
    departmentName
    thumbnailPath
    keyActionRecentlyAverageScores(skillMapIds: $skillMapIds) {
      keyAction {
        id
        name
      }
      recentlyAverageScore
    }
    periodKeyActionScores(
      startDate: $startDate
      endDate: $endDate
      skillMapIds: $skillMapIds
    ) {
      keyAction {
        id
        name
      }
      weeklyScores {
        weekStartDate
        score
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWeeklyUserScoresGQL extends Apollo.Query<GetWeeklyUserScoresQuery, GetWeeklyUserScoresQueryVariables> {
    document = GetWeeklyUserScoresDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckMfaStatusDocument = gql`
    query CheckMfaStatus {
  checkMfaStatus {
    requiredForUser
    phoneNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckMfaStatusGQL extends Apollo.Query<CheckMfaStatusQuery, CheckMfaStatusQueryVariables> {
    document = CheckMfaStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }