<div class="wrapper">
  <div class="container">
    <div class="inbound-integration">
      <div class="page-header">
        <h2 class="heading">インバウンド連携</h2>
      </div>
      <p class="caption">
        外部サービスと接続し、活用した自動的なデータを取得します。
      </p>
      <div class="alert" *ngIf="hasZoomIntegrationError">
        <mat-icon fontIcon="warning_amber" class="icon"></mat-icon>
        <div class="message">
          <span class="strong">zoom連携が切断されました。</span>
          <div>
            接続が切れた可能性があります。認証情報を確認し、再接続してください。
          </div>
        </div>
      </div>
      <div
        class="zoom common-integration"
        *ngIf="this.isZoomIntegrationEnabledTenant()"
      >
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <h2 class="card-heading">Zoomを連携</h2>
              <div class="card-caption">
                Zoomアカウントを連携することで、Zoomレコーディングを元にアセスメントを実施できるようになります。
              </div>
            </div>
            <mat-slide-toggle
              [(ngModel)]="isZoomIntegrationEnabled"
              (change)="onZoomToggleChange($event)"
            ></mat-slide-toggle>
          </div>
          <div class="card-actions" *ngIf="isZoomIntegrationEnabled">
            <button rectangle (click)="openZoomSettingDialog()">
              アセスメント設定
            </button>
          </div>
        </div>
      </div>
      <div class="alert" *ngIf="hasAmptalkIntegrationError">
        <mat-icon fontIcon="warning_amber" class="icon"></mat-icon>
        <div class="message">
          <span class="strong">amptalk連携が切断されました。</span>
          <div>
            APIキーまたは認証キーの有効期限が切れた可能性があります。認証情報を確認し、更新を行ってください。
          </div>
        </div>
      </div>
      <div class="amptalk common-integration">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <h2 class="card-heading">amptalkを連携</h2>
              <div class="card-caption">
                amptalkアカウントを連携することで、コールデータを元にアセスメントを実施できるようになります。
              </div>
            </div>
            <mat-slide-toggle
              [(ngModel)]="isAmptalkIntegrationEnabled"
              (change)="onAmptalkToggleChange($event)"
            ></mat-slide-toggle>
          </div>
          <div class="card-actions" *ngIf="isAmptalkIntegrationEnabled">
            <button rectangle (click)="openAmptalkSettingDialog()">
              アセスメント設定
            </button>
            <button
              rectangle
              (click)="openAmptalkIntegrationDialog()"
              [disabled]="!isAmptalkIntegrationEnabled"
              variant="secondary"
            >
              <mat-icon>sync</mat-icon>
              認証キーを更新
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr class="separator" />
    <div class="outbound-integration">
      <div class="page-header">
        <h2 class="heading">アウトバウンド連携</h2>
      </div>
      <p class="caption">
        AI Sales Coach内のデータを外部サービスに共有します。
      </p>
      <div class="alert" *ngIf="hasSalesforceIntegrationError">
        <mat-icon fontIcon="warning_amber" class="icon"></mat-icon>
        <div class="message">
          <span class="strong">Salesforce連携が切断されました。</span>
          <div>
            接続が切れた可能性があります。認証情報を確認し、再接続してください。
          </div>
        </div>
      </div>
      <div class="salesforce common-integration">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <h2 class="card-heading">Salesforceを連携</h2>
              <div class="card-caption">
                Salesforceアカウントを連携することでSalesforce商談にアセスメント結果やサマリ、進捗予測が表示されます。
              </div>
            </div>
            <mat-slide-toggle
              [(ngModel)]="isSalesforceIntegrationEnabled"
              (change)="onSalesforceToggleChange($event)"
            ></mat-slide-toggle>
          </div>
          <div class="card-actions" *ngIf="isSalesforceIntegrationEnabled">
            <button
              rectangle
              [disabled]="!isSalesforceIntegrationEnabled"
              (click)="validateSalesforceIntegrationSetting()"
            >
              設定検証
            </button>
            <button
              rectangle
              [disabled]="
                !(
                  isSalesforceIntegrationEnabled &&
                  alreadyValidatedSalesforceIntegration
                )
              "
              (click)="openOutboundSettingDialog()"
            >
              連携設定
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading [showLoading]="isLoading"></app-loading>
</div>
