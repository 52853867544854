import { ApolloError } from '@apollo/client/errors'
import { GraphQLErrorExtensions } from 'graphql/error'

interface ErrorParam {
  name?: string
  reportToEmail?: string
  email?: string
}
export enum CustomErrorCode {
  DUPLICATED_USER = 'DUPLICATED_USER',
  MANAGER_RELATION_LOOP = 'MANAGER_RELATION_LOOP',
  FAILED_FETCH_ZOOM_USER = 'FAILED_FETCH_ZOOM_USER',
  BATCH_MANAGER_RELATION_LOOP = 'BATCH_MANAGER_RELATION_LOOP',
  BATCH_LICENSE_NOT_ENOUGH = 'BATCH_LICENSE_NOT_ENOUGH',
  BATCH_TARGET_USER_NOT_FOUND = 'BATCH_TARGET_USER_NOT_FOUND',
  BATCH_MANAGER_NOT_FOUND = 'BATCH_MANAGER_NOT_FOUND',
  BATCH_DUPLICATED_USER = 'BATCH_DUPLICATED_USER',
  BATCH_FIREBASE_DUPLICATED_USER = 'BATCH_FIREBASE_DUPLICATED_USER',
}

export class CustomError extends Error {
  code: CustomErrorCode
  extensions: GraphQLErrorExtensions
  param?: ErrorParam

  constructor(
    code: CustomErrorCode,
    extensions: GraphQLErrorExtensions,
    message: string,
    param?: ErrorParam,
  ) {
    super()
    this.code = code
    this.extensions = extensions
    this.message = message
    this.param = param
  }
}

export const throwIfCustomError = (error: unknown) => {
  if (!error) {
    return
  }

  if (!(error instanceof ApolloError)) {
    return
  }

  if (!error.graphQLErrors.length) {
    return
  }

  const index = error.graphQLErrors.findIndex((err) =>
    Object.values(CustomErrorCode).includes(
      err.extensions?.code as CustomErrorCode,
    ),
  )
  if (index !== -1) {
    const err = error.graphQLErrors[index]
    const extensions = err.extensions as GraphQLErrorExtensions
    throw new CustomError(
      extensions.code as CustomErrorCode,
      extensions,
      err.message,
      extensions.param as ErrorParam,
    )
  } else {
    throw new Error(error.graphQLErrors[0].message)
  }
}
